import React from 'react'
import LanguageTranslator from '../../../locals/LanguageTranslator';
import useRenderClassComponent from '../../../hooks/useRenderClassComponent';
import GenericTableData from '../salesOrder/GenericTableData';
import { getPermissionKey } from '../../../helper/permissions';
import { CONFIG } from '../../../config';

const OrderTrackingProcessedOrders = (props ) => {
    const {generalStore, digivendStore, tableHeadersMap} = props || {};
  return (
    <div>
        <div className="sd-sec">
                    <LanguageTranslator tag="h3" className='tableHeading pl-[30px] pr-[15px] text-fontBlack text-base font-semibold mt-[12px] mb-3 flex items-center gap-3'>Processed Orders <div className='h-[16px] w-[2px] shrink-0 bg-[#b7c6de]'></div></LanguageTranslator>
                    <React.Fragment>
                        {
                            useRenderClassComponent(GenericTableData, {
                                actionBarConfig: {
                                    quickFilter: {
                                        disabled: generalStore?.getQuickFiltersData?.isLoading
                                    },
                                    filters: {
                                        disabled: generalStore?.getHeadersData?.isLoading,
                                    },
                                    search : true,
                                    downloadReport: {
                                        headerLevelModule: tableHeadersMap?.main?.key,
                                        tableHeadersMap: tableHeadersMap,
                                        api : {
                                            payload : (payload) => {
                                                console.log("check payload", payload);
                                                payload.data.excelData = true;
                                                return {
                                                    ...payload,
                                                    apiUrl : "/admin/download/module/data/export",
                                                    baseUrl : CONFIG.SM_BACKEND_BASE_URL,
                                                }
                                            }
                                        }
                                    },
                                },
                                tableConfig: {
                                    pagination : true
                                    // toggleitem: {
                                    //     render: toggleItemsButtons,
                                    // },
                                },
                                tableHeadersPayload: tableHeadersMap,
                                quickFiltersPayload: {
                                    attributeType: "VENDOR_PROCESSED_ORDER",
                                    displayName: "VENDOR_PROCESSED_ORDER",
                                    filterName: ""
                                },
                                tableComExtraHeaders : getPermissionKey("dashboard", "ordertracking", "view"),
                                tableDataPayload: {
                                    "displayName": "VENDOR_PROCESSED_ORDER",
                                    "pageNo": 1,
                                    "pageSize": 10,
                                    "sortedBy": "",
                                    "sortedIn": "",
                                    "search": "",
                                    "filter": {},
                                },
                                getTableDataRequest: 'getOrderTrackingProcessedOrderRequest',
                                getTableDataLoading: digivendStore?.getOrderTrackingProcessedOrderData.isLoading,
                                getTableDataSuccess: digivendStore?.getOrderTrackingProcessedOrderData?.isSuccess,
                                getTableDataError: digivendStore?.getOrderTrackingProcessedOrderData?.isError,
        
                                tableData: {
                                    data: digivendStore?.getOrderTrackingProcessedOrderData?.data?.resource || [],
                                    totalItems: digivendStore?.getOrderTrackingProcessedOrderData?.data?.totalRecord || 0,
                                    currentPage: digivendStore?.getOrderTrackingProcessedOrderData?.data?.currPage || 1,
                                    maxPage: digivendStore?.getOrderTrackingProcessedOrderData?.data?.maxPage || 1
                                },
        
                                generalStore: generalStore,
                                digivendStore : digivendStore,
                                // refreshData: digivendStore?.getOrderTrackingProcessedOrderData?.isSuccess
                            },
                                ["generalStore", "digivendStore"],
                            )}
                    </React.Fragment>
                </div>
    </div>
  )
}

export default OrderTrackingProcessedOrders
