import React from 'react'
import { Tooltip } from 'antd';

const GenericStatusComponent = (props) => {

    const { status, onClick, className = "" } = props;

    const styleName = {
        "APPROVED" : "label-success",
        "PENDING" : "label-pending",
        "FAILED" : "label-failed",
        "SUCCEEDED" : "label-success",
        "ACTIVE" : "label-success",
        "INACTIVE" : "label-failed",
        "HOLD": "label-pending",
        "PASS": "label-success",
        "COMPLETED": "label-success",
        "ERROR": "label-failed",
        "SUCCESS" : "label-success",
    }

    return (
        <div data-interactive="true" onClick={() => onClick && props.onClick(status)}>
            <Tooltip 
                overlayInnerStyle={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    color: 'black',
                    fontSize: '12px'
                }} 
                overlayClassName="!p-[0]"
                title={status?.toString().length >= 23 ? status : null}
                arrow={true}
                >
                    <label className={`${styleName?.[status?.toUpperCase()] || "label-default"} ${className}`}>{status?.toUpperCase()}</label>
            </Tooltip>
        </div>
    )
}

export default GenericStatusComponent