import React, { useEffect, useState } from 'react'
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import Icons from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import SitesSectionSkeleton from './SitesSectionSkeleton';
import NoDataFound from '../../../genericComponents/NoDataFound';
import { Radio } from 'antd';
import { useHistory } from 'react-router-dom'
import { whHeadersData } from './WhHeadersData';
import WhDetailSkeletonLoader from './WhDetailSkeletonLoader';
import dayjs from 'dayjs';
import QuickFilter from '../components/QuickFilter';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';
import moment from 'moment';

const filterPayload = {
    attributeType: "WAREHOUSE_APPOINTMENT_FILTER",
    displayName: "WAREHOUSE_APPOINTMENT_FILTER",
    filterName: ""
};

const WarehouseAppointmentDashboard = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const digivendStore = useSelector(state => state.digivendStore)
    const getWhDashboardData = useSelector(state => state?.digivendStore?.getWhDashboardData || {});
    const whDashboardDetailData = useSelector(state => state?.digivendStore?.whDashboardDetailData);

    const [PRIMARY_FILTERS, SET_PRIMARY_FILTERS] = useState([
        {
            label: "Site",
            key: "siteName",
            code: "siteCode",
            uniqueKey: 'siteCode'
        },
        {
            label: "Vendor",
            key: "slname",
            code: "slcode",
            entity: "vendor",
            uniqueKey: 'slcode'
        },
        {
            label: 'HL1 Name',
            key: 'hl1_name',
            code: '',
            entity: "item",
            uniqueKey: 'hl1_name'
        }
    ])

    // State: To store the expanded site data;
    const [expandedSiteData, setExpandedSiteData] = useState("");

    const [errorData, setErrorData] = useState({})
    const [inputData, setInputData] = useState({
        lr_eta_date: '',
        hl1_name: {},
        slname: {},
        siteName: {}
    })
    const [sitesData, setSitesData] = useState([]);
    const [detailsData, setDetailsData] = useState([]);
    const [currentDetailFilter, setCurrentDetailFilter] = useState("");

    const [updatedKey, setUpdatedKey] = useState("lr_eta_date");

    let levelKey = "hl1_name"

    // Handle the response of the warehouse sites api
    useEffect(() => {
        if (getWhDashboardData?.isSuccess) {
            let data = getWhDashboardData?.data?.resource;
            setSitesData(data?.data || []);
            // Update the primary filters according to level name set in the appointment configuration
            if (data?.levelName !== PRIMARY_FILTERS?.[2]?.key) {

                // Update the filter data in case the levelName is changed
                if (Object.keys(inputData?.[PRIMARY_FILTERS?.[2]?.key] || {})?.length > 0) {
                    let updatedInputData = { ...inputData };
                    delete updatedInputData?.[PRIMARY_FILTERS?.[2]?.key];
                    setInputData(updatedInputData);
                }

                let labelData = {
                    "hl1_name": "HL1 Name",
                    "hl2_name": "HL2 Name",
                    "hl3_name": "HL3 Name",
                }
                let updatedPrimaryFilters = [...PRIMARY_FILTERS];
                updatedPrimaryFilters[2] = {
                    label: labelData?.[data?.levelName] || "",
                    key: data?.levelName || "",
                    code: "",
                    entity: "item",
                    uniqueKey: data?.levelName || ""
                }
                levelKey = data?.levelName;
                SET_PRIMARY_FILTERS(updatedPrimaryFilters || []);
            }
        }
    }, [getWhDashboardData?.isSuccess]);

    useEffect(() => {
        if (whDashboardDetailData?.isSuccess) {
            setDetailsData(whDashboardDetailData?.data?.resource?.data || []);
        }
    }, [whDashboardDetailData?.isSuccess])

    const filterItems = {
        "Default Headers": {
            lr_eta_date: "Date From",
            site_code: "Site Code",
            slname: "Vendor",
            siteName: "Site Name",
            hl1_name: "HL1 Name",
            hl2_name: "HL2 Name",
            hl3_name: "HL3 Name"
        }
        ,
        "columnType": {
            lr_eta_date: "TEXT",
            site_code: "SEARCH",
            slname: "SEARCH",
            siteName: "SEARCH",
            hl1_name: "SEARCH",
            hl2_name: "SEARCH",
            hl3_name: "SEARCH"
        },
    }

    // Function : To get the current filters data to save the filters and send it inside the payload
    const getCurrentFilters = (keys = ["slname", "hl1_name", "lr_eta_date", "siteName"], config = {
        isSiteNameRequired: false
    }) => {
        let filtersData = {};
        keys?.map(key => {
            if (
                Array?.isArray(inputData?.[key]) ? inputData?.[key]?.length > 0 : typeof (inputData?.[key]) == "object" ? Object.keys(inputData?.[key] || {})?.length > 0 : inputData?.[key]
            ) {
                switch (key) {
                    case "lr_eta_date": {
                        filtersData[key] = dayjs(inputData?.["lr_eta_date"])?.format("YYYY-MM-DD")
                        break;
                    }
                    case "siteName": {
                        filtersData["site_code"] = Object?.keys(inputData?.["siteName"] || {})?.join(',')
                        if (config?.isSiteNameRequired) {
                            filtersData["siteName"] = Object?.keys(inputData?.["siteName"] || {})?.map(key =>
                                inputData?.["siteName"]?.[key]?.["siteName"]
                            )?.join(',');
                        }
                        break;
                    }
                    default: {
                        filtersData[key] = Object?.keys(inputData?.[key] || {})?.join(',');
                        break;
                    }
                }
            }
        })
        return filtersData;
    }

    useEffect(() => {
        if (updatedKey) {
            // Call APIs based on the changed input field
            switch (updatedKey) {
                case 'lr_eta_date':
                case 'siteName':
                    getWhSites();
                    break;
                case 'slname':
                case 'hl1_name':
                case 'hl2_name':
                case 'hl3_name':
                    getWhDetailData();
                    break;
                default:
                    break;
            }
            setUpdatedKey("");
        }
    }, [updatedKey])

    const handleChange = (key, value) => {
        setInputData(prevInputData => ({
            ...prevInputData,
            [key]: value
        }));

        setUpdatedKey(key);
    };

    useEffect(() => {
        if (currentDetailFilter) {
            getWhDetailData();
        }
    }, [currentDetailFilter])

    useEffect(() => {
        setCurrentDetailFilter("item");
    }, [expandedSiteData])

    useEffect(() => {
        if (sitesData?.length > 0) {
            setCurrentDetailFilter(prev => "");
            setExpandedSiteData(sitesData?.[0]);
        }
    }, [sitesData])

    // Function : To get the warehouse sites data
    const getWhSites = () => {
        setSitesData([]);
        setExpandedSiteData(false);
        setDetailsData([]);
        let filters = getCurrentFilters(["lr_eta_date", "siteName"]);
        dispatchHandler(dispatch, 'getWhDashboardRequest', {
            "dateFrom": filters?.["lr_eta_date"] || undefined,
            "site_code": filters?.["site_code"] || undefined
        }, getPermissionKey("dashboard", "warehouseappointment", "view"));
    }

    // Function : To get the details data
    const getWhDetailData = () => {
        setDetailsData([]);
        let filtersData = getCurrentFilters(["lr_eta_date", ...PRIMARY_FILTERS?.map(item => item?.key)]);
        dispatchHandler(dispatch, 'whDashboardDetailRequest', {
            lr_eta_date: expandedSiteData?.lr_eta_date || filtersData?.["lr_eta_date"] || undefined,
            site_code: filtersData?.["site_code"] || expandedSiteData?.site_code || undefined,
            type: currentDetailFilter === 'vendor' ? "VENDOR" : undefined,
            vendor_code: filtersData?.["slname"] || undefined,
            level_name: filtersData?.[levelKey] || undefined,
        }, getPermissionKey("dashboard", "warehouseappointment", "view"));
        console.log('filtersdatata56575', filtersData);
    }

    // function to get the payloads
    const getPayloads = (key, params, item) => {
        let payload = {}
        switch (key) {
            case 'siteCode':
                payload = {
                    key: "siteName",
                    code: "siteCode",
                    config: "ADD_APPOINTMENT_WH_SITES"
                }
                break;
            case 'slcode':
            case 'hl1_name':
                payload = {
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    pageNo: params?.currentPage || 1,
                    moduleName: "",
                    other_data: {},
                    columnName: {},
                    code: item?.code,
                    entity: item?.entity,
                    key: item?.key,
                }
                break

            default: return payload
        }
        return payload
    }

    const provideInputs = () => {
        let inputFieldsData = [
            {
                key: "lr_eta_date", type: "date", placeholder: 'Date From',
                onChange: (data) => handleChange("lr_eta_date", data?.format('YYYY-MM-DD')),
                value: inputData?.lr_eta_date
            },]
        inputFieldsData.push(...PRIMARY_FILTERS.map((config) => {
            return {
                key: config?.key,
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `Select ${config?.label}`,
                type: 'dropdown',
                value: inputData?.[config?.key],
                onChange: (data) => handleChange(config?.key, data),
                inputProps: () => ({
                    viewDataType: "table-view",
                    api: {
                        reduxState: config?.uniqueKey === 'siteCode' ? 'digivendStore' : 'generalStore',
                        apiName: config?.uniqueKey === 'siteCode' ? "getDashboardSiteFilter" : 'getSearchFilters',
                        apiPayload: (params) => getPayloads(config?.uniqueKey, params, config),
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                        apiExtraHeaders : getPermissionKey("common", "commonfunctionality", "view")
                    },
                    getListData: (response) => {
                        switch (config.uniqueKey) {
                            case "siteCode": {
                                return response?.resource?.data?.map(item => ({ [config.key?.replace("", "")]: item[config.key], ...item }))
                            }
                            default: return response?.resource?.searchResultNew?.map(data => ({ [config.key]: data[config.key], ...data }))
                        }
                    },
                    headerKeys: {
                        multiSelectCol: {},
                        [config.key]: { value: [config.label] },
                        ...config?.uniqueKey !== 'hl1_name' && { [config.code]: { value: 'Code' } },
                    },
                    itemKeys: {
                        [config.key]: [config.key],
                        ...config?.uniqueKey !== 'hl1_name' && { [config.code]: [config.code] }
                    },
                    multiselect: {
                        selectId: config?.uniqueKey,
                    },
                }),

            }
        }))
        return inputFieldsData
    }

    console.log(56565656, inputData?.siteName?.[Object.keys(inputData?.siteName || {})?.[0]]?.siteName);

    const renderPrimaryFilters = () => {
        return (
            <GenericFormComponent
                rowInput={{
                    rowType: "singleRow",
                    availableInputs: provideInputs(),
                    inputState: { ...inputData },
                    inputStateChangeFunction: (data) => {
                        setInputData(data);
                    },
                    errorData: { ...errorData },
                    updateErrorData: (data) => {
                        setErrorData(data);
                    },
                    rowRightExtras: <React.Fragment>
                        <div className='flex justify-end'>
                            <GenericCustomButton
                                label="Clear All"
                                type="default"
                                onClick={() => setInputData({})}
                                disabled={() => digivendStore?.getWhDashboardData?.isLoading ||
                                    Object.keys(inputData || {})?.length === 0}
                            />
                        </div>
                    </React.Fragment>,
                }}
                availableDocument={props.availableDocument || null}
            />
        )
    }

    console.log('expanded site datatat', expandedSiteData);

    const renderLeftSection = () => {
        if (getWhDashboardData?.isLoading) {
            return <SitesSectionSkeleton />
        }
        if (sitesData?.length == 0) {
            return <NoDataFound primaryText="No Sites Found!" />
        }

        return <>
            {
                sitesData?.map(site => (
                    <button className={expandedSiteData && expandedSiteData?.lr_eta_date === site?.lr_eta_date && "sel-btn"} onClick={() => {
                        if (site?.lr_eta_date != expandedSiteData?.lr_eta_date) {
                            setCurrentDetailFilter(prev => "");
                            setExpandedSiteData(site)
                        }
                    }}> 
                        <div className='btn-top'>
                            <h3>{site?.["lr_eta_date"] ? moment(site?.["lr_eta_date"], "YYYY-MM-DD")?.format("DD MMM YYYY") : ""}</h3>
                            <div className={`range-per ${site?.contribution <= 0 ? 'non-fil' : site?.contribution <= 50 ? 'slow-fil' : site?.contribution <= 80 ? 'fast-fil' : site?.contribution >= 81 ? 'cap-fil' : ''}`}>
                                <div className='pro-bar'>
                                    <LanguageTranslator tag="span">Percentage</LanguageTranslator>
                                    <span className='per-num n-color'>{site?.contribution && site?.contribution}%</span>
                                </div>
                                <progress value={site?.contribution && site?.contribution} max="100">{site?.contribution && site?.contribution}% </progress>
                            </div>
                        </div>
                        <div className='btn-bot'>
                            <div className='btnb'>
                                <LanguageTranslator tag="p">Defined Capacity</LanguageTranslator>
                                <LanguageTranslator tag="label">{site?.capacity || "0"}</LanguageTranslator>
                            </div>
                            <div className='btnb'>
                                <LanguageTranslator tag="p">Utilization</LanguageTranslator>
                                <LanguageTranslator tag="label">{site?.requested_capacity || "0"}</LanguageTranslator>
                            </div>
                            <div className='btnb'>
                                <LanguageTranslator tag="p">Open Capacity</LanguageTranslator>
                                <LanguageTranslator tag="label">{site?.open_capacity || "0"}</LanguageTranslator>
                            </div>
                        </div>
                    </button>
                ))
            }
        </>
    }

    const renderRightSection = () => {
        let headersData = whHeadersData?.[currentDetailFilter] || {};

        if (whDashboardDetailData?.isLoading) {
            return <WhDetailSkeletonLoader />
        }

        console.log(34343434, expandedSiteData)

        if (expandedSiteData) {
            return (
                <>
                    <div className='wabr-top'>
                        <div className='wabrt-left'>
                            <div className='wabrtl m-rgt-10'>
                                <LanguageTranslator tag="label">Showing Data For</LanguageTranslator>
                                <LanguageTranslator tag="p">{expandedSiteData?.["lr_eta_date"] ? moment(expandedSiteData?.["lr_eta_date"], "YYYY-MM-DD")?.format("DD MMM YYYY")  : ""}</LanguageTranslator>
                            </div>
                            <div className='wabrtl m-rgt-10'>
                                <LanguageTranslator tag="label">Site Name</LanguageTranslator>
                                <LanguageTranslator tag="p">{inputData?.siteName?.[Object.keys(inputData?.siteName || {})?.[0]]?.siteName || "-"}</LanguageTranslator>
                            </div>
                            <div className='wabrtl m-rgt-10'>
                                <label>Site Code</label>
                                <LanguageTranslator tag="p">{expandedSiteData?.["site_code"] || ""}</LanguageTranslator>
                            </div>
                            <div className='wabrtl m-rgt-10'>
                                <LanguageTranslator tag="label">Pending Capacity</LanguageTranslator>
                                <LanguageTranslator tag="p">{whDashboardDetailData?.data?.resource?.aptPendingQty || 0}</LanguageTranslator>
                            </div>
                            {whDashboardDetailData?.data?.resource !== "" && whDashboardDetailData?.data?.resource?.aptPendingCount > 0 && <div className='wh-show-data m-lft-15'>
                                <LanguageTranslator tag="p">Pending Appointment</LanguageTranslator>
                                <button type='button' onClick={() => history.push(
                                    {
                                        pathname: '/enterprise/logistics/lrProcessing',
                                        search: `?filter=%257B%2522aptDate%2522%253A%257B%2522from%2522%253A%2522${dayjs(expandedSiteData?.["lr_eta_date"])?.format('YYYY-MM-DD')}%2522%252C%2522to%2522%253A%2522${expandedSiteData?.["lr_eta_date"] && dayjs(expandedSiteData?.["lr_eta_date"])?.format('YYYY-MM-DD')}%2522%257D%257D&status=%2522SHIPMENT_APPT_PENDING%2522`,
                                    })}>{whDashboardDetailData?.data?.resource !== "" && whDashboardDetailData?.data?.resource?.aptPendingCount}
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.39904 0.499646C5.17164 0.272253 4.80297 0.272253 4.57557 0.499646C4.34818 0.727039 4.34818 1.09572 4.57557 1.32311L7.6575 4.40503H0.911377C0.589795 4.40503 0.329102 4.66572 0.329102 4.98731C0.329102 5.30889 0.589795 5.56958 0.911377 5.56958H7.6575L4.57557 8.6515C4.34818 8.87889 4.34818 9.24757 4.57557 9.47496C4.80297 9.70236 5.17164 9.70236 5.39904 9.47496L9.47496 5.39904C9.70236 5.17164 9.70236 4.80297 9.47496 4.57557L5.39904 0.499646Z" fill="white" />
                                    </svg>
                                </button>
                            </div>}
                        </div>

                        <Radio.Group value={currentDetailFilter} onChange={(e) => setCurrentDetailFilter(e?.target?.value || "")}>
                            <Radio.Button value={"item"}><LanguageTranslator>Items</LanguageTranslator></Radio.Button>
                            <Radio.Button value={"vendor"}><LanguageTranslator>Vendors</LanguageTranslator></Radio.Button>
                        </Radio.Group>
                    </div>

                    {/* Render the detail table */}
                    <table>
                        <thead>
                            <tr>
                                {Object.values(headersData || {})?.map((headerValue, headerIndex) => (
                                    <th key={headerIndex?.toString()}>{headerValue || ""}</th>
                                ))}
                                {currentDetailFilter === 'item' && <th>
                                    Trends
                                    <p>(Avg. of last 7 days)</p>
                                </th>}
                            </tr>
                        </thead>
                        {detailsData?.length == 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="100%">
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {
                                    detailsData?.map((item, index) => (
                                        <tr key={index?.toString()}>
                                            {Object.keys(headersData || {})?.map((headerKey, headerIndex) => (
                                                <td key={headerIndex?.toString()}>{item?.[headerKey] || ""}</td>
                                            ))}
                                            {currentDetailFilter === 'item' &&
                                                <td>
                                                    {item?.qty || item?.capacity ? <button className={(Number(item?.qty) * 100) / Number(item?.capacity) <= 0 ? 'non-fill' : (Number(item?.qty) * 100) / Number(item?.capacity) <= 50 ? 'slow-fill' : (Number(item?.qty) * 100) / Number(item?.capacity) <= 80 ? 'fast-fill' : (Number(item?.qty) * 100) / Number(item?.capacity) > 80 ? 'cap-fill' : ''}>
                                                        <progress value={(Number(item?.qty) * 100) / Number(item?.capacity)} max="100"> </progress> {(Number(item?.qty) * 100) / Number(item?.capacity) <= 0 ? 'Non Filling' : (Number(item?.qty) * 100) / Number(item?.capacity) <= 50 ? 'Slow Filling' : (Number(item?.qty) * 100) / Number(item?.capacity) <= 80 ? 'Fast Filling' : (Number(item?.qty) * 100) / Number(item?.capacity) <= 99 ? 'Almost Full' : (Number(item?.qty) * 100) / Number(item?.capacity) >= 99 ? 'Out of Capacity' : ''}
                                                    </button> : null}
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>}
                    </table>
                </>
            )
        }
    }

    // this is used to set the input values of the quick filters 
    const convertFilterToInput = (filters) => {
        let initialData = {};
        Object.keys(filters || {})?.map(key => {
            switch (key) {
                case "hl1_name":
                case "hl2_name":
                case "hl3_name": {
                    let obj = {};
                    filters?.[key]?.split(',')?.map(item => {
                        obj[item] = {
                            [key]: item,
                        }
                    })
                    initialData[key] = obj;
                    break;
                }
                case "slname": {
                    let obj = {};
                    filters?.[key]?.split(',')?.map(item => {
                        obj[item] = {
                            "slcode": item,
                            "slname": ""
                        }
                    })
                    initialData["slname"] = obj;
                    break;
                }
                case "siteName": {
                    let obj = {};
                    let siteCode = filters?.["site_code"];
                    filters?.[key]?.split(',')?.map((item, index) => {
                        obj[siteCode] = {
                            "siteName": item,
                            "siteCode": siteCode
                        }
                    })
                    initialData["siteName"] = obj;
                    break;
                }
                case "lr_eta_date": {
                    initialData[key] = dayjs(filters?.[key])
                    break;
                }
                default:
            }
        })

        return initialData;
    };

    const applyFilter = (filters) => {
        let initialData = convertFilterToInput(filters);
        console.log("Initial Data", initialData, filters);
        setInputData(initialData)
        setUpdatedKey("lr_eta_date");
    }

    console.log('inputData', inputData);

    return (
        <div className='warehouse-appointment'>
            <div className="all-filter-dashboard">
                {renderPrimaryFilters()}

                <QuickFilter
                    applyFilter={applyFilter}
                    filterPayload={filterPayload}
                    getCurrentFilters={() => getCurrentFilters(["lr_eta_date", ...PRIMARY_FILTERS?.map(item => item?.key)], {
                        isSiteNameRequired: true
                    })}
                    filterItems={filterItems}
                />
            </div>
            <div className='wa-bot'>
                <div className='wab-left'>
                    {renderLeftSection()}
                </div>
                <div className='wab-right'>
                    {renderRightSection()}
                </div>
            </div>
        </div>
    )
}

export default WarehouseAppointmentDashboard