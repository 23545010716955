import { generateGlobalActions } from '../redux/StateData/apiFunction'
import { openDB } from 'idb';

const allActions = generateGlobalActions()

export async function dispatchHandler(dispatch, api, payload, headers) {
    payload ? allActions[api] &&
         await dispatch(allActions[api](
            ["getHeadersRequest", "getQuickFiltersRequest", "getDropdownRequest", "getDocumentDropdownRequest", "getItemFilterForDisplayNameRequest"]?.includes(api) ? {
            ...payload,
            dispatch: dispatch,
            apiReqHeaders : headers
         } : {
            payload: payload || "",
            apiReqHeaders : headers || ""
        })) : 
        allActions[api] && await dispatch(allActions[api](["getHeadersRequest", "getQuickFiltersRequest", "getDropdownRequest", "getDocumentDropdownRequest", "getItemFilterForDisplayNameRequest"]?.includes(api)? {dispatch : dispatch, apiReqHeaders : headers} : {apiReqHeaders : headers}))
}

export function getTokenData() {
    return sessionStorage.getItem("token") || null;
}

// Function to return string if string is not a valid JSON
export function parseJSON(string) {
    let parsedJSON = "";
    try {
        parsedJSON = JSON.parse(string);
        return parsedJSON;
    }
    catch (e) {
        return string;
    }
}

// function to check the validation for the number & affter the decimal it will take only 3 digits
export const validateNumber = (input, fieldType, type = "number", decimalPoint = 4) => {
    input = input?.toString();
    if (input?.trim() !== '') {
        const numberRegex = /^[0-9]+$/;
        const decimalRegex = new RegExp(`^[0-9]+(\.[0-9]{1,${decimalPoint}})?$`);
        if (type == "decimal") {
            if (!decimalRegex.test(input)) {
                return [`Please provide valid number format upto ${decimalPoint} decimals!`]
            }
        } else {
            if (!numberRegex.test(input)) {
                return ["Please Provide a Whole Number and Non Decimal Value!"];
            }
        }
        if (!numberRegex.test(input) && !decimalRegex.test(input)) {
            return ["Please provide valid number format!"]
        }
    }

    return null;
};

export const validateEmail = (val) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val);
}

export const validateMobileNumber = (val) => {
    return /^(\+?[0-9]{1,4})?[-.\s]?([1-9][0-9]{6,14})$/.test(val);
}

export const validateMobileNumberWithoutCode = (val) => {
    return /^(\+91[\-\s]?)?[0]?(91)?[1-9]\d{9}$/.test(val)
}

export const handleApiChange = async (dispatch, apiName, apiPayload, headers) => {
    const db = await openDB("Supplymint-ai-local-storage", 1);
    // eslint-disable-next-line default-case
    switch (apiName) {
        case "getHeadersRequest": {
            let masterDbResponse = apiPayload?.displayName ? await db.get("MASTER_DB_HEADER", apiPayload?.displayName) || {} : {}
            apiPayload = { ...apiPayload,  masterDbResponse : masterDbResponse ?? null };
            break;
        }
        // eslint-disable-next-line no-fallthrough
        case "getQuickFiltersRequest": {
            let masterDbResponse =  apiPayload?.displayName ?  await db.get("MASTER_DB_FILTER", apiPayload?.displayName) || [] : []
            apiPayload = { ...apiPayload,  masterDbResponse : masterDbResponse ?? null };
            break;
        }
        case "getDropdownRequest": {
            let masterDbResponse =  apiPayload?.displayName ?  await db.get("MASTER_DB_DROPDOWN", apiPayload?.type) || [] : []
            apiPayload = { ...apiPayload, masterDbResponse : masterDbResponse ?? null };
            break;
        }
        case "getDocumentDropdownRequest":{
            let masterDbResponse =  apiPayload?.displayName ? await db.get("MASTER_DB_DOWNLOAD_DOCUMENTS", apiPayload?.displayName) || {} : {};
            apiPayload = { ...apiPayload, masterDbResponse : masterDbResponse ?? null };
            break;
        }
        case "getItemFilterForDisplayNameRequest":{
            let masterDbResponse = await db.get("MASTER_DB_ITEM_FILTER", apiPayload?.displayName) || {};
            apiPayload = { ...apiPayload, masterDbResponse : masterDbResponse?? null };
            break;
        }
    }

    db?.close();
    dispatchHandler(dispatch, apiName, apiPayload, headers);
}

export const capitalizeFirstLetter = (str) => {
    // Convert the first letter to uppercase
    const firstLetter = str.charAt(0).toUpperCase();
    // Convert the rest of the string to lowercase
    const restOfString = str.slice(1).toLowerCase();
    // Concatenate the first letter and the rest of the string
    return firstLetter + restOfString;
  }

