import React from 'react';
import { changeKey, getFileNameFromExt, getFileTypeFromUrl, sendButtonClickEventToGoogleAnalytics } from '../../helper/helperTableFunctions';
import ColumnSettingModal from "./ColumnSettingModal";
import ConfirmationModal from "./ConfirmationModal";
import '../../styles/stylesheets/TableComponent.scss'
import '../../styles/stylesheets/helper.scss'
import CustomSearch from './tableHelperComponents/CustomSearch';
import EmailScheduler from './emailGenericComponents/EmailScheduler';
import ExpandedRow from "./tableHelperComponents/ExpandedRow";
// import SortingModal from "./tableHelperComponents/SortingModal";
import TableLoader from "./tableHelperComponents/TableLoader";
import DownloadReport from './tableHelperComponents/DownloadReport';
import ImageDetailsTracking from './tableHelperComponents/ImageDetailsTracking'
import RightClickModal from './RightClickModal';
import ReactDragListView from 'react-drag-listview';
import BraedCrumps from '../BraedCrumps'
import TableMainLevelFilters from './TableMainLevelFilters';
import SaveFilter from './tableHelperComponents/SaveFilterModal';
import NewTableLoader from './tableHelperComponents/TableSkeletonLoader';
import ImgPath from '../../assets/images'
import IconsPath from '../../assets/icons/index'
import DocumentAuditLogs from './tableHelperComponents/auditLogs/DocumentAuditLogs';
import { getTokenData } from '../../helper/apiHelperFunctions';
import { parseJwt } from '../../helper/genericFunction';
import { setPayloadAsURLParams, getPayloadFromURLParams } from '../../helper/URLParamsHelper';
import { getNumberOfStrips } from '../../initialPhaseOperations/InitialAnnouncementModal';
import GenericCustomButton from '../GenericCustomButton';
import AppliedFiltersPopUp from '../AppliedFiltersPopUp';
import TableColumnHOC from './tableHelperComponents/tablePopovers/TableColumnHOC';
import SalesCard from '../../components/salesOrder/salesOrderComponents/SalesCard';
import '../../styles/stylesheets/SalesManagement/ProductCatalogue.scss'
import TableComponentSorting from './tableHelperComponents/sorting/TableComponentSorting';
import Icons from '../../assets/icons/index';
import { Tooltip } from 'antd';
import { ListFilter, RefreshCcw } from 'lucide-react';
import NoDataFound from '../NoDataFound';
import CommentTableComponent from './tableHelperComponents/comments/CommentTableComponent';
import LanguageTranslator from '../../locals/LanguageTranslator';
import { Drawer } from 'antd';
import CommentBoxModalWrapper from './tableHelperComponents/comments/CommentBoxModalWrapper';
import { getPermissionKey } from '../../helper/permissions';

import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import CustomSearchPopover from './tableHelperComponents/CustomSearchPopover';
import Lottie from 'lottie-react';
import RightArrowImageComponent from './RightArrowImageComponent';
import {motion} from "framer-motion";
import GenericMediaViewModal from '../media/GenericMediaViewModal';
import ParentModal from '../ParentModal';

export default class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.jumpInputRef = React.createRef();
        this.state = {
            getTableDataSuccess: false,
            getTableDataError: false,
            getTableHeadersSuccess: false,
            getTableHeadersError: false,

            tableDataPayload: this.props.tableDataPayload,
            tableData: this.props.tableData.data,
            currentPage: this.props.tableData.currentPage,
            maxPage: this.props.tableData.maxPage,
            pageNumber: this.props.tableData.currentPage,
            totalItems: this.props.tableData.totalItems,
            mvUpdateDate: this.props.tableData.mvUpdateDate,
            getTableApiData: this.props.tableData.getTableApiData,
            selectedItems: {},

            headersType: {
                main: "Default Headers"
            },
            headersData: {
                main: {}
            },
            customSearchApplied: false,
            customSearchBy: this.props.actionBarConfig.search && this.props.actionBarConfig.search.customSearch ? Object.keys(this.props.actionBarConfig.search.customSearch)[0] : "",
            customSearchData: [],
            quickFilterData: [],
            uppinnedFilter: [],
            applyDefaultFilter: true,
            openChats: [],

            customSearchModal: false,
            quickFilterDropdown: false,
            quickFilterSettings: false,
            documentsDropdown: false,
            emailModal: false,
            downloadReportModal: false,
            // sortingModal: false,
            filterModal: false,
            moreMenuDropdown: false,
            columnSettingModal: false,
            commentModal: null,

            confirmationType: "",
            headMsg: "",
            paraMsg: "",
            rightClickMenu: false,
            selectedColumn: "",
            downloadReport: false,
            customHeaders: false,
            completeHeadersData: {},

            reportFilter: {},
            imageTrackingModal: false,
            imageTrackingData: null,
            loading: false,
            imageVendor: "",
            imageOrderNumber: "",
            imageTrackingNumberType: this.props.imageOrderNumber,
            selectedRowId: "",
            leftPosition: 0,
            topPosition: 0,
            bottomPosition: 0,
            rightPosition: 0,
            successMessage: false,
            selectPage: false,
            tdFixActionClass: this.props.tableConfig.tdFixActionClass || '',
            userminWidth: this.props.tableConfig.userminWidth || '',
            eyeClass: this.props.tableConfig.eyeClass || "",
            saveFilterData: {},
            saveFilterModal: false,
            enableJumpPage: false,
            braedCrumps: this.props.braedCrumps !== undefined ? this.props.braedCrumps : true,
            expandInput: false,
            qfDropdown: false,
            threeDotPin: false,
            pinedQuickFilter: [],
            appliedQFActive: -1,
            threeDotPinIndex: false,
            searchQfvalue: '',
            openDocumentLog: false,
            // State : To store the download documents dropdown data configuration.
            downloadDocumentsConfigurationObject: {},
            showAppliedFilters: false,
            isAppliedFilterModalOpen: false,
            // State : To check for the filter modal is open or not for the grid view case
            filterModalOpen: false,
            showDataKey: {},
            stickyCols: [],
            firstTimeTableComponentLoad: true,
            localConfig: {
                "selectedMode": false,
                tableData: this.props?.tableData?.data || [],
                currentPage: this.props?.tableData?.currentPage || 1,
                maxPage: this.props?.tableData?.maxPage || 1,
                pageNumber: this.props?.tableData?.currentPage || 1,
                getTableApiData: this.props?.tableData?.getTableApiData || {},
                totalItems: this.props?.tableData?.totalItems || 0,
            },
            tableScrollLeft: 0,

            selectedModal : false,
            parentModalData : "",
        };

        this.commentBoxRef = null;
        // this.tableContainerRef = React.createRef;
        this.uType = parseJwt(getTokenData())?.uType?.toUpperCase() || "ENT"
    }

    handleTrueFalse = (state, value) => {
        this.setState({
            [state]: value
        })
    }

    //    setIsAppliedFilterModalOpen = (val) => {
    //     this.setState({ isAppliedFilterModalOpen: val });
    //   };
    //-------------------- REACT LIFECYCLE METHODS --------------------//

    componentDidMount() {
        try {
            const urlSearchParams = new URLSearchParams(this.props.location.search),
                params = Object.fromEntries(urlSearchParams.entries());

            Object.keys(params).forEach(key => {
                params[key] = JSON.parse(decodeURIComponent(params[key]));
            });

            let customSearchApplied = false,
                customSearchBy = this.props.actionBarConfig.search && this.props.actionBarConfig.search.customSearch ? Object.keys(this.props.actionBarConfig.search.customSearch)[0] : "",
                customSearchData = [];
            if (params.customSearch) {
                customSearchApplied = true;
                customSearchBy = Object.keys(params.filter)[0];
                customSearchData = params.filter[customSearchBy].split(",");


            }

            let applyDefaultFilter = true;
            if (params.search || params.filter) {
                applyDefaultFilter = false;
            }
            console.log("Asdkasdf",params.otherInfo?.tableScrollLeft );
            delete params.apiUrl;
            this.setState(prevState => ({
                tableDataPayload: {
                    reportFilter: params.filter ? params.filter : {},
                    displayName: this.props.tableHeadersPayload.main.key,
                    ...prevState.tableDataPayload,
                    ...params
                },
                customSearchApplied,
                customSearchBy,
                customSearchData,
                applyDefaultFilter,
                tableScrollLeft :  params.otherInfo?.tableScrollLeft ?? 0,
            }), () => {
                this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders);
                this.getDocumentDropdownData();
                this.getTableHeaders("db");
                if (!applyDefaultFilter) this.getTableData();
            });

        }
        catch (err) {
            this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders);
            this.getDocumentDropdownData();
            this.getTableHeaders("db");
            this.getTableData();
        }
    }
    componentWillUnmount() {

        let container =document.getElementById("tableContainerRef");
        if (container) {
            container.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        let container = document.getElementById("tableContainerRef");

        if (container) {
            this.setState(() => {
                return {
                    tableScrollLeft: container?.scrollLeft || 0
                }
            }, () => {
                this.updateUrlSearchParams({
                    data : {
                        "otherInfo" : {
                            "tableScrollLeft" : container?.scrollLeft || 0,
                        }
                    }
                })
            });
        }
    };

    // Function : To get the download document dropdown data
    getDocumentDropdownData = () => {
        this.props.handleApiChange("getDocumentDropdownRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.generalStore?.getHeadersData?.isSuccess
            && Object.keys(nextProps?.tableHeadersPayload || {})?.map(key => nextProps?.tableHeadersPayload?.[key]?.["key"])?.includes(nextProps.generalStore.getHeadersData.data?.resource?.displayName
            )) {
            nextProps.handleApiChange("getHeadersClearRequest", {});
            let firstFourEntries = { ...prevState.showDataKey }, stickyColKeys = [...prevState.stickyCols];
            if (nextProps.generalStore.getHeadersData.data.basedOn == "main") {
                let res = nextProps?.generalStore?.getHeadersData?.data?.resource, headerType = res?.["Custom Headers"] === undefined || Object.keys(res["Custom Headers"]).length === 0 ? "Default Headers" : "Custom Headers";
                firstFourEntries = Object.entries(res?.[headerType] || {})
                    .slice(0, 4)  // Get the first 4 entries
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
                    console.log("this is the key", nextProps?.tableConfig?.gridConfig?.mainImgKey)
                    if(nextProps?.tableConfig?.gridConfig?.mainImgKey in firstFourEntries) {
                        // Retrieve the first 5 entries (to replace the removed key)
                        firstFourEntries = Object.entries(res?.[headerType] || {})
                        .slice(0, 5) // Get the first 5 entries
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {});
                        // Remove the specified key from the new object
                        delete firstFourEntries[nextProps?.tableConfig?.gridConfig?.mainImgKey];
                    }
                stickyColKeys = Object.keys(res?.[headerType] || {}).slice(0, +(res?.freezeColumn || 0));

            }
            return {
                getTableHeadersSuccess: prevState.getTableHeadersSuccess || nextProps.generalStore.getHeadersData.data.basedOn == "main",

                headersType: {
                    ...prevState.headersType,
                    [nextProps.generalStore.getHeadersData.data.basedOn]: nextProps.generalStore.getHeadersData?.data?.resource?.["Custom Headers"] === undefined || Object.keys(nextProps.generalStore.getHeadersData.data.resource["Custom Headers"]).length === 0 ? "Default Headers" : "Custom Headers",
                },
                headersData: {
                    ...prevState.headersData,
                    [nextProps.generalStore.getHeadersData.data.basedOn]: nextProps.generalStore.getHeadersData.data.resource
                },
                completeHeadersData: nextProps.generalStore.getHeadersData.data,
                showDataKey: firstFourEntries,
                stickyCols: stickyColKeys
            };
        }
        else if (nextProps.generalStore?.getHeadersData?.isError) {
            return {
                getTableHeadersError: true
            };
        }
        if (nextProps.generalStore.getQuickFiltersData?.isSuccess && nextProps.generalStore.getQuickFiltersData?.data?.resource !== null) {
            let quickFilter = nextProps.generalStore.getQuickFiltersData?.data?.resource;
            const defaultFilter = quickFilter?.filter(item => item.is_default == 1);
            const filterIndex = quickFilter?.findIndex(item => item.is_default == 1);
            let newArr = [], mainArray = [], removedFilter = [];
            if (defaultFilter?.length) {
                removedFilter = quickFilter?.splice(filterIndex, 1);
                newArr = quickFilter.splice(0, 2);
                mainArray = [...removedFilter, ...newArr];
                return {
                    pinedQuickFilter: mainArray || [],
                    uppinnedFilter: quickFilter || [],
                    quickFilterData: [...(quickFilter || []), ...(mainArray || [])]
                }
            }
            else {
                mainArray = quickFilter?.splice(0, 3);
                return {
                    pinedQuickFilter: mainArray || [],
                    uppinnedFilter: quickFilter || [],
                    quickFilterData: [...(quickFilter || []), ...(mainArray || [])]
                }
            }
        }

        // Handle : The response of the download document dropdown configuration.
        if (nextProps?.generalStore?.getDocumentDropdownData?.isSuccess) {
            let documentsDropdownData = nextProps?.generalStore?.getDocumentDropdownData?.data?.resource || {};
            return {
                downloadDocumentsConfigurationObject: documentsDropdownData || {},
            }
        }

        if (nextProps.generalStore.downloadReportData?.isSuccess && nextProps.generalStore.downloadReportData.data.resource !== null) {
            nextProps.generalStore.downloadReportData?.data?.resource && window.open(nextProps.generalStore.downloadReportData?.data?.resource);
        }
        // if (nextProps.generalStore.downloadDocumentsData?.isSuccess && nextProps.generalStore.downloadDocumentsData?.data?.resource !== null) {
        //     window.open(
        //         typeof(nextProps.generalStore.downloadDocumentsData?.data?.resource) == "object" ? 
        //         nextProps.generalStore.downloadDocumentsData?.data?.resource?.url : 
        //         nextProps.generalStore.downloadDocumentsData?.data?.resource
        //     ) 
        // }

        if (nextProps.generalStore?.getImageTrackingData?.isSuccess) {
            return {
                // loading: false,
                imageTrackingData: nextProps.generalStore?.getImageTrackingData?.data?.resource || null,
                imageVendor: nextProps.generalStore.getImageTrackingData?.data?.resource ? nextProps.generalStore.getImageTrackingData?.data?.resource?.data ? nextProps.generalStore.getImageTrackingData?.data?.resource?.data?.[0]?.vendor_name : "" : ""
            }
        }

        if (nextProps.getTableDataSuccess) {
            // Get the comments for all the tabledata at the single time.

            return {
                getTableDataSuccess: true,

                tableData: nextProps.tableData.data,
                currentPage: nextProps.tableData.currentPage,
                maxPage: nextProps.tableData.maxPage,
                pageNumber: nextProps.tableData.currentPage,
                totalItems: nextProps.tableData.totalItems,
                getTableApiData: nextProps.tableData.getTableApiData,
                localConfig: {
                    ...prevState.localConfig,
                    tableData: nextProps.tableData.data,
                    currentPage: nextProps.tableData.currentPage,
                    maxPage: nextProps.tableData.maxPage,
                    pageNumber: nextProps.tableData.currentPage,
                    totalItems: nextProps.tableData.totalItems,
                    getTableApiData: nextProps.tableData.getTableApiData,
                }
            };
        }
        else if (nextProps.getTableDataError) {
            return {
                getTableDataError: true
            };
        }
        else if (nextProps.getTableDataLoading) {
            return {
                getTableDataSuccess: false,
                getTableDataError: false,
                // selectedItems: {}
            };
        }

        if (nextProps.refreshData) {
            return {
                confirmationType: "",
                headMsg: "",
                paraMsg: ""
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {

        let container = document.getElementById("tableContainerRef");
        if (container) {
            container.removeEventListener('scroll', this.handleScroll);
            container.addEventListener('scroll', this.handleScroll);
        }

        if (this.props.tableDataPayloadUpdated) {
            this.setState({
                tableDataPayload: this.props.tableDataPayload,
            }, () => {
                this.getTableData()
                this.props.resetstate()
            })
        }

        if (this.props.updatetablePayload) {
            this.setState({
                tableDataPayload: this.props.tableDataPayload,
            }, () => this.props.resetstate())
        }
        if (this.props.customAction) {
            if (this.props.actionBarConfig.downloadReport.tableHeadersMap.main.key == "SOMAIN_INDENT") {
                this.props.handleApiChange("downloadReportRequest", {
                    module: "EXCEL_EXPORT_SALES_ORDER",
                    isAllData: false,
                    isOnlyCurrentPage: false,
                    data: this.state.tableDataPayload
                }, getPermissionKey("common", "commonFunctionality", "view"))
                this.props.resetstate()
            }
        }

        //     // Update this.state.tableDataPayload and request data & headers after the tab is changed in parent component
        if (prevProps.actionBarConfig.tabs && prevProps.tableDataPayload[prevProps.actionBarConfig.tabs.key] != this.props.tableDataPayload[this.props.actionBarConfig.tabs.key]) {
            console.log("Check324234234", this.state.selectedItems);
            this.setState({
                tableDataPayload: { ...this.props.tableDataPayload, displayName: this.props.tableHeadersPayload.main.key },
                getTableHeadersSuccess: false,
                selectedItems: {},
                customSearchApplied: false,
                customSearchData: []
            }, () => {
                this.getTableData();
                this.getTableHeaders("db");
                this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders)
                this.getDocumentDropdownData()
            });
        }

        if (this.props.actionBarConfig.quickFilter && this.state.applyDefaultFilter) {
            if (this.props.generalStore.getQuickFiltersData?.isSuccess) {
                const defaultFilter = this.state.quickFilterData.filter(item => item?.is_default == 1);
                const filterIndex = this.state.quickFilterData.findIndex(item => item?.is_default == 1);
                let allFilters = [...this.state.quickFilterData], newArr = [], mainArray = [], removedFilter = [];
                if (defaultFilter.length) {
                    this.applyFilter(defaultFilter[0].filterValue)();
                    removedFilter = allFilters.splice(filterIndex, 1);
                    newArr = allFilters.splice(0, 2);
                    mainArray = [...removedFilter, ...newArr]
                    this.setState({
                        appliedQFActive: 0,
                        pinedQuickFilter: mainArray,
                        uppinnedFilter: allFilters
                    });
                }
                else {
                    this.getTableData();
                    mainArray = allFilters.splice(0, 3);
                    this.setState({
                        pinedQuickFilter: mainArray,
                        uppinnedFilter: allFilters
                    });
                }
                this.setState({
                    applyDefaultFilter: false
                });
            }
            else if (this.props.generalStore.getQuickFiltersData?.isError) {
                this.getTableData();
                this.setState({
                    applyDefaultFilter: false
                });
            }
        }

        if (this.props.getTableDataSuccess && prevProps.getTableDataSuccess !== this.props.getTableDataSuccess) {
            ["selectedItems", "expandRow1", "imageDetailsModal"]?.map(key => {
                this.updateComponentFromSearchParams({ key: key })
            })
            setTimeout(() => {
                this.setState({
                    firstTimeTableComponentLoad: false,
                });
                document.querySelectorAll("tr .sticky-col").forEach(elem => {
                    let temp = elem, left = 0;
                    while (temp.previousElementSibling) {
                        left += temp.previousElementSibling.clientWidth;
                        temp = temp.previousElementSibling;
                    }
                    elem.style.left = `${left}px`;
                });

                document.querySelectorAll("div .sticky-col").forEach(elem => {
                    let temp = elem, left = 0;
                    while (temp.previousElementSibling) {
                        left += temp.previousElementSibling.clientWidth;
                        temp = temp.previousElementSibling;
                    }
                    elem.style.left = `${left}px`;
                });
            }, 0);

            if(this.state.tableScrollLeft){
                setTimeout(() => {
                    let container = document.getElementById("tableContainerRef");
                    container.scrollLeft = this.state.tableScrollLeft;
                }, 0);
            }
        }

        if (this.props.generalStore.createHeadersData?.isSuccess) {
            // this.props.createHeadersClear();
            setTimeout(() => {
                this.getTableHeaders("db");
                this.closeColumnSettingModal();
                this.getTableData();
            }, 1000)
        }

        if (this.props.generalStore.createQuickFiltersData?.isSuccess) {
            // this.props.createQuickFiltersClear();
            setTimeout(() => {
                this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders);
            }, 1000)
        }

        if (this.props.refreshData) {
            this.setState({
                selectedItems: {},
                localConfig: {
                    selectedMode: false,
                },
            })
            this.getTableData();
        }
    }

    //-------------------- GET TABLE HEADERS METHODS --------------------//

    getTableHeaders = from => {
        switch (from) {
            case "db": {
                Object.keys(this.props.tableHeadersPayload).forEach(key => {
                    this.props.handleApiChange("getHeadersRequest", {
                        enterpriseName: "TURNINGCLOUD",
                        displayName: this.props.tableHeadersPayload[key].key,
                        basedOn: key
                    }, this.props?.tableComExtraHeaders)
                });
                break;
            }
            case "state": {
                let temp = {};
                Object.keys(this.props.tableHeadersPayload).forEach(key => {
                    temp[key] = {
                        ...this.props.tableHeadersPayload[key],
                        data: this.state.headersData[key]
                    };
                });
                return temp;
            }
            default: { }
        }

    }

    // ------------------- UPDATE SELECTED ITEMS ----------------------//
    updateComponentFromSearchParams = ({ key }) => {
        // Update selected items based on the id inside the url upon api call;
        const urlSearchParams = new URLSearchParams(this.props.location.search),
            params = Object.fromEntries(urlSearchParams.entries());

        let updatedTableData = {};

        this.state.tableData?.map(item => {
            if (item?.id) {
                updatedTableData[item.id] = item;
            }
        })

        switch (key) {
            case "selectedItems": {
                // Check if the selected data functionality is present inside this component or not
                if (this.props?.tableConfig.select) {
                    console.log("Update selected items", this.state.selectedItems);
                    let updatedSelectedData = {};

                    if (params.selectedItems) {
                        JSON.parse(decodeURIComponent(params["selectedItems"]))?.map(id => {
                            if (updatedTableData[id]) {
                                updatedSelectedData[id] = updatedTableData?.[id];
                            }
                        })
                        this.setState({
                            selectedItems: updatedSelectedData
                        })
                    }
                }
                break;
            }
            case "expandRow1": {
                // ------------------- UPDATE EXPANDED ROW WHEN THE EXPANDED ID IS PRESENT INSIDE THE URL SEARCH PARAMS --------------------- //
                if (this?.props?.tableConfig?.expand?.() && !this.props?.tableConfig?.expand?.()?.hidden) {
                    if (params?.expandRow1) {
                        JSON.parse(decodeURIComponent(params?.expandRow1))?.map(id => {
                            // /    console.log("changes id", this.props?.tableConfig?.expand);
                            if (updatedTableData[id]) {
                                this.props?.tableConfig?.expand?.()?.onClick(updatedTableData[id])()
                            }
                        })
                    }
                }
                break;
            }
            case "imageDetailsModal": {
                if (this.props.imageOrderNumber && params?.imageDetailsModal && this.state.firstTimeTableComponentLoad) {
                    let item = updatedTableData?.[JSON.parse(decodeURIComponent(params?.imageDetailsModal))?.[0]]
                    if (item) {
                        this.handleImageTrackingModal(item?.[this.props.imageOrderNumber], item?.["vendorName"], this.props.imageOrderNumber);
                    }
                }
                break;
            }
            default:
        }

        this.updateUrlSearchParams({
            data: {
                [key]: undefined
            }
        })
    }

    // ------------------- UPDATE PROPERTY INSIDE URL SEARCH PARAMS ------------------//
    updateUrlSearchParams = (config = {}) => {
        let { data = {} } = config;

        // Remove the applied selected items from the URL search params 
        setPayloadAsURLParams({
            data: {
                ...getPayloadFromURLParams({
                    l: this.props.location,
                }),
                ...data,
            },
            l: this.props.location,
            h: this.props.history,
        })
    }

    //-------------------- GET TABLE DATA METHODS --------------------//

    getTableData = () => {
        let payload = { ...this.state.tableDataPayload };
        payload.displayName = this.props.tableHeadersPayload.main.key
        this.props.handleApiChange(this.props.getTableDataRequest, payload, this.props?.tableComExtraHeaders || {});
        if (this.props.actionBarConfig.isSummaryApiRequire) {
            this.props.handleApiChange(this.props.summaryRequest, payload, this.props?.tableSummaryHeaders || {});
            // this.props.summaryRequest(payload)
        }
        if (this.state.customSearchApplied) payload.customSearch = true;
        let urlSearchParams = new URLSearchParams("?");
        Object.keys(payload)?.forEach(key => {
            if ((typeof payload[key] != "object" && payload[key]) || (typeof payload[key] == "object" && Object.keys(payload[key] || {})?.length)) {
                urlSearchParams.append(key, encodeURIComponent(JSON.stringify(payload[key])));
            }
        });
        this.setState(prev => ({
            localConfig: {
                ...prev.localConfig,
                selectedMode: false
            }
        }))
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: urlSearchParams == "?" ? "" : urlSearchParams.toString()
        });
    }

    //-------------------- REFRESH METHODS --------------------//

    onRefresh = () => {
        this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload, this.props?.tableComExtraHeaders);
        this.getTableHeaders("db");
        this.setState({
            tableDataPayload: this.props.tableDataPayload,
            selectedItems: {},
            tableScrollLeft : 0,
        }, this.getTableData);
    }

    //-------------------- COLUMN SETTING METHODS --------------------//

    openColumnSettingModal = () => {
        this.setState({
            columnSettingModal: true
        }, () => setTimeout(() => document.addEventListener("click", this.closeColumnSettingModalOnClick, 0)));
    }

    closeColumnSettingModalOnClick = (e) => {
        if (e.target.id == "columnSettingBackdrop") {
            this.closeColumnSettingModal();
        }
    }

    closeColumnSettingModal = () => {
        this.setState({
            columnSettingModal: false
        }, () => setTimeout(() => document.removeEventListener("click", this.closeColumnSettingModalOnClick), 0));
    }

    //-------------------- PAGINATION METHODS --------------------//

    changePage = pageNo => e => {
        if (this.state.localConfig.selectedMode) {
            this.setState({
                tableData: Object.values(this.state.selectedItems || {})?.slice(((pageNo - 1) * this.state.tableDataPayload.pageSize), Math.min(((pageNo) * this.state.tableDataPayload.pageSize), Object.keys(this.state.selectedItems || {})?.length)),
                currentPage: pageNo,
                pageNumber: pageNo,
            })
        } else {
            this.setState(prevState => ({
                tableDataPayload: {
                    ...prevState.tableDataPayload,
                    pageNo
                }
            }), this.getTableData);
        }
    }

    setPageNumber = e => {
        let pageNumber = Number(e.target.value),
            valid = e.target.value == "" || (/^\d*$/.test(pageNumber) && pageNumber > 0 && pageNumber <= this.state.maxPage);
        if (valid) {
            this.setState({
                pageNumber: pageNumber
            });
            if (e.key == "Enter" && pageNumber && pageNumber != this.state.currentPage) {
                this.changePage(pageNumber)();
                this.setState({
                    enableJumpPage: false
                });
                setTimeout(() => document.removeEventListener("click", this.closeJumpPage), 0);
            }
        }
    }

    handlePageSize = e => {
        let pageSize = parseInt(e.target.value);
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                // pageNo: 1,
                pageSize
            }
        }), this.getTableData);
    }

    //-------------------- SORTING METHODS --------------------//

    // openSortingModal = () => {
    //     this.setState(prevState => ({
    //         sortingModal: !prevState.sortingModal
    //     }));
    // }

    // closeSortingModal = () => {
    //     this.setState({
    //         sortingModal: false
    //     });
    // }

    sortData = (sortedBy, sortedIn) => e => {
        console.log("sort dat called");
        this.setState(prevState => {
            const regex = new RegExp(`(^|,\\s*)${sortedBy}(?=,|$)`, 'g');

            let result = prevState.tableDataPayload?.sortedBy || "";
            if (sortedIn == prevState.tableDataPayload?.sortedIn) {
                console.log("Sorted In", sortedIn, prevState.tableDataPayload?.sortedIn, this.isGivenCommaSepratedStringContainsOtherString(prevState.tableDataPayload?.sortedBy, sortedBy))
                if (this.isGivenCommaSepratedStringContainsOtherString(prevState.tableDataPayload?.sortedBy, sortedBy)) {
                    result = prevState.tableDataPayload?.sortedBy?.replace(regex, '').replace(/(^,|,$)/g, '').trim();
                } else {
                    result = result ? `${result},${sortedBy}` : sortedBy;
                }
            } else {
                result = sortedBy;
            }
            return {
                tableDataPayload: {
                    ...prevState.tableDataPayload,
                    sortedBy: result,
                    sortedIn
                }
            }
        }, this.getTableData);
        // this.closeSortingModal();
    }

    // Function : To check if the current key is present inside the given string
    isGivenCommaSepratedStringContainsOtherString = (val1, val2) => {
        let length = -val2?.length;
        // console.log("check 789", val1, val2,  val1?.includes(`${val2},`) , val1?.slice(length) == val2 )
        return val1?.includes(`${val2},`) || val1?.slice(length) == val2
    }

    //-------------------- SEARCH METHODS --------------------//

    setSearchValue = value => e => {
        if (value != "") value = e.target.value;
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                search: value
            }
        }), () => value == "" && this.onSearch());
    }

    handleSearchKeyDown = e => {
        let key = e.key;
        if (key == "Enter") {
            this.onSearch();
        }
        else if (key == "Escape") {
            this.setSearchValue("")();
        }
    }

    onSearch = () => {
        let type;
        if (this.state.customSearchApplied) {
            type = 3;
        }
        else if (this.state.tableDataPayload.search == "") {
            type = this.state.tableDataPayload.type == 3 ? 1 : this.state.tableDataPayload.type == 4 ? 2 : this.state.tableDataPayload.type;
        }
        else {
            type = this.state.tableDataPayload.type == 2 || this.state.tableDataPayload.type == 4 ? 4 : 3;
        }
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                pageNo: 1,
                filter: prevState.customSearchApplied ? {} : prevState.tableDataPayload.filter,
                type
            },
            customSearchApplied: false,
            customSearchData: []
        }), this.getTableData);
    }


    //-------------------- CUSTOM SEARCH METHODS --------------------//

    toggleCustomSearchModal = () => {
        sendButtonClickEventToGoogleAnalytics({
            location: this.props.location,
            label: "Custom Search Modal"
        })
        this.setState(prevState => ({
            customSearchModal: !prevState.customSearchModal
        }));
    }

    applyCustomSearch = (customSearchBy, customSearchData) => {
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                pageNo: 1,
                type: 2,
                filter: { [customSearchBy]: customSearchData.join() },
                search: ""
            },
            customSearchBy,
            customSearchData,
            customSearchApplied: true,
            customSearchModal: false
        }), this.getTableData);
    }

    removeCustomSearchItem = (index) => () => {
        this.setState(prevState => {
            let customSearchData = [...prevState.customSearchData];
            customSearchData.splice(index, 1);
            return {
                customSearchData
            };
        }, () => {
            this.state.customSearchData.length ? this.applyCustomSearch(this.state.customSearchBy, this.state.customSearchData) : this.applyFilter({})();
        });
    }

    //-------------------- QUICK FILTER METHODS --------------------//


    openQuickFilter = () => {
        // Adding event listener when opening the modal

        this.setState({
            quickFilterDropdown: true
        });
        setTimeout(() => {
            document.addEventListener("click", this.closeQuickFilterOnClick);
        }, 0)
    }


    closeQuickFilter = () => {
        // Removing event listener when closing the modal
        setTimeout(() => {
            document.removeEventListener("click", this.closeQuickFilterOnClick);
        }, 0)

        this.setState({
            quickFilterDropdown: false,
            quickFilterSettings: false
        });
    }

    closeQuickFilterOnClick = (e) => {
        let path = e.path || (e.composedPath && e.composedPath());
        if (!path.some(elem => elem.id == "quickFilterDropdown")) {
            this.closeQuickFilter();
        }
    }

    toggleQuickFilterSettings = () => {
        this.setState(prevState => ({
            quickFilterSettings: !prevState.quickFilterSettings
        }));
    }

    saveQuickFilter = (filterName, checkedFilters, isDelete = false, is_default) => e => {
        if (!this.props.generalStore?.createQuickFiltersData?.isLoading) {
            let payload = {
                displayName: this.props.quickFiltersPayload.displayName,
                filterName: [filterName],
                filterValue: checkedFilters
            };
            if (isDelete) {
                payload.isDeleteFilter = true;
            }
            else if (is_default !== undefined) {
                payload.is_default = is_default;
            }
            this.props.handleApiChange("createQuickFiltersRequest", payload, this.props?.tableComExtraHeaders);
            this.closeSaveFilter();
        }
        e && e.stopPropagation();
    }

    //-------------------- FILTER METHODS --------------------//

    openFilterModal = () => {
        this.setState(prevState => ({
            filterModal: !prevState.filterModal
        }), () => setTimeout(() => document.addEventListener('click', this.closeFilterOnClick, 0)));
    }

    closeFilterModal = () => {
        this.setState({
            filterModal: false
        }, () => setTimeout(() => document.removeEventListener('click', this.closeFilterOnClick), 0));
    }

    closeFilterOnClick = e => {
        if (e.target && document.getElementById("vendorFilterBackdrop").contains(e.target)) {
            this.closeFilterModal();
        }
    }

    applyFilter = checkedFilters => () => {
        let type;
        if (Object.keys(checkedFilters).length == 0) {
            type = this.state.tableDataPayload.type == 2 ? 1 : this.state.tableDataPayload.type == 4 ? 3 : this.state.tableDataPayload.type;
        }
        else {
            type = this.state.tableDataPayload.type == 3 || this.state.tableDataPayload.type == 4 ? 4 : 2;
        }
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                pageNo: 1,
                type: type,
                filter: checkedFilters
            },
            customSearchApplied: false,
            customSearchData: []
        }), this.getTableData);
        this.closeFilterModal();
        this.closeQuickFilter();

    }

    removeFilter = key => () => {
        let filters = { ...this.state.tableDataPayload.filter };
        delete filters[key];
        filters["item"] && Object.keys(filters).length === 1 && Object.keys(filters["item"]).length === 0 && delete filters["item"]
        this.applyFilter(filters)();
    }

    removeItemFilter = key => () => {
        let itemFilter = { ...this.state.tableDataPayload.filter };
        delete itemFilter["item"][key];
        itemFilter["item"] && Object.keys(itemFilter).length === 1 && Object.keys(itemFilter["item"]).length === 0 && delete itemFilter["item"]
        this.applyFilter(itemFilter)();
    }

    getFilterValue = key => {
        switch (this.state.headersData.main.columnType[key]) {
            case "NUMBER":
            case "TIME": {
                return `From ${this.state.tableDataPayload.filter[key].from} to ${this.state.tableDataPayload.filter[key].to}`;
            }
            case "DATE": {
                if (!this.state.tableDataPayload.filter[key].key)
                    return `From ${this.state.tableDataPayload.filter[key].from} to ${this.state.tableDataPayload.filter[key].to}`;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_TODAY_VALUE")
                    return <LanguageTranslator>Today</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return <LanguageTranslator>Yesterday</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return <LanguageTranslator>Last 7 Days</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return <LanguageTranslator>Last Week</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_THISMONTH_VALUE")
                    return <LanguageTranslator>This Month</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return <LanguageTranslator>Last Month</LanguageTranslator>;
                return "";
            }
            default: {
                if (this.state.tableDataPayload.filter[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if (this.state.tableDataPayload.filter[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.state.tableDataPayload.filter[key];
            }
        }
    }

    getItemFilterValue = key => {
        if (this.state.headersData && !this.state.headersData.item || !this.state.headersData.item.columnType) {
            return ""
        }
        switch (this.state.headersData.item.columnType[key]) {
            case "NUMBER":
            case "TIME": {
                return `From ${this.state.tableDataPayload.filter.item[key].from} to ${this.state.tableDataPayload.filter.item[key].to}`;
            }
            case "DATE": {
                if (!this.state.tableDataPayload.filter.item[key].key)
                    return `From ${this.state.tableDataPayload.filter.item[key].from} to ${this.state.tableDataPayload.filter.item[key].to}`;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_TODAY_VALUE")
                    return <LanguageTranslator>Today</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return <LanguageTranslator>Yesterday</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return <LanguageTranslator>Last 7 Days</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return <LanguageTranslator>Last Week</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_THISMONTH_VALUE")
                    return <LanguageTranslator>This Month</LanguageTranslator>;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return <LanguageTranslator>Last Month</LanguageTranslator>;
                return "";
            }
            default: {
                if (this.state.tableDataPayload.filter.item[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if (this.state.tableDataPayload.filter.item[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.state.tableDataPayload.filter.item[key];
            }
        }
    }

    //-------------------- DOWNLOAD METHODS --------------------//

    openDocumentsDropdown = () => {
        this.setState(prevState => ({
            documentsDropdown: !prevState.documentsDropdown
        })
            // , () => setTimeout(() => document.addEventListener('click', this.closeDocumentsDropdown), 0)
        );
    }

    closeDocumentsDropdown = () => {
        this.setState({
            documentsDropdown: false
        }
            // , () => setTimeout(() => document.removeEventListener('click', this.closeDocumentsDropdown), 0)
        );
    }

    toggleDownloadReportModal = () => {
        this.setState(prevState => ({
            downloadReportModal: !prevState.downloadReportModal
        }));
    }

    //-------------------- EMAIL METHODS --------------------//

    openEmailModal = () => {
        this.setState(prevState => ({
            emailModal: !prevState.emailModal
        }));
    }

    closeEmailModal = () => {
        this.setState({
            emailModal: false
        });
    }

    //-------------------- MORE-MENU METHODS --------------------//

    openMoreMenu = () => {
        this.setState(prevState => ({
            moreMenuDropdown: !prevState.moreMenuDropdown
        }), () => setTimeout(() => document.addEventListener('click', this.closeMoreMenu, 0)));
    }

    closeMoreMenu = () => {
        this.setState({
            moreMenuDropdown: false
        }, () => setTimeout(() => document.removeEventListener('click', this.closeMoreMenu), 0));
    }

    //-------------------- SELECTION METHODS --------------------//

    selectItem = item => (e) => {
        e?.preventDefault();
        if (e?.target?.closest('[data-interactive]')) {
            return;
        }
        this.setState((prevState) => {
            const { selectedItems: prevSelectedItems } = prevState;
            const { localConfig, tableDataPayload } = this.state;
            const pageSize = tableDataPayload?.pageSize || 10;
            const currentPage = prevState.currentPage;

            // Toggle selected item
            const selectedItems = { ...prevSelectedItems };
            if (selectedItems[item.id]) {
                delete selectedItems[item.id];
            } else {
                selectedItems[item.id] = item;
            }

            // Check for selected mode
            if (localConfig?.selectedMode) {
                const selectedItemsCount = Object.keys(selectedItems).length;

                // If no items are selected, deselect all
                if (selectedItemsCount === 0) {
                    this.deselectAll();
                } else {
                    let startIdx = Math.max((currentPage - 1) * pageSize, 0);
                    const adjustedPage = selectedItemsCount <= startIdx ? Math.max(currentPage - 1, 1) : currentPage;
                    const maxPage = Math.ceil(selectedItemsCount / pageSize);
                    const endIdx = Math.min(currentPage * pageSize, selectedItemsCount);

                    if (startIdx == endIdx && Object.keys(selectedItems).length > 0) {
                        startIdx = startIdx - pageSize
                    }

                    this.setState({
                        tableData: Object.values(selectedItems).slice(startIdx, endIdx),
                        currentPage: adjustedPage,
                        pageNumber: adjustedPage,
                        maxPage: maxPage,
                        totalItems: selectedItemsCount,
                    }, this.handleStickyCols);
                }
            }

            return { selectedItems };
        });

    }

    selectAll = () => {
        this.setState(prevState => {
            let selectedItems = { ...prevState.selectedItems };
            prevState.tableData.forEach(item => {
                if (this.isAllDataOnCurrentPageSelected()) {
                    delete selectedItems[item.id];
                } else {
                    selectedItems[item.id] = item;
                }
            });
            return {
                selectedItems
            };
        });
    }

    deselectAll = () => {
        this.setState(prevState => ({
            selectedItems: {},
        }), () => this.toggleSelectedMode(false));
    }

    // TODO: Revamp Comments Modal and its related functionality
    //-------------------- COMMENT METHODS --------------------//

    // openChatBox = (e, item) => {
    //     if (e == "close") {
    //         if (this.commentBoxRef && this.commentBoxRef.state.confirmModal) {
    //             this.commentBoxRef.closeConfirmModal();
    //         }
    //         else if (this.commentBoxRef && this.commentBoxRef.state.transcriptModal) {
    //             this.commentBoxRef.handleEmailTranscriptModal();
    //         }
    //         else {
    //             this.setState({
    //                 openChats: [], cancelModal: false, filter: false, downloadReport: false,
    //                 showDownloadDrop: false, completeQCModal: false, handleModal: false,
    //                 actionExpand: false, dropOpen: false, headerCondition: false, coloumSetting: false, shipmentModal: false,
    //                 confirmModal: false
    //             });
    //         }
    //     }
    //     else {
    //         let id = e.target.id, handleOpenClose = e.target.dataset.id;
    //         if (handleOpenClose == "openChat" && !this.state.openChats.length && !this.state.openChats.some(innerItem => innerItem.orderNumber == item.orderNumber)) {
    //             let temp = {
    //                 adviceNo: this.uType == "ENT" ? item?.shipmentAdviceCode || "" : item?.asnMask || "",
    //                 commentCode: this.uType == "ENT" ? item?.shipmentAdviceCode || "" : item?.asnMask || "",
    //                 commentId: item.id || "",
    //                 id: item.id || "",
    //                 orderCode: item.orderCode || "",
    //                 orderNumber: item.orderNumber || "",
    //                 orgID: item.orgId || "",
    //                 shipmentId: item.shipmentId || "",
    //                 vendorCode: item.vendorCode || ""
    //             };
    //             this.setState(prevState => ({
    //                 commentModal: "open",
    //                 openChats: [...prevState.openChats, temp]
    //             }));
    //         }
    //         else if (handleOpenClose == "closeChat" && this.state.openChats.some(innerItem => innerItem.orderNumber == id)) {
    //             this.setState(prevState => ({
    //                 openChats: prevState.openChats.filter(innerItem => innerItem.orderNumber != id)
    //             }));
    //         }
    //         else if (id == "minimize") {
    //             this.setState({
    //                 commentModal: "minimize"
    //             });
    //         }
    //         else if (id == "openMinimize") {
    //             this.setState({
    //                 commentModal: "openMinimize"
    //             });
    //         }
    //     }
    // }

    //-------------------- CONFIRMATION METHODS --------------------//

    confirmAction = type => e => {
        switch (type) {
            case "delete": {
                this.setState({
                    confirmationType: type,
                    headMsg: "Delete selected item(s)?",
                    paraMsg: "Are you sure you want to perform this action? It cannot be undone."
                });
                break;
            }
            default: { }
        }
    }

    onConfirmation = () => {
        switch (this.state.confirmationType) {
            case "delete": {
                this.props.actionBarConfig.moreMenu.options(this.state.selectedItems).delete.onClick(this.state.selectedItems);
                break;
            }
            default: { }
        }
    }

    onCancellation = () => {
        this.setState({
            confirmationType: "",
            headMsg: "",
            paraMsg: ""
        });
    }
    closeImageTrackingModal = () => { this.setState({ imageTrackingModal: false, viewImageModal: false }) }
    handleImageTrackingModal = (item, vendor, key) => {
        key = changeKey(key)
        let payload = {
            "orderNumber": key == "setBarCode" || key == "itemCode" ? this.props.tableConfig && this.props.tableConfig.expand && (this.props.tableConfig.expand?.(item)?.expandedRow?.orderNumber) : "",
            "shipmentAdviceCode": "",
            "setBarCode": "",
            "itemCode": "",
            "logisticNumber": "",
            "getBy": key,
            "search": "",
            "pageSize": 10,
            "pageNo": 1,
            "type": 1,
            "filter": {},
        }
        payload[key] = item;
        this.props.handleApiChange("getImageTrackingRequest", payload, this.props?.tableComExtraHeaders);
        this.setState({
            imageVendor: vendor,
            imageOrderNumber: item,
            imageTrackingNumberType: key,
            imageTrackingModal: true
        })
    }

    handleViewImageModal = (item, key) => {
        key = changeKey(key)
        let payload = {
            "orderNumber": "",
            "shipmentAdviceCode": "",
            "setBarCode": "",
            "itemCode": "",
            "logisticNumber": "",
            "getBy": key,
            "search": "",
            "pageSize": 10,
            "pageNo": 1,
            "type": 1,
            "filter": {},
        }
        payload[key] = item;
        this.props.handleApiChange("getImageTrackingRequest", payload, this.props?.tableComExtraHeaders);
        this.setState({
            imageOrderNumber: item,
            imageTrackingNumberType: key,
            viewImageModal: true,
        })
    }

    // Right Click Functions
    onRightClick = (e, key, item, id) => {
        e.preventDefault();
        this.setState(prevState => {
            let selectedItems = { ...prevState.selectedItems };
            if (!selectedItems[item.id]) {
                selectedItems[item.id] = item;
            }
            return {
                selectedItems,
                rightClickMenu: true,
                selectedColumn: key,
                selectedRowId: id,
            };
        }, () => document.addEventListener('click', this.closeRightClickMenu));
        if (window.innerWidth <= e.pageX + 200 && window.innerHeight <= e.pageY + 250) {
            this.setState({
                leftPosition: e.pageX - 200,
                topPosition: e.pageY - 250,
            });
        }
        else if (window.innerWidth <= e.pageX + 200) {
            this.setState({
                leftPosition: e.pageX - 200,
                topPosition: e.pageY,
            });
        }
        else if (window.innerHeight <= e.pageY + 250) {
            this.setState({
                leftPosition: e.pageX,
                topPosition: e.pageY - 250,
            })
        }
        else {
            this.setState({
                leftPosition: e.pageX,
                topPosition: e.pageY,
            })
        }
    }

    rightClickAction = (type) => {
        switch (type) {
            case 'search': {
                this.setState(prevState => ({
                    tableDataPayload: {
                        ...prevState.tableDataPayload,
                        search: this.state.selectedItems[0][this.state.selectedColumn]
                    }
                }), () => this.onSearch())
                break;
            }
            case 'hideColumn': {
                this.createCustomHeaders()
                break;
            }
        }
        this.closeRightClickMenu();
    }

    closeRightClickMenu = () => {
        this.setState({
            rightClickMenu: false,
            selectedColumn: '',
            selectedItems: this.props?.tableConfig?.select ? { ...this.state.selectedItems } : {},
            selectedRowId: '',
        }, () => document.removeEventListener('click', this.closeRightClickMenu))
    }

    createCustomHeaders = () => {
        let customHeadersObj = this.state.headersData.main[this.state.headersType.main];
        let ans = this.state.headersData.main["Default Headers"];
        const deletedCloumn = this.state.selectedColumn;
        delete ans[deletedCloumn];
        delete customHeadersObj[deletedCloumn];
        let payload = {
            displayName: this.props.tableHeadersPayload.main.key,
            fixedHeaders: this.state.headersData.main["Fixed Headers"],
            defaultHeaders: ans,
            customHeaders: customHeadersObj
        }
        this.props.handleApiChange("createHeadersRequest", payload, this.props?.tableComExtraHeaders);
    }

    refreshapidata = () => {
        this.props.handleApiChange("getRefreshReportRequest", {
            viewName: this.props.tableData.getTableApiData.mvName
        }, this.props?.tableComExtraHeaders)
        this.onRefresh()
    }

    selectPageination = () => {
        this.setState({
            selectPage: !this.state.selectPage
        }, () => setTimeout(() => document.addEventListener("click", this.closeSelectPage), 0))
    }
    closeSelectPage = () => {
        this.setState({
            selectPage: false
        }, () => document.removeEventListener("click", this.closeSelectPage), 0)
    }

    //close modal to close the success message notification
    closeModal = () => {
        this.setState({
            successMessage: ""
        });
    }

    // Table Header Filter Functions
    showSelectDropdown = key => () => {
        this.setState({
            selectDropdown: key
        }, () => setTimeout(() => document.addEventListener("click", this.hideSelectDropdown), 0));
    }

    hideSelectDropdown = e => {
        let path = e.path || (e.composedPath && e.composedPath());
        if (!path.some(elem => elem.id == "genericFilterSelectDropdown")) {
            this.setState({
                selectDropdown: ""
            }, () => setTimeout(() => document.removeEventListener("click", this.hideSelectDropdown), 0));
        }
    }

    updatePageSize = (pageSize) => {
        this.setState({ tableDataPayload: { ...this.state.tableDataPayload, pageSize: pageSize, displayName: this.props.tableHeadersPayload.main.key, pageNo: 1 } }, this.getTableData)
    }

    // Save Filters Functionality
    saveFilters = () => {
        this.openSaveFilter();
        let filter = {};
        Object.keys(this.state.tableDataPayload.filter).forEach(key => {
            if (this.state.headersData.main.columnType[key] === "LOV" || this.state.headersData.main.columnType[key] === "SEARCH") {
                // filter[key] = this.state.tableDataPayload.filter[key].join();
                try {
                    filter[key] = this.state.tableDataPayload.filter[key].join();
                }
                catch (err) {
                    filter[key] = this.state.tableDataPayload.filter[key];
                }
            }
            else {
                filter[key] = this.state.tableDataPayload.filter[key];
            }
        });
        this.setState({ saveFilterData: filter });
    }

    openSaveFilter = () => {
        this.setState({
            saveFilterModal: !this.state.saveFilterModal,
        });
    }

    closeSaveFilter = () => {
        this.setState({
            saveFilterModal: false
        });
    }

    handleJumpPage = () => {
        this.setState({
            enableJumpPage: true
        })
        setTimeout(() => document.addEventListener("click", this.closeJumpPage), 0)
    }

    closeJumpPage = (e) => {
        if (this.jumpInputRef && !this.jumpInputRef.current.contains(e.target)) {
            this.setState({
                enableJumpPage: false
            });
            setTimeout(() => document.removeEventListener("click", this.closeJumpPage), 0);
        }
    }

    expandField = () => {
        this.setState({
            expandInput: !this.state.expandInput
        }, () => setTimeout(() => document.addEventListener("click", this.closeExpand), 0))
    }

    closeExpand = () => {
        this.setState({
            expandInput: false,
        }, () => setTimeout(() => document.removeEventListener("click", this.closeExpand), 0))
    }

    handleQF = (item) => {
        this.setState({
            qfDropdown: item
        }, () => setTimeout(() => document.addEventListener("click", this.closeQF), 0))
    }
    closeQF = () => {
        this.setState({
            qfDropdown: false,
        }, () => setTimeout(() => document.removeEventListener("click", this.closeQF), 0))
    }

    handleThreeDot = (item, key) => {
        this.setState({
            threeDotPin: key,
            threeDotPinIndex: item,
        }, () => setTimeout(() => document.addEventListener("click", this.closePinThree), 0))
    }
    closePinThree = () => {
        this.setState({
            threeDotPin: false,
            threeDotPinIndex: false
        }, () => setTimeout(() => document.removeEventListener("click", this.closePinThree), 0))
    }

    handlePinedQuickFilter = (item) => {
        this.setState(prevState => {
            let pinedQuickFilter = [...prevState.pinedQuickFilter], uppinnedFilter = [...prevState.uppinnedFilter], appliedQFActive = -1;
            let index = pinedQuickFilter.indexOf(item), index2 = uppinnedFilter.indexOf(item);
            if (pinedQuickFilter.length >= 3) {
                let removeItem = [];
                pinedQuickFilter.push(item);
                removeItem = pinedQuickFilter.splice(1, 1);
                uppinnedFilter.splice(index2, 1);
                uppinnedFilter.push(...removeItem);
                appliedQFActive = 2
            }
            else if (index === -1) {
                pinedQuickFilter.push(item);
                if (index2 === -1) {
                    uppinnedFilter.push(item);
                }
                else {
                    uppinnedFilter.splice(index2, 1);
                }
            }
            else {
                pinedQuickFilter.splice(index, 1);
                if (index2 === -1) {
                    uppinnedFilter.push(item);
                }
                else {
                    uppinnedFilter.splice(index2, 1);
                }
            }
            return {
                pinedQuickFilter,
                uppinnedFilter,
                appliedQFActive
            };
        });
    }

    removePinnedFilter = (item) => {
        this.setState(prevState => {
            let pinedQuickFilter = [...prevState.pinedQuickFilter], uppinnedFilter = [...prevState.uppinnedFilter];
            let index = pinedQuickFilter.indexOf(item), index2 = uppinnedFilter.indexOf(item);
            if (index === -1) {
                pinedQuickFilter.push(item);
                if (index2 === -1) {
                    uppinnedFilter.push(item);
                }
                else {
                    uppinnedFilter.splice(index2, 1);
                }
            }
            else {
                pinedQuickFilter.splice(index, 1);
                if (index2 === -1) {
                    uppinnedFilter.push(item);
                }
                else {
                    uppinnedFilter.splice(index2, 1);
                }
            }
            return {
                pinedQuickFilter,
                uppinnedFilter
            };
        });
    }

    handleApplyQF = (item, key) => {
        this.applyFilter(item)();
        this.setState({
            appliedQFActive: key
        });
    }

    handleClearFilterWithTab = (key) => {
        this.props.actionBarConfig.tabs.onChange(key)();
        this.setState({ appliedQFActive: -1 })
    }

    // Function : To handle the opening and closing of the filter modal
    handleFilterModalChange = () => {
        this.setState(prevState => ({
            filterModalOpen: !prevState.filterModalOpen
        }));
    }

    // Function : To render the filter modal using Table Main Level Filters
    renderFilterSection = () => {
        const { actionBarConfig, tableConfig } = this.props;
        return actionBarConfig.filters && <TableMainLevelFilters
            appliedFilters={this.state.tableDataPayload.filter}
            applyFilter={this.applyFilter}
            closeFilterModal={this.closeFilterModal}
            filterItems={this.state.headersData.main}
            isLoading={this.props.generalStore.createQuickFiltersData.isLoading}
            quickFilterItems={this.state.quickFilterData}
            saveQuickFilter={this.saveQuickFilter}
            saveSuccess={this.props.generalStore.createQuickFiltersData?.isSuccess}
            headersData={this.getTableHeaders("state")}
            saveFilters={this.saveFilters}
            tableConfig={tableConfig}
            getTableDataSuccess={this.props.getTableDataSuccess}
            clearMainFilter={() => this.handleApplyQF({}, -1)}
            stickyCols={this.state.stickyCols}
            {...this.props}
        />
    }

    toggleSelectedMode = (val) => {
        console.log(23232323, val)
        const { selectedItems, tableDataPayload, localConfig } = this.state;
        const pageSize = tableDataPayload?.pageSize || 10;

        const selectedData = Object.values(selectedItems || {});
        const totalSelectedItems = selectedData.length;

        const maxPage = Math.ceil(totalSelectedItems / pageSize);
        console.log(555555555555, val, selectedData.slice(0, pageSize));

        this.setState((prev) => ({
            tableData: val
                ? selectedData.slice(0, pageSize) // Slice data only if selected mode is active
                : localConfig.tableData || [],
            currentPage: val ? 1 : localConfig.currentPage,
            pageNumber: val ? 1 : localConfig.pageNumber,
            maxPage: val ? maxPage : localConfig.maxPage,
            totalItems: val ? totalSelectedItems : localConfig.totalItems,
            localConfig: {
                ...prev.localConfig,
                selectedMode: val,
                ...(val && {
                    tableData: prev.tableData,
                    currentPage: prev.currentPage,
                    maxPage: prev.maxPage,
                    pageNumber: prev.pageNumber,
                    getTableApiData: prev.getTableApiData,
                    totalItems: prev.totalItems,
                }),
            }
        }), () => this.handleStickyCols());
    };

    handleStickyCols = () => {
        setTimeout(() => {
            document.querySelectorAll("tr .sticky-col").forEach(elem => {
                let temp = elem, left = 0;
                while (temp.previousElementSibling) {
                    left += temp.previousElementSibling.clientWidth;
                    temp = temp.previousElementSibling;
                }
                elem.style.left = `${left}px`;
            });

            document.querySelectorAll("div .sticky-col").forEach(elem => {
                let temp = elem, left = 0;
                while (temp.previousElementSibling) {
                    left += temp.previousElementSibling.clientWidth;
                    temp = temp.previousElementSibling;
                }
                elem.style.left = `${left}px`;
            });
        }, 0);
    }


    isAllDataOnCurrentPageSelected = () => {
        return this.state.tableData?.every(item => this.state.selectedItems?.[item?.id])
    }

          // Function : To render the child component
          getChildComponent = () => {
            switch (this.state.selectedModal) {
                case "imageModal": return GenericMediaViewModal
            }
        }
    
        getParentChildProps = () => {
            switch (this.state.selectedModal) {
                case "imageModal": {
                    let extName = getFileTypeFromUrl(this.state.parentModalData || "");
                    let type = getFileNameFromExt(extName)
                    return {
                        animationType: "bottom",
                        parentModalOuterMostClassName: 'flex items-center justify-center',
                        postitionProps: { position: 'relative' },
                        dimensions: { width: "80%", height: "80%", maxHeight: '660px', maxWidth: '1140px' },
                        closeModal: () => {this.setState({selectedModal : false})},
                        filesData:  [{
                            fileUrl : this.state.parentModalData,
                            fileType: type,
                        }],
                    }
                }
            }
        }

    getUpdatedPayloadForDownloadReport = (payload) => {
        if(this.props.downloadReportColumnName && Object.values(this.state.selectedItems || {})?.length > 0){
            if(!payload?.data?.["filter"]){
                payload.data["filter"] = {};
            }

            payload.data["filter"] = {
                ...payload.data["filter"],
                [this.props.downloadReportColumnName] : Object.values(this.state.selectedItems || {})?.map(item => item?.[this.props.downloadReportColumnName])?.join(',')
            }
        }
        return payload;
    }

    render() {
        //that is declared because this can't be accessed here.
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let data = Object.keys(that.state.headersData.main[that.state.headersType.main]);
                let stickyColsLength = that.state.stickyCols ? that.state.stickyCols.length : 0
                data = that.state.stickyCols ? data.filter(key => !that.state.stickyCols.includes(key)) : data
                const item = data.splice(fromIndex - 1 - stickyColsLength, 1)[0];
                data.splice(toIndex - 1 - stickyColsLength, 0, item);
                if (fromIndex - 1 - stickyColsLength >= 0 && toIndex - 1 - stickyColsLength >= 0) {
                    that.setState((prevState) => {
                        let headersData = { ...prevState.headersData }
                        let x = headersData.main[prevState.headersType.main]
                        headersData.main[prevState.headersType.main] = {};
                        data.map(key => headersData.main[prevState.headersType.main][key] = x[key])
                        that.state.stickyCols && that.state.stickyCols.map(key => { headersData.main[prevState.headersType.main][key] = x[key] })

                        return {
                            headersData: headersData,
                        }
                    }, () => that.createCustomHeaders());
                }

            },
            nodeSelector: "th",

            lineClassName: "chmb-drag-line"
        };
        const { actionBarConfig, tableConfig } = this.props;

        return (
            <div className="table-component-new-style">
                {this.state.braedCrumps && <BraedCrumps
                    OpenDocumentsDropdown={this.openDocumentsDropdown}
                    OpenEmailModal={this.openEmailModal}
                    ToggleDownloadReportModal={this.toggleDownloadReportModal}
                    handleDownloadReportPo={this.handleDownloadReportPo}
                    handleDownloadReportPi={this.handleDownloadReportPi}
                    // OpenSortingModal={this.openSortingModal}
                    OpenMoreMenu={this.openMoreMenu}
                    ConfirmAction={this.confirmAction}
                    handleTrueFalse={this.handleTrueFalse}
                    refreshapidata={this.refreshapidata}
                    // State : To store the response of the document dropdown data for this display name.
                    downloadDocumentsConfigurationObject={this.state.downloadDocumentsConfigurationObject}
                    {...this.props}
                    {...this.state}
                    key={this.props.location.pathname}
                />}

                {actionBarConfig.tabs || this.state.quickFilterData.length !== 0 ?
                    <div className={`select-tab-head vendor-product-tools top-${90 + getNumberOfStrips()}`}>
                        <div className="mep-heading mep-heading-space-between">
                            <div className='flex items-center justify-start'>
                                {actionBarConfig.tabs &&
                                    <div className='all-tabs-area relative'>
                                        {Object.keys(actionBarConfig.tabs.data).map(key =>
                                            <LanguageTranslator id={`${key}tab`} tag='span' style={{position: 'relative'}} className={this.state.appliedQFActive !== -1 ? "sel-drop-item" : this.state.tableDataPayload[actionBarConfig.tabs.key] == key ? "select-item" : "sel-drop-item"} onClick={this.state.appliedQFActive === -1 ? () => {
                                                actionBarConfig.tabs.onChange(key)();
                                                this.setState(() => ({
                                                    tableScrollLeft : 0,
                                                }))
                                            } : this.state.tableDataPayload[actionBarConfig.tabs.key] == key ? () => this.handleApplyQF({}, -1) : () => this.handleClearFilterWithTab(key)}>
                                                <LanguageTranslator>{actionBarConfig.tabs.data[key]}</LanguageTranslator>
                                                {this.state.tableDataPayload[actionBarConfig.tabs.key] == key ? (
                                                <motion.div
                                                    className={`absolute h-[2px] bg-primary -bottom-[1px] left-0 right-0`}
                                                    // layoutId="underline"
                                                    // id="underline"
                                                    layoutId={'underline'}
                                                    id={'underline'}
                                                    initial={false}
                                                    transition={{
                                                        duration: .5,
                                                        type: 'spring'
                                                    }}
                                                />
                                            ) : null}
                                            </LanguageTranslator> 
                                        )}
                                    </div>}

                                {this.state.quickFilterData && this.state.quickFilterData.length !== 0 || this.state.pinedQuickFilter && this.state.pinedQuickFilter.length !== 0 ? <div className='custom-field'>
                                    {this.state.pinedQuickFilter.length !== 0 ?
                                        this.state.pinedQuickFilter.map((item, index) =>
                                            <label className="qf-item-bar">
                                                <span className={Object.keys(this.state.tableDataPayload.filter).length !== 0 ? this.state.appliedQFActive === index ? "select-item" : "sel-drop-item" : "sel-drop-item"} key={item.filterName}>
                                                    <span onClick={() => this.handleApplyQF(item.filterValue, index)} className="seldi-text">{item.filterName}</span>
                                                    <span className="seldi-cross" onClick={() => this.handleApplyQF({}, -1)}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.9523 3.99393C14.2358 4.27751 14.2358 4.73728 13.9523 5.02086L5.23851 13.7346C4.95493 14.0182 4.49516 14.0182 4.21158 13.7346C3.92801 13.451 3.92801 12.9913 4.21158 12.7077L12.9253 3.99393C13.2089 3.71036 13.6687 3.71036 13.9523 3.99393Z" fill="white" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.21158 3.99393C4.49516 3.71036 4.95493 3.71036 5.23851 3.99393L13.9523 12.7077C14.2358 12.9913 14.2358 13.451 13.9523 13.7346C13.6687 14.0182 13.2089 14.0182 12.9253 13.7346L4.21158 5.02086C3.92801 4.73728 3.92801 4.27751 4.21158 3.99393Z" fill="white" />
                                                        </svg>
                                                    </span>
                                                    <span className='quick-three-dot' onClick={() => this.handleQF(index)}>
                                                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="1.60385" cy="2.23935" r="1.28036" fill="#707494" />
                                                            <circle cx="1.60385" cy="7.15927" r="1.28036" fill="#707494" />
                                                            <circle cx="1.60385" cy="12.0792" r="1.28036" fill="#707494" />
                                                        </svg>
                                                        {this.state.qfDropdown === index &&
                                                            <ul className="filter-menu">
                                                                <LanguageTranslator tag='li' className="filter-item" onClick={item.is_default == 1 ? this.saveQuickFilter(item.filterName, item.filterValue, false, 0) : this.saveQuickFilter(item.filterName, item.filterValue, false, 1)}>{item.is_default == 1 ? "Remove As Default" : "Mark As Default"}</LanguageTranslator>
                                                                <LanguageTranslator tag='li' className="filter-item" onClick={() => this.removePinnedFilter(item)}>{this.state.pinedQuickFilter.length !== 0 ? "Remove Pin Item" : "Pin Item"}</LanguageTranslator>
                                                                <LanguageTranslator tag='li' className="filter-item delete-tab" onClick={this.saveQuickFilter(item.filterName, item.filterValue, true)}>Delete Tab</LanguageTranslator>
                                                            </ul>}
                                                    </span>
                                                </span>
                                            </label>
                                        )
                                        : this.state.uppinnedFilter.length !== 0 ?
                                            this.state.uppinnedFilter.slice(0, 3).map((item, index) =>
                                                <label className="qf-item-bar">
                                                    <span className={Object.keys(this.state.tableDataPayload.filter).length !== 0 ? this.state.appliedQFActive === index ? "select-item" : "sel-drop-item tab-qf-hover" : "sel-drop-item tab-qf-hover"} key={item.filterName}>
                                                        <span onClick={() => this.handleApplyQF(item.filterValue, index)} className="seldi-text">{item.filterName}</span>
                                                        <span className="seldi-cross" onClick={() => this.handleApplyQF({}, -1)}>
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.9523 3.99393C14.2358 4.27751 14.2358 4.73728 13.9523 5.02086L5.23851 13.7346C4.95493 14.0182 4.49516 14.0182 4.21158 13.7346C3.92801 13.451 3.92801 12.9913 4.21158 12.7077L12.9253 3.99393C13.2089 3.71036 13.6687 3.71036 13.9523 3.99393Z" fill="white" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.21158 3.99393C4.49516 3.71036 4.95493 3.71036 5.23851 3.99393L13.9523 12.7077C14.2358 12.9913 14.2358 13.451 13.9523 13.7346C13.6687 14.0182 13.2089 14.0182 12.9253 13.7346L4.21158 5.02086C3.92801 4.73728 3.92801 4.27751 4.21158 3.99393Z" fill="white" />
                                                            </svg>
                                                        </span>
                                                        <span className='quick-three-dot' onClick={() => this.handleQF(index)}>
                                                            <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="1.60385" cy="2.23935" r="1.28036" fill="#707494" />
                                                                <circle cx="1.60385" cy="7.15927" r="1.28036" fill="#707494" />
                                                                <circle cx="1.60385" cy="12.0792" r="1.28036" fill="#707494" />
                                                            </svg>
                                                            {this.state.qfDropdown === index &&
                                                                <ul className="filter-menu">
                                                                    <LanguageTranslator tag='li' className="filter-item" onClick={item.is_default == 1 ? this.saveQuickFilter(item.filterName, item.filterValue, false, 0) : this.saveQuickFilter(item.filterName, item.filterValue, false, 1)}>{item.is_default == 1 ? "Remove As Default" : "Mark As Default"}</LanguageTranslator>
                                                                    <LanguageTranslator tag='li' className="filter-item" onClick={() => this.handlePinedQuickFilter(item)}>{this.state.pinedQuickFilter.length !== 0 ? "Remove Pin Item" : "Pin Item"}</LanguageTranslator>
                                                                    <LanguageTranslator tag='li' className="filter-item delete-tab" onClick={this.saveQuickFilter(item.filterName, item.filterValue, true)}>Delete Tab</LanguageTranslator>
                                                                </ul>}
                                                        </span>
                                                    </span>
                                                </label>
                                            ) : null}
                                    {this.state.uppinnedFilter && this.state.uppinnedFilter.length > 0 ? <span className="sel-drop-item mx-0 !p-0">
                                        <button onClick={this.openQuickFilter} className="all-filter-btn">
                                            More
                                            <span className={`more-qf`}>
                                                <svg className={`${this.state.quickFilterDropdown && "rotate180 icon-Fill"}`} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.6665 4.72266L0.666504 0.722656L8.6665 0.722656L4.6665 4.72266Z" fill="#4A5D7A" />
                                                </svg>
                                            </span>
                                        </button>
                                        {this.state.quickFilterDropdown &&
                                            <div className="moreqf-menu" id="quickFilterDropdown">
                                                <div className="inp-btn">
                                                    <input placeholder="Search filters..." type="text" autocomplete="off" onChange={(e) => this.setState({ searchQfvalue: e.target.value })} />
                                                    <span className="search-btn" id="search">
                                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.52673 2.37419C4.23287 2.37419 2.37333 4.23373 2.37333 6.52759C2.37333 8.82145 4.23287 10.681 6.52673 10.681C8.82059 10.681 10.6801 8.82145 10.6801 6.52759C10.6801 4.23373 8.82059 2.37419 6.52673 2.37419ZM1.18665 6.52759C1.18665 3.57834 3.57748 1.1875 6.52673 1.1875C9.47598 1.1875 11.8668 3.57834 11.8668 6.52759C11.8668 9.47684 9.47598 11.8677 6.52673 11.8677C3.57748 11.8677 1.18665 9.47684 1.18665 6.52759Z" fill="#67768E" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.45955 9.45894C9.69127 9.22723 10.067 9.22723 10.2987 9.45894L12.8797 12.04C13.1114 12.2717 13.1114 12.6474 12.8797 12.8791C12.648 13.1108 12.2723 13.1108 12.0406 12.8791L9.45955 10.2981C9.22784 10.0663 9.22784 9.69066 9.45955 9.45894Z" fill="#67768E" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                {this.state.uppinnedFilter.length !== 0 ?
                                                    this.state.uppinnedFilter.map((item, index) => {
                                                        if (item.filterName.toLowerCase().includes(this.state.searchQfvalue.toLowerCase())) {
                                                            return (
                                                                <span className={"more-item"} key={item.filterName}>
                                                                    <label className='qf-items' onClick={() => { this.handleApplyQF(item.filterValue, index); this.handlePinedQuickFilter(item) }}>{item.filterName} {!this.state.quickFilterSettings && item.is_default == 1 && <span>Default</span>}</label>
                                                                    <div className="qf-more-icon">
                                                                        <span className='pin-icon' onClick={this.state.pinedQuickFilter.length >= 3 ? null : () => this.handlePinedQuickFilter(item)}>
                                                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M0.724917 13.3975L4.3208 9.18802L5.84628 10.7135L1.6303 14.3094C1.51027 14.41 1.35776 14.4634 1.20123 14.4596C1.0378 14.4564 0.881703 14.3911 0.764648 14.2769C0.647463 14.1627 0.578319 14.0084 0.570986 13.8451C0.563503 13.6817 0.618579 13.5217 0.724837 13.3975L0.724917 13.3975ZM8.85881 11.681C8.98707 11.8036 9.15798 11.8712 9.33535 11.8696C9.5127 11.8678 9.68227 11.7969 9.80814 11.6719C9.934 11.5469 10.006 11.3778 10.0088 11.2004V8.87683L13.8301 6.55965C14.0395 6.43857 14.1881 6.23458 14.2391 5.99798C14.2903 5.76136 14.2392 5.51427 14.0983 5.31746L9.72793 0.953197C9.53113 0.812366 9.28403 0.761183 9.04741 0.812366C8.81094 0.863399 8.60681 1.01201 8.48574 1.2214L6.16419 5.02981H3.84279C3.66379 5.03056 3.49228 5.10164 3.36552 5.22796C3.23861 5.35427 3.16677 5.52549 3.16527 5.7045C3.16393 5.8835 3.23307 6.05576 3.35789 6.18418L8.85881 11.681Z" fill="#CED5E1" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="qf-three" onClick={() => this.handleThreeDot(false, index)}>
                                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.42627 9.03607C7.42627 8.21577 8.09126 7.55078 8.91156 7.55078C9.73186 7.55078 10.3969 8.21577 10.3969 9.03607C10.3969 9.85637 9.73186 10.5214 8.91156 10.5214C8.09126 10.5214 7.42627 9.85637 7.42627 9.03607Z" fill="#23235F" />
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.42627 3.83685C7.42627 3.01655 8.09126 2.35156 8.91156 2.35156C9.73186 2.35156 10.3969 3.01655 10.3969 3.83685C10.3969 4.65716 9.73186 5.32214 8.91156 5.32214C8.09126 5.32214 7.42627 4.65716 7.42627 3.83685Z" fill="#23235F" />
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.42627 14.2353C7.42627 13.415 8.09126 12.75 8.91156 12.75C9.73186 12.75 10.3969 13.415 10.3969 14.2353C10.3969 15.0556 9.73186 15.7206 8.91156 15.7206C8.09126 15.7206 7.42627 15.0556 7.42627 14.2353Z" fill="#23235F" />
                                                                            </svg>
                                                                        </span>
                                                                        {/* {this.state.threeDotPin === index && */}
                                                                            <ul className={`filter-menu ${this.state.uppinnedFilter?.length > 1 && this.state.uppinnedFilter?.length - 1 === index ? '!top-auto !bottom-full' : '!top-full'} !left-auto !right-0 !duration-100 origin-top-right ${this.state.threeDotPin === index ? 'scale-100 opacity-100 visible pointer-events-auto' : 'h-0 w-0 overflow-hidden scale-90 opacity-0 invisible pointer-events-none'}`}>
                                                                                <li className="filter-item" onClick={item.is_default == 1 ? this.saveQuickFilter(item.filterName, item.filterValue, false, 0) : this.saveQuickFilter(item.filterName, item.filterValue, false, 1)}>{item.is_default == 1 ? "Remove" : "Mark"} as Default</li>
                                                                                <li className="filter-item delete-tab" onClick={this.saveQuickFilter(item.filterName, item.filterValue, true)}>Delete Tab</li>
                                                                            </ul>
                                                                            {/* } */}
                                                                    </div>
                                                                </span>
                                                            )
                                                        }
                                                    }
                                                    ) : null}
                                            </div>}
                                    </span> : null}
                                </div> : null}
                            </div>

                            {actionBarConfig.tabsExtras && actionBarConfig.tabsExtras()}
                        </div>

                    </div> : null}

                {actionBarConfig.topExtra && actionBarConfig.topExtra()}

                {
                    tableConfig.summary &&
                    (tableConfig.summary.render ||
                        tableConfig.summary.viewErrorPo ||
                        (Array.isArray(tableConfig.summary) && tableConfig.summary.length > 0)) &&
                    <div className="invoice-manage-head summaryHead">
                        <div className="imh-left">
                            {tableConfig.summary &&
                                (tableConfig.summary.render ? tableConfig.summary.render() :
                                    <React.Fragment>
                                        {tableConfig.summary.map(item =>
                                            <div className="summary-data">
                                                <LanguageTranslator tag="p">{item.label}</LanguageTranslator>
                                                <LanguageTranslator tag="span">{item.value == null ? "NA" : item.value}</LanguageTranslator>
                                            </div>
                                        )}
                                    </React.Fragment>)}
                            {tableConfig.summary && tableConfig?.summary?.viewErrorPo ?
                                <div className={`p-lr-47 eyeOrder ${this.state.eyeClass}`}>
                                    <div className="otb-reports-content " style={{ display: 'inline' }}>
                                        <div className="otbrc-left">
                                            <div className="otbrc-details">
                                                <span className="nppqh-info otbrc-right" style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        let type;
                                                        let filteredValue = tableConfig.summary.filterValue;
                                                        if (filteredValue && Object.keys(filteredValue).length == 0) {
                                                            type = this.state.tableDataPayload.type == 2 ? 1 : this.state.tableDataPayload.type == 4 ? 3 : this.state.tableDataPayload.type;
                                                        }
                                                        else {
                                                            type = this.state.tableDataPayload.type == 3 || this.state.tableDataPayload.type == 4 ? 4 : 2;
                                                        }
                                                        this.setState(prevState => ({
                                                            tableDataPayload: {
                                                                ...prevState.tableDataPayload,
                                                                filter: filteredValue,
                                                                pageNo: 1,
                                                                type: type
                                                            },
                                                            customSearchApplied: false,
                                                            customSearchData: []
                                                        }), this.getTableData)
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className='m-left-45' width="18" height="12" viewBox="0 0 20.603 12.018">
                                                        <path fill="#a2b7df" d="M10.314 6.717a11.2 11.2 0 0 1 8.159 3.98c-1.2 1.577-4.046 4.6-8.159 4.6-3.794 0-6.808-3.036-8.136-4.642a11.311 11.311 0 0 1 8.136-3.943zm0-1.717C3.817 5 0 10.624 0 10.624s4.151 6.395 10.314 6.395c6.638 0 10.289-6.395 10.289-6.395S16.919 5 10.314 5zM10.3 7.575a3.434 3.434 0 1 0 3.434 3.434A3.435 3.435 0 0 0 10.3 7.575z" transform="translate(0 -5)" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                }


                <div className="p-rgt-0 bg-white">
                    <div className="tableBody-border">
                        <div className='imh-update' id="#floatingHeader">
                            <div className="invoice-manage-head">
                                <div className="imh-left">

                                </div>
                                <div className="imh-right">

                                    <div className="new-gen-pagination">
                                        <div className="ngp-left">
                                            <div className='flex items-center gap-2'>
                                                {/* Render the search mode toggle button here */}
                                                {(this.state.getTableDataSuccess && this.state.getTableHeadersSuccess) && <React.Fragment>
                                                    {this.state.localConfig?.selectedMode ?
                                                        <GenericCustomButton
                                                            label="Back"
                                                            leftIcon={<Icons.BackArrowIcon />}
                                                            onClick={() => {
                                                                console.log("clicked!", 1)
                                                                this.toggleSelectedMode(false)
                                                            }}
                                                            className={'!flex items-center !h-[30px] rounded hover:opacity-75'}
                                                        />
                                                        : Object.keys(this.state.selectedItems || {})?.length > 0 && <div className='flex items-center h-[30px] border border-[#CCD8ED] rounded px-2'>
                                                            <Tooltip
                                                                overlayClassName='!p-0'
                                                                overlayStyle={{ fontSize: '12px' }}
                                                                title={<LanguageTranslator>Deselect All</LanguageTranslator>}
                                                            >
                                                                <label className="generic-checkbox">
                                                                    <input type="checkBox" onChange={this.deselectAll} checked={true} />
                                                                    <span className="checkmark1"></span>
                                                                </label>
                                                            </Tooltip>
                                                            <div className='underline text-xs cursor-pointer hover:text-primary' onClick={() => {
                                                                console.log('clicked!', 2)
                                                                this.toggleSelectedMode(true)
                                                            }}>{Object.keys(this.state.selectedItems || {})?.length} Item{Object.keys(this.state.selectedItems || {})?.length > 1 ? 's' : ''} Selected</div>
                                                        </div>}
                                                </React.Fragment>}
                                                {actionBarConfig.search &&
                                                    <div className={`imhl-search group ${(actionBarConfig.lhsExtras || !(Object.keys(this.state.tableDataPayload?.filter || {})?.length == 0 || (Object.keys(this.state.tableDataPayload?.filter || {})?.length == 1 && 'item' in this.state.tableDataPayload?.filter && Object.keys(this.state.tableDataPayload?.filter?.item || {})?.length == 0)))
                                                        ? 'mr-2.5' : ''}`}>
                                                        <input
                                                            className={`search_bar onfocus ${this.state.tableDataPayload.search?.length !== 0 && "wid-250"}`}
                                                            onChange={this.setSearchValue()}
                                                            onKeyDown={this.handleSearchKeyDown}
                                                            onClick={this.expandField}
                                                            placeholder="Search"
                                                            type="search"
                                                            value={this.state.tableDataPayload.search}
                                                            autoComplete="off"
                                                        />
                                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.52673 2.37419C4.23287 2.37419 2.37333 4.23373 2.37333 6.52759C2.37333 8.82145 4.23287 10.681 6.52673 10.681C8.82059 10.681 10.6801 8.82145 10.6801 6.52759C10.6801 4.23373 8.82059 2.37419 6.52673 2.37419ZM1.18665 6.52759C1.18665 3.57834 3.57748 1.1875 6.52673 1.1875C9.47598 1.1875 11.8668 3.57834 11.8668 6.52759C11.8668 9.47684 9.47598 11.8677 6.52673 11.8677C3.57748 11.8677 1.18665 9.47684 1.18665 6.52759Z" fill="#23235F" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.45955 9.45894C9.69127 9.22723 10.067 9.22723 10.2987 9.45894L12.8797 12.04C13.1114 12.2717 13.1114 12.6474 12.8797 12.8791C12.648 13.1108 12.2723 13.1108 12.0406 12.8791L9.45955 10.2981C9.22784 10.0663 9.22784 9.69066 9.45955 9.45894Z" fill="#23235F" />
                                                        </svg>
                                                        {this.state.tableDataPayload.search !== "" &&
                                                            <span className="absolute top-0 left-[80%]" onClick={this.setSearchValue("")}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 13.119 13.119">
                                                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                                                </svg>
                                                            </span>}
                                                        <span className="td-search cursor-pointer invisible w-2 flex items-center justify-center group-hover:visible" onClick={this.toggleCustomSearchModal}>
                                                            <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="1.60385" cy="2.23935" r="1.28036" fill="#23235F" />
                                                                <circle cx="1.60385" cy="7.15927" r="1.28036" fill="#23235F" />
                                                                <circle cx="1.60385" cy="12.0792" r="1.28036" fill="#23235F" />
                                                            </svg>
                                                        </span>
                                                    </div>}
                                                {actionBarConfig.lhsExtras && actionBarConfig.lhsExtras()}

                                                {!(Object.keys(this.state.tableDataPayload?.filter || {})?.length == 0 || (Object.keys(this.state.tableDataPayload?.filter || {})?.length == 1 && 'item' in this.state.tableDataPayload?.filter && Object.keys(this.state.tableDataPayload?.filter?.item || {})?.length == 0)) &&
                                                    <div className='relative'>
                                                        <GenericCustomButton
                                                            label="Applied Filters"
                                                            className={`${this.state.showAppliedFilters && "applied-filter-opened"} applied-filters ${actionBarConfig.lhsExtras ? '!ml-2.5' : ''}`}
                                                            onClick={() => this.handleTrueFalse("showAppliedFilters", !this.state.showAppliedFilters)}
                                                            rightIcon={<span className='filter-count relative top-[50%] -translate-y-[50%] p-0.5 flex items-center justify-center text-[10px]'>{'item' in this.state.tableDataPayload?.filter ? Object.keys(this.state.tableDataPayload?.filter || {})?.length - 1 + Object.keys(this.state.tableDataPayload?.filter?.item || {})?.length : Object.keys(this.state.tableDataPayload?.filter || {})?.length}</span>}
                                                            leftIcon={
                                                                <span className='mr-2'>
                                                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.75 3.79688H12.25" stroke="#5D6675" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M4.08203 7.29688H9.91536" stroke="#5D6675" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M5.83203 10.7969H8.16536" stroke="#5D6675" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </span>
                                                            }
                                                        />
                                                        {this.state.showAppliedFilters &&
                                                            <AppliedFiltersPopUp
                                                                handleTrueFalse={this.handleTrueFalse}
                                                                appliedFilters={this.state.tableDataPayload.filter}
                                                                headersData={this.state.headersData}
                                                                headersType={this.state.headersType}
                                                                saveQuickFilter={this.saveQuickFilter}
                                                                saveFilters={this.saveFilters}
                                                                tableConfig={tableConfig}
                                                                getTableDataSuccess={this.props.getTableDataSuccess}
                                                                clearFilter={() => this.handleApplyQF({}, -1)}
                                                                isAppliedFilterModalOpen={this.state.showAppliedFilters}
                                                                applyFilter={this.applyFilter}
                                                            />
                                                        }
                                                    </div>}

                                                {/* Render the sorting icons here */}
                                                {/* <TableComponentSorting
                                                    actionBarConfig={actionBarConfig}
                                                    OpenSortingModal={this.openSortingModal}
                                                /> */}

                                                {/* {Object.keys(this.state.tableDataPayload.filter).length != 0 && <button type="button" className='sync-btn' onClick={()=>this.saveFilters()}>Save Filter</button>} */}
                                                {!this.props.hidePaginationDropdown && <div className="drop-toggle-btn tablePageSize">
                                                    <button type="button" className="dtb-show" onClick={this.selectPageination}>
                                                        <span className="total-count">
                                                            <span className='to-num'>{this.state.totalItems <= this.state.tableDataPayload.pageSize ? this.state.totalItems : this.state.tableDataPayload.pageSize || "10"}</span>
                                                            <LanguageTranslator tag="span" className="to-slas">of</LanguageTranslator>
                                                            <span className='to-num'>{this.state.totalItems}
                                                                <span className="generic-tooltip">
                                                                    {this.state.totalItems}
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <LanguageTranslator>Items</LanguageTranslator> <IconsPath.DownArrowFilled /></button>
                                                    {this.state.selectPage &&
                                                        <div className="page-dropdown">
                                                            {[10, 15, 20].map(pageSize =>
                                                                <button type="button" onClick={() => this.updatePageSize(pageSize)} className="sel-page">
                                                                    {pageSize}
                                                                </button>
                                                            )}
                                                        </div>}
                                                </div>}

                                            </div>
                                        </div>
                                        {!this.props.hidePaginationDropdown && <div className="ngp-right tablePagination">
                                            <div className="nt-btn">
                                                <div className="pagination-inner">
                                                    <ul className="pagination-item">
                                                        <li>
                                                            <button className={`${this.state.currentPage == 1 && "gen-action-btn-disable disable-"}first-btn`} disabled={this.state.currentPage == 1} onClick={this.changePage(1)}>
                                                                <span className="page-item-btn-inner">
                                                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8977 6.49114C11.2557 6.84912 11.2557 7.42952 10.8977 7.7875L6.96254 11.7227L10.8977 15.6578C11.2557 16.0158 11.2557 16.5962 10.8977 16.9542C10.5397 17.3122 9.95931 17.3122 9.60133 16.9542L5.018 12.3708C4.66002 12.0129 4.66002 11.4325 5.018 11.0745L9.60133 6.49114C9.95931 6.13316 10.5397 6.13316 10.8977 6.49114Z" fill="#23235F" />
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.3143 6.49114C17.6723 6.84912 17.6723 7.42952 17.3143 7.7875L13.3792 11.7227L17.3143 15.6578C17.6723 16.0158 17.6723 16.5962 17.3143 16.9542C16.9563 17.3122 16.3759 17.3122 16.018 16.9542L11.4346 12.3708C11.0766 12.0129 11.0766 11.4325 11.4346 11.0745L16.018 6.49114C16.3759 6.13316 16.9563 6.13316 17.3143 6.49114Z" fill="#23235F" />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className={`${this.state.currentPage == 1 && "gen-action-btn-disable dis-"}prev-btn`} disabled={this.state.currentPage == 1} onClick={this.changePage(this.state.currentPage - 1)}>
                                                                <span className="page-item-btn-inner">
                                                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.89781 0.491142C7.2558 0.849122 7.2558 1.42952 6.89781 1.7875L2.96266 5.72266L6.89781 9.65781C7.2558 10.0158 7.2558 10.5962 6.89781 10.9542C6.53983 11.3122 5.95943 11.3122 5.60145 10.9542L1.01812 6.37084C0.660139 6.01286 0.660139 5.43246 1.01812 5.07448L5.60145 0.491142C5.95943 0.133161 6.53983 0.133161 6.89781 0.491142Z" fill="#23235F" />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            {!this.state.enableJumpPage ? <button className="pi-number-btn" onClick={() => this.handleJumpPage()}>
                                                                <span>{this.state.currentPage}<label>of</label>{this.state.maxPage}</span>
                                                            </button> :
                                                                <input
                                                                    ref={this.jumpInputRef}
                                                                    className="pinum-input"
                                                                    onChange={this.setPageNumber}
                                                                    onKeyPress={this.setPageNumber}
                                                                    type="number"
                                                                    autoComplete="off"
                                                                    value={this.state.pageNumber}
                                                                />}
                                                        </li>
                                                        <li>
                                                            <button className={`${this.state.currentPage == this.state.maxPage && "gen-action-btn-disable dis-"}next-btn`} disabled={this.state.currentPage == this.state.maxPage} onClick={this.changePage(this.state.currentPage + 1)}>
                                                                <span className="page-item-btn-inner">
                                                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.934461 0.491142C0.57648 0.849122 0.57648 1.42952 0.934461 1.7875L4.86961 5.72266L0.934461 9.65781C0.57648 10.0158 0.57648 10.5962 0.934461 10.9542C1.29244 11.3122 1.87284 11.3122 2.23082 10.9542L6.81416 6.37084C7.17214 6.01286 7.17214 5.43246 6.81416 5.07448L2.23082 0.491142C1.87284 0.133161 1.29244 0.133161 0.934461 0.491142Z" fill="#23235F" />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </li>
                                                        <li >
                                                            <button className={`${this.state.currentPage == this.state.maxPage && "gen-action-btn-disable dis-"}last-btn`} disabled={this.state.currentPage == this.state.maxPage} onClick={this.changePage(this.state.maxPage)}>
                                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9346 6.49114C11.5766 6.84912 11.5766 7.42952 11.9346 7.7875L15.8697 11.7227L11.9346 15.6578C11.5766 16.0158 11.5766 16.5962 11.9346 16.9542C12.2926 17.3122 12.873 17.3122 13.2309 16.9542L17.8143 12.3708C18.1723 12.0129 18.1723 11.4325 17.8143 11.0745L13.2309 6.49114C12.873 6.13316 12.2926 6.13316 11.9346 6.49114Z" fill="#23235F" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.51796 6.49114C5.15998 6.84912 5.15998 7.42952 5.51796 7.7875L9.45311 11.7227L5.51796 15.6578C5.15998 16.0158 5.15998 16.5962 5.51796 16.9542C5.87594 17.3122 6.45634 17.3122 6.81432 16.9542L11.3977 12.3708C11.7556 12.0129 11.7556 11.4325 11.3977 11.0745L6.81432 6.49114C6.45634 6.13316 5.87594 6.13316 5.51796 6.49114Z" fill="#23235F" />
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>}
                                        {this.props.actionBarConfig?.renderPaginationRight?.({
                                            selectedItems: this.state.selectedItems || {}
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {actionBarConfig.newExtraButtons &&
                            <div className="invoice-manage-head">
                                <div className="imh-left">
                                </div>
                                <div className="imh-right">
                                    {actionBarConfig.newExtraButtons && actionBarConfig.newExtraButtons(this.state.selectedItems)}

                                    {actionBarConfig.emailReport &&
                                        <button className="gen-action-btn" type="button" onClick={this.openEmailModal} disabled={actionBarConfig.emailReport.disabled}>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.28467 3.77702C2.87076 3.77702 2.52897 4.11881 2.52897 4.53271V13.6011C2.52897 14.015 2.87076 14.3568 3.28467 14.3568H15.3758C15.7897 14.3568 16.1315 14.015 16.1315 13.6011V4.53271C16.1315 4.11881 15.7897 3.77702 15.3758 3.77702H3.28467ZM1.01758 4.53271C1.01758 3.28409 2.03604 2.26562 3.28467 2.26562H15.3758C16.6244 2.26562 17.6429 3.28409 17.6429 4.53271V13.6011C17.6429 14.8497 16.6244 15.8682 15.3758 15.8682H3.28467C2.03604 15.8682 1.01758 14.8497 1.01758 13.6011V4.53271Z" fill="#23235F" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.15425 4.09974C1.39359 3.75783 1.86479 3.67467 2.2067 3.91401L9.33031 8.90054L16.4539 3.91401C16.7958 3.67467 17.267 3.75783 17.5064 4.09974C17.7457 4.44166 17.6625 4.91286 17.3206 5.1522L9.76367 10.4421C9.50347 10.6242 9.15714 10.6242 8.89694 10.4421L1.33998 5.1522C0.998062 4.91286 0.914909 4.44166 1.15425 4.09974Z" fill="#23235F" />
                                            </svg>
                                            <LanguageTranslator tag="span" className="generic-tooltip">Email Report</LanguageTranslator>
                                        </button>}

                                    {actionBarConfig.downloadReport &&
                                        <button className="gen-action-btn" type="button" onClick={this.toggleDownloadReportModal} disabled={this.props.generalStore.getHeadersData.isLoading || this.props.generalStore.downloadReportData.isLoading}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 21.5 17.917">
                                                <g>
                                                    <path d="M8.735 12.526h1.12v2.911a.9.9 0 1 0 1.792 0v-2.911h1.116a.672.672 0 0 0 .475-1.147l-2.013-2.012a.67.67 0 0 0-.95 0L8.263 11.38a.672.672 0 0 0 .472 1.146z" data-name="Path 632" transform="translate(0 -2)" />
                                                    <path d="M13.438 19.916H8.063a1.793 1.793 0 0 1-1.792-1.791v-.9a.9.9 0 0 1 1.792 0v.9h5.375v-.9a.9.9 0 0 1 1.792 0v.9a1.793 1.793 0 0 1-1.792 1.791z" data-name="Path 633" transform="translate(0 -2)" />
                                                    <path d="M18.813 18.349h-1.344a.9.9 0 0 1 0-1.792h1.344a.9.9 0 0 0 .9-.9V6.479a.9.9 0 0 0-.9-.9H9.182a.892.892 0 0 1-.633-.262L7.02 3.792H2.688a.9.9 0 0 0-.9.9v10.969a.9.9 0 0 0 .9.9h1.343a.9.9 0 0 1 0 1.792H2.688A2.691 2.691 0 0 1 0 15.661V4.688A2.691 2.691 0 0 1 2.688 2h4.7a.892.892 0 0 1 .633.262l1.532 1.53h9.259A2.691 2.691 0 0 1 21.5 6.479v9.182a2.691 2.691 0 0 1-2.687 2.688z" data-name="Path 634" transform="translate(0 -2)" />
                                                </g>
                                            </svg>
                                            <LanguageTranslator tag="span" className="generic-tooltip">Export to Excel</LanguageTranslator>
                                        </button>}

                                    {/* {actionBarConfig.sorting &&
                                        <button type="button" className={this.state.sortingModal == true ? "gen-action-btn gen-action-btn-focus sort-genTooltip" : "sort-genTooltip gen-action-btn"} onClick={this.openSortingModal} disabled={actionBarConfig.sorting.disabled}>
                                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.40254 3.5646L2.77932 1.18791C3.05048 0.916749 3.46517 0.916749 3.73646 1.18791L6.11325 3.56469C6.46418 3.91562 6.41635 4.52183 5.98562 4.8089C5.66662 5.0163 5.21998 4.95243 4.94884 4.68127L4.05559 3.78802V13.8055C4.05559 14.2521 3.68874 14.619 3.2421 14.619C2.79546 14.619 2.42861 14.2521 2.42861 13.8055L2.42838 3.80398L1.56707 4.68122C1.29591 4.95237 0.865178 5.01625 0.530285 4.80884C0.0994551 4.52177 0.0516011 3.91559 0.402548 3.56463L0.40254 3.5646ZM10.2445 14.4275C10.5157 14.6986 10.9304 14.6986 11.2017 14.4275L13.5785 12.0507C13.9294 11.6998 13.8816 11.0936 13.4508 10.8065C13.1318 10.5991 12.6852 10.663 12.4141 10.9341L11.5208 11.8274L11.5207 1.79396C11.5207 1.34732 11.1538 0.980469 10.7072 0.980469C10.2606 0.980469 9.89371 1.34732 9.89371 1.79396V11.8114L9.00046 10.9182C8.7293 10.647 8.29857 10.5831 7.96368 10.7905C7.51704 11.0776 7.48511 11.6838 7.83605 12.0348L10.2445 14.4275Z" fill="#23235F" />
                                            </svg>
                                            <span className="generic-tooltip">Sort</span>
                                        </button>} */}

                                    {actionBarConfig.moreMenu &&
                                        <div className="gvpd-three-dot-btn">
                                            <button className="gvpdtdb-btn" onClick={this.openMoreMenu}><span></span></button>
                                            {this.state.moreMenuDropdown &&
                                                <div className="gvpdtd-menu">
                                                    <ul className="gvpdtdm-inner">
                                                        {Object.keys(actionBarConfig.moreMenu.options(this.state.selectedItems)).map(key => {
                                                            let item = actionBarConfig.moreMenu.options(this.state.selectedItems)[key];
                                                            return (
                                                                <li key={key}>
                                                                    <button
                                                                        type="button"
                                                                        className={`gvpdtdm-${item.type} ${item.disabled && "opacity04"}`}
                                                                        onClick={item.disabled ? null : item.confirm ? this.confirmAction(key) : item.onClick}
                                                                    >
                                                                        {item.label}
                                                                    </button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>}
                                        </div>}
                                </div>
                            </div>}


                        {actionBarConfig.bottomExtra && actionBarConfig.bottomExtra()}

                        {this.state.getTableDataSuccess && this.state.getTableHeadersSuccess ?
                            <div className="invoice-manage-table pad-top-10 mainbgColor">
                                <div id={"tableContainerRef"} className="imt-manage-table">
                                    {tableConfig.columnSetting && (this.props.viewType != "grid") &&
                                        <div className="columnFilterGeneric">
                                            <span className="glyphicon btr-radius" onClick={tableConfig.columnSetting.disabled ? null : this.openColumnSettingModal}>
                                                <svg width="8" height="11" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.89781 0.491142C7.2558 0.849122 7.2558 1.42952 6.89781 1.7875L2.96266 5.72266L6.89781 9.65781C7.2558 10.0158 7.2558 10.5962 6.89781 10.9542C6.53983 11.3122 5.95943 11.3122 5.60145 10.9542L1.01812 6.37084C0.660139 6.01286 0.660139 5.43246 1.01812 5.07448L5.60145 0.491142C5.95943 0.133161 6.53983 0.133161 6.89781 0.491142Z" fill="#fff"></path>
                                                </svg>
                                            </span>
                                        </div>}
                                    {this.state.columnSettingModal &&
                                        <ColumnSettingModal
                                            headersData={this.getTableHeaders("state")}
                                            save={(payload) => { this.props.handleApiChange("createHeadersRequest", payload, this.props?.tableComExtraHeaders) }}
                                            loading={this.props.generalStore.createHeadersData.isLoading}
                                            close={this.closeColumnSettingModal}
                                        />}

                                    {this.props?.viewType !== 'grid' ?
                                        <table className={this.state.headersData.main[this.state.headersType.main] && Object.keys(this.state.headersData.main[this.state.headersType.main]).length <= 5 ? "table imt-main-table imtmt-v2" : "table imt-main-table"}>
                                            <thead>
                                                {this.state.headersData.main[this.state.headersType.main] &&
                                                    <ReactDragListView.DragColumn {...dragProps}>
                                                        <div className={`fix-action-btn brder-top-n default-th refIndex p-lr-0 ${this.state.userminWidth}`}>
                                                            <ul className="rab-refresh">
                                                                {tableConfig.select && !this.state.localConfig?.selectedMode &&
                                                                    <li className="rab-rinner">
                                                                        <label className="checkBoxLabel0">
                                                                            <input type="checkBox" checked={this.state.tableData.length != 0 && this.isAllDataOnCurrentPageSelected()} onChange={this.selectAll} />
                                                                            <span className="checkmark1"></span>
                                                                        </label>
                                                                        {this.state.tableData.length != 0 && this.isAllDataOnCurrentPageSelected() &&
                                                                            <span className="select-all-text">
                                                                                {Object.keys(this.state.selectedItems || {})?.length}
                                                                            </span>}
                                                                    </li>}
                                                                <li className="rab-rinner refresh-button" onClick={this.onRefresh}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.1216 3.23566V3.68806C7.1216 4.0048 7.30237 4.2761 7.57399 4.45687C7.70965 4.54741 7.89073 4.59253 8.02639 4.59253C8.16204 4.59253 8.2977 4.54741 8.43336 4.50198L10.7398 3.32617C11.0111 3.19052 11.1922 2.91921 11.2373 2.55736C11.2825 2.19551 11.1017 1.92421 10.8304 1.74344L8.5239 0.160354C8.25259 -0.0204152 7.89074 -0.065844 7.57398 0.115241C7.25724 0.250896 7.07647 0.567636 7.07647 0.929158V1.42667C3.27752 1.87906 0.29248 5.13541 0.29248 9.07004C0.29248 10.7433 0.835104 12.3264 1.8301 13.6832C2.01087 13.9094 2.2825 14.0451 2.5538 14.0451C2.73457 14.0451 2.91566 14 3.09642 13.8643C3.50339 13.5476 3.59393 13.0049 3.27719 12.598C2.50838 11.5578 2.10138 10.3366 2.10138 9.0703C2.14649 6.13008 4.31732 3.68769 7.12153 3.23558L7.1216 3.23566Z" fill="#67768E" />
                                                                        <path d="M15.7149 9.02496C15.7149 7.35165 15.1723 5.76864 14.1773 4.41176C13.8606 4.0048 13.3179 3.91426 12.911 4.23099C12.504 4.54773 12.4134 5.09038 12.7302 5.49734C13.499 6.53747 13.906 7.75867 13.906 9.02501C13.906 11.9646 11.7352 14.4071 8.93091 14.814V14.4522C8.93091 14.1354 8.75014 13.8641 8.52394 13.6833C8.25263 13.5026 7.9359 13.5026 7.66456 13.6382L5.31269 14.814C5.04138 14.9497 4.81519 15.221 4.81519 15.5829C4.81519 15.9447 4.95084 16.216 5.22215 16.3968L7.52863 17.9799C7.66428 18.0704 7.84537 18.1155 8.02614 18.1155C8.16179 18.1155 8.29745 18.0704 8.4331 18.025C8.74984 17.8893 8.93061 17.5726 8.93061 17.2111V16.7136C12.7299 16.216 15.7149 12.9598 15.7149 9.02512V9.02496Z" fill="#67768E" />
                                                                    </svg>
                                                                    <LanguageTranslator tag="span" className="generic-tooltip">Refresh</LanguageTranslator>
                                                                </li>
                                                                {this.props?.collapseAll?.isCollapseAllOpen?.() && <li className="rab-rinner" onClick={() => this.props?.collapseAll?.onClick?.()}>
                                                                    <Tooltip overlayClassName='!p-[0]' title='Collapse All' arrow={{ pointAtCenter: true }}>
                                                                        <div className='!h-fit !block rounded-full !bg-transparent hover:bg-gray-100 cursor-pointer rotate-90'>
                                                                            <svg width="19" height="19" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_3375_6075)">
                                                                                    <rect x="2.5" y="1.5" width="17" height="17" rx="4.5" fill="white" stroke="#C7D3DF" />
                                                                                    <path d="M13.5996 10L9.59961 14L9.59961 6L13.5996 10Z" fill="#111D31" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_3375_6075">
                                                                                        <rect width="21" height="21" fill="white" transform="translate(0 21) rotate(-90)" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                    </Tooltip>
                                                                </li>}
                                                            </ul>
                                                        </div>

                                                        {this?.state?.stickyCols &&
                                                            this?.state?.stickyCols.map(key =>
                                                                <div key={key} className={`${this.isGivenCommaSepratedStringContainsOtherString(this.state.tableDataPayload.sortedBy, key) && this.state.tableDataPayload.sortedIn == "ASC" ? "rotate180 sticky-col default-th" : "sticky-col default-th"}`} onClick={this.state.headersData.main.columnType[key] == "NOSORT" ? null : this.sortData(key, this.isGivenCommaSepratedStringContainsOtherString(this.state.tableDataPayload.sortedBy, key) && this.state.tableDataPayload.sortedIn == "ASC" ? "DESC" : "ASC")}>
                                                                    <div className="td-header">
                                                                        <label>
                                                                            <LanguageTranslator>{this.state.headersData.main[this.state.headersType.main][key]}</LanguageTranslator>
                                                                            {this.state.headersData.main.columnType[key] != "NOSORT" && <img src={ImgPath.UpArrow} />}
                                                                        </label>
                                                                        {<img src={ImgPath.DragAndDropIcon} className="imgHead" />}
                                                                    </div>
                                                                </div>
                                                            )}

                                                        {Object.keys(this.state.headersData.main[this.state.headersType.main]).map(key =>
                                                            this?.state?.stickyCols && this?.state?.stickyCols.includes(key) ? null :
                                                                <th key={key} className={`${this.isGivenCommaSepratedStringContainsOtherString(this.state.tableDataPayload.sortedBy, key) && this.state.tableDataPayload.sortedIn == "ASC" ? "rotate180" : ""}`} onClick={this.state.headersData.main.columnType[key] == "NOSORT" ? null : this.sortData(key, this.isGivenCommaSepratedStringContainsOtherString(this.state.tableDataPayload.sortedBy, key) && this.state.tableDataPayload.sortedIn == "ASC" ? "DESC" : "ASC")}>
                                                                    <div className={`td-header ${this.isGivenCommaSepratedStringContainsOtherString(this.state.tableDataPayload.sortedBy, key) && this.state.tableDataPayload.sortedIn == "ASC" ? "td-header-sort" : ""}`}>
                                                                        <label>
                                                                            <LanguageTranslator>{this.state.headersData.main[this.state.headersType.main][key]}</LanguageTranslator>
                                                                            {this.state.headersData.main.columnType[key] != "NOSORT" && <img src={ImgPath.UpArrow} />}
                                                                        </label>
                                                                        {<img src={ImgPath.DragAndDropIcon} className="imgHead" />}
                                                                    </div>
                                                                </th>
                                                        )}
                                                    </ReactDragListView.DragColumn>
                                                }

                                                {/* Render the filters section over here */}
                                                {this.renderFilterSection()}
                                            </thead>
                                            <tbody>{
                                                Object.keys(this.state?.headersData?.main).length == 0 || this.state.tableData?.length == 0 ?
                                                    <tr><td colSpan={100} align="left"><LanguageTranslator tag="label">No data found!</LanguageTranslator></td></tr> :
                                                    this.state?.tableData?.map((item, outerKey) =>
                                                        <React.Fragment>
                                                            <tr key={item?.id} className={this.state.selectedItems?.[item?.id] ? "vgt-tr-bg" : ""} onClick={tableConfig?.select ? this.selectItem(item) : null}>
                                                                <td className={`fix-action-btn ${this.state?.tdFixActionClass}`}>
                                                                    <ul className="table-item-list">
                                                                        {tableConfig?.select &&
                                                                            <li className="til-inner">
                                                                                <label className="checkBoxLabel0">
                                                                                    <input type="checkBox" checked={this.state?.selectedItems?.[item?.id] ? true : false} onChange={this.selectItem(item)} />
                                                                                    <span className="checkmark1"></span>
                                                                                    <LanguageTranslator tag="span" className="generic-tooltip">Select</LanguageTranslator>
                                                                                </label>
                                                                            </li>}

                                                                        {tableConfig.view &&
                                                                            <li id='til-eye-btn' className="til-inner til-eye-btn" data-interactive="true" onClick={typeof tableConfig.view?.onClick === "function" ? tableConfig.view?.onClick(item) : null}>
                                                                                <IconsPath.TableEyeIcon />
                                                                                <LanguageTranslator tag="span" className="generic-tooltip">View</LanguageTranslator>
                                                                            </li>}

                                                                        {tableConfig.edit &&
                                                                            <li data-interactive="true" className={`table-edit-btn ${tableConfig?.edit?.disabled(item) ? "til-inner btnDisabled" : "til-inner til-edit-btn"}`} onClick={typeof tableConfig.edit.onClick === "function" && !tableConfig.edit.disabled(item) ? tableConfig.edit.onClick(item) : null}>
                                                                                <IconsPath.TableEditIcon />
                                                                                {!tableConfig.edit?.disabled(item) && <LanguageTranslator tag="span" className="generic-tooltip">Edit</LanguageTranslator>}
                                                                            </li>}

                                                                        {tableConfig.comment &&
                                                                            <CommentTableComponent
                                                                                commentRowIndex={outerKey}
                                                                                comment={tableConfig?.comment}
                                                                                commentModal={this.state.commentModal}
                                                                                item={item}
                                                                                openCommentModal={() => {
                                                                                    this.setState({
                                                                                        commentModal: item,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        }

                                                                        {tableConfig?.expand?.(item) && (!tableConfig?.expand(item)?.hidden) &&
                                                                            <li data-interactive="true" className="til-inner til-add-btn" onClick={typeof tableConfig.expand?.(item)?.onClick === "function" ? tableConfig.expand?.(item)?.onClick(item) : null}>
                                                                                {(tableConfig.expand?.(item)?.expandedRow?.[item.id]) ?
                                                                                    <React.Fragment>
                                                                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_3375_6075)">
                                                                                                <rect x="19.5" y="2.5" width="17" height="17" rx="4.5" transform="rotate(90 19.5 2.5)" fill="white" stroke="#C7D3DF" />
                                                                                                <path d="M11 13.6001L7 9.6001L15 9.6001L11 13.6001Z" fill="#111D31" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_3375_6075">
                                                                                                    <rect width="21" height="21" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                        <span className="generic-tooltip"><LanguageTranslator>Collapse</LanguageTranslator></span>
                                                                                    </React.Fragment> :
                                                                                    <React.Fragment>
                                                                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_3375_6075)">
                                                                                                <rect x="2.5" y="1.5" width="17" height="17" rx="4.5" fill="white" stroke="#C7D3DF" />
                                                                                                <path d="M13.5996 10L9.59961 14L9.59961 6L13.5996 10Z" fill="#111D31" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_3375_6075">
                                                                                                    <rect width="21" height="21" fill="white" transform="translate(0 21) rotate(-90)" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                        <span className="generic-tooltip"><LanguageTranslator>Expand</LanguageTranslator></span>
                                                                                    </React.Fragment>}
                                                                            </li>}

                                                                        {tableConfig.extras && tableConfig.extras({ ...item, index: outerKey })}

                                                                    </ul>
                                                                </td>

                                                                {this?.state?.stickyCols &&
                                                                    this?.state?.stickyCols.map((key, index) =>
                                                                        <td className={key === this.props.imageOrderNumber || key === this.props.showImageOn ? "sticky-col po-num-col" : "sticky-col"} align={this.state.headersData.main.columnType[key] === "NUMBER" ? "right" : "left"}>
                                                                            {tableConfig.customCols && tableConfig.customCols[key] ? tableConfig.customCols[key](item, {
                                                                                'labelRightIcons': () => key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                                    <div>
                                                                                        <div className='ponc-icon rightArrowIcon' >
                                                                                            <RightArrowImageComponent />
                                                                                        </div>
                                                                                        {/* <svg data-interactive="true" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M14.25 2.54688H3.75C2.92157 2.54688 2.25 3.21845 2.25 4.04688V14.5469C2.25 15.3753 2.92157 16.0469 3.75 16.0469H14.25C15.0784 16.0469 15.75 15.3753 15.75 14.5469V4.04688C15.75 3.21845 15.0784 2.54688 14.25 2.54688Z" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M6 9.29688H12" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M9 12.2969L12 9.29688L9 6.29688" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </svg> */}
                                                                                    </div>
                                                                                    : null,
                                                                                "labelOnClick": key === this.props.imageOrderNumber ? () => this.handleImageTrackingModal(item[key], item["vendorName"], key) : null
                                                                            }) :
                                                                                // <Tooltip
                                                                                //     overlayInnerStyle={{
                                                                                //         maxHeight: '200px',
                                                                                //         overflowY: 'auto',
                                                                                //         backgroundColor: 'white',
                                                                                //         color: 'black',
                                                                                //         fontSize: '12px'
                                                                                //     }}
                                                                                //     overlayClassName="!p-[0]"
                                                                                //     title={item[key]?.toString().length >= 26 ? item[key] : null}
                                                                                //     arrow={true}
                                                                                //     placement="leftTop"
                                                                                // >
                                                                                <div className='flex items-center gap-3'>
                                                                                    <label id={`tooltip-${item['apiName']}-${item['id']}-${index}-${outerKey}`} >{item[key]}
                                                                                        {key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                                            <div>
                                                                                                <div className='ponc-icon rightArrowIcon' >
                                                                                                    <RightArrowImageComponent />
                                                                                                </div>
                                                                                                {/* <svg data-interactive="true" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M14.25 2.54688H3.75C2.92157 2.54688 2.25 3.21845 2.25 4.04688V14.5469C2.25 15.3753 2.92157 16.0469 3.75 16.0469H14.25C15.0784 16.0469 15.75 15.3753 15.75 14.5469V4.04688C15.75 3.21845 15.0784 2.54688 14.25 2.54688Z" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M6 9.29688H12" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M9 12.2969L12 9.29688L9 6.29688" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </svg> */}
                                                                                            </div> : null}
                                                                                    </label>
                                                                                    {["slname", "vendorname"].includes(key?.toLowerCase()) && item?.["qcVerifiedQtyCheck"] === 1 && (
                                                                                        <span className='px-1.5 text-[#0D925A] text-xs font-medium rounded-[5px] border border-[#0D925A]'>GC</span>
                                                                                    )}
                                                                                    {item[key]?.toString().length >= 26 &&
                                                                                        <ReactTooltip
                                                                                            anchorSelect={`#tooltip-${item['apiName']}-${item['id']}-${index}-${outerKey}`}
                                                                                            clickable={true}
                                                                                            border={true}
                                                                                            borderColor="gray"
                                                                                            place="left"
                                                                                            className="tooltip"
                                                                                            delayShow={.3}
                                                                                            delayHide={0}
                                                                                            effect={false}
                                                                                            globalEventOff="click" // Ensure tooltips are dismissed when another is triggered
                                                                                            events="hover focus"
                                                                                            style={{ zIndex: '4', transition: 'none', fontSize: "12px", wordWrap: "break-word", whiteSpace: 'normal', wordBreak: 'break-all', pointerEvents: "auto" }}
                                                                                        >
                                                                                            <div className='max-w-[250px] max-h-[200px] overflow-y-auto'>{item[key]}</div>
                                                                                        </ReactTooltip>
                                                                                    }
                                                                                </div>
                                                                                // </Tooltip>
                                                                            }
                                                                        </td>
                                                                    )}

                                                                {Object.keys(this.state.headersData.main[this.state.headersType.main]).map((key, index) =>
                                                                    this?.state?.stickyCols && this?.state?.stickyCols.includes(key) ? null :
                                                                        <td className={`${key === this.props.imageOrderNumber || key === this.props.showImageOn ? "po-num-col" : ''} ${this.state.selectedColumn === key && this.state.selectedRowId === outerKey && "selected-col"} ${tableConfig?.columnConfig?.purcahseTermCharges && key in tableConfig?.columnConfig?.purcahseTermCharges && "purchase-term-avaliable"}`} align={this.state.headersData.main.columnType[key] === "NUMBER" ? "right" : "left"} id={"selectedCol" + outerKey} onContextMenu={(e) => this.onRightClick(e, key, item, outerKey)}>
                                                                            <TableColumnHOC item={item} mainKey={key} {...this.props}>
                                                                                {tableConfig.customCols && tableConfig.customCols[key] ? tableConfig.customCols[key](item, {
                                                                                    "labelRightIcons": () => key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                                        <div>
                                                                                            <div className='ponc-icon rightArrowIcon' >
                                                                                                <RightArrowImageComponent />
                                                                                            </div>
                                                                                            {/* <svg data-interactive="true" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M14.25 2.54688H3.75C2.92157 2.54688 2.25 3.21845 2.25 4.04688V14.5469C2.25 15.3753 2.92157 16.0469 3.75 16.0469H14.25C15.0784 16.0469 15.75 15.3753 15.75 14.5469V4.04688C15.75 3.21845 15.0784 2.54688 14.25 2.54688Z" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                <path d="M6 9.29688H12" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                <path d="M9 12.2969L12 9.29688L9 6.29688" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            </svg> */}
                                                                                        </div> : null,
                                                                                    "labelOnClick": key === this.props.imageOrderNumber ? () => this.handleImageTrackingModal(item[key], item["vendorName"], key) : null
                                                                                }) :
                                                                                    <>
                                                                                        <label id={`tooltip-${item['apiName']}-${item['id']}-${index}-${outerKey}`} className='rightArrowLabel'>{item[key]}
                                                                                            {key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                                                <div>
                                                                                                    <div className='ponc-icon rightArrowIcon' >
                                                                                                        <RightArrowImageComponent />
                                                                                                    </div>
                                                                                                    {/* <svg data-interactive="true" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M14.25 2.54688H3.75C2.92157 2.54688 2.25 3.21845 2.25 4.04688V14.5469C2.25 15.3753 2.92157 16.0469 3.75 16.0469H14.25C15.0784 16.0469 15.75 15.3753 15.75 14.5469V4.04688C15.75 3.21845 15.0784 2.54688 14.25 2.54688Z" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M6 9.29688H12" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M9 12.2969L12 9.29688L9 6.29688" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg> */}
                                                                                                </div>
                                                                                                : null}
                                                                                        </label>
                                                                                        {["slname", "vendorname"].includes(key?.toLowerCase()) && item?.["qcVerifiedQtyCheck"] === 1 && (
                                                                                            <span className='px-1.5 text-[#0D925A] text-xs font-medium rounded-[5px] border border-[#0D925A]'>GC</span>
                                                                                        )}
                                                                                        {item[key]?.toString().length >= 26 &&
                                                                                            <ReactTooltip
                                                                                                anchorSelect={`#tooltip-${item['apiName']}-${item['id']}-${index}-${outerKey}`}
                                                                                                clickable={true}
                                                                                                border={true}
                                                                                                borderColor="gray"
                                                                                                place="left"
                                                                                                className="tooltip"
                                                                                                delayShow={.3}
                                                                                                delayHide={0}
                                                                                                effect={false}
                                                                                                globalEventOff="click" // Ensure tooltips are dismissed when another is triggered
                                                                                                events="hover focus"
                                                                                                style={{ zIndex: '4', transition: 'none', fontSize: "12px", wordWrap: "break-word", whiteSpace: 'normal', wordBreak: 'break-all', pointerEvents: "auto" }}
                                                                                            >
                                                                                                <div className='max-w-[250px] max-h-[200px] overflow-y-auto'>{item[key]}</div>
                                                                                            </ReactTooltip>
                                                                                        }
                                                                                    </>}
                                                                            </TableColumnHOC>
                                                                        </td>
                                                                )}
                                                            </tr>

                                                            {tableConfig.expand && (tableConfig.expand?.(item)?.expandedRow?.[item.id]) &&
                                                                <tr>
                                                                    <td colSpan="100%" className="pad-0">
                                                                        {(tableConfig?.expand?.(item)?.render) ? (tableConfig?.expand?.(item)?.render?.()) :
                                                                            <ExpandedRow
                                                                                mainItem={item}
                                                                                handleImageTrackingModal={this.handleImageTrackingModal}
                                                                                applyFilter={this.applyFilter}
                                                                                closeFilterModal={this.closeFilterModal}
                                                                                saveQuickFilter={this.saveQuickFilter}
                                                                                updateState = {(values) => {
                                                                                    this.setState(values)
                                                                                }}
                                                                                {...(tableConfig?.expand?.(item))}
                                                                            />}
                                                                    </td>
                                                                </tr>}
                                                        </React.Fragment>)
                                            }</tbody>
                                        </table>
                                        : <div className='imtm-product-view'>
                                            <>
                                                {/* select all and refresh */}
                                                <div className='px-3 py-2 flex items-center gap-4 justify-between bg-gradient-to-b from-white to-[#F3F4F9]'>
                                                    <ul className='flex items-center gap-4'>
                                                        {tableConfig?.select && !this.state.localConfig?.selectedMode &&
                                                            <li className="rab-rinner flex items-center cursor-pointer">
                                                                <Tooltip
                                                                    overlayClassName='!p-[0]'
                                                                    title={() => <span className="select-all-text">{Object.keys(this.state.selectedItems || {})?.length || 0} Item{Object.keys(this.state.selectedItems || {})?.length > 1 ? 's' : ''} Selected</span>}
                                                                    arrow={{ pointAtCenter: true }}
                                                                >
                                                                    <label className="generic-checkbox">
                                                                        <input type="checkBox" onChange={this.selectAll} checked={this.state.tableData.length != 0 && this.isAllDataOnCurrentPageSelected()} />
                                                                        <span className="checkmark1"></span>
                                                                        {Object.keys(this.state.selectedItems || {})?.length != 0 && this.isAllDataOnCurrentPageSelected() ? <span>Deselect All</span> : <span>Select All</span>}
                                                                    </label>
                                                                </Tooltip>
                                                            </li>}

                                                        <li className="rab-rinner" onClick={this.onRefresh}>
                                                            <Tooltip overlayClassName='!p-[0]' title='Refresh' arrow={{ pointAtCenter: true }}>
                                                                <div className='p-[10px] rounded-full hover:bg-gray-100 cursor-pointer'>
                                                                    <RefreshCcw size={16} />
                                                                </div>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>

                                                    {/* Render the filter open button here */}
                                                    <GenericCustomButton
                                                        label="Filters"
                                                        rightIcon={<ListFilter size={16} />}
                                                        onClick={() => this.handleFilterModalChange()}
                                                        type="default"
                                                        className={'gridFilterBtn flex justify-center items-center gap-2'}
                                                    />

                                                </div>

                                                {
                                                    Object.keys(this.state?.headersData?.main).length == 0 || this.state.tableData?.length == 0 ?
                                                        <div className='imtmp-no-data pt-0 pb-14'>
                                                            {/* <label>No data found!</label> */}
                                                            <NoDataFound />
                                                        </div> :
                                                        <div className='splrs-card-section'>
                                                            {this.state?.tableData?.map((item, index) => (
                                                                <SalesCard
                                                                    showCardButtonsDownDirection={this.props.tableConfig?.gridConfig?.showCardButtonsDownDirection ?? false}
                                                                    multipleSelect={tableConfig?.select}
                                                                    selectedData={this.state?.selectedItems}
                                                                    handleSelect={(x) => this.selectItem(x)()}
                                                                    useKeyboardShortcuts={true}
                                                                    outerIndex={index}
                                                                    item={item}
                                                                    comment={tableConfig.comment ? () => {
                                                                        return (
                                                                            <CommentTableComponent
                                                                                commentRowIndex={index}
                                                                                comment={tableConfig?.comment}
                                                                                commentModal={this.state.commentModal}
                                                                                item={item}
                                                                                openCommentModal={() => {
                                                                                    this.setState({
                                                                                        commentModal: item,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        )
                                                                    } : false}
                                                                    // wishlist={
                                                                    //     tableConfig?.gridConfig?.wishlist ? {
                                                                    //         onClick: () => tableConfig?.gridConfig?.wishlist?.onClick(item),
                                                                    //         isWishlisted: () => tableConfig?.gridConfig?.wishlist?.isWishlisted(item)
                                                                    //     } : null
                                                                    // }
                                                                    topExtra={tableConfig?.gridConfig?.topExtra}
                                                                    mainImgKey={tableConfig?.gridConfig?.mainImgKey}
                                                                    imageOnClick={tableConfig?.gridConfig?.imageOnClick}
                                                                    showDataKey={this.state?.showDataKey || tableConfig?.gridConfig?.showDataKey || {}}
                                                                    buttons={
                                                                        tableConfig?.gridConfig?.gridConfigButtons ? tableConfig?.gridConfig?.gridConfigButtons?.map(buttonConfig => ({
                                                                            onClick: () => buttonConfig?.onClick?.(item),
                                                                            disabled: () => buttonConfig?.disabled?.(item),
                                                                            leftIcon: buttonConfig?.icon,
                                                                        })) : null
                                                                    }
                                                                    bottomExtras={() => tableConfig?.gridConfig?.gridBottomExtra ? tableConfig?.gridConfig?.gridBottomExtra(item) : null}
                                                                    viewProductType={this.props.viewType}
                                                                />
                                                            ))}
                                                        </div>
                                                }
                                            </>
                                        </div>
                                    }
                                </div>
                                {this.state.rightClickMenu &&
                                    <RightClickModal
                                        applyFilter={this.applyFilter}
                                        filterItems={this.state.headersData.main}
                                        id={'selectedCol' + this.state.selectedRowId}
                                        selectedItems={this.state.selectedItems}
                                        selectedColumn={this.state.selectedColumn}
                                        rightClickAction={this.rightClickAction}
                                        headersData={this.state.headersData}
                                        headersType={this.state.headersType}
                                        close={() => this.closeRightClickMenu()}
                                        ConfirmAction={this.confirmAction}
                                        handleTrueFalse={this.handleTrueFalse}
                                        {...this.props}
                                        {...this.state}
                                    />}
                            </div> :
                            this.state.getTableDataError || this.state.getTableHeadersError ?
                                <TableLoader isError={true} onRefresh={this.onRefresh} /> :
                                <React.Fragment>
                                    {this.props.viewType != "grid" ?
                                        <NewTableLoader /> : <div className='pl-8 pr-4 mt-3'>
                                            <div className='grid grid-cols-3 lg:grid-cols-5 gap-3 p-[12px] bg-white rounded'>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 19].map((obj, index) =>
                                                        <div className='generic-skeleton !h-[300px] !rounded-none'></div>
                                                    )
                                                }
                                            </div>
                                        </div>}
                                </React.Fragment>}

                        <div className="chatMain">
                        </div>
                    </div>
                </div>
                {this.state.downloadReportModal &&
                    <DownloadReport
                        dataLength={this.props.tableData.totalItems}
                        mainHeadersKey={this.props.tableHeadersPayload.main.key}
                        mainHeadersData={this.state.headersData.main}
                        mainHeadersType={this.state.headersType.main}
                        payload={{ ...this.state.tableDataPayload }}
                        submit={(payload) => { this.props.handleApiChange("downloadReportRequest", this.getUpdatedPayloadForDownloadReport(payload), getPermissionKey("common", "commonFunctionality", "view")) }}
                        loading={this.props.generalStore.downloadReportData.isLoading}
                        close={this.toggleDownloadReportModal}
                        getFilterValue={this.getFilterValue}
                        getItemFilterValue={this.getItemFilterValue}
                        downloadReportName={this.props.downloadReportName}
                        applyFilter={this.applyFilter}
                        checkedbox={this.checkedbox}
                        {...this.props.actionBarConfig.downloadReport}
                        {...this.props}
                    />}
                {this.state.customSearchModal &&
                    <CustomSearchPopover
                        columnList={this.state.headersData.main}
                        columnBy={this.state.customSearchBy}
                        headerType={this.state.headersType.main}
                        data={this.state.customSearchData}
                        submit={this.applyCustomSearch}
                        close={this.toggleCustomSearchModal}
                        appliedFilters={this.state.tableDataPayload.filter}
                        applyFilter={this.applyFilter}
                        closeFilterModal={this.closeFilterModal}
                        filterItems={this.state.headersData.main}
                        isLoading={this.props.generalStore.createQuickFiltersData.isLoading}
                        headersData={this.getTableHeaders("state")}
                        isItemRequire={this.props.actionBarConfig.filters.isItemRequire}
                        mainDisplayName = {this.props.tableHeadersPayload.main.key}
                        {...this.props}
                    />}
                {this.state.emailModal &&
                    <EmailScheduler
                        close={this.closeEmailModal}
                        headerLevelModule={this.props.actionBarConfig?.downloadReport?.tableHeadersMap?.main?.key || ""}
                        detailLevelModule={Object.keys(this.props.actionBarConfig?.downloadReport?.tableHeadersMap || {})?.map(key => this.props.actionBarConfig.downloadReport?.tableHeadersMap?.[key]?.["key"]) || ""}
                        exportDataNode={this.state.tableDataPayload}
                        mainHeadersData={this.state.headersData.main}
                        mainHeadersType={this.state.headersType.main}
                        {...this.props}
                        {...this.props.actionBarConfig.downloadReport}
                    />}
                {/* {this.state.sortingModal &&
                    <SortingModal
                        apply={this.sortData}
                        checkedItems={this.state.tableDataPayload.sortedBy}
                        close={this.closeSortingModal}
                        columnType={this.state.headersData.main.columnType}
                        filterItems={this.state.headersData.main[this.state.headersType.main]}
                        sortedIn={this.state.tableDataPayload.sortedIn}
                    />} */}
                {this.state.confirmationType &&
                    <ConfirmationModal
                        headMsg={this.state.headMsg}
                        loading={this.props.confirmationLoading}
                        onCancellation={this.onCancellation}
                        onConfirmation={this.onConfirmation}
                        paraMsg={this.state.paraMsg}
                    />}
                {this.props.confirmAction && this.props.confirmAction.type &&
                    <ConfirmationModal
                        headMsg={this.props.confirmAction.headMsg}
                        loading={this.props.confirmAction.loading}
                        onCancellation={this.props.confirmAction.onCancellation}
                        onConfirmation={this.props.confirmAction.onConfirmation}
                        paraMsg={this.props.confirmAction.paraMsg}
                    />}
                {this.state.imageTrackingModal && this.state.imageTrackingData ?
                    <ImageDetailsTracking close={this.closeImageTrackingModal} data={this.state.imageTrackingData} {...this.props} imageTrackingNumber={this.state.imageTrackingNumberType} vendor={this.state.imageVendor} orderNumber={this.state.imageOrderNumber} />
                    : null}
                {this.state.saveFilterModal &&
                    <SaveFilter
                        quickFilterItems={this.state.quickFilterData}
                        saveQuickFilter={this.saveQuickFilter}
                        filterItems={this.state.headersData.main}
                        checkedFilters={this.state.saveFilterData}
                        headerType={this.state.headersType.main}
                        closeSaveFilter={this.closeSaveFilter}
                        saveSuccess={this.props.generalStore.createQuickFiltersData?.isSuccess}
                        isLoading={this.props.generalStore.createQuickFiltersData.isLoading}
                        headersData={this.getTableHeaders("state")}
                        isItemRequire={this.props.actionBarConfig.filters.isItemRequire}
                    />}
                {this.state.openDocumentLog &&
                    <DocumentAuditLogs
                        {...this.props}
                        ducumentNoKey={this.props.actionBarConfig.documentLog.key}
                        auditData={Object.values(this.state.openDocumentLog)}
                        Close={() => this.handleTrueFalse("openDocumentLog", false)}
                    />}

                
                {this.state.selectedModal ? <ParentModal
                    getParentChildProps={this.getParentChildProps()}
                    childComponent={this.getChildComponent()}
                    closeModal={() => { this.setState({ selectedModal: false, parentModalData : "" }) }}
                /> : null}

                {/* Render the filter selection modal in the right */}
                {this.state.filterModalOpen && <div className="modal">
                    <div className="backdrop" onClick={this.handleFilterModalChange}></div>
                    <div style={{ width: "40%", position: "absolute", right: 0, backgroundColor: "white", zIndex: 100, height: '100vh' }}>
                        {this.renderFilterSection()}
                    </div>
                </div>}
                {
                    this.state.commentModal && <Drawer
                        title={null}
                        placement="right"
                        onClose={() => {
                            this.setState({ commentModal: null })
                        }}
                        open={this.state.commentModal}
                        closable={false}
                        footer={null}
                        styles={{ wrapper: { minWidth: '500px' }, body: { height: '100vh', borderRadius: 0, padding: 0, overflow: 'hidden' } }}
                        width={'fit-content'}
                        style={{
                            fontFamily: 'poppins'
                        }}
                    >

                        <CommentBoxModalWrapper
                            onClose={() => {
                                this.setState({ commentModal: null })
                            }}
                            item={this.state.commentModal}
                            comment={tableConfig?.comment}
                        />

                    </Drawer>
                }
            </div>
        )
    }
}