import React from 'react'
import IconsPath from '../assets/icons/index'
import LanguageTranslator from '../locals/LanguageTranslator'

const NoDataFound = (props) => {
  return (
    <div className='no-data-found-screen'>
      <div className='ndfs-inner'>
        <IconsPath.NoDataFound />
        <LanguageTranslator tag="h3" className='text-center'>{props?.primaryText || "No Data Found!"}</LanguageTranslator>
        {props.secondryText && (typeof(props.secondryText) == "function" ? props.secondryText() : <LanguageTranslator tag="p">{props.secondryText}</LanguageTranslator>)}
      </div>
    </div>
  )
}

export default NoDataFound