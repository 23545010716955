/* eslint-disable no-lone-blocks */
import React from 'react'
import Icons from '../../../assets/icons'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import OrderTrackingCardSkeleton from './OrderTrackingCardSkeleton'
import { parseJwt } from '../../../helper/genericFunction'
import LanguageTranslator from '../../../locals/LanguageTranslator'

const UpperCardComponent = (props) => {
    const { upperDashboardData, toggleType, bottomDashboardData, grcDashboardData, saveFiltersData } = props
    const { upperDataV2 = {} } = upperDashboardData;

    const history = useHistory()
    const digivendStore = useSelector(state => state.digivendStore)
    let uType = parseJwt(sessionStorage?.getItem('token'))?.uType || "ENT";
    const isENT = uType === 'ENT'

    const bottomCards = bottomDashboardData?.highlights?.lowerDataV2 || []

    // function to navigate with filters
    const navigateWithFilters = (url, params, state) => {
        const modifiedFilters = { ...saveFiltersData };
        let keyMappingObj;

        if (uType === 'ENT') {
            if (url === "/enterprise/logistics/goodsIntransit") {
                keyMappingObj = {
                    "slcode": "vendorCode",
                    "code": "site",
                    "vendorCode": "consignorCode"
                };
            } else {
                keyMappingObj = {
                    "slcode": "vendorCode",
                    "code": "site"
                };
            }
        } else {
            keyMappingObj = {
                "slcode": "vendorCode",
                "code": "siteCode"
            };
        }

        Object.keys(keyMappingObj).forEach(key => {
            if (key in modifiedFilters) {
                modifiedFilters[keyMappingObj[key]] = modifiedFilters[key];
                delete modifiedFilters[key];
            }
        });

        let urlSearchParams = new URLSearchParams("?");
        urlSearchParams.append("filter", encodeURIComponent(JSON.stringify(modifiedFilters)));

        params && Object.keys(params)?.forEach((key) => {
            urlSearchParams.append(key, encodeURIComponent(JSON.stringify(params[key])));
        });

        console.log('modified filters', modifiedFilters)
        console.log('search paraams', urlSearchParams.toString())

        history.push({
            pathname: url,
            search: urlSearchParams.toString(),
            state: state,
        });
    };

    let CardsData = [
        {
            amountHover: upperDataV2?.po_amount_hover || 0,
            amount: upperDataV2?.po_amount || 0,
            quantityHover: upperDataV2?.po_qty_hover || 0,
            quantity: upperDataV2?.po_qty || 0,
            mainLinks: [],
            bottomLinks: [
                upperDataV2?.outside_po_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/purchaseOrder/pendingOrders' : '/vendor/purchaseOrder/pendingOrders', {}, { id: "outside" })
                }}>{isENT ? 'Open PO' : 'Pending Orders'}</LanguageTranslator>
            ],
            upperLinks: [upperDataV2?.within_po_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/purchaseOrder/pendingOrders' : '/vendor/purchaseOrder/pendingOrders', {}, { id: "within" });
                }}>{isENT ? 'Open PO' : 'Pending Orders'}</LanguageTranslator>
            ],
            cardClassName: "ot-opo",
            label: isENT ? <LanguageTranslator tag='p' onClick={() => navigateWithFilters('/enterprise/purchaseOrder/pendingOrders')}>Open Purchase Orders</LanguageTranslator> : <LanguageTranslator tag='p' onClick={() => navigateWithFilters('/vendor/purchaseOrder/pendingOrders')}>Pending Orders</LanguageTranslator>,
            count: upperDataV2?.po_count || 0,
            totalBoxes: 0,
            withinAmountHover: upperDataV2?.within_po_amt_hover || 0,
            withinAmount: upperDataV2?.within_po_amt || 0,
            withinQuantityHover: upperDataV2?.within_po_qty_hover || 0,
            withinQuantity: upperDataV2?.within_po_qty || 0,
            withinCount: upperDataV2?.within_po_count || 0,
            withinPercentage: upperDataV2?.within_po_per || 0,
            withinQtyPercentage: upperDataV2?.within_po_qty_per || 0,
            outsideAmountHover: upperDataV2?.outside_po_amt_hover || 0,
            outsideAmount: upperDataV2?.outside_po_amt || 0,
            outsideQuantityHover: upperDataV2?.outside_po_qty_hover || 0,
            outsideQuantity: upperDataV2?.outside_po_qty || 0,
            outsideCount: upperDataV2?.outside_po_count || 0,
            outsidePercentage: upperDataV2?.outside_po_per || 0,
            outsideQtyPercentage: upperDataV2?.outside_po_qty_per || 0,
        },
        {
            amountHover: upperDataV2?.shipment_amount_hover || 0,
            amount: upperDataV2?.shipment_amount || 0,
            quantityHover: upperDataV2?.shipment_qty_hover || 0,
            quantity: upperDataV2?.shipment_qty || 0,
            mainLinks: isENT ? [
                bottomCards?.pen_qc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC", state: { upper: "upper" } })
                }}>Pending QC</LanguageTranslator>,
                bottomCards?.qc_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC_CONFIRM", state: { upper: "upper" } })
                }}>Confirm QC</LanguageTranslator>,
                bottomCards?.pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", state: { upper: "upper" } })
                }}>ASN Under Approval</LanguageTranslator>
            ] : [
                bottomCards?.pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/vendor/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", state: { upper: "upper" } })
                }}><Link>Pending ASN Approval</Link></LanguageTranslator>
            ],
            bottomLinks: isENT ? [
                bottomCards?.outside_pen_qc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC", }, { id: "outside" })
                }}>Pending QC</LanguageTranslator>,
                bottomCards?.outside_qc_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC_CONFIRM", }, { id: "outside" })
                }}>Confirm Qc</LanguageTranslator>,
                bottomCards?.outside_pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", }, { id: "outside" })
                }}>ASN Under Approval</LanguageTranslator>
            ] : [bottomCards?.outside_pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/vendor/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", }, { id: "outside" })
                }}>ASN Under Approval
                </LanguageTranslator>],
            upperLinks: isENT ? [
                bottomCards.within_pen_qc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC", }, { id: "within" })
                }}>Pending QC</LanguageTranslator>,
                bottomCards.within_qc_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/qualityCheck/pendingQc', { "status": "PENDING_QC_CONFIRM", }, { id: "within" })
                }}>Confirm Qc</LanguageTranslator>,
                bottomCards.within_pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/enterprise/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", }, { id: "within" })
                }
                }>ASN Under Approval</LanguageTranslator>
            ] : [
                bottomCards.within_pen_asn_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters('/vendor/shipment/asnUnderApproval', { "status": "SHIPMENT_REQUESTED", }, { id: "within" })
                }
                }>ASN Under Approval</LanguageTranslator>
            ],
            cardClassName: "ot-paa",
            label: 'Pending ASN Approval',
            count: upperDataV2?.shipment_count || 0,
            totalBoxes: upperDataV2?.count_pending_asn_box || 0,
            withinAmountHover: upperDataV2?.within_shipment_amount_hover || 0,
            withinAmount: upperDataV2?.within_shipment_amt || 0,
            withinQuantityHover: upperDataV2?.within_shipment_qty_hover || 0,
            withinQuantity: upperDataV2?.within_shipment_qty || 0,
            withinCount: upperDataV2?.within_shipment_count || 0,
            withinPercentage: upperDataV2?.within_shipment_per || 0,
            withinQtyPercentage: upperDataV2?.within_shipment_qty_per || 0,
            outsideAmountHover: upperDataV2?.outside_shipment_amount_hover || 0,
            outsideAmount: upperDataV2?.outside_shipment_amt || 0,
            outsideQuantityHover: upperDataV2?.outside_shipment_qty_hover || 0,
            outsideQuantity: upperDataV2?.outside_shipment_qty || 0,
            outsideCount: upperDataV2?.outside_shipment_count || 0,
            outsidePercentage: upperDataV2?.outside_shipment_per || 0,
            outsideQtyPercentage: upperDataV2?.outside_shipment_qty_per || 0,
        },
        {
            amountHover: upperDataV2?.logistics_amount_hover || 0,
            amount: upperDataV2?.logistics_amount || 0,
            quantityHover: upperDataV2?.logistics_qty_hover || 0,
            quantity: upperDataV2?.logistics_qty || 0,
            mainLinks: [
                bottomCards?.asn_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    isENT ? navigateWithFilters('/enterprise/shipment/approvedAsn', {
                        "status": "SHIPMENT_CONFIRMED",
                        "pageNo": "1",
                        "pageSize": "10",
                        "type": "1",
                    }) : navigateWithFilters('/vendor/shipment/approvedAsn', {})
                }} >Approved ASN</LanguageTranslator>,
                bottomCards?.lr_conf_count !== 0 && <LanguageTranslator tag='li'
                    onClick={
                        () => {
                            isENT ? navigateWithFilters('/enterprise/logistics/lrProcessing', {
                                "status": "SHIPMENT_SHIPPED",
                                "pageNo": "1",
                                "pageSize": "10",
                                "type": "1",
                                "userType": "entlogi"
                            }) : navigateWithFilters('/vendor/logistics/lrProcessing', {})
                        }}
                >LR Processing</LanguageTranslator>,
                bottomCards?.lr_req_count !== 0 && <LanguageTranslator tag='li'
                    onClick={
                        () => {
                            isENT ? navigateWithFilters('/enterprise/logistics/lrProcessing', {
                                "status": "SHIPMENT_INVOICE_REQUESTED",
                                "pageNo": "1",
                                "pageSize": "10",
                                "type": "1",
                                "userType": "entlogi"
                            }) : navigateWithFilters('/vendor/logistics/lrProcessing', {
                                "pageNo": "1",
                                "type": "1",
                                "status": "SHIPMENT_INVOICE_REQUESTED",
                                "userType": "vendorlogi",
                                "isDashboardComment": "0"
                            })
                        }}
                >Pending Approval</LanguageTranslator>,
                <LanguageTranslator tag='li'
                    onClick={() => {
                        isENT ? navigateWithFilters('/enterprise/logistics/lrProcessing', {
                            "status": "SHIPMENT_APPT_PENDING",
                            "pageNo": "1",
                            "pageSize": "10",
                            "type": "1",
                            "userType": "entlogi"
                        }) : navigateWithFilters('/vendor/logistics/lrProcessing', {
                            "pageNo": "1",
                            "type": "1",
                            "status": "SHIPMENT_APPT_PENDING",
                            "userType": "vendorlogi",
                            "isDashboardComment": "0"
                        })
                    }}
                >Appoinment Pendings</LanguageTranslator>

            ],
            bottomLinks: [
                bottomCards?.outside_asn_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/shipment/approvedAsn' : '/vendor/shipment/approvedAsn', { "status": "SHIPMENT_CONFIRMED", }, { id: "outside" })
                }}>Approved ASN
                </LanguageTranslator>,
                bottomCards?.outside_lr_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_SHIPPED", }, { id: "outside" })
                }}>LR Processing</LanguageTranslator>,
                bottomCards?.outside_lr_req_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_INVOICE_REQUESTED", }, { id: "outside" })
                }}>Pending Approval</LanguageTranslator>,

                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_APPT_PENDING", }, { id: "outside" })
                }}>Appoinment Pending</LanguageTranslator>

            ],
            upperLinks: [
                bottomCards?.within_asn_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/shipment/approvedAsn' : '/vendor/shipment/approvedAsn', { "status": "SHIPMENT_CONFIRMED", }, { id: "within" })
                }}>Approved ASN
                </LanguageTranslator>,
                bottomCards?.within_lr_conf_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_SHIPPED", }, { id: "within" })
                }}>LR Processing</LanguageTranslator>,
                bottomCards?.within_lr_req_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_INVOICE_REQUESTED", }, { id: "within" })
                }}>Pending Approval</LanguageTranslator>,
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? '/enterprise/logistics/lrProcessing' : '/vendor/logistics/lrProcessing', { "status": "SHIPMENT_APPT_PENDING", }, { id: "within" })
                }}>Appoinment Pending</LanguageTranslator>
            ],
            cardClassName: "ot-plc",
            label: 'Pending LR Creation',
            count: upperDataV2?.logistics_count || 0,
            totalBoxes: upperDataV2?.count_pending_lr_box || 0,
            withinAmountHover: upperDataV2?.within_logistics_amount_hover || 0,
            withinAmount: upperDataV2?.within_logistics_amt || 0,
            withinQuantityHover: upperDataV2?.within_logistics_qty_hover || 0,
            withinQuantity: upperDataV2?.within_logistics_qty || 0,
            withinCount: upperDataV2?.within_logistics_count || 0,
            withinPercentage: upperDataV2?.within_logistics_per || 0,
            withinQtyPercentage: upperDataV2?.within_logistics_qty_per || 0,
            outsideAmountHover: upperDataV2?.outside_logistics_amount_hover || 0,
            outsideAmount: upperDataV2?.outside_logistics_amt || 0,
            outsideQuantityHover: upperDataV2?.outside_logistics_qty_hover || 0,
            outsideQuantity: upperDataV2?.outside_logistics_qty || 0,
            outsideCount: upperDataV2?.outside_logistics_count || 0,
            outsidePercentage: upperDataV2?.outside_logistics_per || 0,
            outsideQtyPercentage: upperDataV2?.outside_logistics_qty_per || 0,
        },
        {
            amountHover: upperDataV2?.gate_amount_hover || 0,
            amount: upperDataV2?.gate_amount || 0,
            quantityHover: upperDataV2?.gate_qty_hover || 0,
            quantity: upperDataV2?.gate_qty || 0,
            mainLinks: [],
            bottomLinks: [
                upperDataV2?.outside_gate_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/goodsIntransit" : '/vendor/logistics/goodsIntransit', {}, { id: "outside" })
                }}>Goods In-Transit</LanguageTranslator>
            ],
            upperLinks: [
                upperDataV2?.within_gate_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/goodsIntransit" : '/vendor/logistics/goodsIntransit', {}, { id: "within" })
                }}>Goods In-Transit</LanguageTranslator>
            ],
            cardClassName: "ot-pge",
            label: <LanguageTranslator tag='p' onClick={() => {
                isENT ? navigateWithFilters("/enterprise/logistics/goodsIntransit", { "status": "SHIPMENT_INTRANSIT" }) : navigateWithFilters('/vendor/logistics/goodsIntransit')
            }}>Pending Gate Entry</LanguageTranslator>,
            count: upperDataV2?.gate_count || 0,
            totalBoxes: upperDataV2?.count_pending_gate_box || 0,
            withinAmountHover: upperDataV2?.within_gate_amount_hover || 0,
            withinAmount: upperDataV2?.within_gate_amt || 0,
            withinQuantityHover: upperDataV2?.within_gate_qty_hover || 0,
            withinQuantity: upperDataV2?.within_gate_qty || 0,
            withinCount: upperDataV2?.within_gate_count || 0,
            withinPercentage: upperDataV2?.within_gate_per || 0,
            withinQtyPercentage: upperDataV2?.within_gate_qty_per || 0,
            outsideAmountHover: upperDataV2?.outside_gate_amount_hover || 0,
            outsideAmount: upperDataV2?.outside_gate_amt || 0,
            outsideQuantityHover: upperDataV2?.outside_gate_qty_hover || 0,
            outsideQuantity: upperDataV2?.outside_gate_qty || 0,
            outsideCount: upperDataV2?.outside_gate_count || 0,
            outsidePercentage: upperDataV2?.outside_gate_per || 0,
            outsideQtyPercentage: upperDataV2?.outside_gate_qty_per || 0,
        },
        {
            amountHover: upperDataV2?.grc_amount_hover || 0,
            amount: upperDataV2?.grc_amount || 0,
            quantityHover: upperDataV2?.grc_qty_hover || 0,
            quantity: upperDataV2?.grc_qty || 0,
            mainLinks: [],
            bottomLinks: [
                upperDataV2?.outside_grc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/goodsDelivered" : '/vendor/logistics/grcStatus', {}, { id: "outside" })
                }}>Pending GRC</LanguageTranslator>
            ],
            upperLinks: [
                upperDataV2?.within_grc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/goodsDelivered" : '/vendor/logistics/grcStatus', {}, { id: "within" })
                }}>Pending GRC</LanguageTranslator>
            ],
            label: <LanguageTranslator tag='p' onClick={() => {
                navigateWithFilters(isENT ? "/enterprise/logistics/goodsDelivered" : '/vendor/logistics/goodsDelivered', { "status": "SHIPMENT_DELIVERED" })
            }}>Pending GRC</LanguageTranslator>,
            cardClassName: "ot-pgrc",
            count: upperDataV2?.grc_count || 0,
            totalBoxes: upperDataV2?.count_pending_grc_box || 0,
            withinAmountHover: upperDataV2?.within_grc_amount_hover || 0,
            withinAmount: upperDataV2?.within_grc_amt || 0,
            withinQuantityHover: upperDataV2?.within_grc_qty_hover || 0,
            withinQuantity: upperDataV2?.within_grc_qty || 0,
            withinCount: upperDataV2?.within_grc_count || 0,
            withinPercentage: upperDataV2?.within_grc_per || 0,
            withinQtyPercentage: upperDataV2?.within_grc_qty_per || 0,
            outsideAmountHover: upperDataV2?.outside_grc_amount_hover || 0,
            outsideAmount: upperDataV2?.outside_grc_amt || 0,
            outsideQuantityHover: upperDataV2?.outside_grc_qty_hover || 0,
            outsideQuantity: upperDataV2?.outside_grc_qty || 0,
            outsideCount: upperDataV2?.outside_grc_count || 0,
            outsidePercentage: upperDataV2?.outside_grc_per || 0,
            outsideQtyPercentage: upperDataV2?.outside_grc_qty_per || 0,
        },
        {
            amountHover: grcDashboardData?.grc_amount_hover || 0,
            amount: grcDashboardData?.grc_amount || 0,
            quantityHover: grcDashboardData?.inward_qty_hover || 0,
            quantity: grcDashboardData?.inward_qty || 0,
            mainLinks: [],
            bottomLinks: [
                grcDashboardData?.outside_grc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/grcStatus" : '/vendor/logistics/grcStatus', {})
                }}>Completed GRC</LanguageTranslator>
            ],
            upperLinks: [
                grcDashboardData?.within_grc_count !== 0 &&
                <LanguageTranslator tag='li' onClick={() => {
                    navigateWithFilters(isENT ? "/enterprise/logistics/grcStatus" : '/vendor/logistics/grcStatus', {})
                }}>Completed GRC</LanguageTranslator>
            ],
            label: <LanguageTranslator tag='p' onClick={() => {
                isENT ? navigateWithFilters("/enterprise/logistics/grcStatus", { "status": "SHIPMENT_DELIVERED" }) : navigateWithFilters('/vendor/logistics/grcStatus')
            }}>Completed GRC</LanguageTranslator>,
            cardClassName: "ot-comgrc",
            count: grcDashboardData?.grc_count || 0,
            totalBoxes: 0,
            last7DaysTag: true,
            withinAmountHover: grcDashboardData?.within_grc_amount_hover || 0,
            withinAmount: grcDashboardData?.within_grc_amount || 0,
            withinQuantityHover: grcDashboardData?.within_inward_qty_hover || 0,
            withinQuantity: grcDashboardData?.within_inward_qty || 0,
            withinCount: grcDashboardData?.within_grc_count || 0,
            withinPercentage: grcDashboardData?.within_amount_per || 0,
            withinQtyPercentage: grcDashboardData?.within_inward_qty_per || 0,
            outsideAmountHover: grcDashboardData?.outside_grc_amount_hover || 0,
            outsideAmount: grcDashboardData?.outside_grc_amount || 0,
            outsideQuantityHover: grcDashboardData?.outside_inward_qty_hover || 0,
            outsideQuantity: grcDashboardData?.outside_inward_qty || 0,
            outsideCount: grcDashboardData?.outside_grc_count || 0,
            outsidePercentage: grcDashboardData?.outside_amount_per || 0,
            outsideQtyPercentage: grcDashboardData?.outside_inward_qty_per || 0,
        },
    ]

    console.log(33333333333, upperDashboardData, upperDashboardData?.transporterDashboard?.pending_lr_amount)

    // Remove first two items
    if(parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T") {
        // CardsData = CardsData.slice(2, -2);
        CardsData = [
            {
                amountHover: upperDashboardData?.transporterDashboard?.pending_lr_amount_hover || 0,
                amount: upperDashboardData?.transporterDashboard?.pending_lr_amount || 0,
                quantityHover: upperDashboardData?.transporterDashboard?.pending_lr_qty_hover || 0,
                quantity: upperDashboardData?.transporterDashboard?.pending_lr_qty || 0,
                mainLinks: [],
                bottomLinks: [],
                upperLinks: [],
                label: <LanguageTranslator tag='p' onClick={() => navigateWithFilters('/vendor/logistics/lrProcessing', { "status": "SHIPMENT_INTRANSIT_PENDING_TRANSPORTER" })}>Pending LR Confirmation</LanguageTranslator>,
                count: upperDashboardData?.transporterDashboard?.pending_lr_count || 0,
                totalBoxes: upperDashboardData?.transporterDashboard?.count_pending_lr_box || 0,
                withinAmountHover: upperDashboardData?.transporterDashboard?.within_pending_lr_amount_hover || 0,
                withinAmount: upperDashboardData?.transporterDashboard?.within_pending_lr_amt || 0,
                withinQuantityHover: upperDashboardData?.transporterDashboard?.within_pending_lr_qty_hover || 0,
                withinQuantity: upperDashboardData?.transporterDashboard?.within_pending_lr_qty || 0,
                withinCount: upperDashboardData?.transporterDashboard?.within_pending_lr_count || 0,
                withinPercentage: upperDashboardData?.transporterDashboard?.within_pending_lr_per || 0,
                withinQtyPercentage: upperDashboardData?.transporterDashboard?.within_pending_lr_qty_per || 0,
                outsideAmountHover: upperDashboardData?.transporterDashboard?.outside_pending_lr_amount_hover || 0,
                outsideAmount: upperDashboardData?.transporterDashboard?.outside_pending_lr_amt || 0,
                outsideQuantityHover: upperDashboardData?.transporterDashboard?.outside_pending_lr_qty_hover || 0,
                outsideQuantity: upperDashboardData?.transporterDashboard?.outside_pending_lr_qty || 0,
                outsideCount: upperDashboardData?.transporterDashboard?.outside_pending_lr_count || 0,
                outsidePercentage: upperDashboardData?.transporterDashboard?.outside_pending_lr_per || 0,
                outsideQtyPercentage: upperDashboardData?.transporterDashboard?.outside_pending_lr_qty_per || 0,
            },
            {
                amountHover: upperDashboardData?.transporterDashboard?.gate_amount_hover || 0,
                amount: upperDashboardData?.transporterDashboard?.gate_amount || 0,
                quantityHover: upperDashboardData?.transporterDashboard?.gate_qty_hover || 0,
                quantity: upperDashboardData?.transporterDashboard?.gate_qty || 0,
                mainLinks: [],
                bottomLinks: [
                    upperDashboardData?.transporterDashboard?.outside_gate_count !== 0 &&
                    <LanguageTranslator tag='li' onClick={() => {
                        navigateWithFilters(isENT ? "/enterprise/logistics/goodsIntransit" : '/vendor/logistics/goodsIntransit', {}, { id: "outside" })
                    }}>Goods In-Transit</LanguageTranslator>
                ],
                upperLinks: [
                    upperDashboardData?.transporterDashboard?.within_gate_count !== 0 &&
                    <LanguageTranslator tag='li' onClick={() => {
                        navigateWithFilters(isENT ? "/enterprise/logistics/goodsIntransit" : '/vendor/logistics/goodsIntransit', {}, { id: "within" })
                    }}>Goods In-Transit</LanguageTranslator>
                ],
                label: <LanguageTranslator tag='p' onClick={() => {
                    isENT ? navigateWithFilters("/enterprise/logistics/goodsIntransit", { "status": "SHIPMENT_INTRANSIT" }) : navigateWithFilters('/vendor/logistics/goodsIntransit')
                }}>Pending Gate Entry</LanguageTranslator>,
                count: upperDashboardData?.transporterDashboard?.gate_count || 0,
                totalBoxes: upperDashboardData?.transporterDashboard?.count_pending_gate_box || 0,
                withinAmountHover: upperDashboardData?.transporterDashboard?.within_gate_amount_hover || 0,
                withinAmount: upperDashboardData?.transporterDashboard?.within_gate_amt || 0,
                withinQuantityHover: upperDashboardData?.transporterDashboard?.within_gate_qty_hover || 0,
                withinQuantity: upperDashboardData?.transporterDashboard?.within_gate_qty || 0,
                withinCount: upperDashboardData?.transporterDashboard?.within_gate_count || 0,
                withinPercentage: upperDashboardData?.transporterDashboard?.within_gate_per || 0,
                withinQtyPercentage: upperDashboardData?.transporterDashboard?.within_gate_qty_per || 0,
                outsideAmountHover: upperDashboardData?.transporterDashboard?.outside_gate_amount_hover || 0,
                outsideAmount: upperDashboardData?.transporterDashboard?.outside_gate_amt || 0,
                outsideQuantityHover: upperDashboardData?.transporterDashboard?.outside_gate_qty_hover || 0,
                outsideQuantity: upperDashboardData?.transporterDashboard?.outside_gate_qty || 0,
                outsideCount: upperDashboardData?.transporterDashboard?.outside_gate_count || 0,
                outsidePercentage: upperDashboardData?.transporterDashboard?.outside_gate_per || 0,
                outsideQtyPercentage: upperDashboardData?.transporterDashboard?.outside_gate_qty_per || 0,
            }
        ];
    }

    return (
        <React.Fragment>
            {digivendStore?.getOrderTrackingDashboardUpperDetailsData?.isLoading ? (<OrderTrackingCardSkeleton />) 
            : (
                CardsData.map((upperCardData) => {
                    return <div className='otd-gap'>
                        <div className={`otdc-cards ${upperCardData?.cardClassName}`}>
                            <div className='otdcc-left'>
                                <h3>
                                    <span className='success-icon'><Icons.GRCSuccessIcon /></span>
                                    {toggleType !== 'quantityWise' && <b className='rupee-icon'>₹</b>}{toggleType === 'quantityWise' ? upperCardData?.quantityHover : upperCardData?.amountHover}
                                    <span className='generic-tooltip'>{toggleType === 'quantityWise' ? upperCardData.quantity : upperCardData.amount}</span>
                                    {toggleType === 'quantityWise' && <LanguageTranslator tag="b">Qty</LanguageTranslator>}
                                    {upperCardData?.last7DaysTag && Object.keys(saveFiltersData || {})?.length == 0 && <LanguageTranslator tag="span" className='last-days-tag'>(Last 7 Days)</LanguageTranslator>}
                                </h3>

                                <p> <LanguageTranslator>{upperCardData?.label}</LanguageTranslator>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="7" viewBox="0 0 5 7" fill="none">
                                            <path d="M0.365514 5.30624C0.115533 5.55598 0.115533 5.96129 0.365514 6.21103C0.490483 6.33595 0.654178 6.39844 0.817883 6.39844C0.981625 6.39844 1.14528 6.33595 1.27025 6.21098L3.8296 3.65163C4.07959 3.40189 4.07959 2.99658 3.8296 2.74684L1.27025 0.187486C1.02027 -0.0624953 0.615407 -0.0624953 0.365463 0.187486C0.115482 0.437226 0.115482 0.842533 0.365463 1.09227L2.47236 3.1993L0.365514 5.30624Z" fill="#0F172A"></path>
                                        </svg>
                                        <ul>
                                            {
                                                upperCardData?.mainLinks?.map((link) => {
                                                    return link
                                                })
                                            }
                                        </ul>
                                    </span>
                                </p>
                                <div className='flex'>
                                    <div className='otdccl'>
                                        <LanguageTranslator tag="label">Count</LanguageTranslator>
                                        <LanguageTranslator tag="p">{upperCardData?.count}</LanguageTranslator>
                                    </div>
                                    {upperCardData?.totalBoxes !== 0 &&
                                        <div className='otdccl'>
                                            <LanguageTranslator tag="label">Total Boxes</LanguageTranslator>
                                            <LanguageTranslator tag="p">{upperCardData?.totalBoxes}</LanguageTranslator>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='otdcc-right'>
                                <div className='otdccr-top'>
                                    <div className='otdccrt'>
                                        <p>{toggleType === "quantityWise" ? <LanguageTranslator>Quantity</LanguageTranslator> : <LanguageTranslator>Amount</LanguageTranslator>}</p>
                                        <label>
                                            {toggleType !== 'quantityWise' && <b>₹</b>}
                                            {toggleType === 'quantityWise' ? upperCardData?.withinQuantityHover : upperCardData?.withinAmountHover}
                                            <span className='generic-tooltip'>{toggleType === 'quantityWise' ? upperCardData?.withinQuantity : upperCardData?.withinAmount}</span>
                                        </label>
                                    </div>
                                    <div className='otdccrt'>
                                        <LanguageTranslator tag="p">Count</LanguageTranslator>
                                        <LanguageTranslator tag="span">{upperCardData?.withinCount}</LanguageTranslator>
                                    </div>
                                    <div className='otdccrt-count'>{toggleType === 'quantityWise' ? upperCardData?.withinQtyPercentage : upperCardData?.withinPercentage}%</div>
                                    <ul>
                                        {upperCardData?.upperLinks?.map((link) => {
                                            return link
                                        })}
                                    </ul>
                                </div>
                                <div className='otdccr-bot'>
                                    <div className='otdccrt'>
                                    <p>{toggleType === "quantityWise" ? <LanguageTranslator>Quantity</LanguageTranslator> : <LanguageTranslator>Amount</LanguageTranslator>}</p>
                                        <label>
                                            {toggleType !== 'quantityWise' && <b>₹</b>}
                                            {toggleType === 'quantityWise' ? upperCardData?.outsideQuantityHover : upperCardData?.outsideAmountHover}
                                            <span className='generic-tooltip'>{toggleType === 'quantityWise' ? upperCardData?.outsideQuantity : upperCardData?.outsideAmount}</span>
                                        </label>
                                    </div>
                                    <div className='otdccrt'>
                                        <LanguageTranslator tag="p">Count</LanguageTranslator>
                                        <LanguageTranslator tag="label">{upperCardData?.outsideCount}</LanguageTranslator>
                                    </div>
                                    <div className='otdccrt-count'>{toggleType === 'quantityWise' ? upperCardData?.outsideQtyPercentage : upperCardData?.outsidePercentage}%</div>
                                    <ul>
                                        {upperCardData?.bottomLinks?.map((link) => {
                                            return link
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }))}

        </React.Fragment>
    )
}

export default UpperCardComponent