import React from 'react'
import GenericCustomButton from '../GenericCustomButton';

const GenericFormFooterComponent = (props) => {

    const {buttons, leftButtons = [], isTopBorderRequired=false} = props;

    return (
        <React.Fragment>
            <div className={`generic-form-footer ${isTopBorderRequired ? 'border-t border-[#D6D9E4]' : ''}`}>
                {/* The buttons div always aligned at the right bottom of the modal */}
                <div>
                    {leftButtons?.length > 0 && leftButtons?.map((item, index) => {
                            return <>
                                <GenericCustomButton
                                    sendEventAnalyticsToGoogle = { item?.hasOwnProperty("sendEventAnalyticsToGoogle") ? item?.sendEventAnalyticsToGoogle : true}
                                    {...item}
                                />
                            </>
                        })}
                </div>
                <div className='gff-right'>
                    {/* Render all the buttons data */}
                    {buttons?.map((item, index) => {
                        return <>
                            <GenericCustomButton
                                sendEventAnalyticsToGoogle = { item?.hasOwnProperty("sendEventAnalyticsToGoogle") ? item?.sendEventAnalyticsToGoogle : true}
                                {...item}
                            />
                        </>
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default GenericFormFooterComponent