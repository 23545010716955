import React, { useEffect, useState } from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { getTourSteps } from './tourData';
import { dispatchHandler } from '../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';

const AppTour = ({ location }) => {
    const [enabled, setEnabled] = useState(false);
    const [steps, setSteps] = useState([]);

    const dispatch = useDispatch();

    const [multipleTour, setMultipleTour] = useState(false);
    const [isReplayAppTour, setIsReplayAppTour] = useState(false);
    const [replayTour, setReplayTour] = useState(false)

    const tableComponentPathname = {
        "/inventoryPlanning/assortment": true,
        "/enterprise/purchaseOrder/pendingOrders": true,
        "/enterprise/purchaseOrder/processedOrders": true,
        "/enterprise/purchaseOrder/cancelledOrders": true,
        "/inventoryPlanning/siteMapping": true,
        "/inventoryPlanning/event-mapping": true,
        "/inventoryPlanning/summary": true,
        "/inventoryPlanning/inventoryAutoConfig": true,
        "/inventoryPlanning/order": true,
        "/inventoryPlanning/history": true,
        "/inventoryPlanning/manageAdhocRequest": true,
        "/inventoryPlanning/transferOrder": true,
        "/core/custom/upload/digiars": true,
        "/enterprise/qualityCheck/pendingQc": true,
        "/enterprise/shipment/asnUnderApproval": true,
        "/enterprise/shipment/approvedAsn": true,
        "/enterprise/shipment/cancelledAsn": true,
        "/enterprise/logistics/lrProcessing": true,
        "/enterprise/logistics/goodsIntransit": true,
        "/enterprise/logistics/goodsDelivered": true,
        "/enterprise/logistics/grcStatus": true,
    }

    const getDashKey = {
        "/home/asn/dashboard": "asnLifecycleDashboard",
        "/home/box/analysis/dashboard": "boxAnalyticsDash",
        "/home/orderTracking/dashboard": "entOrderTrackingDash",
        "/home/sales/dashboard": "soTrackingDash",
        "home/vendor/orderTracking/dashboard": "vendorOrderTrackingDash",
        "/home/procurement/dashboard": "procDashboard"
    }

    const multipleTourOfTableComponent = {
        "/inventoryPlanning/assortment": "plannigAssortment",
        "/inventoryPlanning/siteMapping": "siteToSiteMapping",
        "/inventoryPlanning/summary": "repSummary",
        "/inventoryPlanning/transferOrder": "transferOrderPage",
        "/digiproc/excelUploadHistory": "excelUploadHistory",
        "/digiproc/manage/mrpRange": "mrpRange",
        "/digiproc/manageOtbPlan": "manageOtbPlan",
        "/enterprise/purchaseOrder/pendingOrders": "digiVend",
        "/enterprise/order/design": "digiVend",
        "/enterprise/purchaseOrder/processedOrders": "digiVend",
        "/enterprise/purchaseOrder/cancelledOrders": "digiVend",
        "/enterprise/qualityCheck/pendingQc": "digiVend",
        "/enterprise/qualityCheck/cancelledInspection": "digiVend",
        "/enterprise/shipment/asnUnderApproval": "digiVend",
        "/enterprise/shipment/approvedAsn": "digiVend",
        "/enterprise/shipment/cancelledAsn": "digiVend",
        "/enterprise/logistics/lrProcessing": "digiVend",
        "/enterprise/logistics/goodsIntransit": "digiVend",
        "/enterprise/logistics/goodsDelivered": "digiVend",
        "/enterprise/logistics/grcStatus": "digiVend",
        "/digivend/manage/autoASN": "digiVend",
        "/digivend/manage/siteAppointment": "digiVend",
        "/digivend/manage/poConfiguration": "digiVend",
        "/digivend/searchComment": "digiVend",
        "/retailer/retailerPurchaseInvoice": "digiVend",
        "/retailer/retailerBillDiscounting": "digiVend",
        "/core/custom/upload/digivend": "digiVend",
        "/digiproc/fabricNomination": "fabricNominationView",
        "/digiproc/fabricConsumptionLogs": "fabricConsumptionLogs",
        "/purchase/purchaseIndentHistory": "viewIndents",
        "/purchase/purchaseOrderHistory": "viewOrders",
        "/salesManagement/customer/customerMapping": "salesCustomerMapping",
        "/salesManagement/manageDcChallan": "salesManageDcChallan",
        "/salesManagement/retailerManageBuy": "salesRetailerManageBuy",
        "/digiars/inventoryPlanningReport": "inventoryPlanningReport",
        "/inventoryPlanning/transferOrderReport": "transferOrderReport",
        "/digiars/salesInventoryReport": "salesInventoryReport",
        "/inventoryPlanning/saleContribution": "saleContribution",
        "/inventoryPlanning/lastMonthReport": "lastMonthReport",
        "/inventoryPlanning/topBottomReports": "topBottomReports",
        "/inventoryPlanning/catSizeReport": "catSizeReport",
        "/inventoryPlanning/topMovingReport": "topMovingReport",
        "/inventoryPlanning/sell/through/report": "sellThroughReport",
        "/inventoryPlanning/report7": "LYComparison",
        "/inventoryPlanning/bill/report": "billReport",
        "/analytics/retailerSalesReport": "retailerSalesReport",
        "/analytics/retailerSalesReturnReport": "retailerSalesReturnReport",
        "/analytics/retailerSalesReport/SALES_PERSON_REPORT": "SALES_PERSON_REPORT",
        "/analytics/retailerSalesStockItemReport": "retailerSalesStockItemReport",
        "/salesManagement/salesDrrMisReport": "salesDrrMisReport",
        "/salesManagement/so/status/report": "soStatusReport",
        "/inventoryPlanning/grn/report": "grnReport",
        "/analytics/opsreport": "opsReport",
        "/analytics/dispatchreport": "dispatchreport",
        "/analytics/retailerPoReport": "retailerPoReport",
        "/analytics/entPurchaseReport": "entPurchaseReport",
        "/analytics/potogrnreport": "potogrnreport",
        "/analytics/multiLevelApprovalLogs": "multiLevelApprovalLogs",
        "/analytics/poGrnException": "poGrnException",
        "/analytics/tnaorderstatus": "tnaorderstatus",
        "/analytics/retailerLedgerReport": "retailerLedgerReport",
        "/analytics/retailerOutstandingReport": "retailerOutstandingReport",
        "/analytics/customerOutstandingReport": "customerOutstandingReport",
        "/analytics/customerLedgerReport": "customerLedgerReport",
        "/analytics/retailerStockReport": "retailerStockReport",
        "/analytics/stockagingReport": "stockagingReport",
        "/salesManagement/stockDrrMisReport": "stockDrrMisReport",
        "/mdm/factoryaddressreport": "factoryaddressreport",
        "/analytics/appointmentReport": "appointmentReport",
        "/analytics/retailerLrReport": "retailerLrReport",
        "/analytics/logisticsInventoryReport": "logisticsInventoryReport",
        "/analytics/logisticsPaymentReconciliation": "logisticsPaymentReconciliation",
        "/analytics/autoAsnReport": "autoAsnReport",
        "/analytics/shipmentBoxReport": "shipmentBoxReport",
        "/analytics/retailerInspectionReport": "retailerInspectionReport",
        "/analytics/retailerAsnReport": "retailerAsnReport",
        "/mdm/manageVendors": "manageVendors",
        "/mdm/manageItems": "manageItems",
        "/mdm/manageAgent": "manageAgent",
        "/mdm/manageTransporters": "manageTransporters",
        "/mdm/materiallibrary": "materialLibrary",
        "/mdm/fabric/library": "fabricLibrary",
        "/catalogue/cost/sheet/library": "costsheetLibrary",
        "/mdm/manageMailingList": "manageMailingList",
        "/mdm/manageCustomers": "manageCustomers",
        "/mdm/manageSalesAgents": "manageSalesAgents",
        "/mdm/manageSites": "manageSites",
        "/administration/rolesMaster/manageRoles": "manageRoles",
        "/administration/rolesMaster/manageRolesNew": "manageRolesNew",
        "/administration/rolesMaster/createRoles": "createRoles",
        "/administration/users": "users",
        "/administration/userDataMapping": "userDataMapping",
        "/administration/dataSync": "dataSync",
        "/administration/apilogs": "apilogs",
        "/administration/auditLogs": "auditLogs",
        "/administration/systemMails": "systemMails"
    };

    const dashboardPaths = [
        "/home/asn/dashboard",
        "/home/box/analysis/dashboard",
        "/home/ars/new/dashboard",
        "/home/customer/dashboard",
        "/home/procurement/dashboard",
        "/home/wh/appointment/dashboard",
        "/home/orderTracking/dashboard",
        "/home/vendor/orderTracking/dashboard",
        "/home/ars/dashboard",
        "/home/sales/dashboard",
    ];

    const getTourKey = (path) => {
        const isTourCompleted = (key) => sessionStorage.getItem?.("frontendViewId")?.includes(key);

        if (dashboardPaths.includes(path)) {
            const tourCompleted = isTourCompleted("genericComponents");
            // if (path.includes("home") && (!tourCompleted || isReplayAppTour)) {
            //     setMultipleTour(true);
            //     setIsReplayAppTour(false)
            //     return "genericComponents";
            // }
            return getDashKey?.[path] || "";
        }
        if (path === "/administration/globalConfiguration") return "globalConfiguration";

        if (path in multipleTourOfTableComponent) {
            const tourCompleted = isTourCompleted("tableComponent");
            if (!tourCompleted || isReplayAppTour) {
                setIsReplayAppTour(false)
                setMultipleTour(true);
                return "tableComponent";
            }
            return multipleTourOfTableComponent?.[path];
        }

        switch (path) {
            case "/digiproc/createIndent": return "createIndent";
            case "/digiproc/createOrder": return "createOrder";
            case "/salesManagement/viewSalesOrders": return "viewSalesOrders";
            case "/catalogue/products/history": return "catalogueHistory";
            default: {
                if (tableComponentPathname?.[path]) {
                    return "tableComponent";
                }
            }
        }
    }

    const fetchTourSteps = async (isMounted) => {
        let tourKey = getTourKey(location?.pathname);
        try {
            const tourSteps = await getTourSteps(tourKey);
            if (isMounted) setSteps(tourSteps); // Ensure state updates only if mounted
            // const tourCompleted = sessionStorage.getItem?.("frontendViewId")?.includes(tourKey);
            // Show the tour only if it hasn't been shown before and steps are available
            // (isMounted && (!tourCompleted || replayTour) && tourSteps.length > 0) if condition if user didnt show the tour
            if (isMounted && (replayTour) && tourSteps.length > 0) {
                setTimeout(() => setEnabled(true), 500); // Delay to ensure elements are rendered
            }
        } catch (error) {
            console.error("Failed to fetch tour steps:", error);
        }
    };

    useEffect(() => {
        let isMounted = true; // Track if component is still mounted
        if(location?.replayAppTour) {
            setIsReplayAppTour(true);
            setReplayTour(true);
        }
        setTimeout(() => {
            fetchTourSteps(true);
        }, 500);
        // Cleanup function to avoid memory leaks if component unmounts
        return () => {
            isMounted = false;
            setReplayTour(false);
        };
    }, [location?.pathname]);

    useEffect(() => {
        if(isReplayAppTour) {
            setTimeout(() => {
                fetchTourSteps(true);
            }, 500);
        }
    }, [isReplayAppTour])

    useEffect(() => {
        if(multipleTour === "runTourAgain") {
            fetchTourSteps(true);
            setMultipleTour(false)
        }
    }, [multipleTour])

    const handleExit = () => {
        const tourKey = getTourKey(location?.pathname);
        setEnabled(false);
    
        let frontendViewId = sessionStorage.getItem("frontendViewId") || "";
    
        if (!frontendViewId.includes(tourKey)) {
            // Dispatch system config update
            dispatchHandler(dispatch, "systemConfigCreateViewRequest", { frontendViewId: tourKey });
            frontendViewId = frontendViewId ? `${frontendViewId}|${tourKey}` : tourKey;
            sessionStorage.setItem("frontendViewId", frontendViewId);
        }
    
        // Handle multiple tours
        if (multipleTour === true) {
            setMultipleTour("runTourAgain");
        }
    };

    const scrollToElement = (element) => {
        if (element) {
            const pathsWithOffsetParentLogic = [
                '/home/asn/dashboard',
                '/home/box/analysis/dashboard',
                '/home/sales/dashboard',
                '/core/custom/upload/digiars'
            ];
            const currentPath = location?.pathname;
            const shouldUseOffsetParent = pathsWithOffsetParentLogic.includes(currentPath);

            // Conditionally run the offsetParent logic
            if (shouldUseOffsetParent && element.offsetParent) {
                element = element.offsetParent;
            }

            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    return (
        <Steps
            enabled={enabled}
            steps={steps}
            initialStep={0}
            onExit={handleExit}
            onComplete={handleExit}
            options={{
                overlayOpacity: 0.5,
                exitOnOverlayClick: true,
                showStepNumbers: false,
                keyboardNavigation: true,
                scrollToElement: true,
                scrollPadding: 100,
                showBullets: false,
                disableInteraction: true,
            }}
            onBeforeChange={(nextIndex) => {
                const nextStep = steps[nextIndex];
                let element = document?.querySelector(nextStep?.element);
                scrollToElement(element);
            }}
        />
    );
};

export default AppTour;
