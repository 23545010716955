import Lottie, { useLottie } from 'lottie-react'
import React, { useEffect, useRef } from 'react'

import ImagesRightArrow from '../../animationsJSON.js/ImagesRightArrow.json'

const RightArrowImageComponent = () => {
    // const lottieRef = useRef(null);

    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: ImagesRightArrow,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice",
    //     },
    // };

    // return (
    //     <div
    //         onMouseEnter={() => {
    //             if (lottieRef.current) {
    //                 lottieRef.current.play();
    //             }
    //         }}
    //         onMouseLeave={() => {
    //             if (lottieRef.current) {
    //                 lottieRef.current.stop();
    //             }
    //         }}
    //         style={{ width: 19, height: 18 }}
    //     >
    //         <Lottie
    //             // ref={lottieRef}
    //             lottieRef = {lottieRef}
    //             {...defaultOptions}
    //             style={{
    //                 height: 19,
    //                 width:18
    //             }}  
    //         />
    //     </div>
    // )

    const options = {
        animationData: ImagesRightArrow,
        loop: true,
        autoplay: true, // Start animation automatically
        renderer: "svg", 
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice", // Keeps aspect ratio intact
        },
      };
    
      const { View } = useLottie(options);
    
      return (
        <div className='relative'>
          {/* <div
            className='imageLottieAnimation'
            style={{ width: "18px", height: "18px" }}
            >
            {View}
          </div> */}
          <div className='imageLottieStatic mr-[6px]'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" height={18} viewBox="0 0 96 96" id="ImageRight">
              <circle cx="35" cy="31" r="6" stroke="#2969ff" stroke-width="5" class="colorStroke000000 svgStroke"></circle>
              <path stroke="#2969ff" stroke-linecap="round" stroke-width="5" d="M52.5 9H24C15.7157 9 9 15.7157 9 24V71C9 79.2843 15.7157 86 24 86H71C79.2843 86 86 79.2843 86 71V52.5 49.3137C86 47.192 85.1571 45.1571 83.6569 43.6569L79.182 39.182C77.4246 37.4246 74.5754 37.4246 72.818 39.182L51.682 60.318C49.9246 62.0754 47.0754 62.0754 45.318 60.318L39.682 54.682C37.9246 52.9246 35.0754 52.9246 33.318 54.682L11 77M72 20L63 20M77 13L80.4645 16.4645C82.4171 18.4171 82.4171 21.5829 80.4645 23.5355L77 27" class="colorStroke000000 svgStroke"></path>
            </svg>
          </div>
        </div>
      );
}

export default RightArrowImageComponent