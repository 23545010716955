import React, { useEffect, useState } from 'react'
import AllFilters from '../components/AllFilters'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import AsnDashboardSkeleton from './AsnDashboardSkeleton';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Dropdown, Menu, Radio } from 'antd';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';

const PRIMARY_FILTERS = [
    {
        value: "Vendor",
        key: "vendor_name",
        code: "",
        entity: "asn_dashboard_details",
    },
    {
        value: "City",
        key: "city",
        entity: "asn_dashboard_details",
    },
];

const MAIN_FILTERS = [
    {
        value: "Division",
        key: "hl1_name",
        entity: "item",
    },
    {
        value: "Section",
        key: "hl2_name",
        entity: "item",
    },
    {
        value: "Department",
        key: "hl3_name",
        entity: "item",
    },
    {
        value: "State",
        key: "state1",
        entity: "asn_dashboard_details",
    },
    {
        value: "Transporter Name",
        key: "transporter_name",
        entity: "asn_dashboard_details",
    },
    {
        value: "Site Code",
        key: "site_name",
        entity: "asn_dashboard_details",
        code: ""
    },
]

const filterItems = {
    "Default Headers": {
        dateRange: "Date From - Date To",
        vendor_name: "Vendor",
        city: "City",
        hl1_name: "Division",
        hl2_name: "Section",
        hl3_name: "Department",
        site: "Site",
        state1: "State",
        transporter_name: "Transporter Name",
        site_name: "Site Code",
    }
    ,
    "columnType": {
        dateRange: "DATE",
        vendor_name: "SEARCH",
        city: "SEARCH",
        hl1_name: "SEARCH",
        hl2_name: "SEARCH",
        hl3_name: "SEARCH",
        site: "SEARCH",
        state1: "SEARCH",
        transporter_name: "SEARCH",
        site_name: "SEARCH",
    },
}

const filterPayload = {
    attributeType: "ASN_LIFECYCLE_DASHBOARD",
    displayName: "ASN_LIFECYCLE_DASHBOARD",
    filterName: ""
};

const mappingSelectedFilters = {
    vendor_name: "Vendor",
    city: "City",
    hl1_name: "Division",
    hl2_name: "Section",
    hl3_name: "Department",
    site: "Site",
    state1: "State",
    transporter_name: "Transporter Name",
    site_name: "Site Code",
}
const currentFilterKeys = ["vendor_name", 'transporter_name', "city", "state1", "hl1_name", "hl2_name", "hl3_name", 'site', "dateRange", "site_name"]

const currentDate = dayjs()
const disabledDate = d => !d || d.isAfter(currentDate)

const AsnLifecycleDashboard = (props) => {
    const digivendStore = useSelector((state) => state.digivendStore)
    const generalStore = useSelector((state) => state.generalStoreF)
    const dispatch = useDispatch()

    const [dashboardData, setDashboardData] = useState([])
    const [graphType, setGraphType] = useState('normal')
    const [zoneWise, setZoneWise] = useState('asnToLr')
    const [zoneTitle, setZoneTitle] = useState('Pickup Request')
    const [selectVendorWise, setSelectVendorWise] = useState("asnToLr");
    const [vendorTitle, setVendorTitle] = useState("Pickup Request");
    const [vendorGraphType, setvendorGraphType] = useState('normal')

    useEffect(() => {
        if (digivendStore?.getASNDashboardDetailsData?.isSuccess) {
            setDashboardData(digivendStore?.getASNDashboardDetailsData?.data?.resource || [])
        }
    }, [digivendStore?.getASNDashboardDetailsData?.isSuccess])

    useEffect(() => {
        getDashboardData()
    }, [])

    useEffect(() => {
        if (generalStore?.getQuickFiltersData?.isSuccess) {
            getDashboardData()
        }
    }, [generalStore?.getQuickFiltersData?.isSuccess])

    const getDashboardData = (filtersData = {}) => {
        console.log(56565656, "Running")
        setDashboardData([]);

        let apiPayload = {};
        if (Object.keys(filtersData)?.length === 0) {
            apiPayload = {};
        } else {
            apiPayload = { filter: {} }

            Object.keys(filtersData).forEach((filter) => {
                switch (filter) {
                    case 'dateRange':
                        const { from, to } = filtersData.dateRange || {};
                        if (from && to) {
                            apiPayload.filter.dateFrom = dayjs(from)?.format('YYYY-MM-DD');
                            apiPayload.filter.dateTo = dayjs(to)?.format('YYYY-MM-DD');
                        }
                        break;
                    case 'dateFrom':
                        apiPayload.filter.dateFrom = filtersData['dateFrom']
                        break
                    case 'dateTo':
                        apiPayload.filter.dateTo = filtersData['dateTo']
                        break
                    default:
                        if (typeof filtersData[filter] === 'string') {
                            apiPayload.filter[filter] = filtersData[filter]?.split(',');
                        } else if (Array.isArray(filtersData?.[filter])) {
                            apiPayload.filter[filter] = filtersData[filter]
                        }

                        break;
                }
            });
        }

        console.log(6767676767, apiPayload)

        dispatchHandler(dispatch, 'getASNDashboardDetailsRequest', apiPayload, getPermissionKey("dashboard", "asnlifecycle", "view"))
    }

    const renderPieData = dashboardData && dashboardData?.asnLifeCycle?.map((item) => {
        if (item?.stage === "Inspection request to Inspection Approval") {
            return {
                "name": "ASN Approval",
                "value": item.value,
            }
        }
        else if (item?.stage === "ASN Approval to LR Creation") {
            return {
                "name": "Pickup Request",
                "value": item?.value,
            }
        }
        else if (item?.stage === "In Transit to Gate Entry") {
            return {
                "name": "Transit Time",
                "value": item?.value,
            }
        }
        else if (item?.stage === "Gate Entry TO GRC") {
            return {
                "name": "GRN",
                "value": item?.value,
            }
        }
        return {
            "name": item?.stage,
            "value": item?.value,
        }
    })

    const renderLeftComponent = () => {
        return <div className="pie-chart-section !flex-col asn-dash-left">
            <div className='piecs-inner piecs-inner-header'>
                <div className='piecsi-top alignItemBaseLine m0'>
                    <div className='piecsit-left'>
                        <LanguageTranslator tag="h3">ASN Complete Lifecycle</LanguageTranslator>
                        <LanguageTranslator tag="p">All Bucket Events</LanguageTranslator>
                    </div>
                </div>
            </div>
            <div className='graph-table-section d-flex flex-row'>
                <div className="pcs-left">
                    <div className='pcsl-circle'>
                        {renderPieData &&
                            <PieChart width={250} height={250}
                            >
                                <Pie
                                    data={renderPieData}
                                    cx={120}
                                    cy={120}
                                    isAnimationActive={false}
                                    innerRadius={60}
                                    outerRadius={90}
                                    fill="#8884d8"
                                    label={{ color: "#121212", fontSize: 12, fontWeight: 500, }}
                                    dataKey="value"
                                >
                                    {dashboardData && dashboardData?.asnLifeCycle?.map((item, index) => (
                                        <Cell key={item?.stage} fill={
                                            item?.stage === "Inspection request to Inspection Approval" ? "#6bf3e3" :
                                                item?.stage === "ASN Approval to LR Creation" ? "#ef6e8b" :
                                                    item?.stage === "In Transit to Gate Entry" ? "#824bf6" : "#ffaf4f"
                                        }
                                        />
                                    ))}
                                </Pie>
                                <Tooltip
                                    overlayClassName='!p-[0]'
                                    labelStyle={{ color: "#ffffff82", fontWeight: 600, }}
                                    contentStyle={{ color: "#fff", fontWeight: 600 }}
                                />
                            </PieChart>}
                    </div>
                </div>
                {dashboardData &&
                    <div className="pcs-right p-0">
                        <table className='table asn-lifecycle-table'>
                            <thead>
                                <tr>
                                    <th><LanguageTranslator tag="label">Labels</LanguageTranslator></th>
                                    <th><LanguageTranslator tag="label">Actual</LanguageTranslator></th>
                                    <th><LanguageTranslator tag="label">Expected</LanguageTranslator></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='pcsr-row'>
                                            <span className='pcsr-dot gate-entry-grc'></span>
                                            <h3><LanguageTranslator>ASN Approval</LanguageTranslator>
                                                <span className="generic-tooltip">Inspection request to Inspection Approval</span>
                                            </h3>
                                        </div>
                                    </td>
                                    <td>
                                        {dashboardData && dashboardData?.asnLifeCycle?.map((item) => {
                                            if (item?.stage === "Inspection request to Inspection Approval") return item?.value
                                        })}
                                    </td>
                                    <td>{dashboardData && dashboardData?.insreq_to_insapp_expected_value}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='pcsr-row'>
                                            <span className='pcsr-dot asn-aprv-lrcreat'></span>
                                            <h3><LanguageTranslator>Pickup Request</LanguageTranslator>
                                                <LanguageTranslator tag='span' className="generic-tooltip">ASN Approval to LR Creation</LanguageTranslator>
                                            </h3>
                                        </div>
                                    </td>
                                    <td>
                                        {dashboardData && dashboardData?.asnLifeCycle?.map((item) => {
                                            if (item?.stage === "ASN Approval to LR Creation")
                                                return <label>{item?.value}</label>
                                        })}
                                    </td>
                                    <td>{dashboardData && dashboardData?.asn_to_lr_expected_value}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='pcsr-row'>
                                            <span className='pcsr-dot'></span>
                                            <h3><LanguageTranslator>Transit Time</LanguageTranslator>
                                                <span className="generic-tooltip">In Transit to Gate Entry</span>
                                            </h3>
                                        </div>
                                    </td>
                                    <td>
                                        {dashboardData && dashboardData?.asnLifeCycle?.map((item) => {
                                            if (item?.stage === "In Transit to Gate Entry")
                                                return <label>{item?.value}</label>
                                        })}
                                    </td>
                                    <td>{dashboardData && dashboardData?.intransit_to_gateentry_expected_value}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='pcsr-row'>
                                            <span className='pcsr-dot intran-gate-entry'></span>
                                            <h3>GRN
                                                <span className="generic-tooltip">Gate Entry TO GRC</span>
                                            </h3>
                                        </div>
                                    </td>
                                    <td>
                                        {dashboardData && dashboardData?.asnLifeCycle?.map((item) => {
                                            if (item.stage === "Gate Entry TO GRC")
                                                return <label>{item?.value}</label>
                                        })}
                                    </td>
                                    <td>{dashboardData && dashboardData?.gateentry_to_grc_expected_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </div>
        </div>
    }

    const handleMenuClick = (key, data) => {
        switch (key) {
            case 'zone':
                setZoneTitle(data?.title)
                setZoneWise(data?.key)
                break;
            case 'vendorSide':
                setSelectVendorWise(data?.key)
                setVendorTitle(data?.title)
                break

            default:
                break;
        }
    }

    const menuItems = {
        inspToInsApp: "ASN Approval",
        asnToLr: "Pickup Request",
        inTransToGateEntry: "Transit Time",
        gateToGrc: "GRN"
    };
    const menu = (type) => (
        <Menu onClick={({ key }) => handleMenuClick(type, {
            key: key,
            title: menuItems?.[key],
        })}>
            <Menu.Item key="inspToInsApp"><LanguageTranslator>ASN Approval</LanguageTranslator></Menu.Item>
            <Menu.Item key="asnToLr"><LanguageTranslator>Pickup Request</LanguageTranslator></Menu.Item>
            <Menu.Item key="inTransToGateEntry"><LanguageTranslator>Transit Time</LanguageTranslator></Menu.Item>
            <Menu.Item key="gateToGrc">GRN</Menu.Item>
        </Menu>
    );

    const ZONE_TYPE_MAP = {
        asnToLr: "asn_to_lr",
        gateToGrc: "gateentry_to_grc",
        inspToInsApp: "insreq_to_insapp",
        inTransToGateEntry: "intransit_to_gateentry",
    };

    const renderZoneData = dashboardData && dashboardData?.cityDetails?.map(item => {
        if (ZONE_TYPE_MAP?.[zoneWise] === "asn_to_lr") {
            return {
                "city": item?.city,
                "Actual": item?.asn_to_lr,
                "Expected": dashboardData?.asn_to_lr_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP?.[zoneWise] === "gateentry_to_grc") {
            return {
                "city": item?.city,
                "Actual": item?.gateentry_to_grc,
                "Expected": dashboardData?.gateentry_to_grc_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP?.[zoneWise] === "insreq_to_insapp") {
            return {
                "city": item?.city,
                "Actual": item?.insreq_to_insapp,
                "Expected": dashboardData?.insreq_to_insapp_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP?.[zoneWise] === "intransit_to_gateentry") {
            return {
                "city": item?.city,
                "Actual": item?.intransit_to_gateentry,
                "Expected": dashboardData?.intransit_to_gateentry_expected_value,
            }
        }
    })

    const renderRightComponent = () => {
        return <div className="pie-chart-section m-lft-12 asn-dash-right">
            <div className='piecs-inner'>
                <div className='piecsi-top'>
                    <div className='piecsit-left'>
                        <LanguageTranslator tag="h3">Pick Up Zone</LanguageTranslator>
                        <LanguageTranslator tag="p">ASN Lifecycle</LanguageTranslator>
                    </div>
                    <div className='piecsit-right flex justify-between'>
                        <Radio.Group value={graphType} onChange={(e) => setGraphType(e?.target?.value || "")}>
                            <Radio.Button value={"normal"}><LanguageTranslator>Normal</LanguageTranslator></Radio.Button>
                            <Radio.Button value={"compact"}><LanguageTranslator>Compact Graph</LanguageTranslator></Radio.Button>
                        </Radio.Group>
                        <Dropdown.Button overlay={menu('zone')} placement="bottomLeft">
                        <LanguageTranslator>{zoneTitle}</LanguageTranslator>
                        </Dropdown.Button>
                    </div>
                </div>
                <div className='piecsi-bottom'>
                    {renderZoneData &&
                        <ResponsiveContainer width="100%" height={250}>
                            <ComposedChart
                                data={renderZoneData}
                            >
                                <CartesianGrid stroke="#d8d9db" strokeDasharray="2" />
                                <XAxis yAxisId="left" dataKey="city" label={{ position: "insideLeft" }} angle={45} textAnchor="start" interval={graphType === "compact" ? "preserveEnd" : 0} wrapperStyle={{ position: 'relative' }} />
                                <Tooltip overlayClassName='!p-[0]' labelStyle={{ color: "#111d31", fontWeight: 700, fontSize: 14 }} contentStyle={{ color: "#12203c", fontWeight: 600, fontSize: 12, }} />
                                <Legend layout="horizontal" verticalAlign="top" align="left" />
                                <Bar dataKey="Actual" barSize={10} fill="#5f59f7" />
                                <Line type="monotone" dataKey="Expected" stroke="#566464" dot={false} />
                            </ComposedChart>
                        </ResponsiveContainer>}
                </div>
            </div>
        </div>
    }

    const renderVendorData = dashboardData && dashboardData?.vendorDetails?.map(item => {
        if (ZONE_TYPE_MAP[selectVendorWise] === "asn_to_lr") {
            return {
                "Name": item?.vendor_name,
                "Actual": item?.asn_to_lr,
                "Expected": dashboardData?.asn_to_lr_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP[selectVendorWise] === "gateentry_to_grc") {
            return {
                "Name": item?.vendor_name,
                "Actual": item?.gateentry_to_grc,
                "Expected": dashboardData?.gateentry_to_grc_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP[selectVendorWise] === "insreq_to_insapp") {
            return {
                "Name": item?.vendor_name,
                "Actual": item?.insreq_to_insapp,
                "Expected": dashboardData?.insreq_to_insapp_expected_value,
            }
        }
        else if (ZONE_TYPE_MAP[selectVendorWise] === "intransit_to_gateentry") {
            return {
                "Name": item?.vendor_name,
                "Actual": item?.intransit_to_gateentry,
                "Expected": dashboardData?.intransit_to_gateentry_expected_value,
            }
        }
    });

    const renderBottomComponent = () => {
        return <div className="pie-chart-section m-top-12 asn-dash-bottom" >
            <div className='piecs-inner'>
                <div className='piecsi-top'>
                    <div className='piecsit-left'>
                        <LanguageTranslator tag="h3">Vendor</LanguageTranslator>
                        <LanguageTranslator tag="p">ASN Lifecycle</LanguageTranslator>
                    </div>
                    <div className='piecsit-right'>
                        <Radio.Group className='asn-dash-button' value={vendorGraphType} onChange={(e) => setvendorGraphType(e?.target?.value || "")}>
                            <Radio.Button value={"normal"}><LanguageTranslator>Normal</LanguageTranslator></Radio.Button>
                            <Radio.Button value={"compact"}><LanguageTranslator>Compact Graph</LanguageTranslator></Radio.Button>
                        </Radio.Group>
                        <Dropdown.Button className='asn-dash-dropdown' overlay={menu("vendorSide")} placement="bottomLeft">
                            <LanguageTranslator>{vendorTitle}</LanguageTranslator>
                        </Dropdown.Button>
                    </div>
                </div>
                {renderVendorData &&
                    <ResponsiveContainer width="100%" height={350}>
                        <ComposedChart data={renderVendorData}>
                            <CartesianGrid stroke="#d8d9db" strokeDasharray="2" />
                            <XAxis yAxisId="left" dataKey="Name" label={{ position: "insideLeft", }} angle={45} textAnchor="start" interval={vendorGraphType === "compact" ? "preserveEnd" : 0} wrapperStyle={{ position: 'relative' }} />
                            <Tooltip overlayClassName='!p-[0]' labelStyle={{ color: "#111d31", fontWeight: 700, fontSize: 14 }} contentStyle={{ color: "#12203c", fontWeight: 600, fontSize: 12, }} />
                            <Legend layout="horizontal" verticalAlign="top" align="left" contentStyle={{ marginBottom: 10, }} wrapperStyle={{ marginBottom: 20 }} />
                            <Bar dataKey="Actual" barSize={10} fill="#5f59f7" />
                            <Line type="monotone" dataKey="Expected" stroke="#566464" dot={false} />
                        </ComposedChart>
                    </ResponsiveContainer>}
            </div>
        </div>
    }

    const defaultDateValue = [dayjs().subtract(1, 'month'), dayjs()]
    return (
        <React.Fragment>
            <AllFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                MAIN_FILTERS={MAIN_FILTERS}
                disabledDate={disabledDate}
                applyFilter={getDashboardData}
                mappingSelectedFilters={mappingSelectedFilters}
                filterPayload={filterPayload}
                filterItems={filterItems}
                currentFilterKeys={currentFilterKeys}
                defaultInputState={{
                    "dateRange": defaultDateValue
                }}
            />
            {digivendStore?.getASNDashboardDetailsData?.isLoading ? 
                    <AsnDashboardSkeleton /> :
                    <div className='asn-life-cycle'>
                        <div className='flex' >
                            {renderLeftComponent()}

                            {renderRightComponent()}
                        </div>
                        {renderBottomComponent()}
                    </div>
            }

        </React.Fragment>
    )
}

export default AsnLifecycleDashboard