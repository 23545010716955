import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from '../../../../genericComponents/forms/GenericFormHeaderComponent'
import GenericFormFooterComponent from '../../../../genericComponents/forms/GenericFormFooterComponent'
import LoginPasswordInput from '../../../../authComponents/login/LoginPasswordInput';
import GenericInput from '../../../../genericComponents/input/GenericInputComponent';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import OtpForm from '../../../../authComponents/login/OtpForm';
import Icons from '../../../../assets/icons';

const EditEmail = (props) => {
    const { type = "email", flowName = "editEmailOrMobile", logoutFunction = null } = props;

    // Default current step according to the flowName
    let defaultCurrentStep = {
        "verifyEmailOrMobile": "otp",
        "editEmailOrMobile": "password",
    }

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [currentStep, setCurrentStep] = useState(defaultCurrentStep?.[flowName])
    const authStore = useSelector(state => state.authStore);
    const [detailsUpdated, setDetailsUpdated] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (authStore?.["updateUserInformationData"]?.isSuccess) {
            setCurrentStep("otp");
            setDetailsUpdated(true)
            if(!props?.popUpOpenedFromProfile) {
                setPassword("");
                setEmail("");
            }
        }

        if(authStore?.["getOtpData"]?.isSuccess) {
            setDetailsUpdated(false)
        }
        
    }, [authStore?.["updateUserInformationData"]?.isSuccess, authStore?.["getOtpData"]?.isSuccess])

    const handleChange = (type, subType = currentStep) => {
        switch (type) {
            case "submit": {
                switch (subType) {
                    case "password": {
                        if (!(password?.trim() == "" || !password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/))) {
                            setCurrentStep(props.type);
                        }
                        break;
                    }
                    case "email": {
                        if (!(email?.trim() == "" || !email?.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
                            dispatchHandler(dispatch, "updateUserInformationRequest", {
                                "token": sessionStorage?.getItem("token") || "",
                                "source": "EMAIL",
                                "password": btoa(password),
                                "hash": "true",
                                "email": email
                            })
                        }
                        break;
                    }
                    case "mobile": {
                        if (!(email?.trim() == "" || !email?.match(/^(\+?[0-9]{1,4})?[-.\s]?([1-9][0-9]{6,14})$/))) {
                            dispatchHandler(dispatch, "updateUserInformationRequest", {
                                "token": sessionStorage?.getItem("token") || "",
                                "source": "MOBILE",
                                "password": btoa(password),
                                "hash": "true",
                                "mobile": email
                            })
                        }
                        break;
                    }
                    case "otp": {
                        dispatchHandler(dispatch, "getOtpRequest", {
                            token: sessionStorage?.getItem("token"),
                            source: props.type
                        })
                        break;
                    }
                }
                break;
            }
        }
    }

    const isNextButtonDisabled = () => {
        switch (currentStep) {
            case "password": {
                return password?.trim() == "" || !password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/)
            }
            case "email": {
                return email?.trim() == "" || !email?.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            }
            case "mobile": {
                return email?.trim() == "" || !email?.match(/^(\+?[0-9]{1,4})?[-.\s]?([1-9][0-9]{6,14})$/)
            }
        }
    }

    console.log("Current Step", currentStep);

    // Function : To get the otp resource data
    const getOtpResourceData = () => {
        switch (flowName) {
            case "verifyEmailOrMobile": {
                return detailsUpdated ? authStore?.["updateUserInformationData"]?.["data"]?.["resource"]?.["mobile"] || "" : authStore?.["getOtpData"]?.["data"]?.["resource"]?.["mobile"] || ""
            }
            case "editEmailOrMobile": {
                return authStore?.["updateUserInformationData"]?.["data"]?.["resource"]?.["mobile"] || ""
            }
        }
    }

    const renderEmailForm = () => {
        switch (currentStep) {
            case "password": {
                return <LoginPasswordInput
                    lable="Password"
                    password={password}
                    setPassword={setPassword}
                    submit={() => handleChange("submit")}
                />
            }
            case "email": {
                return <GenericInput
                    label="Email"
                    key="email"
                    onKeyDown={(e) => e.key == "Enter" && handleChange("submit")}
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value || "")}
                />
            }
            case "mobile": {
                return <GenericInput
                    label="New Mobile Number"
                    key="mobile"
                    type="mobileNo"
                    onKeyDown={(e) => e.key == "Enter" && handleChange("submit")}
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value || "")}
                />
            }
            case "otp": {
                return <OtpForm
                    otpResource={getOtpResourceData()}
                    editOtpResource={() => { setCurrentStep(type == "mobile" || type == "email" ? "password" : type) }}
                    source={type == "mobile" ? "MOBILE" : "EMAIL"}
                    flowType={undefined}
                    token={sessionStorage?.getItem("token")}
                    finalSubmit={(data) => {
                        console.log("Change Done", props);
                        props.closeModal();
                    }}
                    flowName={flowName}
                    showLogoutBtn={true}
                    resendOtp={() => flowName == "verifyEmailOrMobile" ? handleChange("submit") :handleChange("submit", type) }
                    logoutFunction={logoutFunction}
                />
            }
        }
    }

    return (<div className='edit-email h-full flex flex-col'>
        {currentStep == "otp" && (
            <div className='success-msg'>
                <Icons.CircleTickFilledWhite />
                {type == "mobile" ?
                    <p>We have sent a six digit OTP to your registered mobile number.</p>
                    :
                    <p>We have sent a six digit OTP to your registered email.</p>
                }
            </div>
        )}
        <GenericFormHeaderComponent
            label={{
                contentPrimary: `${flowName == "verifyEmailOrMobile" ? "Verify" : "Update"} ${type == "mobile" ? "Mobile" : "Email"}`,
            }}
            closeModal={flowName == "verifyEmailOrMobile" ? null : props.closeModal}
        />

        <div className={`ee-body ${!["otp"]?.includes(currentStep) ? "" : "ee-otp-body"} ${flowName == "verifyEmailOrMobile" ? "ee-otp-body-verify" : ""}`}>
            {currentStep == "otp" && type == "mobile" && authStore?.["updateUserInformationData"]?.data?.resource?.flowType?.toUpperCase() == "REQUIRED_MERGE_OTP" &&
                <div className='ee-alert'>
                    <div>
                        <Icons.WarningYellowIcon />
                    </div>
                    <div className='p-lft-10'>
                        <p>Multiple accounts linked to your contact number have been identified by our system.</p>
                        <span>Since you have multiple accounts associated with this number, you will be required to merge your accounts in the next step</span>
                    </div>
                </div>
            }

            {renderEmailForm()}
        </div>

        {!["otp"]?.includes(currentStep) && <GenericFormFooterComponent
            buttons={
                [
                    currentStep == type && { label: "Back", type: "default", onClick: () => { setCurrentStep("password"); setPassword("") } },
                    { label: ["email", "mobile"]?.includes(currentStep) ? "Update" : "Continue", type: "primary", disabled: () => isNextButtonDisabled(), onClick: () => handleChange("submit") }
                ]
            }
        />}
    </div>
    )
}

export default EditEmail