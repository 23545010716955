import React from 'react'
const divsArray = Array.from({ length: 11 }, index => index + 1);
const divsArray2 = Array.from({ length: 30 }, index => index + 1);

const TableSkeleton = (props) => {
    const { className='' } = props;

    return (
        <div className={`dsr-skeleton ot-table-loader ${className}`}>
            <table>
                <tbody>
                    {divsArray2.map(() => (
                        <tr>
                            {divsArray.map(() => (
                                <td>
                                    <div className='generic-skeleton'></div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableSkeleton