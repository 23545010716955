import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../../styles/stylesheets/SideBar.css'
import { parseJwt } from '../../helper/genericFunction'
import IconsPath from '../../assets/icons/index'
import $ from 'jquery'
import { getNumberOfStrips } from '../../initialPhaseOperations/InitialAnnouncementModal'
import '../../styles/stylesheets/Breadcrumb/Sidebar.scss'
import LanguageTranslator from '../../locals/LanguageTranslator'
import { UserDetailsContext } from '../../App'
import { ReloadContext } from '../../App'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const Sidebar = () => {
    const history = useHistory();
    let activeHome = history.location.pathname.includes("home");
    let {userDetails} = useContext(UserDetailsContext)
    let modules = JSON.parse(userDetails?.modules || "[]");
    const [topValue, setTopValue] = useState(0);
    const [sideBackdrop, setSideBackdrop] = useState(false)
    const location = useLocation();

    const [modulesJson, setModulesJson] = useState({});
    const availableModules = ["RDAMAIN", "RADMMAIN", "MDMMAIN", "SALMNGMAIN", "RSUPERADM", "VDREPOMAIN", "RDREPOMAIN", "RDVMAIN", "VDVMAIN", "RDPMAIN", "CSALMNGMAIN", "PDRMAIN", "PCVMAIN", "CDREPOMAIN"] // Available modules in newer portal

    let LOGO = {
        "VENDOR": IconsPath.VendorSupplymintLogo,
        "CUSTOMER": IconsPath.CustomerSupplymintLogo,
        "ENT": IconsPath.NewSupplymintLogo
    }

    const [stripHeight, setStripHeight] = useState(getNumberOfStrips() || 0);

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    const moveToHome = () => {
        if (activeHome) return;
        // Function to move the route to home
        let dashboardModules = JSON.parse(userDetails?.["dashboardModules"] || "{}");
        if (dashboardModules?.length !== 0) {
            let pinnedModules = localStorage.getItem(parseJwt(sessionStorage.getItem('token')).jti), pageUrl = "";
            if (pinnedModules) {
                let id = Number(pinnedModules.split(",")[0]);
                let page_Url = dashboardModules.find(item => item.id === id).pageUrl;
                pageUrl = page_Url?.replace(/^#/, '');
            }
            else {
                let page_Url = dashboardModules[0].pageUrl;
                pageUrl = page_Url?.replace(/^#/, '');
            }
            history.push(pageUrl);
            return;
        }
        history.push("/");
    }

    console.log("ljasdbflasd", location.pathname)

    const isCurrentModuleSelected = (code) => {
        let path = location.pathname;
        return modulesJson?.[code]?.[`#${path}`];
    }

    useEffect(() => {
        let obj = {};

        modules?.filter(item => availableModules?.includes(item?.code))?.map(item => {
            const updateObject = (module, mainCode) => {
                module?.map(val => {
                    if(val?.isPage == 1){
                        if(!obj[mainCode]) obj[mainCode] = {};
                        obj[mainCode][val.pageUrl] = true;
                    }else{
                        updateObject(val.subModules, mainCode);
                    }
                })
            }
            updateObject(item.subModules, item?.code)
        })

        setModulesJson(obj);
    },[])

    const changeIcon = () => {
        if (document.getElementsByClassName('sub-menu').length > 0) {
            var h1 = document.querySelectorAll('.sub-menu');
            for (var i = 0; i < h1.length; i++) {
                var bounding = h1[i].getBoundingClientRect()
                if (bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)) {
                }
                else {
                    if (h1[i].className.includes("last-sub-menu-bottom-up")) {

                    }
                    else {
                        h1[i].className += " last-sub-menu-bottom-up";
                    }
                    var bounding2 = h1[i]?.getBoundingClientRect();
                    if (bounding2?.top < 45) {
                        // MAKE YOUR CHANGES HERE
                        h1[i].className += " top-new-pos";
                    }
                }
            }
        } else {
            setTimeout(changeIcon, 5000);
        }
    }
    const activeMenu = (e) => {
        $("li.h2-main-item.active")?.removeClass("active");
    }
    // Function -> To get the current icon
    const getIcon = (url) => {
        switch (url) {
            case "digi-ars-icon.svg": return < IconsPath.DigiArsMenuIcon  />
            case "digi-vendor-icon.svg": return < IconsPath.DigiVendorMenuIcon />
            case "vendor-catalogue-icon.svg" :
            case "ent-catalogue-icon.svg": return < IconsPath.EntCatalogueMenuIcon />
            case "sales-management.svg": return < IconsPath.SalesManagementMenuIcon />
            case "master-data-icon.svg": return < IconsPath.MasterDataMenuIcon />
            case "admin-icon-sidebar.svg": return < IconsPath.AdminIconMenuIcon />
            // case "vendor-catalogue-icon.svg": return < IconsPath.VendorCatalogueMenuIcon />
            case "invoice-management.svg": return < IconsPath.InvoiceManagementMenuIcon />
            case "digi-proc-icon.svg": return < IconsPath.DigiProcMenuIcon />
            case "reports-analytics.svg": return < IconsPath.ReportsAnalyticsMenuIcon />
            case "superadmin-icon-sidebar.svg": return < IconsPath.SuperAdminMenuIcon />
            default: return <IconsPath.DigiArsMenuIcon />
        }
    }

    const getIconFilled = (url) => {
        switch(url){
            case "digi-ars-icon.svg": return < IconsPath.DigiArsMenuIconFilled  />
            case "digi-vendor-icon.svg": return < IconsPath.DigiVendorMenuIconFilled />
            case "vendor-catalogue-icon.svg" :
            case "ent-catalogue-icon.svg": return < IconsPath.EntCatalogueMenuIconFilled />
            case "sales-management.svg": return < IconsPath.SalesManagementMenuIconFilled />
            case "master-data-icon.svg": return < IconsPath.MasterDataMenuIconFilled />
            case "admin-icon-sidebar.svg": return < IconsPath.AdminIconMenuIconFilled />
            // case "vendor-catalogue-icon.svg": return < IconsPath.VendorCatalogueMenuIconFilled />
            case "invoice-management.svg": return < IconsPath.InvoiceManagementMenuIconFilled />
            case "digi-proc-icon.svg": return < IconsPath.DigiProcMenuIconFilled />
            case "reports-analytics.svg": return < IconsPath.ReportsAnalyticsMenuIconFilled />
            case "superadmin-icon-sidebar.svg": return < IconsPath.SuperAdminMenuIconFilled />
            default: return <IconsPath.DigiArsMenuIconFilled />
        }
    }

    // Function to render the menu list
    const renderMenu = (module) => {
        return <React.Fragment>
            <ul className="sub-menu" id="subMenu">
                {!module.parentCode && <li className="sm-head">
                    <span data-key="order_number" className="sm-head-menu"><LanguageTranslator>{module.name}</LanguageTranslator></span>
                </li>}
                {module?.subModules?.[0]?.category ?
                    renderMenuByCategory(module?.subModules) :
                    module?.subModules?.map((subModule, index) => {
                        if (subModule?.pageUrl === null && subModule?.subModules === null) return null
                        else {
                            return (
                                <li className="sm-inner-item" key={index} onClick={subModule?.pageUrl && openPage(subModule)}>
                                    <div className="mdd-content">
                                        <LanguageTranslator tag="p">{subModule?.name || ""}</LanguageTranslator>
                                    </div>
                                    {subModule?.isPage === 0 &&
                                        (<svg className="drop-menu-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.485" height="10.97" viewBox="0 0 6.485 10.97">
                                            <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_206" d="M6 9l4.071 4.071L14.142 9" data-name="Path 206" transform="rotate(-90 3.985 11.571)" />
                                        </svg>)}
                                    {subModule?.isPage === 0 && renderMenu(subModule)}
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </React.Fragment>
    }
    // Function to render the submenu
    const renderMenuByCategory = subModules => {
        const CATEGORY_MAP = {};
        subModules?.forEach(item => {
            if (!CATEGORY_MAP[item.category]) {
                CATEGORY_MAP[item.category] = [];
            }
            CATEGORY_MAP[item.category].push(item);
        });
        return (
            <div className="smv2">
                {Object.keys(CATEGORY_MAP || {})?.map(key =>
                    <ul className="sm-cat-vice" key={key}>
                        <li className="sm-inner-heading">{key}</li>
                        {CATEGORY_MAP?.[key]?.map((item, index) =>
                            <li key={index} className="sm-inner-item" onClick={item?.pageUrl ? openPage(item) : null}>
                                {item?.name}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        );
    }
    // Function to move to the another url

    const openPage = module => e => {
        const cleanPageUrl = module?.pageUrl?.replace(/^#/, ''); // Remove '#' from the beginning

        history.push(cleanPageUrl);
    };

    const shiftIcons = (e) => {
        changeIcon();
        if (e.clientX <= 65) {
            let iconsCount = modules?.filter(item => availableModules?.includes(item?.code)).length + 1;
            setTopValue((((iconsCount * 70) - document.getElementById("mainUl2").clientHeight) * (e.clientY - 75)) / (document.getElementById("sidebarDiv").clientHeight - 75))
        }
    }

    const { bootConfig } = useContext(ReloadContext)
    // console.log("Sidebar logo",bootConfig);/

    // render the supplyming logo
    const renderSupplymintLogo = () => {
        let logo = <img className={parseJwt(sessionStorage.getItem('token'))?.uType == "ENT"?.toUpperCase() ? "" : "snl-img"} onClick={moveToHome}
            src={LOGO?.[parseJwt(sessionStorage.getItem('token'))?.uType?.toUpperCase()] || IconsPath.NewSupplymintLogo} alt='Supplymint Auth Logo Image.'
        />
        if (parseJwt(sessionStorage.getItem("token"))?.uType != "ENT") {
            return logo
        } else {
            if (bootConfig?.appLogo) {
                return <img onClick={moveToHome}
                    src={bootConfig?.["appLogo"]} alt='Supplymint Auth Logo Image.'
                />
            }
            return logo
        }
    }

    console.log("Coiuasbdlfpasd",location.pathname );

    return (
        <React.Fragment>
            {sideBackdrop && <div className="backdrop zi99"></div>}
            <div id="sidebarDiv" className={`sidebar-new top-${stripHeight}`}>
                <div className="sn-logo">
                    {/* The Home Supplymint Logo according to user. */}
                    {renderSupplymintLogo()}
                </div>
                <ul id="mainUl2" onMouseMove={(e) => shiftIcons(e)} onMouseLeave={() => { setTopValue(0) }} style={{ top: `-${topValue}px` }}>
                    {/* Render the Home Icon */}
                    <li className={`h2-main-item ${activeHome && 'active'}`} id="home-page" >
                        <a className="h2mi-home" id="homeUrl">
                            <span className="h2mi-icon-area" onClick={moveToHome}>
                                {location.pathname?.includes('/home') ? <svg className='hmim-svg' width="22" height="22" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className='svg-stroke-none' d="M6.12432 8.61411C6.12432 8.09864 5.70652 7.68105 5.19126 7.68105C4.67581 7.68105 4.2582 8.09866 4.2582 8.61411V19.0548C4.2582 20.7233 5.61073 22.076 7.2793 22.076H17.7204C19.3889 22.076 20.7415 20.7233 20.7415 19.0548V8.61411C20.7415 8.09864 20.3237 7.68105 19.8084 7.68105C19.2932 7.68105 18.8754 8.09864 18.8754 8.61411V19.0548C18.8754 19.6928 18.3584 20.2099 17.7204 20.2099H7.2793C6.64152 20.2099 6.12432 19.6928 6.12432 19.0548V8.61411Z" fill="#5D5FEF" stroke="#5D5FEF" stroke-width="0.3"/>
                                            <path className='svg-stroke-none' d="M5.14551 20.905V8.87568L12.5084 3.92603L19.8544 8.87568L19.6069 20.905H5.14551Z" fill="#5D5FEF"/>
                                            <path  className='svg-stroke-none svg-fill-white' fill-rule="evenodd" clip-rule="evenodd" d="M10.6279 13.356C10.6279 12.3226 11.4656 11.4849 12.499 11.4849C13.5324 11.4849 14.3701 12.3226 14.3701 13.356C14.3701 14.3893 13.5324 15.2271 12.499 15.2271C11.4656 15.2271 10.6279 14.3893 10.6279 13.356Z" fill="#F2F1FE"/>
                                            <path className='svg-stroke-none' d="M13.1626 4.99564L13.1626 4.99564L22.4061 11.466C22.8283 11.7615 23.41 11.6588 23.7055 11.2367C24.001 10.8145 23.8983 10.2328 23.4762 9.93723L23.3902 10.0601L23.4762 9.93723L14.2329 3.46692L14.2329 3.46691C13.1925 2.73877 11.8079 2.73876 10.7677 3.46691L10.8537 3.5898L10.7677 3.46692L1.52443 9.93723L1.52442 9.93723C1.10227 10.2328 0.999572 10.8145 1.29512 11.2367C1.59065 11.6588 2.17236 11.7615 2.59453 11.466L11.8378 4.99564L11.8378 4.99564C12.2355 4.71718 12.7649 4.71718 13.1626 4.99564Z" fill="#5D5FEF" stroke="#5D5FEF" stroke-width="0.3"/>
                                            </svg> : <svg className='hmim-svg homeIconSvg' width="22" height="22" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.65839 6.64433C5.65839 6.13631 5.24663 5.72476 4.73882 5.72476C4.23081 5.72476 3.81925 6.13633 3.81925 6.64433V17.6311C3.81925 19.3526 5.21464 20.7482 6.93608 20.7482H17.9234C19.6448 20.7482 21.0402 19.3526 21.0402 17.6311V6.64433C21.0402 6.13631 20.6284 5.72476 20.1206 5.72476C19.6128 5.72476 19.2011 6.13631 19.2011 6.64433V17.6311C19.2011 18.3369 18.6291 18.909 17.9234 18.909H6.93608C6.23053 18.909 5.65839 18.3369 5.65839 17.6311V6.64433Z" fill="#707494" stroke="#707494" stroke-width="0.191097"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4604 11.633C10.4604 10.5456 11.342 9.66406 12.4294 9.66406C13.5169 9.66406 14.3984 10.5456 14.3984 11.633C14.3984 12.7205 13.5169 13.602 12.4294 13.602C11.342 13.602 10.4604 12.7205 10.4604 11.633Z" fill="#707494"></path><path d="M13.1626 2.78308L13.1627 2.78309L22.8896 9.59186C23.3057 9.88313 23.879 9.78193 24.1702 9.36587C24.4615 8.9498 24.3603 8.3765 23.9443 8.08524L14.2175 1.27647L14.2175 1.27646C13.1441 0.525242 11.7157 0.525236 10.6424 1.27647L10.6968 1.35419L10.6424 1.27647L0.915657 8.08524L0.97045 8.16352L0.915654 8.08524C0.499597 8.3765 0.398393 8.9498 0.689664 9.36587C0.980925 9.78193 1.55422 9.88313 1.97029 9.59186L11.697 2.78309L11.6422 2.70481L11.697 2.78308C12.137 2.47504 12.7227 2.47504 13.1626 2.78308Z" fill="#707494" stroke="#707494" stroke-width="0.191097"></path>
                                </svg>}
                            </span>
                        </a>
                    </li>
                    {/* Render the complete menu list */}
                    {modules?.filter(item => availableModules?.includes(item?.code))?.map((_, key) => {
                        return (
                            <li onMouseEnter={() => setSideBackdrop(true)} onMouseLeave={() => setSideBackdrop(false)} className={`h2-main-item ${isCurrentModuleSelected(_.code) ? 'active' : ''}`} id={`main${key}`} key={key}>
                                <span className={`hmi-img ${_?.name === 'DigiVend' ? 'digiVendOption' : ''}`}>
                                    {isCurrentModuleSelected(_.code) ? getIconFilled(_.iconUrl) : getIcon(_.iconUrl)}
                                    {renderMenu(_)}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </React.Fragment>
    )
}
export default Sidebar