import React from 'react';
import GenericSpinnerLoader from '../loaders/GenericSpinnerLoader';
import { Tooltip } from 'antd';
import LanguageTranslator from '../locals/LanguageTranslator';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { sendButtonClickEventToGoogleAnalytics } from '../helper/helperTableFunctions';
import Icons from '../assets/icons';

const GenericCustomButton = ({
  label,
  loading,
  disabled,
  onClick,
  className,
  leftIcon,
  rightIcon,
  tooltip = false,
  tooltipClassName,
  tooltipTextClassName,
  type = "normal",
  style = {},
  sendEventAnalyticsToGoogle = false,
  eventAnlyticsLabel = null,
}) => {
  const location = useLocation();

  let isButtonDisabled = typeof (disabled) == "function" ? disabled() : disabled

  const handleClick = (e) => {
    if (!(isButtonDisabled) && !(typeof (loading) == "function" ? loading() : loading)) {
      // Execute the onClick function only if the button is not disabled or in a loading state
      console.log("Check label", label);

      if(sendEventAnalyticsToGoogle && (label || eventAnlyticsLabel)){
          sendButtonClickEventToGoogleAnalytics({
            location : location,
            label : eventAnlyticsLabel ? eventAnlyticsLabel :  typeof (label) == "function" ? label() : label,
          })
      }
      
      onClick && onClick(e);
    }
  };
  const buttonType = {
    "primary": "generic-approve-btn",
    "success": "generic-approve-btn",
    "normal": "",
    "cancel": "generic-reject-btn",
    "default": "generic-default-btn",
    "active": "btn-active",
    "error": "generic-reject-btn",
    "failed": "generic-reject-btn",
    "reject": "generic-reject-btn",
    "retry": "p-[8px] rounded-full border border-borderSlateGray"
  }

  let button = <button
    className={`${buttonType?.[type]} ${className}`}
    onClick={handleClick}
    disabled={isButtonDisabled}
    type='button'
    data-interactive="true"
    style={{position: "relative", ...style}}
  >
    {loading ? (
      <GenericSpinnerLoader />
    ) : (
      <>
        {type === 'retry' && <Icons.ReloadIcon />}
        {typeof (leftIcon) == "function" ? leftIcon() : leftIcon}
        <LanguageTranslator>{typeof (label) == "function" ? label() : label}</LanguageTranslator>
        {typeof (rightIcon) == "function" ? rightIcon() : rightIcon}
        {/* {tooltip && <span onClick={(e) => e.preventDefault()} className="generic-tooltip">{tooltip}</span>} */}
      </>
    )}
  </button>

  return (
    <>
      {tooltip ? <Tooltip overlayClassName={`button-tooltip !p-0 ${tooltipClassName}`}
        title={<LanguageTranslator tag="p" className={`text-center ${tooltipTextClassName}`}>{tooltip}</LanguageTranslator>}
        color='black'
      >
        {button}
      </Tooltip> : button}
    </>
  );
};

export default GenericCustomButton;
