import React, { useEffect, useRef, useState } from 'react'
import { Radio } from 'antd'
import Icons from '../../../../assets/icons';
import ParentModal from '../../../../genericComponents/ParentModal';
import GenericMediaViewModal from '../../../../genericComponents/media/GenericMediaViewModal';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../styles/stylesheets/GenericComponents.scss'
import GenericCustomButton from '../../../../genericComponents/GenericCustomButton';
import LanguageTranslator from '../../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../../helper/permissions';

const ImageUpload = (props) => {

    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const uploadUtilityDocumentData = useSelector(state => state?.digivendStore?.uploadUtilityDocumentData);

    const { mediaData = {}, setMediaData } = props || {};

    const [selectedModal, setSelectedModal] = useState("");
    const [selectedData, setSelectedData] = useState({});

    const [showUploadError, setShowUploadError] = useState(false);

    const {
        module = "product",
        viewOnly = true,
        formSettings,
        mandatoryKey,
        displayKey,
        isEditableKey,
    } = props || {};

    const [currentType, setCurrentType] = useState("");

    const [currentMedia, setCurrentMedia] = useState("");

    const [options, setOptions] = useState({});

    const [updatedKey, setUpdatedKey] = useState("");

    let mediaOptions = {
        "product": {
            "allImages": {
                "imageUrlFront": {
                    type: "image",
                    label: "Front"
                },
                "imageUrlBack": {
                    type: "image",
                    label: "Back"
                },
                "imageUrlSide": {
                    type: "image",
                    label: "Side"
                },
                "imageUrlDetailed": {
                    type: "image",
                    label: "Detail"
                },
                "imageUrlLook": {
                    type: "image",
                    label: "Look"
                },
                "videoUrl": {
                    type: "video",
                    label: "Video"
                }
            },
            "rawImages": {
                "rawImage1": {
                    type: "image",
                    label: "Raw Image 1",
                },
                "rawImage2": {
                    type: "image",
                    label: "Raw Image 2"
                }
            },
            "document": {
                "teckPackUrl": {
                    type: "pdf",
                    label: "Tec Pack"
                },
                "pdfUrl": {
                    type: "pdf",
                    label: "PDF"
                }
            }
        },
        "fabric": {
            "fabric": {
                "fabricImage": {
                    type: "image",
                    label: "Fabric",
                }
            }
        }
    }


    useEffect(() => {
        if (uploadUtilityDocumentData?.isSuccess) {
            let updatedMediaData = { ...mediaData };
            if (uploadUtilityDocumentData?.data?.resource?.[0]) {
                updatedMediaData[updatedKey] = { ...uploadUtilityDocumentData?.data?.resource?.[0], "requestId": uploadUtilityDocumentData?.data?.requestId || "" } || {};
                let updatedErrorData = { ...props.mainErrorData };
                if (updatedErrorData?.[updatedKey]) {
                    delete updatedErrorData?.[updatedKey];
                }
                props.updateErrorData(updatedErrorData)
                setMediaData(updatedMediaData);

                if(updatedKey == "imageUrlFront" && props.customAction?.["callApiAfterImageUrlFrontUpload"]){
                    props.customAction?.["callApiAfterImageUrlFrontUpload"]?.(uploadUtilityDocumentData?.data?.resource?.[0]);
                }
            }
        };

        setUpdatedKey("");
    }, [uploadUtilityDocumentData?.isSuccess, uploadUtilityDocumentData?.isError])

    useEffect(() => {
        let updatedOptions = {};
        let obj = {
            "allImages": "All Images",
            "rawImages": "Raw Images",
            "document": "Document",
            "fabric": "Fabric",
        }

        Object.keys(mediaOptions?.[module] || {})?.map(mainKey => {
            if (Object.keys(mediaOptions?.[module]?.[mainKey])?.some(key => formSettings?.[key]?.[displayKey])) {
                updatedOptions[mainKey] = obj[mainKey]
            }
        })
        setOptions(updatedOptions)
        setCurrentType(Object?.keys(updatedOptions || {})?.[0] || "");
    }, [formSettings])

    useEffect(() => {
        setCurrentMedia(Object.keys(getUpdatedMediaOptions() || {})?.[0] || "");
    }, [currentType])

    const handleChange = (key, data) => {
        switch (key) {
            case "viewImage": {
                setSelectedModal("viewImage");
                setSelectedData(data);
                break;
            }
            case "removeFile": {
                let updatedMedia = { ...mediaData };
                delete updatedMedia?.[data];
                setMediaData(updatedMedia);
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "viewImage": return GenericMediaViewModal;
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal(false); },
            animationType: "bottom",
            // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: "fixed", borderRadius: 4 },
            parentModalOuterMostClassName: 'flex items-center justify-center',
			postitionProps: { position: 'relative' },
            dimensions: { width: "70%", height: "50%", maxHeight: '430px', maxWidth: '1000px' },
        }
        
        switch (selectedModal) { 
            case "viewImage": {

                console.log("Media data", mediaData);

                return {
                    ...payload,
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: "fixed", margin: 0, borderRadius: 3 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "70vw", height: "90vh", maxHeight: '740px', maxWidth: '1000px' },
                    filesData: Object.keys(mediaData || {})?.map(key => (mediaData?.[key]?.["url"] && mediaOptions?.[module]?.[currentType]?.[key] ? {
                        fileUrl: mediaData?.[key]?.["url"] || "",
                        fileType: mediaOptions?.[module]?.[currentType]?.[key]?.["type"] || "",
                        fileName: mediaData?.[key]?.["fileName"] || ""
                    } : null))?.filter(item => Boolean(item)) ,
                    current : Object.keys(mediaData)?.filter(key => mediaOptions?.[module]?.[currentType]?.[key])?.findIndex(item  => item == currentMedia)
                }
            }
            default :return null;
        }
    }


    // Function  : To render the currentMedia
    const renderMedia = (type, key) => {
        let fileType = mediaOptions?.[module]?.[currentType]?.[key]?.["type"]
        const renderPlaceholder = () => {
            switch (fileType) {
                case "video": {
                    return <>
                        {mediaData?.[key] ? <video controls={type == "main"} width="100%" className="videoPlayer" preload="metadata">
                            <source src={mediaData?.[key]?.["url"]} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> :
                            type == "main" ? <div className='guii-no-img'>
                                <Icons.CatalogueImageThumbnail />
                                <p>No Video Uploaded</p>
                            </div> :
                                <div className='guii-normal-no-img'>
                                    <Icons.PlusIcon />
                                </div>
                        }
                    </>
                }
                case "pdf": {
                    return mediaData?.[key] ?
                        <div className='guii-no-img'>
                            {type == "main" && <Icons.DraftFileIcon />}
                            <p>{type == "main" ? "PDF Uploaded" : "PDF"}</p>
                        </div> :
                        type == "main" ? <div className='guii-no-img'>
                            <Icons.CatalogueImageThumbnail />
                            <LanguageTranslator tag="p">No PDF Uploaded</LanguageTranslator>
                        </div> :
                            <div className='guii-normal-no-img'>
                                <Icons.PlusIcon />
                            </div>
                }
                default: {
                    return mediaData?.[key] ? <img className='object-cover object-center' src={mediaData?.[key]?.["url"]} /> :
                        type == "main" ? <div className='guii-no-img'>
                            <Icons.CatalogueImageThumbnail />
                            <LanguageTranslator tag="p">No Image Uploaded</LanguageTranslator>
                        </div> :
                            <div className='guii-normal-no-img'>
                                <Icons.PlusIcon />
                            </div>
                }
            }
        }

        let acceptFiles = {
            "video": "video/*",
            "image": "image/*",
            "pdf": "application/pdf"
        }

        return <>
            {type != "main" &&
                <input
                    className={mediaData?.[key] && "!hidden"}
                    type="file"
                    autoComplete="off"
                    disabled={viewOnly || mediaData?.[key] || imageConfigurationCases("disabled", key)}
                    style={{ position: "absolute", top: 0 }}
                    ref={inputRef} // Assign inputRef to the ref attribute
                    accept={acceptFiles?.[fileType]}
                    onChange={(e) => {
                        setUpdatedKey(key);

                        if (e?.target?.files?.[0]) {
                            let file = e?.target?.files?.[0]
                            console.log("File Changes", file?.size);
                            if (file?.size <= 100 * 1024 * 1024) {
                                const formData = new FormData();
                                formData?.append("file", e?.target?.files?.[0]);
                                formData?.append("type", "CATALOGUE_PRODUCT");
                                formData?.append("documentId", 0);
                                dispatchHandler(dispatch, "uploadUtilityDocumentRequest", formData, getPermissionKey("common", "commonFunctionality", "upload"));
                            } else {
                                setShowUploadError(true);
                                setTimeout(() => {
                                    setShowUploadError(false);
                                }, 5000)
                            }
                        }

                        if (inputRef?.current)
                            inputRef.current.value = ""; // Now inputRef is accessible
                    }}
                />}
            <div className='guii-img h-full w-full flex items-center justify-center'>
                {renderPlaceholder()}
            </div>
        </>
    }

    const imageConfigurationCases = (key, mainKey) => {
        // console.log("ASdfsd",formSettings?.[mainKey]);
        if (formSettings?.[mainKey]) {
            switch (key) {
                case "mandatory": {
                    return formSettings?.[mainKey]?.[mandatoryKey] == 1;
                }
                case "display": {
                    return formSettings?.[mainKey]?.[displayKey] == 1;
                }
                case "disabled": {
                    return !formSettings?.[mainKey]?.[isEditableKey];
                }
                case "displayName": {
                    return formSettings?.[mainKey]?.["displayName"] || ""
                }
            }
        }
        return null;
    }
    const getUpdatedMediaOptions = () => {
        let mediaImageOption = {};
        Object.keys(mediaOptions?.[module]?.[currentType] || {})?.map(key => {
            console.log("asdfasdf", key, imageConfigurationCases("display", key))
            if (imageConfigurationCases("display", key)) {
                mediaImageOption[key] = mediaOptions?.[module]?.[currentType]?.[key];
            }
        })
        return mediaImageOption
    }

    console.log("Media data",getUpdatedMediaOptions(), currentType );



    const renderImageUploadError = () => {
        return Object.keys(mediaOptions?.[module] || {})?.some(mainKey => Object.keys(props.errorData?.[mainKey] || {})?.length > 0)  && <div className='gui-error'>
            {Object.keys(mediaOptions?.[module] || {})?.map(mainKey => Object.keys(props.errorData?.[mainKey] || {})?.length > 0 &&
                <div className='guie-sec'>
                    <h1>{options?.[mainKey] || ""} : </h1>
                    {Object.keys(props.errorData?.[mainKey] || {})?.map(key => {
                        console.log("asdfasf", key, formSettings);
                        return <li>{props.errorData?.[mainKey]?.[key] || ""}</li>
                    })}
                </div>)
            }
        </div>
    }

    return (
        Object.keys(options)?.length > 0 && <div className='generic-upload-img viewproductDetails'>
            {/* Render the images section here */}

            {/* Render the errors here for image upload section */}
                {renderImageUploadError()}

            <LanguageTranslator tag="h4">Product Images & Documents</LanguageTranslator>

            <Radio.Group onChange={(e) => setCurrentType(e?.target?.value || "")} value={currentType}>
                {
                    Object.keys(options || {})?.map(key => (
                        <Radio.Button value={key}><LanguageTranslator>{options?.[key]}</LanguageTranslator></Radio.Button>
                    ))
                }
            </Radio.Group>

            {/* Render the image section here */}
            <div className='gu-img-sec'>
                <div className='gui-img' onClick={() => mediaData?.[currentMedia] && handleChange("viewImage",)}>
                    {mediaData?.[currentMedia] && <div className='guii-btns'>
                        <GenericCustomButton
                            className='preview-img'
                            label="Preview"
                        />
                        <GenericCustomButton
                            className='expand-img'
                            leftIcon={<Icons.ImgExpandIcon />}
                        />
                    </div>}
                    {renderMedia("main", currentMedia)}
                </div>
                <div className='gui-sub-img'>
                    {Object.keys(getUpdatedMediaOptions() || {})?.map(key => (
                        <div className={`guis`}>
                            {!viewOnly && !imageConfigurationCases("disabled", key) && mediaData?.[key] && <LanguageTranslator tag="p" className='remove-p' onClick={() => {handleChange("removeFile", key) }}>Remove</LanguageTranslator>}
                            {(viewOnly || imageConfigurationCases("disabled", key) || !mediaData?.[key]) && <LanguageTranslator tag="p"><LanguageTranslator>{imageConfigurationCases("displayName", key) || ""}</LanguageTranslator> <span>{imageConfigurationCases("mandatory", key) && "*"}</span></LanguageTranslator>}
                            <button className={imageConfigurationCases("disabled", key) && !viewOnly && "guis-btn-disable"} onClick={() => setCurrentMedia(key)}>
                                {renderMedia("normal", key)}
                            </button>
                        </div>
                    ))}
                </div>
                {/* Render the error to show the size limit has been exceeded */}
                {
                    showUploadError && <LanguageTranslator tag="p" style={{ color: "red" }}>File Size should be less than 100MB.</LanguageTranslator>
                }
            </div>


            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false); }}
            />}

        </div>
    )
}

export default ImageUpload