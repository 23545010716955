const displayNamesForNewSubmitApi = {
    "SOMAIN_INDENT" : true,
    "SOMAIN_ORDER" : true,
    "SOMAIN_PROCESSED" : true,
    "SOMAIN_REJECTED" : true,
    
    // Orders Section : 
    "ENT_PENDING_ALL" : true,
    "ENT_CONFIRMED_VENDOR" : true,
    "ENT_REJECTED_VENDOR" : true,
    "ENT_PROCESSED_ALL" : true,
    // "ENT_CANCELLED_ALL" : true,

    // Inspection : 
    // "ENT_PENDING_QC" : true,
    // "ENT_PENDING_QC_CONFIRM" : true,
    // "ENT_INSPECTION_CANCELLED_ALL" : true,
    // "ENT_PENDING_INSPECTION_WAREHOUSE" : true,
    // "ENT_ASN_APPROVAL_ALL" : true,

    // Delivery Challan
    "DELIVERY_CHALLAN_APPROVED" : true,
    "DELIVERY_CHALLAN_PENDING" : true,
    "DELIVERY_CHALLAN_REJECTED" : true,

    // Items
    "MANAGE_ITEMS_ALL" : true,    

    // Vendors
    // "MANAGE_VENDOR_ALL" : true,
    // "MANAGE_VENDOR_INACTIVE" : true,
    // "VENODR_PENDING_SIGN_UP" : true,
    // "EMAIL_NOT_VERIFIED_VENDOR" : true,
    // "VENDOR_REQUESTED" : true,

    
    // Purchase Invoice 
    "ENT_PURCHASE_INVOICE_BOOKED": true,
    "ENT_PURCHASE_INVOICE_PENDING_GRC": true,
    "VENDOR_PURCHASE_INVOICE_BOOKED": true,
    "VENDOR_PURCHASE_INVOICE_PENDING_GRC": true,

    // Replenishment History
    "REPLENISHMENT_HISTORY" : true,
    
    // Master Data 
    "AGENT_MASTER_ALL": true,
    "AGENT_MASTER_INACTIVE": true,
    "MATERIAL_LIBRARY_MASTER": true,
    "MANAGE_TRANSPORTER_ALL": true,
    // "FABRIC_MASTER": true,
    "MANAGE_CUSTOMERS_ACTIVE": true,
    "MANAGE_CUSTOMER_INACTIVE": true,
    "MANAGE_CUSTOMERS_PENDING": true,
    "MANAGE_SALES_CUSTOMER": true,

    // Custom Data Upload 
    "VEND_CUSTOM_DATA": true,

    // For Manage Descrepancies
    "ENT_DEBIT_NOTE_DATA" : true,
    "ENT_DISCREPANCY_DETAILS" : true,
    "VEND_DEBIT_NOTE_DATA" : true,
    "VEND_DISCREPANCY_DETAILS" : true,
    
    // Open PO Vendor Side
    "VENDOR_PENDING_ALL" : true,
    "VENDOR_PENDING_APPROVAL_ALL" : true,
    "VENDOR_REJECTED_ALL" : true,

    // LR Processing Retailer and Vendor
    ENT_LR_PROCESSING_ALL: true,
    ENT_LR_PROCESSING_PEN_APPROVAL: true,
    ENT_LR_PROCESSING_APPT_PEN: true,
    // ENT_INTRANSIT_PENDING_TRANSPORTER: true,
    VENDOR_LR_PROCESSING_ALL: true,
    VENDOR_LR_PROCESSING_PEN_APPROVAL: true,
    VENDOR_LR_PROCESSING_APPT_PEN: true,
    // VENDOR_INTRANSIT_PENDING_TRANSPORTER: true,
    
    // Goods In Transit
    ENT_LR_INTRANSIT_ALL: true,
    ENT_LR_INTRANSIT_CANCELLED: true,
    ENT_INTRANSIT_APPT_PENDING: true,
    // Vendor Side
    VENDOR_LR_INTRANSIT_ALL: true,
    VENDOR_INTRANSIT_APPT_PENDING: true
}
export {displayNamesForNewSubmitApi}