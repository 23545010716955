import React, { useEffect, useState } from 'react'
import { dispatchHandler } from '../../../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import SummarySection from './SummarySection';
import StatusWiseStatistics from './StatusWiseStatistics';
import { getPermissionKey } from '../../../../helper/permissions';

function ProcDashTopSection(props) {
    const { filters = {}, setFilters, inputData } = props;
    const dispatch = useDispatch();
    const digiProcStore = useSelector(state => state.digiProcStore);
    const { getPiSummaryData, getPiCountPerData, getPoCountPerData } = digiProcStore;
    const [dashboardSummaryData, setDashboardSummaryData] = useState({})
    const [countPerData, setCountPerData] = useState({})


    useEffect(() => {
        let payload = {
            displayName: "PI_PO_QUANTITY_AMOUNT_DATA",
            filter: filters
        }
        dispatchHandler(dispatch, "getPiSummaryRequest", payload, getPermissionKey("dashboard", "procurement", "view"));
        dispatchHandler(dispatch, "getPoCountPerRequest", { ...payload, displayName: "PO_COUNT_DATA" }, getPermissionKey("dashboard", "procurement", "view"));
        dispatchHandler(dispatch, "getPiCountPerRequest", { ...payload, displayName: "PI_COUNT_DATA" }, getPermissionKey("dashboard", "procurement", "view"));
    }, [filters])

    useEffect(() => {
        if (getPiSummaryData?.isSuccess) {
            let sumObj = getPiSummaryData?.data || {};
            setDashboardSummaryData(sumObj);
        }
    }, [getPiSummaryData?.isSuccess]);

    useEffect(() => {
        if (getPiCountPerData?.isSuccess || getPoCountPerData?.isSuccess) {
            let piCountPerData = getPiCountPerData?.data || {}, poCountPerData = getPoCountPerData?.data || {};
            setCountPerData(prevState => ({
                ...prevState,
                PI: piCountPerData,
                PO: poCountPerData,
            }));
        }
    }, [getPiCountPerData?.isSuccess, getPoCountPerData?.isSuccess])

    const renderLoadingSkeleton = () => {
        return <div className='p-[12px] !w-full flex flex-col gap-4 bg-white'>
            <div className='generic-skeleton w-full !h-[130px]'></div>
            <div className='flex flex-col gap-2'>
                <div className='!w-full flex justify-between items-center gap-3'>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div className='generic-skeleton !w-[20px]'></div>
                </div>
                <div className='!w-full flex justify-between items-center gap-3'>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div className='generic-skeleton !w-[20px]'></div>
                </div>
                <div className='!w-full flex justify-between items-center gap-3'>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div className='generic-skeleton !w-[20px]'></div>
                </div>
                <div className='!w-full flex justify-between items-center gap-3'>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div>
                        <div className='generic-skeleton !h-[12px] !w-[50px] !mb-1'></div>
                        <div className='generic-skeleton !w-[80px]'></div>
                    </div>
                    <div className='generic-skeleton !w-[20px]'></div>
                </div>
            </div>
        </div>
    }

    return (
        <div className='grid grid-cols-1 h-full gap-5'>
            <div className='w-full grid grid-cols-2 gap-5'>
                {digiProcStore?.getPiSummaryData?.isLoading ? renderLoadingSkeleton() : <SummarySection summaryKey="PI" currentSumKey="pi" dashboardSummaryData={dashboardSummaryData} filters={filters} setFilters={setFilters} inputData={inputData} countPerData={countPerData} />}
                {digiProcStore?.getPoCountPerData?.isLoading ? renderLoadingSkeleton() : <SummarySection summaryKey="PO" currentSumKey="po" dashboardSummaryData={dashboardSummaryData} filters={filters} setFilters={setFilters} inputData={inputData} countPerData={countPerData} />}
            </div>
            {/* {digiProcStore?.getPiCountPerData?.isLoading ? <div>Loading</div> : <StatusWiseStatistics statusWiseStatisticsData={statusWiseStatisticsData} filters={filters} setFilters={setFilters} />} */}
        </div>
    )
}

export default ProcDashTopSection