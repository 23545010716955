import { CONFIG } from '../../config/index';
// import {AUTH_CONFIG } from '../../authConfig/index'
export const seasonPlanningStore = [
    {
        title: "getAssortmentPlanningData",
        actionPrefix: 'getAssortmentPlanning',
        message: 'Request Completed Successfully',
        requestData: {
            // We have to spread the payload here to deep copy the object.
            updatePayload: ({ ...payload }) => {
                delete payload.summaryApiUrl; 
                delete payload.apiUrl;
                if (payload?.key === "categorySizeMbq") delete payload?.["key"];
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`
        },
    },
    {
        title: "getSitePlanningData",
        actionPrefix: 'getSitePlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/site-planning-data`
        },
    },
    {
        title: "getEventMappingPlanningData",
        actionPrefix: 'getEventMappingPlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/event-planning`
        },
    },
    {
        title: "getAutoConfigData",
        actionPrefix: 'getAutoConfig',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.SM_BACKEND_BASE_URL}/ars/rule/engine/get/all/job/trigger`
        },
    },
    {
        title: "generateMbqSuggestionsData",
        actionPrefix: 'generateMbqSuggestions',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/assortment/last/sale/days`
        }
    },
    {
        title: "deleteAutoConfigTriggerData",
        actionPrefix: 'deleteAutoConfigTrigger',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/job/delete/trigger?id=${payload?.ids}`
        },
    },
    {
        title: "deleteSitePlanningData",
        actionPrefix: 'deleteSitePlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/delete/sitePlanning`
        },
    },
    {
        title: "getArsSiteFiltersData",
        actionPrefix: 'getArsSiteFilters',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/site-planning-filters`
        },
    },
    {
        title: "getSitePlanningSitesData",
        actionPrefix: 'getSitePlanningSites',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/mapped/site`
        },
    },
    {
        title: "getDefaultSitePlanningSitesData",
        actionPrefix: 'getDefaultSitePlanningSites',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/mapped/site`
        },
    },
    {
        title: "saveSitePlanningData",
        actionPrefix: 'saveSitePlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/save/site-planning`
        },
    },
    {
        title: "deleteEventPlanningData",
        actionPrefix: 'deleteEventPlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/delete/eventPlanning`
        },
    },
    {
        title: "createEventPlanningData",
        actionPrefix: 'createEventPlanning',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/save/event-planning`
        },
    },
    {
        title: "getAllWarehouseJobsData",
        actionPrefix: 'getAllWarehouseJobs',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/ars/jobs`
        },
    },
    {
        title: "getReplenishmentPlanningParameterData",
        actionPrefix: 'getReplenishmentPlanningParameter',
        message: 'Request Completed Successfully',
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/jobs/param/config/${payload.id}`
        },
    },
    {
        title: "getArsAndSiteFilterSiteData",
        actionPrefix: 'getArsAndSiteFilterSite',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/arsItemAndArsSiteFilter?filterType=${payload.id}`
        },
    },
    {
        title: "getArsAndSiteFilterCustomData",
        actionPrefix: 'getArsAndSiteFilterCustom',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/arsItemAndArsSiteFilter?filterType=${payload.id}`
        },
    },
    {
        title: "getArsAndSiteFilterItemData",
        actionPrefix: 'getArsAndSiteFilterItem',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/arsItemAndArsSiteFilter?filterType=${payload.id}`
        },
    },
    {
        title: "getArsFilterCountData",
        actionPrefix: 'getArsFilterCount',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/job/get/count`
        },
    },
    {
        title: "createRunOnDemandJobData",
        actionPrefix: "createRunOnDemandJob",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/rule/engine/job/run/ondemand/start`
        }
    },
    {
        title: "stopRunOnDemandNewData",
        actionPrefix: "stopRunOnDemandNew",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.REPLENISHMENT}/run/ondemand/stop`
        }
    },
    {
        title: "jobRunDetailNewData",
        actionPrefix: "jobRunDetailNew",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.REPLENISHMENT}/run/ondemand/status`
        }
    },
    {
        title: "currentJobRunDetailData",
        actionPrefix: "currentJobRunDetail",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.REPLENISHMENT}/run/ondemand/status`
        }
    },
    {
        title: "getMRPHistoryData",
        actionPrefix: "getMRPHistory",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/mrp/range`
        }
    },
    {
        title: "getMRPRangeData",
        actionPrefix: "getMRPRange",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/mrp/range/icode`
        }
    },
    {
        title: "getSiteAndItemFilterConfigurationData",
        actionPrefix: "getSiteAndItemFilterConfiguration",
        message: "Request Completed Successfully",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/arsItemAndArsSiteFilter?filterType=${payload}`
        }
    },
    {
        title: "getGenericTableDataData",
        actionPrefix: "getGenericTableData",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "updateMRPRangeData",
        actionPrefix: "updateMRPRange",
        message: "Request Completed Successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/update/mrp/range/icode`
        }
    },
    {
        title: "updatePlanningParameterConfigurationData",
        actionPrefix: "updatePlanningParameterConfiguration",
        message: "Configuration Updated Successfully!",
        errorMessge : "Failed to Update the Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/rule/engine/configure/ars/job`
        }
    },
    {
        title: "savePlanningParameterData",
        actionPrefix: "savePlanningParameter",
        message: "Planning Parameter Configuration Updated Successfully!",
        errorMessge : "Failed to Update the Planning Parameter Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/rule/engine/configure/ars/job/attributes`
        }
    },
    {
        title: "ruleEngineJobRunStatisticsData",
        actionPrefix: 'ruleEngineJobRunStatistics',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/job/run/statistics`
        },
    },
]