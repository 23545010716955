import React from 'react'
import PerformanceDashFilters from './PerformanceDashFilters'
import Icons from '../../../assets/icons'
import { useSelector } from 'react-redux'
import DivisionTabTableSkeleton from './skeletons/DivisionTabTableSkeleton'
import LanguageTranslator from '../../../locals/LanguageTranslator'

const PRIMARY_FILTERS = [
    {
        entity: "site",
        key: "group1",
        value: "Client Type"
    },
    {
        entity: "pan_asm_dashboard",
        key: "region",
        value: "Region"
    },
    {
        entity: "site",
        key: "udf4",
        value: "Sales ASM"
    },
    {
        entity: "site",
        key: "udf2",
        value: "Store Grade"
    },
    {
        entity: "generic_item_master_report",
        key: "hl1_name",
        value: "Product Category"
    }
]
const currentFilterKeys = ['group1', 'region', 'udf4', 'udf2', 'hl1_name']
const DEP_WISE_SALES_HEADERS_MAP = {
    dep: "Department",
    salesqty: "Sales Qty",
    salesvalue: "Sales Value",
    salesqtycontriper: "Sales Qty Contribution %",
    salesvaluecontriper: "Sales Value Contribution %",
};

const DivisionWiseSales = (props) => {
    const { dashboardData } = props
    const digivendStore = useSelector((state) => state.digivendStore)

    const getDashboardData = (filtersData = {}) => {
        props.getDashboardData(filtersData)
    }

    return (
        <div className='division-wise'>
            <PerformanceDashFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                applyFilter={getDashboardData}
                currentFilterKeys={currentFilterKeys}
            />

            {/* table data */}
            {digivendStore?.getARSDashboardDataData?.isLoading ? (<DivisionTabTableSkeleton />) : (
                <div role="tabpanel" className="tab-pane fade p-lr-12" id="divisionWiseSales">
                    <div className="store-analytics-table-head">
                        <div className="sath-left">
                            <h3>Division-wise Sales</h3>
                        </div>
                    </div>
                    <div className="vendor-gen-table vgtbg-fcfdfe ars-data-table">
                        <div className="manage-table">
                            <table className="table gen-main-table w-100">
                                <thead>
                                    <tr>
                                        <th className="fix-action-btn width40">
                                            <ul className="rab-refresh">
                                                <li className="rab-rinner" onClick={() => getDashboardData({})}>
                                                    <Icons.RefreshIcon />
                                                    <LanguageTranslator tag="span" className="generic-tooltip">Refresh</LanguageTranslator>
                                                </li>
                                            </ul>
                                        </th>
                                        <th><label>Section</label></th>
                                        {Object.values(DEP_WISE_SALES_HEADERS_MAP)?.map(value =>
                                            <th><label>{value}</label></th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData?.depWiseSales &&
                                        <React.Fragment>
                                            {Object.keys(dashboardData?.depWiseSales)?.map(key => key != "grandTotal" &&
                                                <React.Fragment key={key}>
                                                    {dashboardData?.depWiseSales?.[key]?.data?.map((item, index) =>
                                                        <tr key={key + item?.dep}>
                                                            {index == 0 &&
                                                                <td className="fix-action-btn width40" rowSpan={dashboardData?.depWiseSales?.[key]?.data?.length}>
                                                                    <ul className="table-item-list">
                                                                        <li className="til-inner"></li>
                                                                    </ul>
                                                                </td>}
                                                            {index == 0 &&
                                                                <td className="vgtvf-first-col" rowSpan={dashboardData?.depWiseSales?.[key]?.data?.length}>
                                                                    <label>{key}</label>
                                                                </td>}
                                                            {Object.keys(DEP_WISE_SALES_HEADERS_MAP)?.map(innerKey =>
                                                                <td key={key + item?.dep + innerKey} align={innerKey == "dep" ? "left" : "right"}>
                                                                    <label>{item?.[innerKey]}{innerKey?.endsWith("per") ? "%" : ""}</label>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}
                                                    <tr key={key + "total"} style={{ backgroundColor: "#ecedf7" }}>
                                                        <td className="fix-action-btn width40">
                                                            <ul className="table-item-list">
                                                                <li className="til-inner"></li>
                                                            </ul>
                                                        </td>
                                                        <td className="vgtvf-first-col">
                                                            <label className="bold">{key} <LanguageTranslator>Total</LanguageTranslator></label>
                                                        </td>
                                                        {Object.keys(DEP_WISE_SALES_HEADERS_MAP)?.map(innerKey =>
                                                            <td key={key + "total" + innerKey} align="right">
                                                                <label className="bold">{dashboardData?.depWiseSales?.[key]?.total?.[innerKey]}{innerKey?.endsWith("Per") ? "%" : ""}</label>
                                                            </td>
                                                        )}
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            <tr className="vgtvf-footer">
                                                <td className="fix-action-btn width40">
                                                    <ul className="table-item-list">
                                                        <li className="til-inner"></li>
                                                    </ul>
                                                </td>
                                                <td><LanguageTranslator tag="label" className="bold">GRAND TOTAL</LanguageTranslator></td>
                                                {dashboardData?.depWiseSales?.grandTotal && Object.keys(DEP_WISE_SALES_HEADERS_MAP)?.map(innerKey =>
                                                    <td key={innerKey} align="right">
                                                        <div className="vgtvf-col">
                                                            <label className="vgtvf-num">{dashboardData?.depWiseSales?.grandTotal?.[innerKey]}{innerKey?.endsWith("Per") ? "%" : ""}</label>
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        </React.Fragment>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

        </div>

    )
}

export default DivisionWiseSales