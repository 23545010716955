import React, { useState } from 'react'
import { formatNumber } from '../../../../helper/genericFunction';
import { CircleX, Clock1, IndianRupee, InfoIcon, SquareArrowOutUpRight, ThumbsDown, ThumbsUp, TriangleAlert, X } from 'lucide-react';
import GenericCustomButton from '../../../../genericComponents/GenericCustomButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Icons from '../../../../assets/icons';
import dayjs from 'dayjs';

function SummarySection(props) {

    const [showAdditionalDetails, setShowAdditionalDetails] = useState(false)

    const history = useHistory();

    const { dashboardSummaryData, summaryKey = "PI", filters = {}, inputData = {}, currentSumKey = "pi", countPerData = {} } = props;

    let changeInKey = {
        pending: "pi_pending"
    }
    const summaryDataHeadersKeys = {
        PI: {
            pending: "Pending",
            approved: "Approved",
            rejected: "Rejected",
            cancelled: "Cancelled"
        },
        PO: {
            pending: "Pending",
            approved: "Approved",
            rejected: "Rejected",
            cancelled: "Cancelled",
            expired: "Expired PO's"
        }
    }
    const handleChange = (key) => {
        const queryParams = new URLSearchParams("");
        let filterObj = {
            siteName: inputData?.site?.SiteName || undefined,
            slName: inputData?.vendor?.slName || undefined,
            siteCode: inputData?.site?.SiteCode || undefined,
            createdTime: inputData?.dateRange ? { from: dayjs(inputData?.dateRange?.[0]?.$d)?.format("YYYY-MM-DD"), to: dayjs(inputData?.dateRange?.[1]?.$d)?.format("YYYY-MM-DD") } : "",
            status: key?.toUpperCase()
        },
            itemFilterObj = {
                hl1Name: inputData?.division?.hl1Name || undefined,
                hl2Name: inputData?.section?.hl2Name || undefined,
                hl3Name: inputData?.department?.hl3Name || undefined,
                hl4Name: inputData?.article?.hl4Name || undefined,
            };
        Object.keys(filterObj || {})?.map((key) => {
            if (!filterObj?.[key]) {
                delete filterObj[key]
            }
        });
        Object.keys(itemFilterObj || {})?.map((key) => {
            if (!itemFilterObj?.[key]) {
                delete itemFilterObj[key]
            }
        })
        if (Object.keys(itemFilterObj || {})?.length) {
            filterObj.item = itemFilterObj
        }
        queryParams.set("filter", encodeURIComponent(JSON.stringify(filterObj)));
        history?.push({
            pathname: summaryKey == "PI" ? "/purchase/purchaseIndentHistory" : "/purchase/purchaseOrderHistory",
            search: queryParams.toString()
        });
    }

    // Find the key with the maximum value for keys ending in "_per"
    const getMaxKeyEndingWithPer = (obj) => {
        // Filter keys ending with "_per"
        const filteredKeys = Object.keys(obj || {}).filter((key) => key?.endsWith("_per"));
        // Find the key with the maximum value
        if (filteredKeys?.length) {
            const maxKey = filteredKeys.reduce((maxKey, currentKey) =>
                obj?.[currentKey] > obj?.[maxKey] ? currentKey : maxKey
            );
            let makeKey = `_${currentSumKey}_per`
            const removePerSuffix = maxKey.replace(new RegExp(`${makeKey}$`), '');
            return { maxKey: maxKey, maxValue: obj[`${currentSumKey}_${removePerSuffix}_count`] }; // Return both key and max value
        }
        else {
            return false; // Return false if no keys match
        }
    };


    const approvedPiData = {
        pi_approved: "Approved PI’s",
        pending_conversion_pi_approved: "Pending PI’s for conversion",
        converted_pi_approved: "Converted PI’s",
        merged_pi_approved: "Merged PI’s",
    }

    const getCurrentDashData = dashboardSummaryData;

    console.log(32323333233, getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {}), countPerData)
    const getDescription = () => {
        const dataShort = formatNumber(getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxValue ?? '')?.short
        const dataFull = getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxValue

        console.log('data:', dataShort, dataFull, getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {}))
        switch (getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxKey) {
            case 'pending_pi_per': case "pending_po_per": 
                return <div className='mb-4 flex items-start gap-2'>
                    <div className='p-[4px] rounded bg-[#EE5A24]'><Clock1 size={11} color='white' /></div>
                    <p className='mb-0 mt-[2px] text-xs font-normal'>You have <span className='font-bold'><span className='group relative'>{dataShort} <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{dataFull}</span></span> {summaryKey === 'PI' ? 'indents' : 'orders'}</span> awaiting approval. Please check and approve or reject as needed.
                    {" "}
                    <span
                        className={'cursor-pointer underline whitespace-nowrap text-xs font-semibold text-[#E66767] hover:opacity-75'}
                        onClick={() => handleChange('pending')}
                    >
                        Review Now
                    </span>
                    </p>
                </div>
                break;

            case 'approved_pi_per': case "approved_po_per":
                return <div className='mb-4 flex items-start gap-2'>
                    <div className='p-[4px] rounded bg-[#3EB655]'><ThumbsUp size={11} color='white' /></div>
                    <p className='mb-0 mt-[2px] text-xs font-normal'>With <span className='font-bold'><span className='group relative'>{dataShort} <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{dataFull}</span></span> approved {summaryKey === 'PI' ? 'indents' : 'orders'},</span> your orders are moving forward smoothly. Review next steps to keep things on track.</p>
                </div>
                break;

            case 'rejected_pi_per': case "rejected_po_per":
                return <div className='mb-4 flex items-start gap-2'>
                    <div className='p-[4px] rounded bg-[#EF4444]'><ThumbsDown size={11} color='white' /></div>
                    <p className='mb-0 mt-[2px] text-xs font-normal'><span className='font-bold'><span className='group relative'>{dataShort} <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{dataFull}</span></span> purchase {summaryKey === 'PI' ? 'indents' : 'orders'}</span> were rejected due to discrepancies or unmet requirements. Review the reasons and resubmit if necessary.</p>
                </div>
                break;

            case 'cancelled_pi_per': case "cancelled_po_per":
                return <div className='mb-4 flex items-start gap-2'>
                    <div className='p-[4px] rounded bg-[#EF4444]'><CircleX size={11} color='white' /></div>
                    <p className='mb-0 mt-[2px] text-xs font-normal'><span className='font-bold'><span className='group relative'>{dataShort} <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{dataFull}</span></span> purchase {summaryKey === 'PI' ? 'indents' : 'orders'}</span> have been cancelled. These requests are no longer active and require no further action.</p>
                </div>
                break;

            case 'expired_per': case "expired_po_per":
                return <div className='mb-4 flex items-start gap-2'>
                    <div className='p-[4px] rounded bg-[#EF4444]'><TriangleAlert size={11} color='white' /></div>
                    {/* <div className='flex items-center gap-1 flex-wrap'> */}
                    <p className='mb-0 mt-[2px] text-xs font-normal'><span className='font-bold'><span className='group relative'>{dataShort} <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{dataFull}</span></span> purchase {summaryKey === 'PI' ? 'indents' : 'orders'}</span> have expired. Please review them to proceed with the next steps.
                        {" "}
                        <span
                            className={'cursor-pointer underline whitespace-nowrap text-xs font-semibold text-[#E66767] hover:opacity-75'}
                            onClick={() => handleChange('expired')}
                        >
                            Review Now
                        </span>
                    </p>
                    {/* </div> */}
                </div>
                break;

            default:
                break;
        }
    }

    const getArrowShape = ()=>{
        return <>
            <div className='relative top-[80px] left-[6px] bg-white pr-[2px]'>
                <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.2422 1L35.2422 6.89717C35.2422 11.9245 31.1667 16 26.1394 16L5.24219 16" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                    <path d="M35.2422 31L35.2422 25.1028C35.2422 20.0755 31.1667 16 26.1394 16L5.24219 16" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                    <circle cx="4.55142" cy="15.8717" r="3.41356" fill="white" stroke="#20BF6B" stroke-width="2.27571" />
                </svg>
            </div>
            <svg width="45" height="290" viewBox="0 0 45 330" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 27.1328L1 21.2356C1 16.2083 5.07548 12.1328 10.1028 12.1328L31 12.1328" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                <path d="M0.999988 21.1328L1 306.133" stroke="#D9E4DD" stroke-width="1.5" stroke-linecap="round" />
                <path d="M1 301.82L1 307.717C1 312.745 5.07548 316.82 10.1028 316.82L31 316.82" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                <path d="M31.2344 12L20.1301 12" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                <path d="M25.9236 18.0668L31.9922 11.9982L25.9236 5.92969" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.2344 317L20.1301 317" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" />
                <path d="M25.9236 323.067L31.9922 316.998L25.9236 310.93" stroke="#D9E4DD" stroke-width="1.51714" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    }

    const getBorder = {
        Pending: "border-[#F7B500]",
        Approved: "border-[#28A745]",
        Rejected: "border-[#DC3545]",
        Cancelled: "border-[#6C757D]",
        "Expired PO's": "!border-t-0",
        '' : ''
    }

    const getLeftBorderColor = {
        Pending: "bg-[#F7B500]",
        Approved: "bg-[#28A745]",
        Rejected: "bg-[#DC3545]",
        Cancelled: "bg-[#6C757D]",
        "Expired PO's": "!border-t-0",
        '' : ''
    }

    const getTopCardGradient = {
        [`pending_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#F7B500]/50 to-transparent',
        [`approved_${currentSumKey}_per`]: 'bg-grad,ient-to-b from-[#28A745]/50 to-transparent',
        [`rejected_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#DC3545]/50 to-transparent',
        [`cancelled_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#6C757D]/50 to-transparent',
        [`expired_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#E66767]/50 to-transparent',
        '' : 'bg-gradient-to-b from-white to-transparent'
    }

    const getPercentDivGradient = {
        [`pending_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#F7B500]/40 to-transparent',
        [`approved_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#28A745]/40 to-transparent',
        [`rejected_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#DC3545]/40 to-transparent',
        [`cancelled_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#6C757D]/40 to-transparent',
        [`expired_${currentSumKey}_per`]: 'bg-gradient-to-b from-[#E66767]/40 to-transparent',
        '' : ''
    }
    console.log('dashboardSummaryData:', getTopCardGradient[getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxKey])

    return (
        <div className='h-full flex flex-col gap-5 text-fontBlack'>
            <div className={`px-4 pt-[12px] bg-gradient-to-b 
                ${getTopCardGradient[getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxKey ?? '']}
                `}
            >
                <div className='flex items-center justify-between gap-3'>
                    <div className=''>
                        <p className='text-[15px] font-semibold'>All {summaryKey == "PI" ? 'Indents' : 'Orders'}</p>
                        <div className='group relative'>
                            <p className='text-[29px] font-semibold'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_qty`])?.short} <span className='text-[10px] font-normal'>Qty</span></p>
                            {formatNumber(getCurrentDashData?.[`${currentSumKey}_qty`])?.full && <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_qty`])?.full}</span>}
                        </div>
                    </div>
                    <div className='flex items-center gap-5'>
                        <div>
                            <p className='text-[#718098] text-[11px] font-medium mb-1'>Amount</p>
                            <div className='group relative'>
                                <div className='text-[19px] font-semibold flex items-center gap-1'><span><IndianRupee size={17} /></span>{formatNumber(getCurrentDashData?.[`${currentSumKey}_amount`])?.short}</div>
                                {formatNumber(getCurrentDashData?.[`${currentSumKey}_amount`])?.full && <div className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_amount`])?.full}</div>}
                            </div>
                        </div>
                        <div>
                            <p className='text-[#718098] text-xs font-medium mb-1'>Count</p>
                            <div className='group relative'>
                                <div className='text-xl font-semibold'>{formatNumber(countPerData?.[summaryKey]?.[`${currentSumKey}_count`])?.short}</div>
                                {formatNumber(countPerData?.[summaryKey]?.[`${currentSumKey}_count`])?.full && <div className='generic-tooltip !left-0 group-hover:visible group-hover:opacity-100'>{formatNumber(countPerData?.[summaryKey]?.[`${currentSumKey}_count`])?.full}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {getDescription()}
                    <div className='flex w-full'>
                        {Object.keys(summaryDataHeadersKeys?.[summaryKey] || {})?.map((mainKey) =>
                            <div
                                className={`shrink border-l last:border-r px-3 border-[#E3E4E7] text-xs`}
                                style={{
                                    width: `${countPerData?.[summaryKey]?.[`${mainKey}_${currentSumKey}_per`] ?? "0"}%` ?? 'fit-content',
                                    minWidth: '70px'
                                }}
                            >
                                <div
                                    className={`mb-0 pt-[8px] px-1.5 border-t-4 flex items-center justify-center gap-1.5 text-center
                                    ${getBorder[summaryDataHeadersKeys?.[summaryKey]?.[mainKey] ?? '']}
                                    ${summaryDataHeadersKeys?.[summaryKey]?.[mainKey] === "Expired PO's" ? 'p-0 flex-col' : ''}
                                    ${getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxKey === `${mainKey}_${currentSumKey}_per` && getPercentDivGradient[getMaxKeyEndingWithPer(countPerData?.[summaryKey] ?? {})?.maxKey]}
                                `}
                                >
                                    {summaryDataHeadersKeys?.[summaryKey]?.[mainKey] === "Expired PO's" && <div className='h-[4px] w-full bg-[#E66767] flex items-center gap-0.5 overflow-hidden'>
                                        {Array.from({ length: 500 }, (_, index) => index + 1)?.map((obj, index) => (
                                            <div key={index} className='h-[4px] w-[1px] bg-white shrink-0 rotate-45'></div>
                                        ))}
                                    </div>}
                                    <div className='flex items-center justify-center gap-1.5'>{summaryDataHeadersKeys?.[summaryKey]?.[mainKey] === "Expired PO's" && <span><TriangleAlert size={14} color='#DC3545' /></span>} <p className='mb-0'>{countPerData?.[summaryKey]?.[`${mainKey}_${currentSumKey}_per`] ?? "0"}%</p></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className='h-full p-[12px] flex items-start bg-white'
                style={{
                    boxShadow: '0px 14px 25px 1px #E1E1E7'
                }}
            >
                <div className={`${showAdditionalDetails ? 'flex-[.4]' : 'w-full'}`}>
                    <div className={`px-3 pb-3 grid grid-cols-[1.5fr_1fr_2fr] items-center justify-between`}>
                        <div><p className='mb-0 text-[13px] font-semibold'>Quantity</p></div>
                        {!showAdditionalDetails && <>
                            <div><p className='mb-0 text-[13px] font-semibold'>Count</p></div>
                            <div className='flex items-center gap-1 whitespace-nowrap'>
                                <p className='mb-0 text-[13px] font-semibold'>Amount</p>
                                <p className='mb-0 text-[#718098] text-[10px] font-medium'>Excluding GST</p>
                            </div>
                        </>
                        }
                    </div>
                    <div className={`space-y-3`}>
                        {Object.keys(summaryDataHeadersKeys?.[summaryKey] || {})?.map((mainKey) =>
                            <div className={`relative px-3 pt-[6px] pb-1 grid ${showAdditionalDetails ? mainKey == "approved" ? 'grid-cols-[1.5fr_1fr] gap-3' : '' : 'grid-cols-[1.5fr_1fr_1fr_1fr]'} items-center justify-between bg-gradient-to-r w-full ${summaryDataHeadersKeys?.[summaryKey]?.[mainKey] === 'Approved' && showAdditionalDetails ? 'from-[#20BF6B]/50 to-[#F7F8FB1A]' : 'from-[#CFD0D3B2]/50 to-[#F7F8FB00]/5'}`}>
                                <div className={`flex items-center gap-4`}>
                                    {summaryDataHeadersKeys?.[summaryKey]?.[mainKey] !== "Expired PO's" ? <div
                                        className={`w-[4px] h-[20px]
                                            ${getLeftBorderColor[summaryDataHeadersKeys?.[summaryKey]?.[mainKey] ?? '']}
                                                    `}
                                    ></div> :
                                        <div>
                                            <svg width="5" height="20" viewBox="0 0 5 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="22" width="22" height="4" transform="rotate(-90 0 22)" fill="#E66767"/>
                                                <line x1="0.232967" y1="17.3188" x2="3.85936" y2="20.1104" stroke="white" stroke-width="0.674188"/>
                                                <line x1="0.232967" y1="14.0298" x2="3.85936" y2="16.8213" stroke="white" stroke-width="0.674188"/>
                                                <line x1="0.232967" y1="10.7407" x2="3.85936" y2="13.5323" stroke="white" stroke-width="0.674188"/>
                                                <line x1="0.232967" y1="7.45163" x2="3.85936" y2="10.2432" stroke="white" stroke-width="0.674188"/>
                                                <line x1="0.232967" y1="4.16257" x2="3.85936" y2="6.95413" stroke="white" stroke-width="0.674188"/>
                                                <line x1="0.232967" y1="0.873509" x2="3.85936" y2="3.66507" stroke="white" stroke-width="0.674188"/>
                                            </svg>
                                        </div>
                                    }
                                    <div className=''>
                                        <p className='text-xs font-medium mb-1'>{summaryDataHeadersKeys?.[summaryKey]?.[mainKey]}</p>
                                        <span className='whitespace-nowrap group relative'>
                                            <span className='text-xl font-medium'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_qty`])?.short}</span> <span className='text-[10px] font-medium text-[#718098]'>Qty</span>
                                            {formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_qty`])?.full && <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_qty`])?.full}</span>}
                                        </span>
                                    </div>
                                </div>
                                {!showAdditionalDetails &&
                                    <>
                                        <div>
                                            {/* <p className='text-[10px] font-medium text-[#718098] mb-[2px]'>Count</p> */}
                                            <span className='text-base font-normal'>{countPerData?.[summaryKey]?.[`${currentSumKey}_${mainKey}_count`] ?? "NA"}</span>
                                        </div>
                                        <div>
                                            {/* <p className='text-[10px] font-medium text-[#718098] mb-[2px]'>Amount</p> */}
                                            <span className='group relative'>
                                                <span className='text-base font-normal'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_amt`])?.short}</span>
                                                {formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_amt`])?.full && <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${currentSumKey}_${mainKey}_amt`])?.full}</span>}
                                            </span>
                                        </div>
                                    </>
                                }
                                <div className='flex items-center gap-3 justify-end'>
                                    {summaryKey == "PI" && mainKey == "approved" && <GenericCustomButton
                                        leftIcon={showAdditionalDetails ? <X size={18} /> : <InfoIcon size={17} />}
                                        onClick={() => setShowAdditionalDetails(!showAdditionalDetails)}
                                        className={'p-[6px] cursor-pointer hover:bg-[#F0F1F2]'}
                                    />}
                                    {!showAdditionalDetails && summaryDataHeadersKeys?.[summaryKey]?.[mainKey] !== "Expired PO's" && <GenericCustomButton
                                        leftIcon={<SquareArrowOutUpRight size={16} />}
                                        onClick={() => handleChange(mainKey)}
                                        className={'p-[6px] cursor-pointer hover:bg-[#F0F1F2]'}
                                    />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {<div
                    className={`flex-[.6] mt-[2rem] z-[1] flex items-center ${summaryKey == "PI" && showAdditionalDetails ? 'w-fit' : 'w-0'}`}
                >
                    <div className={`relative flex items-start ${summaryKey == "PI" && showAdditionalDetails ? 'duration-200 origin-left scale-100' : 'scale-0'}`}>
                        {getArrowShape()}

                    </div>
                    <div className={`flex-1 px-3 py-2 h-full min-w-[280px] bg-[#F7F8FB] border border-[#EDEEF1] ${summaryKey == "PI" && showAdditionalDetails ? 'delay-200 duration-200 visible opacity-100 translate-x-0' : 'invisible opacity-0 -translate-x-2'}`}>
                        {Object.keys(approvedPiData || {})?.map((key) =>
                            <div>
                                <label className='text-[13px] font-semibold mb-1'>{approvedPiData?.[key]}</label>
                                <div className='grid grid-cols-3 justify-between items-start gap-3'>
                                    <div className='flex flex-col gap-1'>
                                        <label className='text-[9px] font-medium text-[#718098] mb-0'>Qty</label>
                                        <p className='text-[13px] whitespace-nowrap group relative'>
                                            {formatNumber(getCurrentDashData?.[`${key}_qty`])?.short}
                                            {formatNumber(getCurrentDashData?.[`${key}_qty`])?.full && <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${key}_qty`])?.full}</span>}
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label className='text-[9px] font-medium text-[#718098] mb-0'>Count</label>
                                        <p className='text-[13px] whitespace-nowrap'>{countPerData?.[summaryKey]?.[`${key}_count`] ?? "NA"}</p>
                                    </div>
                                    <div className='flex flex-col justify-end gap-1'>
                                        <label className='text-[9px] font-medium text-[#718098] mb-0'>Amount</label>
                                        <p className='text-[13px] whitespace-nowrap mb-0 group relative'>
                                            {formatNumber(getCurrentDashData?.[`${key}_amt`])?.short}
                                            {formatNumber(getCurrentDashData?.[`${key}_amt`])?.full && <span className='generic-tooltip group-hover:visible group-hover:opacity-100'>{formatNumber(getCurrentDashData?.[`${key}_amt`])?.full}</span>}
                                        </p>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default SummarySection