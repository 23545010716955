import React, { useEffect, useState } from 'react'
import Icons from '../../assets/icons';
import OtpInput from 'react-otp-input'
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import GenericCustomButton from '../../genericComponents/GenericCustomButton';
import '../../styles/stylesheets/SignIn/SignIn.scss'
import GenericFormFooterComponent from '../../genericComponents/forms/GenericFormFooterComponent';
import LanguageTranslator from '../../locals/LanguageTranslator';
import { BadgeCheck } from 'lucide-react';

const OtpForm = (props) => {
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.authStore)

    // signUpLoading : will get used in case of sign up using otp process
    const { otpResource, editOtpResource, flowType, source, resendOtp, setSignUpLoading, signUpLoading = false, showLogoutBtn = false, flowName = "", logoutFunction = null } = props;
    let timer;
    const [resendTime, setResendTime] = useState(30)
    const [otp, setOtp] = useState(""); 

    useEffect(() => {
        if (resendTime > 0) {
            timer = setInterval(() => {
                setResendTime(prev => Number(prev) - 1);
            }, 1000)
        }
        return () => {
            clearInterval(timer);
        }
    }, [resendTime])

    useEffect(() => {
        if (authStore?.["getOtpData"]?.isSuccess) {
            setResendTime(30);
        }
    }, [authStore?.["getOtpData"]?.isSuccess])

    useEffect(() => {
        if (otp?.length === 6 && !authStore?.["verifyOtpData"]?.isLoading) {
            verifyOtp()
        }
    }, [otp])

  
    const verifyOtp = (type, val) => {
        if(props.verifyOtp){
            props.verifyOtp?.({otp : otp})
        }else{
            dispatchHandler(dispatch, "verifyOtpRequest", {
                flowType: flowType,
                generatedToken: otp || "",
                token: props?.token,
                source: source,
                forceLogin : type == "forceLogin" ? 1 : undefined,
                mfaPasscode : type == "2faLogin" ? val : undefined,
            })
        }
    }

    // Handle the response of the otp verify api
    useEffect(() => {
        if (authStore?.["verifyOtpData"]?.isSuccess) {
            setOtp("")
            document?.getElementById?.('ot2pInput0')?.focus();
            let data = authStore?.["verifyOtpData"]?.data?.resource || {};
            setSignUpLoading?.(true)
            props?.finalSubmit(data);
        }
    }, [authStore?.["verifyOtpData"]?.isSuccess]);

    useEffect(() => {
        if (authStore?.["verifyOtpData"]?.isError &&  props.setLoginResponse) {
            if(authStore?.["verifyOtpData"]?.message?.code == "4826"){
                props.setLoginResponse({
                    ...authStore?.["verifyOtpData"]?.data,
                    loginAgain : () => {
                        verifyOtp("forceLogin");
                    }
                });
                props.setCurrentType("singleLogin");   
            }
        }
    }, [authStore?.["verifyOtpData"]?.isError])

    // let footerBtnArray =  [];
    // if(showLogoutBtn && flowName == "verifyEmailOrMobile") {
    //     footerBtnArray.push({ label: "Logout", type: "default", onClick: () => logoutFunction() });
    // }
    // footerBtnArray.push({ label: "Verify", disabled: otp?.length !== 6 || signUpLoading, type: "primary", onClick: () => verifyOtp() });
    
    return (<div className='otp-form h-full flex flex-col'>
        <div className='otp-head h-full'>
            <LanguageTranslator>Enter OTP</LanguageTranslator><LanguageTranslator>{props.passcode ? " / Passcode" : ""}</LanguageTranslator>
            <p><LanguageTranslator>We have sent an OTP to</LanguageTranslator>&nbsp;<b>{otpResource}</b>
                <GenericCustomButton
                    className="edit-btn"
                    leftIcon={<Icons.EditIcon />}
                    onClick={() => { editOtpResource() }}
                />
            </p>
            <div className='of-otp'>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props, index) => <input id={`otpInput${index}`} {...props} autoComplete="off" />}
                    shouldAutoFocus={true}
                    inputType={"tel"}
                    placeholder="0"
                />
            </div>
            {(resendTime > 0) && <div className='flex p-top-20'>
                <div className="clock-icon-loader">
                    <div className="clock-loader">
                        <svg><circle r="15" cx="20" cy="20"></circle></svg>
                    </div>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.97217 4.28011V6.4917L7.63086 8.15039M10.9482 6.4917C10.9482 9.23991 8.72038 11.4678 5.97217 11.4678C3.22396 11.4678 0.996094 9.23991 0.996094 6.4917C0.996094 3.74349 3.22396 1.51562 5.97217 1.51562C8.72038 1.51562 10.9482 3.74349 10.9482 6.4917Z" stroke="#67768E" stroke-width="1.10579" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className="time-text">
                    <p>00:{resendTime < 10 ? `0${resendTime}` : resendTime}</p>
                    <label>seconds left</label>
                </div>
            </div>}
            <div className='of-resend-otp'>
                <GenericCustomButton
                    label={"Resend OTP"}
                    onClick={() => resendOtp()}
                    disabled={Number(resendTime) > 0 || signUpLoading }
                />
            </div>
        </div>
        <GenericFormFooterComponent
            // buttons={footerBtnArray}
            isTopBorderRequired={true}
            leftButtons={[
                showLogoutBtn && flowName == "verifyEmailOrMobile" && { 
                    className: 'text-[13px] text-danger font-semibold flex items-center gap-1.5 h-[32px] px-[15px] rounded-[3px] hover:bg-danger_w_less_opacity',
                    leftIcon: <span className="hund-icon">
                                <Icons.LogoutIcon />
                            </span>, 
                    label: "Logout", 
                    onClick: () => logoutFunction() 
                }
            ]}
            buttons={[
                { 
                    
                    className: 'no-fill-icon-button !px-5 !w-[120px] !flex !items-center !justify-center !gap-1.5',
                    leftIcon: <span className='no-fill-icon'><BadgeCheck size={22} className='!fill-none text-white'/></span>,
                    label: "Verify", 
                    disabled: otp?.length !== 6 || signUpLoading, 
                    type: "primary", 
                    onClick: () => verifyOtp() 
                }
            ]}
        />
    </div>
    )
}

export default OtpForm