import { CONFIG } from '../../config/index';
export const digiArsStore = [
    {
        title: "getOrderRequirementData",
        actionPrefix: 'getOrderRequirement',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "getReplenishmentSummaryData",
        actionPrefix: 'getReplenishmentSummary',
        message: 'Request Completed Successfully',
        requestData: {
            // We have to spread the payload here to deep copy the object.
            updatePayload: ({ ...payload }) => {
                delete payload.summaryApiUrl;
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`
        },
    },
    {
        title: "getSummaryAllJobData",
        actionPrefix: 'getSummaryAllJob',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/job`
        },
    },
    {
        title: "arsPlanGetRepSumData",
        actionPrefix: 'arsPlanGetRepSum',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/replenishment/summary`
        },
    },
    {
        title: "getSummaryLastJobData",
        actionPrefix: 'getSummaryLastJob',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/job/getLastJob/summary`
        },
    },
    {
        title: "summaryToStatusData",
        actionPrefix: 'summaryToStatus',
        message: 'Request Completed Successfully',
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/transferOrder?id=${payload.jobId}`
        },
    },
    {
        title: "getReplenishmentHistoryData",
        actionPrefix: 'getReplenishmentHistory',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.baseUrl;
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${decodeURIComponent(payload.apiUrl)}`
        },
    },
    {
        title: "getTransferOrderData",
        actionPrefix: 'getTransferOrder',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.otherInfo;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/transferOrder`
        },
    },
    {
        title: "getTransferOrderDropdownData",
        actionPrefix: 'getTransferOrderDropdown',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/transfer/order/ruleEngine`
        },
    },
    {
        title: "getTransferOrderDropdownV2Data",
        actionPrefix: 'getTransferOrderDropdownV2',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/transfer/order/ruleEngine`
        },
    },
    {
        title: "getTransferOrderStatusData",
        actionPrefix: 'getTransferOrderStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/update/transferOrder`
        },
    },
    {
        title: "getArsDashboardData",
        actionPrefix: 'getArsDashboard',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/transfer/order/summary`
        },
    },
    {
        title: "uploadExcelTransferOrderData",
        actionPrefix: 'uploadExcelTransferOrder',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/rule/engine/upload/to/detail`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        },
    },
    {
        title: "downloadTemplateData",
        actionPrefix: 'downloadTemplate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/download/excel/template`, headers: { "Content-Type": "multipart/form-data" }
        },
    },
    {
        title: "getAdhocData",
        actionPrefix: 'getAdhoc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/get/all`
        },
    },
    {
        title: "deleteAdhocData",
        actionPrefix: 'deleteAdhoc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/delete/adhocRequest?workOrder=${payload?.selectedDeleteItem}`
        },
    },
    {
        title: "updateAdhocItemsPerWOData",
        actionPrefix: 'updateAdhocItemsPerWO',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/update/itemsPerWorkOrder`
        },
    },
    {
        title: "updateAdhocData",
        actionPrefix: 'updateAdhoc',
        message: 'Request Completed Successfully',
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/update/pendingToApproveReject?statusType=${payload?.type}`
        },
    },
    {
        title: "getHeaderConfigData",
        actionPrefix: 'getHeaderConfig',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/headerconfig/get/header?enterpriseName=${payload?.enterpriseName}&attributeType=${payload.attributeType}&displayName=${payload.displayName}&orgId=$'}`
        },
    },
    {
        title: "getAdhocItemsPerWOData",
        actionPrefix: 'getAdhocItemsPerWO',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/get/itemsPerWorkOrder?pageNo=${payload?.pageNo}&workOrder=${payload?.workOrder}&statusType=${payload?.status}`
        },
    },
    {
        title: "dataSyncData",
        actionPrefix: 'dataSync',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.SM_BACKEND_BASE_URL}/ars/custom/data/get`
        },
    },
    {
        title: "getTemplateData",
        actionPrefix: 'getTemplate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/custom/get/template`
        },
    },
    {
        title: "lastCustomUploadFileData",
        actionPrefix: 'lastCustomUploadFile',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                payload?.openUrl !== undefined ?
                    `${CONFIG.BASE_URL}/upload/open/url/download/csv/${payload.template}/${payload.ocode}?orgId=${payload.oid}&orgCode=${payload.ocode}&bucketName=${payload.bname}` :
                    `${CONFIG.BASE_URL}/aws/s3bucket/download/csv/${payload}`
        },
    },
    {
        title: "getCreateAdhocPrimaryInfoData",
        actionPrefix: 'getCreateAdhocPrimaryInfo',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/search/adhoc${payload.key}?search=${payload.search}&pageNo=${payload.pageNo}`
        },
    },
    {
        title: "createAdhocReplenishmentData",
        actionPrefix: 'createAdhocReplenishment',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/inv/plan/adhoc/create/adhocRequest`
        },
    },
    {
        title: "retryTransferOrderData",
        actionPrefix: 'retryTransferOrder',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/transfer/order/retry`
        },
    },
    {
        title: "unapproveTransferOrderData",
        actionPrefix: 'upapproveTransferOrder',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/transfer/order/unapprove`
        },
    },
    {
        title: "uploadArsCustomData",
        actionPrefix: 'uploadArsCustom',
        message: 'Request Completed Successfully',
        requestData: {
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/aws/s3bucket/upload`
        },
    },
    {
        title: "getReviewReasonSummaryData",
        actionPrefix: 'getReviewReasonSummary',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.ARS}/get/review/reason/summary`
        },
    },
    {
        title: "getDataWithoutFilterData",
        actionPrefix: 'getDataWithoutFilter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.ARS}/get/all`
        },
    },
    {
        title: "getReviewReasonData",
        actionPrefix: 'getReviewReason',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.ARS}/get/all`
        },
    },
    {
        title: "getfiltersArsDataData",
        actionPrefix: 'getfiltersArsData',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.ARS}/get/filters?reviewReason=${payload?.reviewReason}`
        },
    },
    {
        title: "getTransferOrderDetailData",
        actionPrefix: 'getTransferOrderDetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/all/transfer/order/detail`
        },
    },
    {
        title: "updateTODetailsData",
        actionPrefix: 'updateTODetails',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                let newPayload = Object.assign({}, payload);
                delete newPayload.transferOrderNo;
                return newPayload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/transfer/order/update?transferOrderNo=${payload?.transferOrderNo}`
        },
    },
    {
        title: "getReplenishmentOutputLogsData",
        actionPrefix: 'getReplenishmentOutputLogs',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/rule/engine/get/cloud/watch/logs/${payload.jobId}/${payload.type}`
        },
    },
    {
        title: "getAllMultiLogData",
        actionPrefix: 'getAllMultiLog',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/multi/level/config/get/multi/log`
        },
    },
    {
        title: "getArsScheduledJobDetailData",
        actionPrefix: 'getArsScheduledJobDetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.SM_BACKEND_BASE_URL}/ars/rule/engine/get/job/detail`
        },
    },
    {
        title: "editRunOnDemandJobData",
        actionPrefix: 'editRunOnDemandJob',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.SM_BACKEND_BASE_URL}/ars/rule/engine/update/job/trigger`
        },
    },
    {
        title: "getArsParamsAndFiltersData",
        actionPrefix: 'getArsParamsAndFilters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.SM_BACKEND_BASE_URL}/ars/rule/engine/get/job/filter`
        },
    },
]
