import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler, handleApiChange } from '../../../helper/apiHelperFunctions';
import Icons from '../../../assets/icons';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import ParentModal from '../../../genericComponents/ParentModal';
import SaveFilterModal from '../../../genericComponents/oldGenericComponents/tableHelperComponents/SaveFilterModal';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';
import { Pi, Pin, PinIcon } from 'lucide-react';

const QuickFilter = (props) => {
    const dispatch = useDispatch();
    const { applyFilter, filterPayload = {}, getCurrentFilters = () => ({}), filterItems = {}, } = props;

    const generalStore = useSelector(state => state?.generalStore);
    const [quickFilterData, setQuickFilterData] = useState([]);
    const [currentSelectedQuickFilter, setCurrentSelectedQuickFilter] = useState("");
    const [qfDropdown, setQfDropdown] = useState("");
    const [pinnedFilter, setPinnedFilter] = useState([]);
    const [saveFilterModal, setsaveFilterModal] = useState(false)
    const [showUnpinnedFilters, setShowUnpinnedFilters] = useState(false)

    useEffect(() => {
        handleApiChange(dispatch, 'getQuickFiltersRequest', filterPayload)
    }, [])

    // function to clear filters
    useEffect(() => {
        if(props?.callFun === "clearFilters") {
            // console.log(3434343434 , "clearFilter Running")
            setCurrentSelectedQuickFilter("");
            props?.setCallFun("")
        }
    }, [props?.callFun])

    useEffect(() => {
        if (Object.keys(currentSelectedQuickFilter || {})?.length > 0) {
            applyFilter(currentSelectedQuickFilter?.filterValue);
        }
    }, [currentSelectedQuickFilter])

    useEffect(() => {
        if (generalStore?.getQuickFiltersData?.isSuccess) {
            let updatedPinnedFilter = [];
            setQuickFilterData(generalStore?.getQuickFiltersData?.data?.resource);
            let filterData = generalStore?.getQuickFiltersData?.data?.resource;
            let defaultFilter = filterData?.find(item => item?.is_default == 1);
            if (defaultFilter) {
                setCurrentSelectedQuickFilter(defaultFilter || {});
                updatedPinnedFilter.push(defaultFilter)
            } else {
                filterData?.map(item => {
                    if (Object.keys(updatedPinnedFilter || {})?.length < 3) {
                        updatedPinnedFilter.push(item)
                    }
                })
            }
            setPinnedFilter(updatedPinnedFilter || []);
        }
    }, [generalStore?.getQuickFiltersData?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "filterClick": {
                if (data?.filterName === currentSelectedQuickFilter?.filterName) {
                    setCurrentSelectedQuickFilter("");
                    applyFilter({});
                } else {
                    setCurrentSelectedQuickFilter(data);
                    handleChange("pinFilter",data);
                }
                break;
            }
            case "pinFilter": {
                let updatedPinnedFilter = [...pinnedFilter]
                if (pinnedFilter?.some(item => item?.filterName === data?.filterName)) {
                    updatedPinnedFilter = updatedPinnedFilter?.filter(filter => filter?.filterName != data?.filterName);
                } else {
                    if (updatedPinnedFilter?.length >= 3) {
                        updatedPinnedFilter = [...updatedPinnedFilter?.slice(0, 2), data];
                    } else {
                        updatedPinnedFilter.push(data)
                    }
                }
                setPinnedFilter(updatedPinnedFilter);
                break;
            }
            case "deleteFilter":
            case "defaultFilter": {
                if (!generalStore?.createQuickFiltersData?.isLoading) {
                    let payload = {
                        displayName: filterPayload?.displayName,
                        filterName: [data?.filterName],
                        filterValue: data?.filterValue
                    };
                    if (type === "deleteFilter") {
                        payload.isDeleteFilter = true;
                    }
                    else if (type === "defaultFilter") {
                        payload.is_default = data?.is_default === 1 ? 0 : 1;
                    }
                    dispatchHandler(dispatch, "createQuickFiltersRequest", payload, getPermissionKey("common", "commonFunctionality", "quickfilters"))
                }
                break;
            }
            case "saveFilter": {
                let payload = {
                    displayName: filterPayload?.displayName,
                    filterName: [data],
                    filterValue: getCurrentFilters(),
                    is_default: undefined
                };
                dispatchHandler(dispatch, "createQuickFiltersRequest", payload, getPermissionKey("common", "commonFunctionality", "quickfilters"));
                break;
            }
            default:
        }
    }

    useEffect(() => {
        if (generalStore?.createQuickFiltersData?.isSuccess) {
                setsaveFilterModal(false)
                setTimeout(() => {
                    dispatchHandler(dispatch, 'getQuickFiltersRequest', filterPayload, getPermissionKey("common", "commonFunctionality", "quickfilters"))  
                },1000)
        }
    }, [generalStore?.createQuickFiltersData?.isSuccess])

    const handleQF = (item, e) => {

        e.preventDefault?.(); e.stopPropagation?.() ;
        setQfDropdown((prev)=> !prev || prev?.filterName !== item?.filterName ? item : false);
        setTimeout(() => document.addEventListener("click", closeQF), 0)
    }
    const closeQF = () => {
        setQfDropdown(false);
        setTimeout(() => document.removeEventListener("click", closeQF), 0)
    }

    let filtersData = pinnedFilter?.length > 0 ? pinnedFilter || [] : quickFilterData?.slice(0, 3);

    let pinnedFilterKeys = pinnedFilter?.map(item => item?.filterName);
    let unpinnedFilters = quickFilterData?.filter(item => !pinnedFilterKeys?.includes(item?.filterName))

    const handleClickOutsideButton = (event) => {
        const secondButton = document.getElementById('unpinnedFilters');
        if (secondButton && !secondButton.contains(event.target)) {
            setShowUnpinnedFilters(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideButton);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideButton);
        };
    }, []);

    return (
        <React.Fragment>
            <div className='afd-filters'>
                {
                    filtersData?.map((item, index) => {
                        return (
                            <div
                                className={`afdff ${currentSelectedQuickFilter?.filterName === item?.filterName && "afdff-sel"}`}>
                                <div className='h-100 flex items-center' id={index?.toString()} onClick={() => handleChange("filterClick", item)}>
                                    <p>{item?.filterName || ""}</p>
                                </div>

                                {currentSelectedQuickFilter?.filterName === item?.filterName && <button onClick={(e) => {
                                    e.stopPropagation?.();
                                    setCurrentSelectedQuickFilter("")
                                    applyFilter({})
                                }} ><Icons.ModalCloseIcon /></button>}

                                <span className='three-dot' onClick={(e) => handleQF(item, e)}>
                                    <Icons.Threedot />
                                    {qfDropdown?.filterName === item?.filterName &&
                                        <ul>
                                            <LanguageTranslator tag='li' onClick={(e) => { handleChange("defaultFilter", item)}}>{item.is_default == 1 ? "Remove as Default" : "Mark as Default"}</LanguageTranslator>
                                            <LanguageTranslator tag='li' onClick={(e) => {handleChange("pinFilter", item)}}>{pinnedFilter?.some(filter => filter?.filterName == item?.filterName) ? "Remove Pin Item" : "Pin Item"}</LanguageTranslator>
                                            <LanguageTranslator tag='li' className="delete-tab" onClick={() => handleChange("deleteFilter", item)}>Delete Tab</LanguageTranslator>
                                        </ul>}
                                </span>
                            </div>
                        )
                    }
                    )
                }

                {/* div for more button */}
                <div className='afdff-btns'>
                    <GenericCustomButton
                        rightIcon={<Icons.DownArrowFilled />}
                        label='More'
                        type='default'
                        onClick={() => setShowUnpinnedFilters(!showUnpinnedFilters)}
                    />
                    {Object.keys(getCurrentFilters() || {})?.length > 0 &&
                        <GenericCustomButton
                            type='primary'
                            label='Save Filter'
                            onClick={() => { setsaveFilterModal(true) }}
                        />}

                    {showUnpinnedFilters && <ul id='unpinnedFilters' className='pinned-filter-dropdown'>
                        {unpinnedFilters?.map((item, index) => {
                            return (
                                <li
                                    id={index?.toString()}
                                    onClick={() => handleChange("filterClick", item)}
                                >
                                    <p>{item?.filterName || ""}</p>
                                    <div className='flex items-center'>
                                        <span onClick={() => handleChange("pinFilter", item)} >  
                                            <Icons.FilterPinned className='m-lr-5' />
                                        </span>
                                        <span className='three-dot !px-[8px] hover:bg-white rounded-full' onClick={(e) => handleQF(item, e)}>
                                            <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="1.60385" cy="2.23935" r="1.28036" fill="#707494" />
                                                <circle cx="1.60385" cy="7.15927" r="1.28036" fill="#707494" />
                                                <circle cx="1.60385" cy="12.0792" r="1.28036" fill="#707494" />
                                            </svg>
                                            {/* {qfDropdown?.filterName === item?.filterName && */}
                                                <ul className={`filter-menu ${unpinnedFilters?.length > 1 && unpinnedFilters?.length -1 === index ? '!top-auto !bottom-full' : '!top-full'} !left-auto !right-0 !duration-100 origin-top-right ${qfDropdown?.filterName === item?.filterName ? 'scale-100 opacity-100 visible pointer-events-auto' : 'h-0 w-0 overflow-hidden scale-90 opacity-0 invisible pointer-events-none'}`}>
                                                    <li className="filter-item" onClick={() => handleChange("defaultFilter", item)}>{item.is_default == 1 ? "Remove" : "Mark"} as Default</li>
                                                    <li className="filter-item delete-tab" onClick={() => handleChange("deleteFilter", item)}>Delete Tab</li>
                                                </ul>
                                                {/* } */}
                                        </span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    }
                </div>
            </div>
            {saveFilterModal && <ParentModal
                getParentChildProps={{
                    closeModal: () => setsaveFilterModal(false),
                    // postitionProps: { top: '50%', left: '50%',transform: 'translate(-50%, -50%)', borderRadius: 0, margin: 0 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
			        postitionProps: { position: 'relative' },
                    dimensions: { width: "100%", height: "100%", background: "transparent" },
                    quickFilterItems: quickFilterData,
                    saveQuickFilter: (name) => () => handleChange("saveFilter", name),
                    checkedFilters: getCurrentFilters(),
                    filterItems: filterItems || {},
                    headerType: "Default Headers",
                    closeSaveFilter: () => setsaveFilterModal(false)
                }}
                childComponent={SaveFilterModal}
                closeModal={() => { setsaveFilterModal(false) }}
            />}
        </React.Fragment>
    )
}

export default QuickFilter