import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import SwitchOrgAnimation from '../animationsJSON.js/SwitchOrgAnimation.json';

const FullPageLoader = ({ type }) => {
    const [currentAnimation, setCurrentAnimation] = useState(1); // State to track the current animation (1 or 2)

    useEffect(() => {
        // Set an interval to toggle between the two animations every 2 seconds
        const interval = setInterval(() => {
            setCurrentAnimation((prev) => (prev === 1 ? 2 : 1)); // Toggle between 1 and 2
        }, 2300);

        // Cleanup the interval on component unmount
        return () => {
            clearInterval(interval);
        };
    }, []); // Empty dependency array ensures this effect runs only once (on mount)

    switch (type) {
        case "switchOrg":
            return (
                <div className="full-page-loader">
                    <div 
                        className="white-backdrop !opacity-90" 
                        // style={{ 
                        //     background: 'linear-gradient(to bottom, var(--primary-light), #FFFFFF)', 
                        //     opacity: .9 
                        // }}
                    />
                    <div className="relative">
                        <div className="mt-[20px]">
                            <p className="text-2xl font-semibold">
                                <span className="text-primary">Switching </span>
                                <span>your Profile</span>
                                <span className="animate-dot-flash">.</span>
                                <span className="animate-dot-flash anim-delay-200">.</span>
                                <span className="animate-dot-flash anim-delay-400">.</span>
                            </p>
                            <p className="text-[18px] text-[#4A5D7A]">"Loading your profile experience, please wait."</p>
                        </div>
                        <div className="mt-[-40px] ml-[2rem]">
                            {/* Lottie animation shown when currentAnimation is 1 */}
                            {currentAnimation === 1  && <div style={{ visibility: currentAnimation === 1 ? 'visible' : 'hidden' }}>
                                <Lottie
                                    animationData={SwitchOrgAnimation}
                                    loop={true}
                                    autoplay={true}
                                    style={{ width: 'auto', height: 400 }}
                                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                                />
                            </div>}

                            {/* Lottie animation shown when currentAnimation is 2 */}
                            {currentAnimation === 2 && <div style={{ visibility: currentAnimation === 2 ? 'visible' : 'hidden' }}>
                                <Lottie
                                    animationData={SwitchOrgAnimation}
                                    loop={true}
                                    autoplay={true}
                                    style={{ width: 'auto', height: 400 }}
                                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                                />
                            </div>}
                        </div>
                    </div>
                </div>
            );

        default:
            return (
                <div className="full-page-loader">
                    <div className="white-backdrop"></div>
                    <div className="ball-scale-multiple">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            );
    }
};

export default FullPageLoader;
