import React, { useContext, useState } from 'react'
import GenericTableComponent from '../../genericComponents/tableComponent/GenericTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../assets/icons';
import { getPermissionKey } from '../../helper/permissions';
import GenericStatusComponent from '../../genericComponents/GenericStatusComponent';
import { parseJwt } from '../../helper/genericFunction';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { UserDetailsContext } from '../../App';

function ExcelUploadLogs() {
    const location = useLocation();
    const uType = parseJwt(sessionStorage.getItem('token'))?.uType?.toUpperCase();
    const urlSearchParams = new URLSearchParams(location.search),
        params = Object.fromEntries(urlSearchParams.entries());
    const generalStore = useSelector(state => state.generalStore);
    const [currentTab, setCurrentTab] = useState(params.status ? JSON.parse(decodeURIComponent(params.status)) : "salesIndent");

    const { userDetails } = useContext(UserDetailsContext)
    const checkIfModulePresent = (moduleCode = "") => {
        if (moduleCode) {
            let modules = JSON.parse(userDetails?.modules || "[]");
            return modules?.flatMap?.(item => item?.subModules)?.findIndex(item => item?.code === moduleCode) != -1;
        }
        return false;
    }

    const isSalesIndentModuleAvailable = () => {
        return checkIfModulePresent("PCVSHINDHISV2")
    }

    const tableHeadersMap = uType == "VENDOR" && isSalesIndentModuleAvailable() ? {
        salesIndent: {
            main: {
                name: "Main",
                key: "PO_EXCEL_TABLE_HEADER",
            }
        },
        otherExcelLogs: {
            main: {
                name: "Main",
                key: "EXCEL_UPLOAD_LOGS",
            }
        }
    } : {
        main: {
            name: "Main",
            key: "EXCEL_UPLOAD_LOGS"
        }
    }

    const changeTab = (tab) => (e) => {
        setCurrentTab(tab);
    }

    if (uType == "VENDOR" && isSalesIndentModuleAvailable()) {
        return <React.Fragment>
            <GenericTableComponent
                actionBarConfig={{
                    downloadReport: {
                        headerLevelModule: tableHeadersMap?.[currentTab]?.main?.key,
                        tableHeadersMap: tableHeadersMap?.[currentTab]
                    },
                    tabs: {
                        key: "status",
                        data: {
                            "salesIndent": "Sales Indent Logs",
                            "otherExcelLogs": "Other Excel Logs"
                        },
                        onChange: changeTab
                    }
                }}

                tableConfig={{
                    extras: item => <React.Fragment>
                        <li data-interactive="true" onClick={() => window.open(item.filePath)} className={item.filePath == null ? 'til-inner opacity04' : 'til-inner til-copy-btn'}>
                            <span>
                                <Icons.BlueDownloadIcon />
                            </span>
                        </li>
                    </React.Fragment>,
                    customCols: {
                        status: item =>
                            <React.Fragment>
                                <GenericStatusComponent status={item?.["status"]} />
                            </React.Fragment>,
                    }
                }}

                tableDataPayload={{
                    type: 1,
                    pageNo: 1,
                    pageSize: 10,
                    sortedBy: "",
                    sortedIn: "",
                    search: "",
                    filter: {},
                    status: currentTab,
                    apiUrl: uType == "VENDOR" && isSalesIndentModuleAvailable() && currentTab == "salesIndent" ? "/admin/po/get/excel/upload/log" : "/system/config/excel/upload/log"
                }}
                tableComExtraHeaders={getPermissionKey("purchase", "excelupload", "view")}
                tableData={uType == "VENDOR" && isSalesIndentModuleAvailable() && currentTab == "salesIndent" ?

                    {
                        data: generalStore?.getTableComponentListData?.data?.resource || [],
                        totalItems: generalStore?.getTableComponentListData?.data?.totalRecord || 0,
                        currentPage: generalStore?.getTableComponentListData?.data?.currPage || 1,
                        maxPage: generalStore?.getTableComponentListData?.data?.maxPage || 1
                    }

                    : generalStore?.getTableComponentListData?.data?.resource ?
                        {
                            data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
                            totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                            currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                            maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                            getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                        } :
                        {
                            data: [],
                            totalItems: 0,
                            currentPage: 1,
                            maxPage: 1
                        }
                }

                tableHeadersPayload={tableHeadersMap?.[currentTab]}
                tableHeadersMap={tableHeadersMap}
                availableStores={["generalStore"]}
            />
        </React.Fragment>
    }

    return (
        <>
            <GenericTableComponent
                actionBarConfig={{
                    downloadReport: {
                        headerLevelModule: tableHeadersMap?.main?.key,
                        tableHeadersMap: tableHeadersMap
                    }
                }}

                tableConfig={{
                    extras: item => <React.Fragment>
                        <li data-interactive="true" onClick={() => window.open(item.filePath)} className={item.filePath == null ? 'til-inner opacity04' : 'til-inner til-copy-btn'}>
                            <span>
                                <Icons.BlueDownloadIcon />
                            </span>
                        </li>
                    </React.Fragment>,
                    customCols: {
                        status: item =>
                            <React.Fragment>
                                <GenericStatusComponent status={item?.["status"]} />
                            </React.Fragment>,
                    }
                }}

                tableDataPayload={{
                    type: 1,
                    pageNo: 1,
                    pageSize: 10,
                    sortedBy: "",
                    sortedIn: "",
                    search: "",
                    filter: {},
                    apiUrl: "/system/config/excel/upload/log"
                }}
                tableComExtraHeaders={getPermissionKey("purchase", "excelupload", "view")}

                tableData={generalStore?.getTableComponentListData?.data?.resource ?
                    {
                        data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
                        totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                        currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                        maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                        getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                    } :
                    {
                        data: [],
                        totalItems: 0,
                        currentPage: 1,
                        maxPage: 1
                    }
                }

                tableHeadersPayload={tableHeadersMap}
                tableHeadersMap={tableHeadersMap}
                availableStores={["generalStore"]}
            />
        </>
    )
}

export default ExcelUploadLogs