import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionStorageItems, setLocalStorageItems } from '../../../authComponents/login/LoginApiResponse';
import FullPageLoader from '../../../loaders/FullPageLoader';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parseJwt } from '../../../helper/genericFunction';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { deleteDB } from 'idb';
import { useContext } from 'react';
import { UserDetailsContext } from '../../../App';

const SwitchEntResponseHandler = (props) => {
    const tokenData = parseJwt(sessionStorage.getItem("token") || "") || {};
    const [loading, setLoading] = useState(false)
    const authStore = useSelector(state => state.authStore);
    const location = useLocation()
    const history = useHistory();
    const dispatch = useDispatch();
    const userDetailsContext = useContext(UserDetailsContext)

    useEffect(() => {
        if (authStore?.["updateOrgIdData"]?.isSuccess) {
            let data = authStore?.["updateOrgIdData"]?.data || {};
            dispatchHandler(dispatch, "switchEntRequest", {
                "orgId": data?.orgId || "",
                "entId": data?.entId || "",
                "uCode": data?.ucode ,
                "token": sessionStorage?.getItem("token") || ""
            })
        }
    }, [authStore?.["updateOrgIdData"]?.isSuccess])

    useEffect(() => {
        if(authStore?.["switchEntData"]?.isLoading){ setLoading(true);}

        if(authStore?.["switchEntData"]?.isError){ setLoading(false);}
        
        if (authStore?.["switchEntData"]?.isSuccess) {
            let data = authStore?.["switchEntData"]?.data?.resource || {};
            setTimeout(() => {
                setData(data);
            }, 10)
        }
    }, [authStore?.["switchEntData"]])

    const setData = async (data) => {
        await deleteDB("Supplymint-ai-local-storage");
        await setSessionStorageItems(data, userDetailsContext);
        // sessionStorage.setItem("switchOrgResponse", JSON.stringify(data));
        // userDetailsContext.setUserDetails(prev => {
        //     prev["switchOrgResponse"] = JSON.stringify(data);
        //     return prev;
        // }) 
        handleReRouting(data);
    }

    // Function : To reroute the data on organisation change
    const handleReRouting = (data) => {
        let currentPath = location.pathname;
        let dashboardPaths = data?.dashboardModules?.map(item => item?.pageUrl);

        console.log("Current path: " + currentPath?.includes("/home"));

        let newPath = dashboardPaths?.[0];
        history.replace(newPath ? newPath?.replace("#", "") : "/home");
        setTimeout(() => {
            window.location?.reload();
        }, 100)
        // See if the current path is present inside the current modules.
        // if (currentPath?.includes("/home")) {
        //     // Just reload the screen if the user is currently is on the dashboard
        //         setTimeout(() => {
        //             window.location?.reload();
        //         }, 100)
        // } else {
        //     // Get first url of the dashboard
            
        //     dispatchHandler(dispatch, "getCurrrentUserEntDetailsRequest", {
        //         flowType: "ENT_DETAILS",
        //         token: sessionStorage.getItem("token"),
        //     })
        //     dispatchHandler(dispatch, "getCurrrentUserEntModulesRequest", {
        //         flowType: "MODULES",
        //         token: sessionStorage.getItem("token"),
        //     })

        //     let newPath = dashboardPaths?.[0];
        //     history.replace(newPath ? newPath?.replace("#", "") : "/home");
        //     setLoading(false);
        // }
    }


    return (
        <React.Fragment>
            {/* Set Loading to true and show the full page loader */}
            {loading && <div style={{ position: "absolute", width: "100vw", height: "100vh", position: "fixed", zIndex: 999}}>
                {/* Render the full page loader for the switch organisation */}
                <FullPageLoader type = "switchOrg"/>
            </div>}
        </React.Fragment>
    )
}

export default SwitchEntResponseHandler