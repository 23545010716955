import React, { useEffect, useState } from 'react'
import { BarChart, ComposedChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import GenericInput from '../../../genericComponents/input/GenericInputComponent';
import Icons from '../../../assets/icons';
import dayjs from 'dayjs';
import { formatNumber } from '../../../helper/genericFunction';
import { Factory } from 'lucide-react';
import SkeletonLoader from './dashboardComponents/SkeletonLoader';
import { getPermissionKey } from '../../../helper/permissions';

function ProcDashBottomSection(props) {
    const { filters } = props;
    const dispatch = useDispatch();
    const digiProcStore = useSelector(state => state.digiProcStore);
    const [currentTab, setCurrentTab] = useState("vendors");
    const [performanceAnalysisData, setPerformanceAnalysisData] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ displayName: "Top 5", value: 5 })
    const [graphData, setGraphData] = useState([]);
    const [graphDataRange, setGraphDataRange] = useState(null)

    useEffect(() => {
        dispatchHandler(dispatch, "getPiPoCreationStatsRequest", { filter: {}, displayName: "PI_PO_STAT_DATE_WISE" }, getPermissionKey("dashboard", "procurement", "view"));
    }, []);

    useEffect(() => {
        let payload = {
            displayName: currentTab == "vendors" ? "TOP_VENDORS_DATA" : "TOP_ITEMS_DATA",
            filter: filters,
            pageSize: selectedItem?.value,
        }
        dispatchHandler(dispatch, "getPerformanceAnalysisRequest", payload, getPermissionKey("dashboard", "procurement", "view"));
    }, [currentTab, filters, selectedItem?.value]);

    const handleChange = (type, val) => {
        switch (type) {
            case "tab":
                setCurrentTab(val?.target?.value)
                break;
            case "itemCount":
                setSelectedItem(val)
                break;
            case "statisticsFilter":
                setGraphDataRange(val)
                dispatchHandler(dispatch, "getPiPoCreationStatsRequest", { filter: { creationTime: { from: val?.[0]?.$d ? dayjs(val?.[0]?.$d)?.format("YYYY-MM-DD") : undefined, to: val?.[1]?.$d ? dayjs(val?.[1]?.$d)?.format("YYYY-MM-DD") : undefined } }, displayName: "PI_PO_STAT_DATE_WISE" }, getPermissionKey("dashboard", "procurement", "view"));
                break;
        }
    }

    // Function to convert the date to the desired format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
    }

    // Merging the data
    const mergeData = (data, poData) => {
        const mergedData = [];

        // Process data
        data.forEach(item => {
            let obj = {};
            obj = {
                "name": formatDate(item?.date),
                "Indents Created": item?.pi_created_count || 0,
                "Indents Approved": item?.approved_pi_count || 0,
                "Orders Created": item?.created_count || 0,
                "Orders Approved": item?.approved_count || 0,
                // "GRC Completed": item?.grc || 0
            }
            mergedData.push(obj)
        });

        return mergedData;
    }

    useEffect(() => {
        if (digiProcStore?.getPiPoCreationStatsData?.isSuccess) {
            let data = digiProcStore?.getPiPoCreationStatsData?.data;
            const result = mergeData(data);
            setGraphData(result);
        }
        if (digiProcStore?.getPerformanceAnalysisData?.isSuccess) {
            setPerformanceAnalysisData(digiProcStore?.getPerformanceAnalysisData?.data)
        }
    }, [digiProcStore?.getPiPoCreationStatsData?.isSuccess, digiProcStore?.getPerformanceAnalysisData?.isSuccess]);

    const performanceAnalysisObj = {
        vendors: {
            slname: "Supplier Name",
            slcode: "Supplier Code",
            // total_count: "Order Count",
            po_qty: "Total Qty",
            po_amount: "Total Amount",
        },
        items: {
            hl1name: "Division",
            // total_count: "Order Count",
            po_qty: "Total Qty",
            po_amount: "Total Amount"
        }
    }

    const provideProps = (key) => {
        switch (key) {
            case "itemCount": return ({
                getListData: () => ([{ displayName: "Top 5", value: 5 }, { displayName: "Top 10", value: 10 }, { displayName: "Top 15", value: 15 }, { displayName: "Top 20", value: 20 }]),
                headerKeys: {
                    "displayName": {
                        value: "Name",
                    }
                },
                itemKeys: {
                    "displayName": ["displayName"]
                },
            })
            case "statisticsFilter": return ({
                hideDefaultRanges: true
            })
        }
    }

    const renderLoadingSkeleton = () => {
        return <SkeletonLoader />
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="px-3 py-2 bg-[#0F172A] text-xs space-y-2    "
                    style={{
                        fontFamily: 'poppins',
                    }}
                >
                    {/* <div className='flex items-center gap-2'>
                    <div></div>
                    <p className="font-bold">{`Category: ${label}`}</p>
                </div> */}
                    {payload.map((item, index) => (
                        <div className='flex items-center gap-2.5 justify-between w-[200px] font-semibold'>
                            <div className='flex items-center gap-2'>
                                <div className='h-[10px] w-[10px]' style={{ backgroundColor: item?.color }}></div>
                                <p key={index} className='mb-0 text-[#979CA7]'>{item.name}</p>
                            </div>
                            <p className='mb-0 text-white'>{item.value}</p>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf("day");
    };

    const renderChartSkeletonLoader = ()=>{
        return <div className='px-7 mt-[3rem] h-[300px] w-full'><div className='px-8 h-full flex items-end gap-5 justify-between border-l border-b'>
            <div className='generic-skeleton !w-[30px] !h-[250px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[100px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[200px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[250px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[100px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[200px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[30px] !h-[250px] !rounded-none !mb-0'></div>
        </div></div>
    }


    return (
        <div className='grid grid-cols-[3fr_1fr] h-full gap-5 prodDashboardBottomSection'>
            <div className='bg-white px-5 py-3'
                style={{ boxShadow: "0px 14px 25px 1px #E1E1E7" }}
            >
                <div>
                    <div className='mb-3 flex items-center justify-between gap-6'>
                        <h3 className='text-[15px] font-semibold mt-[2px]'>Indents & Orders Creation Statistics</h3>
                        <GenericInput
                            key="statisticsFilter"
                            type="range"
                            value={graphDataRange}
                            onChange={(data) => handleChange("statisticsFilter", data)}
                            inputProps={() => provideProps("statisticsFilter")}
                            genInputExtraClass="!w-[220px] !rounded-none"
                            hideDefaultRanges={false}
                            disabledDate={(val) => disabledDate(val)}
                            dependentFieldsObject={[graphDataRange]}
                        />
                    </div>
                    <div className='flex gap-4 items-center text-xs font-medium my-4'>
                        <div className='flex items-center gap-1.5'>
                            <div className='h-2.5 w-2.5 bg-[#FFB142]'></div>
                            <span>Indents Created</span>
                        </div>
                        <div className='flex items-center gap-1.5'>
                            <div className='h-2.5 w-2.5 bg-[#FF793F]'></div>
                            <span>Indents Approved</span>
                        </div>
                        <div className='flex items-center gap-1.5'>
                            <div className='h-2.5 w-2.5 bg-[#34ACE0]'></div>
                            <span>Order Created</span>
                        </div>
                        <div className='flex items-center gap-1.5'>
                            <div className='h-2.5 w-2.5 bg-[#218C74]'></div>
                            <span>Orders Approved</span>
                        </div>
                        {/* <div className='flex items-center gap-1.5'>
                            <div className='h-2.5 w-2.5 bg-[#474787]'></div>
                            <span>GRC Completed</span>
                        </div> */}
                    </div>
                    {/* <p className='text-[#7C7D7E] text-xs mb-5'>This bar graph helps you identify the statistics for indent and order creation, including the quantities and amounts of the orders for comparision.</p> */}
                </div>
                {digiProcStore?.getPiPoCreationStatsData?.isLoading ? renderChartSkeletonLoader() : 
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                        width={500}
                        height={200}
                        data={graphData}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                        barSize={33}
                    >
                        <CartesianGrid strokeDasharray={null} vertical={false} />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="Indents Created" stackId="a" width={15} fill="#FFB142" isAnimationActive={false} />
                        <Bar dataKey="Indents Approved" stackId="a" width={15} fill="#FF793F" isAnimationActive={false} />
                        <Bar dataKey="Orders Created" stackId="a" width={15} fill="#34ACE0" isAnimationActive={false} />
                        <Bar dataKey="Orders Approved" stackId="a" width={15} fill="#218C74" isAnimationActive={false} />
                        {/* <Bar dataKey="GRC Completed" stackId="a" width={15} fill="#474787" isAnimationActive={false}/> */}
                    </BarChart>
                </ResponsiveContainer>}
            </div>

            <div className="bg-white p-[12px] procDashboardBottom"
                style={{ boxShadow: "0px 14px 25px 1px #E1E1E7" }}
            >
                <div className='flex justify-between gap-2 items-start mb-3'>
                    <h3 className='text-[15px] font-semibold mb-0 mt-[6px]'>Performance Analysis</h3>
                    <GenericInput
                        type="dropdown"
                        value={selectedItem?.displayName}
                        onChange={(data) => handleChange("itemCount", data)}
                        inputProps={() => provideProps("itemCount")}
                        iconRight={<Icons.DownArrowNew />}
                        genInputExtraClass="!w-[80px] !rounded-none"
                    />
                </div>
                <p className='text-[#7C7D7E] text-xs mb-3'>Showing the top-performing vendors and items up to date</p>
                <Radio.Group className='flex w-full text-xs text-center mb-3 whitespace-nowrap border-2 border-[#ECEEEF]' value={currentTab} onChange={(val) => handleChange("tab", val)}>
                    <Radio.Button className='flex items-center justify-center w-full text-xs border-none' value={"vendors"}>Vendors</Radio.Button>
                    <Radio.Button className='flex items-center justify-center w-full text-xs border-none' value={"items"}>Items</Radio.Button>
                </Radio.Group>

                {digiProcStore?.getPerformanceAnalysisData?.isLoading ? renderLoadingSkeleton() : <div className='max-h-[300px] pt-[8px] overflow-y-auto'>
                    {performanceAnalysisData?.map((item) =>
                        <div className='flex items-start gap-1.5 border-b border-[#E5E6EB] last:border-none'>
                            {Object.keys(performanceAnalysisObj?.[currentTab] || {}).includes('slname') && <div className='mt-[32px]'><Factory size={13} /></div>}
                            <div className={`py-2 flex-1 grid grid-cols-3 gap-x-2 ${Object.keys(performanceAnalysisObj?.[currentTab] || {}).includes('slname') ? 'second-tooltip-right' : 'total-amount-val'}`}>
                                {Object.keys(performanceAnalysisObj?.[currentTab] || {})?.map((key, index) =>
                                    <div className={`performanceAnalysisItem ${key === 'slname' ? 'col-span-2' : ''}`}>
                                        <label className='text-[#8496A9] text-[9px] mb-0'>{performanceAnalysisObj?.[currentTab]?.[key]}</label>
                                        <div className='group relative'>
                                            <p className={`mb-0 ${key === 'slname' ? 'text-[13px] font-medium' : 'text-xs'}`}>{["slcode", "slname", "hl1name"]?.some(k => key == k) ? item?.[key] || "" :  formatNumber(item?.[key])?.short}</p>
                                            {!["slcode", "slname", "hl1name"]?.some(k => key == k) && formatNumber(item?.[key])?.full && <span className='generic-tooltip !left-[18px] group-hover:visible group-hover:opacity-100'>{formatNumber(item?.[key])?.full}</span>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>}
            </div>
        </div>
    )
}

export default ProcDashBottomSection