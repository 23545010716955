import React, { useEffect, useState } from 'react'
import CustomSearch from './CustomSearch'
import GenericSpinnerLoader from '../../../loaders/GenericSpinnerLoader';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiChange } from '../../../helper/apiHelperFunctions';
import { getPermissionKey } from '../../../helper/permissions';
import ParentModal from '../../ParentModal';
const CustomSearchPopover = (props) => {
    const [itemFiltersLoading, setItemFiltersLoading] = useState(true);
    const getItemFilterForDisplayNameData = useSelector(state => state?.generalStore?.getItemFilterForDisplayNameData);
    const [headersData, setHeadersData] = useState(null);
    const [selectedModal, setSelectedModal] = useState('itemFiltersLoading');
    
    const dispatch = useDispatch();
    useEffect(() => {
        handleApiChange(dispatch, "getItemFilterForDisplayNameRequest", {
            displayName : props.mainDisplayName,
        }, getPermissionKey("common", "commonfunctionality", "view"))
    },[])
    
    useEffect(() => {
        if(getItemFilterForDisplayNameData?.isSuccess){
            setItemFiltersLoading(false);
            setSelectedModal(false)
            if(Object.keys(getItemFilterForDisplayNameData?.data?.["resource"]?.["Default Headers"] || {})?.length > 0 && props.mainDisplayName === getItemFilterForDisplayNameData?.data?.displayName){
                setHeadersData(getItemFilterForDisplayNameData?.data?.["resource"]);
            }
        }
        if(getItemFilterForDisplayNameData?.isError){
            setItemFiltersLoading(false);
            setSelectedModal(false)
        }
    },[getItemFilterForDisplayNameData])
    const getItemFiltersLoadingComponent = ()=>{
        return <div className='flex items-center justify-center h-full w-full'>
                    <GenericSpinnerLoader/>
                </div>
    }
    const getParentChildProps = () => {
		switch (selectedModal) {
			case "itemFiltersLoading": {
				return {
					animationType: "",
					closeModal: () => { setSelectedModal(false) },
					postitionProps: { top: "50%", left: "50%", transform: "translate(-50%, -50%)", position: "fixed", borderRadius: '0' },
					dimensions: { width: "80vw", height: "70vh" },
				}
			}
			default:
		}
	}
	const getChildComponent = () => {
		switch (selectedModal) {
			case "itemFiltersLoading": return getItemFiltersLoadingComponent;
			default:
		}
	}
    if(itemFiltersLoading){
        return <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
            <GenericSpinnerLoader/>
        </div>
    }
    if(headersData == null || !props.headersData?.["item"]){
        return (
            <>
            <CustomSearch
                {...props}
            />
            {selectedModal && <ParentModal
						getParentChildProps={getParentChildProps()}
						childComponent={getChildComponent()}
						closeModal={() => { setSelectedModal(false) }}
					/>}
            </>
        )
    }
    else{
        return (
            <>
            <CustomSearch
                {...props}
                headersData = {{
                    ...props.headersData,
                    "item" : {
                        ...props?.headersData?.["item"],
                        "data" : headersData,
                    },
                }}
            />
            {selectedModal && <ParentModal
						getParentChildProps={getParentChildProps()}
						childComponent={getChildComponent()}
						closeModal={() => { setSelectedModal(false) }}
					/>}
            </>
        )
    }
}
export default CustomSearchPopover