import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { DatePicker, TimePicker, Slider } from "antd";
import ListComponent from "../ListComponent";
import CustomPopover from '../../hooks/CustomPopOver'
import { validatePincode } from "../../helper/helperTableFunctions";
import { validateMobileNumberWithoutCode, validateNumber } from "../../helper/apiHelperFunctions";
import { dispatchHandler } from "../../helper/apiHelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import GenericUploadFileComponent from "./GenericUploadFileComponent";
import GenericClearFunction from "./GenericClearComponent";
import IconsPath from '../../assets/icons/index'
import { validateMobileNumber, validateEmail } from "../../helper/apiHelperFunctions";
import useCustomToast from "../../hooks/useCustomToast";
import Icons from "../../assets/icons/index";
import dayjs from "dayjs";
import LanguageTranslator from "../../locals/LanguageTranslator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "../../helper/countryCodes.json";
import UseDatePickerLocale from "./LocaleInputDatePicker";
import { convertToChinese } from "../../locals/convertToChinese";

const { RangePicker } = DatePicker;

const GenericInput = (props) => {
	let {
		type = "text",
		inputKey,
		label,
		labelRightComponent,
		mandatory,
		placeholder,
		value,
		onChange,
		onBlur,
		onFocus,
		errorData,
		setErrorData,
		// Function for the validation of the data .
		validationFunction,
		additionalElement,
		iconLeft,
		iconRight,
		inputProps = () => {
			return {};
		},
		customstyles = {},
		isDisabled,
		dependentFieldsObject,
		// Function to render the custom input field.
		renderCutomInput,
		// Function to render the top & bottom component for the input field.
		inputComponents,
		genInputExtraClass,
		gibInnerClassName,
		isUploadIconRequire,
		actualInputValue, // When we will pass the custom Input value then we need the actual key value to check for errors.
		inputExtraClass,
		isCustomDetailsIcons,
		inputDropdownExtraClass = "",
		minimumValue = "",
		callApiToGetImages,
		customeDropdownFields,
		hideInputTooltip = false,
		showTooltipLenght = 18,
		inputClassName = '',
		clearBtnClassName='',
		genericTooltipClassName='',
		isCaptalizedFirstLetter = false,
		...rest
	} = props;

	let inputPropsData = inputProps();
	const customToast = useCustomToast();

	const [triggerOnBlurFunction, setTriggerOnBlurFunction] = useState("");

	const dispatch = useDispatch();

	const currentRef = useRef(null);

	let availableDocument = props.availableDocument || document;

	const isDisabledValue = typeof isDisabled === "function" ? isDisabled() : isDisabled;
	const [selectedModal, setSelectedModal] = useState(false);
	const [apiCalled, setApiCalled] = useState(false);

	// State to store the currrent slider input vqalue
	const [sliderInputValue, setSliderInputValue] = useState(value ? [value?.from, value?.to] : []);
	const [updateSliderParentValue, setUpdateSliderParentValue] = useState(false);
	const [focus, setFocus] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState({})

	const updatedLocale = UseDatePickerLocale()

	useEffect(() => {
		let valLength = typeof (value) !== "function" ? value?.length : typeof (value()) == "string" ? value()?.length : 0;
		if (hideInputTooltip !== true && type !== "textarea" && valLength > showTooltipLenght) {
			setShowTooltip(true); // Display tooltip if text overflows
		} else {
			setShowTooltip(false); // Hide tooltip if text fits within input field
		}
	}, [value])

	useEffect(() => {
		if(type == "mobileNo" && value && (typeof value == "function" ? !value()?.includes("+") : !value?.includes("+"))) {
			setTimeout(() => {
				handleChange({val: `91${typeof value == "function" ? value() : value}`, country: { dialCode: "91" }})
			}, 0);
		}
	}, [])

	useEffect(() => {
		if (typeof (triggerOnBlurFunction) == "boolean") {
			handleBlur();
		}
	}, [triggerOnBlurFunction]);

	useEffect(() => {
		if (updateSliderParentValue) {
			handleChange(sliderInputValue);
			setUpdateSliderParentValue(false)
		}
	}, [updateSliderParentValue])

	useLayoutEffect(() => {
		const handleOutsideClick = (e) => {
			let path = e.path || (e.composedPath && e.composedPath())

			// Note : Please do not use mdlr-l -> this class anywhere else!
			const shouldNotClose = e.target.classList.contains('iugm-remove-file-btn')
			if (!currentRef?.current?.contains(e.target) && (type == "upload" ? !shouldNotClose : !path?.some(item => item?.id === "list_component_main_li"))) {
				setSelectedModal(false);
				availableDocument && availableDocument.removeEventListener("click", handleOutsideClick);
			}
		};

		if (selectedModal) {
			availableDocument && availableDocument.addEventListener("click", handleOutsideClick);
		} else {
			availableDocument && availableDocument.removeEventListener("click", handleOutsideClick);
		}

		return () => {
			// Cleanup when the component unmounts
			availableDocument && availableDocument.removeEventListener("click", handleOutsideClick);
		};
	}, [selectedModal, inputKey, props.inputIndex, availableDocument]);



	// Api state in case user gets the input data from an api.
	let apiResponseData = useSelector(
		(state) => state?.[inputPropsData?.api?.reduxState]
	);

	useEffect(() => {
		if (apiResponseData?.[`${inputPropsData?.api?.apiName}Data`]?.isSuccess && apiCalled) {
			// handle the upload file case
			setApiCalled(false)
			if (type === "upload") {
				!inputPropsData?.multipleFileUpload && handleInputChange("setUploadedFilesResponse", inputPropsData?.getListData?.(
					apiResponseData[`${inputPropsData?.api?.apiName}Data`]?.data
				))
				if (props.inputRef?.current) {
					props.inputRef.current.value = ""
				}
			}
		}
	}, [apiResponseData, inputPropsData, onChange, type]);

	const validateFiles = (files) => {
		if (files) {
			const validFiles = [...files]?.filter((file) => {
				const isSizeWithinLimit =(file.size / (1024 * 1024)) < (inputPropsData?.maxSize || 5)
				return isSizeWithinLimit;
			});

			return validFiles;
		}
		return null;
	};

	const capitalizeWords = (textVal) => {
		return textVal
		?.split(' ')    // Split the string into an array of words based on spaces
		?.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word's first letter
		?.join(' ');    // Join the words back into a single string
	}

	const handleChange = (newValue) => {
		let triggerOnBlur = true;
		switch (type) {
			case "upload": {
				if (inputPropsData?.multipleFileUpload) {
					// Open File Upload modal if user wants to upload multiple files.
					triggerOnBlur = false;
				} else {
					if (inputPropsData?.api) {
						if (newValue) {
							setApiCalled(true);
							let uploadFilesPayload = inputPropsData?.api?.apiPayload(newValue?.target?.files);
							dispatchHandler(
								dispatch,
								`${inputPropsData?.api?.apiName}Request`,
								uploadFilesPayload, inputPropsData.api?.apiExtraHeaders || {}
							);
						}
					} else {
						let files = newValue?.target?.files;
						if (inputPropsData?.maxSize) {
							files = validateFiles(files);
						}
						onChange && onChange({ "target": { "files": files } });
						if (files != null && files?.length == 0 && newValue?.target?.files?.length != 0) {
							customToast({
								title: `Either the file size is greater than ${inputPropsData?.maxSize || 5}MB or the file format is not supported!`,
								status: "error",
							});
						}
					}
				}
				break;
			}
			case "dropdown": case "customeDropdown": {
				triggerOnBlur = false;
				setSelectedModal(selectedModal === "listComponent" ? false : "listComponent");
				break;
			}
			case "setDropdownListValue": {
				onChange && onChange(newValue);
				break;
			}
			case "sliderInput": {
				onChange && onChange(newValue);
				break;
			}
			case "number":
			case "decimal":
			case "password":
			case "pincode":
			case "email":
			case "mobileNoWithoutCode":
			// case "monthYear":
			case "text": {
				triggerOnBlur = false;
				if (isCaptalizedFirstLetter && type === "text") {
					const inputElement = newValue?.target;
					
					// Store the cursor position
					const cursorPosition = inputElement?.selectionStart || 0;
			
					// Capitalize words
					const updatedText = capitalizeWords(inputElement?.value || "");
			
					// Restore the cursor position
					setTimeout(() => {
						if (inputElement) {
							inputElement.selectionStart = cursorPosition;
							inputElement.selectionEnd = cursorPosition;
						}
					}, 0);
			
					onChange?.({ 
						...newValue, 
						target: { ...inputElement, value: updatedText } 
					});
				} else {
					onChange?.(newValue);
				}			
				break;
			}
			case "date":
			case "range":
			case "monthYear":
			case "time": {
				triggerOnBlur = true;
				onChange && onChange(newValue);
				break;
			}
			case "mobileNo": {
				triggerOnBlur = false;
				onChange && onChange({target: {value: `+${newValue?.val}`}});
				setSelectedCountry(newValue?.country || {})
				break;
			}
			default: {
				triggerOnBlur = false;
				onChange && onChange(newValue);
				break;
			}
		}
		// Function to trigger onBlur Functionality in case of dropdowns and list.
		if (triggerOnBlur) {
			setTriggerOnBlurFunction(triggerOnBlurFunction === "" ? true : !triggerOnBlurFunction)
		}
	};

	const handleInputChange = (type, newValue) => {
		let triggerOnBlur = true;
		switch (type) {
			case "setDropdownListValue": {
				onChange && onChange(newValue);
				break;
			}
			case "setUploadedFilesResponse": {
				onChange && onChange(newValue);
				break;
			}
			default:
		}

		// Function to trigger onBlur Functionality in case of dropdowns and list.
		if (triggerOnBlur) {
			setTriggerOnBlurFunction(triggerOnBlurFunction === "" ? true : !triggerOnBlurFunction)
		}
	}

	const getCountryByDialCode = (dialCode) => {
		return countries.find((country) => country?.dial_code === dialCode);
	}
	
	useEffect(() => {
		if(selectedCountry.dialCode) {
			handleBlur(); // Run validation when the country changes
		}
	}, [selectedCountry.dialCode]);

	const handleBlur = (e) => {
		// Handle the input field is focused or not
		setFocus(false);

		if (!errorData) errorData = {};

		// Errors for the current item;
		let inputErrorData = []

		let inputValue = e === undefined ? typeof (value) == "function" ? actualInputValue : value : e.target.value;
		let avoidMandatoryCheck = props?.avoidMandatoryCheck ? typeof (props?.avoidMandatoryCheck) === "function" ? props?.avoidMandatoryCheck() : props.avoidMandatoryCheck : false;

		// Validate number format
		if (type === "number" || type === "decimal") {
			const numberValidation = validateNumber(inputValue, type, type, inputPropsData?.decimalPoint) || [];
			inputErrorData = [...inputErrorData, ...numberValidation];
		} else if (type === "email") {
			let isEmailValid = validateEmail(inputValue)
			!isEmailValid && inputValue && inputErrorData.push(`Please provide a valid email inside ${label} input field.`);
		} 
		else if (type === "mobileNo") {
			const countryData = getCountryByDialCode(selectedCountry.dialCode);
			if (countryData) {
				let valueData = typeof value == "function" ? value() : value;
				const localNumber = valueData?.replace(`+${selectedCountry?.dialCode}`, "");
				// Check if the local number matches the expected length
				if(localNumber?.length != countryData?.mobile_number_length) {
					valueData && inputErrorData.push(`Please provide a valid mobile number inside ${typeof label == "function" ? "" : label} input field.`);
				}
			}
		} 
		else if (type === "mobileNoWithoutCode") {
			let isMobileNumberValid = validateMobileNumberWithoutCode(inputValue)
			!isMobileNumberValid && inputValue && inputErrorData.push(`Please provide a valid mobile number inside ${label} input field.`);
		}
		else if (type === "pincode") {
			let isPincodeValid = validatePincode(inputValue);
			!isPincodeValid && inputValue && inputErrorData.push(`Please provide a valid pincode inside ${label} input field.`);
		}

		if (!avoidMandatoryCheck && (typeof (mandatory) === "function" ? mandatory() : mandatory)) {
			// Check for the mandatory condition
			if (Array.isArray(inputValue) ? inputValue?.length <= 0 : typeof (inputValue || "") == "object" ? Object.keys(inputValue)?.length <= 0 : !inputValue) {
				inputErrorData = [...inputErrorData, `${props.errorLabel ? props.errorLabel : typeof (label) == "function" ? label() : label || "This"} is a mandatory field, please fill valid value inside this field.`];
			}
		}

		if (validationFunction) {
			const validation = validationFunction(inputValue) || [];
			if (validation)
				inputErrorData = [...inputErrorData, ...validation];
		}

		// If any error is present inside the input field
		if (inputErrorData?.length > 0) {
			setErrorData?.({ ...errorData, [inputKey]: inputErrorData });
		} else if (errorData?.[inputKey]?.length > 0) {
			delete errorData[inputKey];
			setErrorData?.({ ...errorData });
		};

		// Call for the onBlur function inside the parent component
		onBlur && setTimeout(() => {
			onBlur && onBlur(e);
		}, 0)
	};

	const handleFocus = async (e) => {
		onFocus && onFocus(e);
		setFocus(true);
	};

    // Function  : To check if the date range picker format is available or not
	const renderInputComponent = () => {
		let Component;
		switch (type) {
			case "textarea": {
				Component = <React.Fragment>
					<textarea
						placeholder={
							typeof placeholder == "function"
								? placeholder()
								: placeholder
						}
						autoComplete="off"
						value={value}
						onChange={(val) => handleChange(val)}
						onBlur={(e) => handleBlur(e)}
						onFocus={handleFocus}
						disabled={isDisabledValue}
						{...rest}
					/>
				</React.Fragment>
				break
			}
			case "static": {
				Component = <React.Fragment>
					{value ? typeof (value) == "function" ? value() : value : "-"}
				</React.Fragment>
				break;
			}
			case "text":
			case "number":
			case "decimal":
			case "password":
			case "email":
			case "pincode":
			case "mobileNoWithoutCode":
				Component = <React.Fragment>
					<input
						type={["password", "number"]?.includes(type) ? type : ["mobileNoWithoutCode", "decimal", "pincode"]?.includes(type) ? "number" : "text"}
						placeholder={
							typeof placeholder == "function"
								? placeholder()
								: placeholder
						}
						autoComplete="off"
						value={value ? typeof (value) == "function" ? value() : value : ""}
						onChange={(val) => handleChange(val)}
						onBlur={(e) => handleBlur(e)}
						onFocus={handleFocus}
						disabled={isDisabledValue}
						className={inputClassName}
						{...rest}
					/>
				</React.Fragment>
				break;
			case "dropdown": {
				Component = <input
					className={inputDropdownExtraClass}
					autoComplete="off"
					readOnly={true}
					onClick={() => { !isDisabledValue && handleChange() }}
					onKeyDown={(e) => { !isDisabledValue && (!e.shiftKey && e.key == "Enter" && handleChange()) }}
					style={{ ...customstyles, cursor: "pointer" }}
					contentEditable={false}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					value={value ? typeof (value) == "function" ? value() : value : ""}
					placeholder={typeof (placeholder) == "function" ? placeholder() : placeholder || ""}
				/>
				break;
			}
			case "customeDropdown": {
				Component = <div className="custome-dropdown-input" tabIndex={0}
					onClick={() => { !isDisabledValue && handleChange() }}
					onKeyDown={(e) => { !isDisabledValue && (!e.shiftKey && e.key == "Enter" && handleChange()) }}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}>
					{customeDropdownFields ? typeof (customeDropdownFields) == "function" ? customeDropdownFields() : customeDropdownFields : null}
				</div>
				break;
			}
			case "date":
				// TODO: Provide Error Data inside these components
				Component = <>
					<DatePicker
						locale={updatedLocale}
						placeholder={placeholder || ""}
						disabled={isDisabledValue}
						value={value}
						inputReadOnly={true}
						allowClear={false}
						onChange={(val) => handleChange(val)}
						{...rest}
					/>
				</>
				break;

			case 'monthYear':
				Component = <>
					<DatePicker
						locale={ updatedLocale }
						picker="month"
						inputReadOnly={props.readOnly || true}
						placeholder={placeholder || "Select Month Year"}
						value={value}
						onChange={(val) => handleChange(val)}
						allowClear={false}
						disabled={isDisabledValue}
						{...rest}
						format =  {inputPropsData?.format || 'YYYY-MMM'}
					/>
				</>
				break
			case "range":
				// TODO: Provide Error Data inside these components
				Component = <>
					<RangePicker
						locale={updatedLocale}
						placeholder={placeholder || ["Start Date", "End Date"]}
						disabled={isDisabledValue}
						inputReadOnly={true}
						value={typeof (value) == "function" ? value() : value}
						onChange={(val) => handleChange(val)}
						allowClear={false}
						picker={inputPropsData?.pickertype ?? 'date'}
						id="rangePicker"
						// ranges={inputPropsData?.ranges || {}}
						format={inputPropsData?.format || 'YYYY-MM-DD'}
						{...rest}
						ranges={!inputPropsData?.hideDefaultRanges && {
							[convertToChinese("Today")]: [dayjs(), dayjs()],
							[convertToChinese('Yesterday')]: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
							[convertToChinese('Last 7 Days')]: [dayjs().subtract(7, "days"), dayjs().subtract(1, "day")],
							[convertToChinese('Last Week')]: [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')],
							[convertToChinese('This Month')]: [dayjs().startOf('month'), dayjs().endOf('month')],
							[convertToChinese('Last Month')]: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
							[convertToChinese('Last 90 Days')]: [dayjs().subtract(90, "day"), dayjs().subtract(0, "day")],
						}}
						{...rest}
					/>
				</>
				break;
			case "time":
				// TODO: Provide Error Data inside these components
				Component = <>
					<TimePicker
						inputReadOnly={props.readOnly || true}
						format={props.format || "HH:mm:ss"}
						placeholder={placeholder || "HH:MM:SS"}
						disabled={isDisabledValue}
						allowClear={false}
						value={value}
						onChange={(val) => handleChange(val)}
						{...rest}
					/>
				</>
				break;
			case "upload": {
				// let { multipleFileUpload} = inputPropsData;
				let inputValue = typeof (value) == "function" ? value() : value;
				Component = <React.Fragment>
					<label className="gib-upload">
						<div pointerEvents="none">
							{iconLeft && (
								<React.Fragment>
									{typeof (iconLeft) === "function"
										? iconLeft()
										: iconLeft}
								</React.Fragment>
							)}
						</div>
						<div pointerEvents="none">
							{iconRight && (
								<React.Fragment>
									{typeof (iconRight) === "function"
										? iconRight()
										: iconRight}
								</React.Fragment>
							)}
						</div>
						{inputPropsData?.multipleFileUpload ? (
							<React.Fragment>
								<button
									className="gibu-upload-btn"
									disabled={isDisabledValue}
									onFocus={() => setFocus(true)}
									onBlur={() => setFocus(false)}
									onClick={() => {
										if (callApiToGetImages) {
											callApiToGetImages()
										}
										setSelectedModal(selectedModal === "uploadFilesModal" ? false : "uploadFilesModal");
									}}
								>
									<Icons.ProcChooseFileIcon />
									<LanguageTranslator>{typeof (value) === "function" ? value() || "Attach File" : value || "Attach File"}</LanguageTranslator>
								</button>
							</React.Fragment>
						) : (
							<React.Fragment>
								<input
									autoComplete="off"
									key={inputKey}
									type={"file"}
									onFocus={() => setFocus(true)}
									onBlur={() => setFocus(false)}
									placeholder={
										typeof placeholder == "function"
											? placeholder()
											: placeholder
									}
									ref={props.inputRef || null}
									accept={inputPropsData?.acceptFiles || "*"}
									onChange={(val) => handleChange(val)}
									disabled={isDisabledValue}
									style={{
										padding: "10px",
										border: `1px solid ${errorData?.[inputKey] ? "red" : "#ccc"
											}`,
										borderRadius: "5px",
										paddingLeft: iconLeft ? "30px" : "10px",
										paddingRight: iconRight ? "30px" : "10px",
										display:"none",
										...customstyles,
									}}
									{...rest}
								/>
								{inputPropsData?.renderCustomPlaceholder ? inputPropsData?.renderCustomPlaceholder() : <span className={`gibu-text ${inputPropsData?.placeholderClass ?? ""}`}>Upload File</span>}
							</React.Fragment>
						)}
						{isUploadIconRequire && <IconsPath.UploadImg />}
						{inputPropsData?.multipleFileUpload ? null :
							<div className="flex justify-center">
								<span className={`uembri-uploaded-text ${(inputValue?.[0]?.name || inputValue?.[0]?.[inputPropsData?.fileKeys?.[0]]) ? '' : 'uembri-hide'}`}>{inputValue?.[0]?.name || inputValue?.[0]?.[inputPropsData?.fileKeys?.[0]]}
								</span>
							</div>}
					</label>
				</React.Fragment>
				break;
			}
			case "sliderInput": {
				let sliderInputList = inputPropsData?.getListData?.() || [];
				return <>
					{sliderInputList?.map((item, index) => {
						return <button disabled={typeof(isDisabled) == "function" ? isDisabled() || (value?.key === item?.key) : isDisabled || (value?.key === item?.key)} key={index?.toString()} type="button" className={`whitespace-nowrap ${value?.key === item?.key && "asmbmt-active"}`} onClick={() => { handleChange(item) }}>{item?.value}</button>
					})}
				</>
			}
			case "radioInput": {
				let radioInputList = inputPropsData?.getListData?.() || [];
				return <>
					{radioInputList?.map((item, index) => {
						return <button key={index?.toString()} type="button" className={`gibri-btn ${value?.key === item?.key && "asmbmt-active"}`} onClick={() => { handleChange(value?.key === item?.key ? null : item) }}>
							{value?.key === item?.key ? <IconsPath.SelectedItemIcon className="checkedIcon" /> : <IconsPath.DeselectedItemIcon />}
							{item?.value}
						</button>
					})}
				</>
				break;
			}
			case "numberRange": {
				Component = <Slider
					range={{
						draggableTrack: true,
					}}
					min={0}
					max={3000}
					value={sliderInputValue}
					onChange={(val) => setSliderInputValue(val)}
					onChangeComplete={() => setUpdateSliderParentValue(true)}
					disabled={isDisabledValue}
				/>
				break;
			}
			case "mobileNo":
				Component = <React.Fragment>
					<PhoneInput 
						inputProps={{
							id: "contactNo",
							className: {inputClassName},
							// placeholder: {placeholder}
							// placeholder={typeof placeholder == "function"
							// 		? placeholder()
							// 		: placeholder
							// }
						}}
						enableSearch={true}
						// disableSearchIcon={true}
						country={'in'}
						onChange={(val, country) => handleChange({val, country})}
						value={value ? typeof (value) == "function" ? value() : value : ""}
						disabled={isDisabledValue}
						onFocus={handleFocus}
						onBlur={(e) => handleBlur(e)}
						autoComplete="off"
						searchPlaceholder="Search Country"
						searchNotFound="No Country Found"
						{...rest}
					/>
				</React.Fragment>
				break;
			default:
				return null;
		}

		return Component;
	}

	const renderClearComponent = (rightComponent,) => {
		const provideValue = () => {
			if (actualInputValue) {
				if (Array.isArray(actualInputValue)?.length > 0) {
					return actualInputValue?.length > 0 ? actualInputValue : "";
				} else if (typeof (actualInputValue) == "object") {
					return Object.keys(actualInputValue || {})?.length > 0 ? actualInputValue : "";
				} else {
					return actualInputValue;
				}
			} else  if(type != "upload"){
				return value;
			}
		}
		return <GenericClearFunction
			dependentFieldsObject={{}}
			itemData={{ "key": inputKey, }}
			clearPressFunction={(data) => {
				switch (type) {
					case "dropdown": case "customeDropdown": {
						handleInputChange("setDropdownListValue", null)
						break;
					}
					case "upload": {
						if (inputPropsData?.multipleFileUpload) {
							handleInputChange("setUploadedFilesResponse", null)
						} else {
							if (props.inputRef?.current) {
								props.inputRef.current.value = ""
							}
							if (inputPropsData?.api) {
								handleInputChange("setUploadedFilesResponse", null)
							} else {
								handleChange(null);
							}
						}
					}
					default: {
						handleChange(null);
					}
				};
				if (props.clearPressFunction) {
					props?.clearPressFunction();
				}
			}}
			data={!(isDisabledValue) ? { [inputKey]: typeof (value) == "function" ? value() : provideValue() || "" } : {}}
			RightComponent={typeof (rightComponent) == "function" ? rightComponent() : rightComponent}
			clearBtnClassName={clearBtnClassName}
		/>
	}

	const renderInputModal = () => {
		switch (type) {
			case "dropdown": case "customeDropdown":
				return selectedModal == "listComponent" && <ListComponent
					{...inputPropsData}
					search={inputPropsData?.["search"] ? typeof (inputPropsData?.search) === "function" ? inputPropsData?.search : {
						isSearchIconRequire: true,
						...inputPropsData?.search,
					} : null}
					showSelectedDataCheck={true}
					multiselect={inputPropsData?.multiselect ? {
						submitHandler: (data) => {
							handleInputChange("setDropdownListValue", data);
						},
						selectedData: actualInputValue,
						...inputPropsData?.multiselect,
					} : false}
					inputId={`input${inputKey}${props.inputIndex ? props.inputIndex : 0}`}
					submitHandler={(data) => { handleInputChange("setDropdownListValue", data) }}
					closeModal={() => { setSelectedModal(false) }}
				/>
			case "upload": {
				{/* Render the upload file component */ }
				{/* Provide the input width here */ }
				return selectedModal == "uploadFilesModal" &&
					<GenericUploadFileComponent
						{...inputPropsData}
						currentUploadedFiles={actualInputValue || []}
						submitHandler={(data) => handleInputChange("setUploadedFilesResponse", data)}
						closeModal={() => { setSelectedModal(false) }}
						{...inputPropsData}
					/>
			}
			default:
		}
	}

	return (
		<div ref={currentRef} id="inputComponent" className={`generic-input-box ${genInputExtraClass}`}>
			{label && (typeof label === "function" ? label() : (
				<label tag="label" className="gib-label-heading">
					<LanguageTranslator>{label}</LanguageTranslator>
					{mandatory && (typeof (mandatory) == "function" ? mandatory() : mandatory) ? <span className="mandatory">*</span> : ""}
					{labelRightComponent && (typeof (labelRightComponent) === "function" ? labelRightComponent() : labelRightComponent)}
				</label>
			))}

			{/* Render the top component for the input field  */}
			{renderCutomInput ? renderCutomInput() : <div id={`input${inputKey}${props.inputIndex ? props.inputIndex : 0}`} className={`gib-inner ${gibInnerClassName} ${errorData?.[inputKey] && "error"} gib-${type} ${isDisabledValue && 'gen-input-disabled'} ${focus && "focusOn"}`}>
				{/* Render the left component for the input field */}
				<>{inputComponents?.["top"]?.()}


					{iconLeft && (
						<React.Fragment>
							{typeof (iconLeft) === "function"
								? iconLeft()
								: iconLeft}
						</React.Fragment>
					)}
				</>

				{/* Render the input field component */}
				{renderInputComponent()}

				{inputComponents?.["inputExtra"]?.()}
				{showTooltip && (<span className={`generic-tooltip ${genericTooltipClassName}`}>{value ? typeof (value) == "function" ? value() : value : ""}</span>)}

				{/* render the right component for the input field. */}
				<React.Fragment>

					{/* The Clear button to clear the data from the input field */}
					{(dependentFieldsObject?.length > 0 && (type !== "radioInput")) ? renderClearComponent(iconRight) : <div className="cursor-pointer">{iconRight}</div>}
					{/* The Error Management */}
					<CustomPopover
						infoMessage={errorData?.[inputKey]}
						triggerType="hover"
					/>

				</React.Fragment>
			</div>}

			{/* Render the bottom component for the input field  */}
			{inputComponents?.["bottom"]?.()}

			{/* Render the modal in case of selected modal is on */}
			{renderInputModal()}
		</div>
	);
};

export default GenericInput;
