import React, { useRef, useState } from "react";
import ClearCache from 'react-clear-cache';
import Icons from '../../assets/icons/index'
import GenericCustomButton from "../../genericComponents/GenericCustomButton";
import { CircleFadingArrowUp, Minimize2, Move, ShieldBanIcon, X } from "lucide-react";
import Draggable from "react-draggable";
import DraggableNotification from "./DraggableNotification";
import {AnimatePresence, motion} from "framer-motion";

class CacheChecker extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            minimiseModal : false,
            isDragging: false,
            updatedWindowHeight: window.innerHeight
        }
    }

    updateWindowHeight = () => {
        this.setState({ updatedWindowHeight: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowHeight);
    }

    setIsDragging = (val) => {
        this.setState({
            isDragging : val
        })
    }

    handleChange = (type, data) => {
        switch(type){
            case "minimizeModal" : {
                this.setState({
                    minimiseModal : !this.state.minimiseModal,
                })
                break;
            }   
            default : {}
        }
    }

    render() {
        return (
            <div>
                <ClearCache duration={600000}>
                    {({ isLatestVersion, emptyCacheStorage, latestVersion }) => (
                        renderUpgradePopup({
                            isLatestVersion, emptyCacheStorage, latestVersion, minimiseModal : this.state.minimiseModal, 
                            handleChange : this.handleChange,
                            minimiseOptionAvailable : true,
                            isDragging: this.state.isDragging,
                            setIsDragging: this.setIsDragging,
                            updatedWindowHeight: this.state.updatedWindowHeight
                        })
                    )}
                </ClearCache>
            </div>
        );
    }
};

const renderUpgradePopup = ({ isLatestVersion, emptyCacheStorage, renderFromModal, latestVersion, minimiseOptionAvailable, minimiseModal, handleChange, isDragging, setIsDragging, updatedWindowHeight}) => {
    const position = React.createRef({ x: 200, y: 150 }); // Default bottom-right position

    const handleStop = (e, data) => {
      const container = e.target.parentElement.getBoundingClientRect();
      const newX = container.width - data.node.offsetWidth;
      const newY = container.height - data.node.offsetHeight;
      
      position.current = { x: newX, y: newY };
    };
    // if(!isLatestVersion){
    //     emptyCacheStorage(latestVersion);
    //     window.location.reload();
    //     return null;
    // }
    return <>
        {!isLatestVersion && (
            // minimiseModal ? <DraggableNotification onClick = {() =>  handleChange("minimizeModal")}/> : 
            <AnimatePresence mode="wait">
            {minimiseModal ?
                    <motion.div 
                            key='minimizeButton'
                            drag={minimiseModal ? "y" : false}
                            onDragStart={()=>setIsDragging(true)}
                            onDragEnd={() => setTimeout(() => setIsDragging(false), 100)}
                            whileDrag={{scale: 1.05}} 
                            whileHover={{scale: 1.05}}
                            dragElastic={0} 
                            dragTransition={{ power: 0, timeConstant: 0 }}
                            dragConstraints={{ top: -(updatedWindowHeight || window.innerHeight)+100, bottom: 30 }}
                            initial={{ scale: 0, opacity: 0 }}
                            animate={{ scale: 1 , opacity: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            transition={{ duration: 0.1 }}
                            disabled={isDragging}
                            onClick={(e)=>{
                                if(isDragging){
                                    e.stopPropagation();
                                    return;
                                }
                                handleChange("minimizeModal")
                            }}
                            className={`group fixed bottom-[40px] right-[40px] z-[999] cursor-pointer bg-white h-[50px] w-[50px] p-[4px] flex items-center justify-center rounded-full bg-gradient-to-b from-[#5D5FEFB2] to-[#6ABAA4]`}
                            style={{
                                boxShadow: '0px 0px 10px 0px #00000040'
                            }}
                        ><div className="relative h-full w-full flex items-center justify-center">
                            {/* <CircleFadingArrowUp size={25} color="white"/> */}
                            <Icons.Upgrade  />
                            <div className={`invisible group-hover:visible p-[2px] bg-white rounded-full absolute top-[-6px] right-[-6px]`}><Move size={14} color="#707494" /></div>
                        </div>
                        </motion.div> :
                <motion.div
                    key='PopupContent'
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1,  opacity: 1}}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    className={`min-w-[420px] origin-bottom-right notification-alert ${renderFromModal && "notification-pop-up"}`}
                >
                    <div className="na-top">
                        {renderFromModal && <h3>Update Software</h3>}
                        <div className="npu-icon">{renderFromModal ? <Icons.UpgradSoft /> : <Icons.GreenCircledGreenTick />}</div>
                        <div className="nat-det">
                            <h1>A New Version of Supplymint is Available </h1>
                            {/* Render the icon to minimise the upgrade popup */}
                            <p>We have resolve some important issues. Please update to the latest version by clicking on below upgrade now button.</p>
                            <div className="natd-btns">
                                <GenericCustomButton  
                                    type="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        emptyCacheStorage(latestVersion);
                                        window.location.reload();
                                    }}
                                    label="Upgrade Now"
                                />
                            </div>
                        </div>
                        <div>
                            {minimiseOptionAvailable && <GenericCustomButton
                                leftIcon={<X size={18}/>}
                                onClick = {() => {
                                    handleChange("minimizeModal")
                                }}
                                className={'ml-8 hover:text-primary'}
                            />}
                        </div>
                        {/* <button className="nat-close-btn"><Icons.ModalCloseIcon /></button> */}
                    </div>
                </motion.div>}
            </AnimatePresence>
        )}
    </>
}

export {CacheChecker, renderUpgradePopup};