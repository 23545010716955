import React, { useEffect, useState } from 'react'
import { dispatchHandler } from '../../../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux';
import { getPermissionKey } from '../../../../helper/permissions';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Sector } from "recharts";
import NoDataFound from '../../../../genericComponents/NoDataFound';
import Icons from '../../../../assets/icons';
import { Progress } from 'antd';
import { motion } from 'framer-motion';
import { Image } from 'lucide-react';

const ProductList = ({ title, products, bgColor, textColor, emoji, maxProductsLimit }) => {
    const productsList = products.slice(0, maxProductsLimit);
    return (
        <div style={{boxShadow: '0px 7px 14px 0px #D2D2D240', backgroundColor: bgColor || ''}} className={`px-5 py-3 rounded-[10px] border border-[#EBEBEB] flex items-start gap-8`}>
            {/* <div className='flex flex-col'>
                <div className='h-[110px]'>
                    <h3 style={{color: textColor || ''}} className="text-lg font-semibold flex items-center gap-2 whitespace-nowrap">
                        {title}
                    </h3>
                    <div className='text-xl'>{emoji}</div>
                </div>

                <div className="mt-4 text-sm text-gray-600">
                    <p className='mb-0 text-[#8D99AC] text-[11px] font-medium'>Expected Sales <br />Qty.</p>
                    <p className='mb-0 text-[#8D99AC] text-[11px] font-medium'>Expected Sell <br />Through Qty.</p>
                </div>
            </div>
            <div className='flex items-start'>
                {products.map((product) => (
                    <div className='flex flex-col'>
                        <div className="relative mx-3">
                            <div className='relative w-[64px] h-[70px] rounded-[10px] overflow-hidden' style={{boxShadow: '0px 4.4px 4.4px 0px #00000040'}}>
                                <img
                                    src={product?.image_url}
                                    alt={product?.hl3name}
                                    className="h-full w-full object-cover object-center"
                                />
                                <div className='w-full h-[50%] absolute bottom-[0] left-[0] bg-gradient-to-t from-black to-transparent px-2 py-1 flex justify-center items-end gap-1.5'>
                                    <p className="mb-0 text-white text-[11px] font-semibold flex items-center gap-1">
                                        ⭐
                                        {product.rating} <Icons.Star/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-sm font-medium mt-2 mx-3">{product?.hl3name ?? "__"}</p>
                        <p className='mx-3 py-4 border-b'>{product?.expected_sales_qty || 0}</p>
                        <p className='mx-3 pt-[16px]'>{product?.expected_sale_through || 0}</p>
                    </div>
                ))}
            </div> */}
            <table>
                <tbody>
                    <tr>
                        <td rowSpan={2} className='pr-[32px] align-top [133px]'>
                            <h3 style={{color: textColor || ''}} className="text-lg font-semibold flex items-center gap-2 whitespace-nowrap">
                                {title}
                            </h3>
                            <div className='text-xl mb-4'>{emoji}</div>
                        </td>
                        {productsList.map((product) => (
                            <td className='pr-[20px] pt-[4px] last:pr-0'>
                                        <div className='relative w-[64px] h-[70px] rounded-[10px] overflow-hidden' style={{boxShadow: '0px 4.4px 4.4px 0px #00000040'}}>
                                            {product?.image_url ? <img
                                                src={product?.image_url || ''}
                                                className="h-full w-full object-cover object-center"
                                            /> :
                                            <div className='h-full w-full flex items-center justify-center bg-[#dfe1e6]'><Image size={15} /></div>
                                            }
                                            <div className='px-2 pb-[18px] w-full h-full absolute bottom-[-16px] left-[0] bg-gradient-to-t to-80% from-black to-transparent flex justify-center items-end gap-1.5'>
                                                <p className="mb-0 text-white text-[11px] font-semibold flex items-center gap-1">
                                                    {product.rating} <Icons.Star/>
                                                </p>
                                            </div>
                                        </div>
                            </td>
                            ))
                        }
                    </tr>
                    <tr>
                        {productsList.map((product) => (
                            <td className='pt-[12px] pb-4 pr-[20px] last:pr-0'>
                                <p className="mb-0 min-h-[33px] text-[11px] font-medium whitespace-normal break-words break-all line-clamp-3">{product?.hl3name ?? "__"}</p>
                            </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td className='align-top'>
                            <p className='text-[#8D99AC] text-[11px] font-medium'>Expected Sales <br />Qty.</p>
                        </td>
                        {productsList.map((product) => (
                            <td className='pb-3 border-b border-dashed border-[#D3D6DF]'>
                                <p className='text-sm font-semibold'>{product?.expected_sales_qty || 0}</p>
                            </td>
                            ))
                        }
                    </tr>
                    <tr>
                        <td className='pr-[20px]'>
                            <p className='mb-0 text-[#8D99AC] text-[11px] font-medium'>Expected Sell <br />Through Qty.</p>
                        </td>
                        {productsList.map((product) => (
                            <td className='pt-[16px] pr-[20px] last:pr-0'>
                                <p className='mb-0 text-sm font-semibold'>{product?.expected_sale_through || 0}</p>
                            </td>
                            ))
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {/* <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        /> */}
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

function ProductSurveyDashboard(props) {
    const { selectedData } = props;
    const dispatch = useDispatch();

    const customerSatisfactionBreakdownData = useSelector(state => state?.digicatStore?.customerSatisfactionBreakdownData);
    const topRatedProductsData = useSelector(state => state?.digicatStore?.topRatedProductsData);
    const bottomRatedProductsData = useSelector(state => state?.digicatStore?.bottomRatedProductsData);
    const [customerSatisfactionData, setCustomerSatisfactionData] = useState({})
    const [topRated, setTopRated] = useState([]);
    const [lowestRated, setLowestRated] = useState([])
    const [pieChartsActiveIndex, setPieChartsActiveIndex] = useState(null)

    const responseRate = 56;
    const totalSurveys = 10000;
    const responsesReceived = (totalSurveys * responseRate) / 100;

    useEffect(() => {
        let payload = {
            surveyId: selectedData?.id,
            dataType: "CUSTOMER_SATISFACTION_BREAKDOWN"
        }
        dispatchHandler(dispatch, "customerSatisfactionBreakdownRequest", payload, getPermissionKey("purchase", "productsurvey", "view"))
        dispatchHandler(dispatch, "topRatedProductsRequest", { ...payload, dataType: "TOP_RATED_PRODUCTS" }, getPermissionKey("purchase", "productsurvey", "view"))
        dispatchHandler(dispatch, "bottomRatedProductsRequest", { ...payload, dataType: "BOTTOM_RATED_PRODUCTS" }, getPermissionKey("purchase", "productsurvey", "view"))
    }, [])

    useEffect(() => {
        if (customerSatisfactionBreakdownData?.isSuccess) {
            let data = transformData(customerSatisfactionBreakdownData?.data?.resource || {})
            setCustomerSatisfactionData(data)
        }

        if (topRatedProductsData?.isSuccess) {
            setTopRated(topRatedProductsData?.data?.resource || [])
        }

        if (bottomRatedProductsData?.isSuccess) {
            setLowestRated(bottomRatedProductsData?.data?.resource || [])
        }
    }, [customerSatisfactionBreakdownData?.isSuccess, topRatedProductsData?.isSuccess, bottomRatedProductsData?.isSuccess])

    const transformData = (resource) => {

        return {
            totalProducts: resource?.total_product ?? 0,
            responseRate: resource?.response_rate ?? 0,
            avgRating: resource?.avg_rating ?? 0,
            totalCount: resource?.total_count ?? 0,
            responseReceived: resource?.responseReceived ?? 0,
            noOfSurveySent: resource?.noOfSurveySent ?? 0,
            likesData: [
                { name: "Likes", value: resource.like_percentage, color: "#51b850" },
                { name: "Dislikes", value: resource.dislike_percentage, color: "#eef8ed" },
            ],
            dislikesData: [
                { name: "Likes", value: resource.like_percentage, color: "#ffe8e9" },
                { name: "Dislikes", value: resource.dislike_percentage, color: "#ff2928" },
            ],
            ratingData: [
                { name: "5 Stars", value: resource?.rating_5_percentage ?? 0, color: "#00b894" },
                { name: "4 Stars", value: resource?.rating_4_percentage ?? 0, color: "#89e5d5" },
                { name: "3 Stars", value: resource?.rating_3_percentage ?? 0, color: "#fdcb6e" },
                { name: "2 Stars", value: resource?.rating_2_percentage ?? 0, color: "#ffa17a" },
                { name: "1 Star", value: resource?.rating_1_percentage ?? 0, color: "#d3354f" },
            ],
        };
    };

    const onPieEnter = (_, index) => {
        setPieChartsActiveIndex(index)
    }

    const getEmoji = {
        1: <Icons.RatingEmoji1/>,
        2: <Icons.RatingEmoji2/>,
        3: <Icons.RatingEmoji3/>,
        4: <Icons.RatingEmoji4/>,
        5: <Icons.RatingEmoji5/>,
    }

    const CustomerSatisfactionChartTooltip = ({ active, payload }) => {
        const rating = payload[0]?.name?.toString()?.trim() && payload[0].name.toString().trim().charAt(0);
        if (active && payload && payload.length) {
          return (
            <motion.div initial={{scale: .9, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: .9, opacity: 0}} transition={{duration: .1}} style={{boxShadow: '0px 4.4px 4.4px 0px #00000040'}} className={`bg-white p-[8px] rounded-[10px] border border-[#EBEBEB]`}>
                <div className='flex items-center gap-1.5 mb-1'>
                    <div className={`shrink-0 w-[16px] h-[16px] bg-white rounded-full`} style={{ border: `6px solid ${payload[0]?.payload?.color}` }} ></div>
                    <p className="mb-0 text-base font-semibold text-fontBlack">{payload[0].value}</p>
                </div>
                <p className='text-[11px] font-medium text-[#8D99AC]'>Customer Rating</p>
                <div className='flex items-center gap-1.5'>
                    <div>{getEmoji[rating]}</div>
                    <p className='mb-0 text-xs font-semibold'>{rating}/5</p>
                    <div className='flex items-center'>
                        {
                            Array.from({ length: rating }).map((_, index)=>(<div key={index}><Icons.Star/></div>))
                        }
                    </div>
                </div>
            </motion.div>
          );
        }
        return null;
      };


    return (
            <div className='p-[15px] h-full text-fontBlack flex flex-col gap-5 overflow-auto'>
                <div className="flex-[60%] flex gap-5">
                    {/* Customer Satisfaction Breakdown */}
                    <div style={{boxShadow: '0px 7px 14px 0px #D2D2D240'}} className="px-5 py-3 w-[47%] rounded-[10px] border border-[#EBEBEB] flex flex-col bg-white">
                        <h2 className="text-base font-semibold mb-4">Customer Satisfaction Breakdown</h2>
                        <div className='h-full flex items-center justify-center gap-6'>
                            {/* {console.log('customerSatisfactionData', customerSatisfactionData)} */}
                            <ResponsiveContainer width="50%" height={200}>  {/* 80% of 250 */}
                                <PieChart>
                                    <Pie
                                        activeIndex={pieChartsActiveIndex}
                                        data={customerSatisfactionData?.ratingData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={52} // Reduced to 80% of 65
                                        outerRadius={85} // Reduced to 80% of 100
                                        cornerRadius={5}
                                        paddingAngle={1.5}
                                        onMouseEnter={onPieEnter}
                                        onMouseLeave={() => setPieChartsActiveIndex(null)}
                                    >
                                        {customerSatisfactionData?.ratingData?.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    {/* Custom Tooltip */}
                                    <Tooltip content={<CustomerSatisfactionChartTooltip />} />
                                    {customerSatisfactionData?.totalProducts !== undefined && 
                                    <g>
                                        <text 
                                            x="50%" 
                                            y="47%" 
                                            textAnchor="middle" 
                                            dominantBaseline="middle" 
                                            className="text-xs font-medium fill-[#797F87]"
                                        >
                                            Total Products
                                        </text>
                                        <text 
                                            x="50%" 
                                            y="57%" 
                                            textAnchor="middle" 
                                            dominantBaseline="middle" 
                                            className="text-[25px] font-semibold fill-fontBlack"
                                        >
                                            {customerSatisfactionData?.totalProducts}
                                        </text>
                                    </g>
                                    }
                                </PieChart>
                            </ResponsiveContainer>

                            <div className='space-y-3'>
                                {customerSatisfactionData?.ratingData?.map((entry, index) => {
                                    const rating = entry?.name?.toString()?.trim() && entry?.name.toString().trim().charAt(0);
                                    
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <div className='w-[70px] flex items-center gap-2'>
                                                <div className={`shrink-0 w-[16px] h-[16px] bg-white rounded-full`} style={{ border: `6px solid ${entry.color}` }} ></div>
                                                <p className='m-[0] p-[0] leading-[initial] text-xs font-semibold'>{entry?.value ?? 0}%</p>
                                            </div>
                                            <div>{getEmoji[rating]}</div>
                                            <div className='flex items-center'>
                                                {
                                                    Array.from({ length: rating }).map((_, index)=>(<div key={index}><Icons.Star/></div>))
                                                }
                                            </div>
                                        </div>
                                )})}
                            </div>
                        </div>
                    </div>

                    {/* Survey Response Rate */}
                    <div style={{boxShadow: '0px 7px 14px 0px #D2D2D240'}} className="px-5 py-3 w-[53%] rounded-[10px] border border-[#EBEBEB] bg-white flex flex-col">
                        <h2 className="text-base font-semibold mb-4">Survey Responses and Distribution</h2>
                        <div className='whitespace-nowrap flex items-center justify-between gap-7'>
                            <div>
                                <p className="text-[25px] font-semibold mb-0">{customerSatisfactionData?.responseRate}%</p>
                                <p className='text-xs font-medium mb-0'>Response Rate</p>
                            </div>
                            <div className='h-[42px] w-[1.5px] bg-[#D3D6DF]'></div>
                            <div className='flex items-center gap-7'>
                                <div className='flex items-start gap-2'>
                                    <div className='bg-[#0984E3] p-[2px] rounded-[5px] mt-[6px]'><Icons.EmailReceived/></div>
                                    <div className='w-full'>
                                        <p className='text-base font-semibold text-[#0984E3] mb-0 leading-[initial]'>{customerSatisfactionData?.responseReceived}</p>
                                        <p className='text-[11px] font-medium mb-0 leading-[initial]'>Responses Received</p>
                                    </div>
                                </div>
                                <div className='flex items-start gap-2'>
                                    <div className='bg-[#8D99AC] p-[2px] rounded-[5px] mt-[6px]'><Icons.EmailSent/></div>
                                    <div className='w-full'>
                                        <p className='text-base font-semibold text-[#8D99AC] mb-0 leading-[initial]'>{customerSatisfactionData?.noOfSurveySent}</p>
                                        <p className='text-[11px] font-medium mb-0 leading-[initial]'>Number of Survey Sent</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative w-full bg-[#f2f2f2] rounded-full shrink-0 h-[14px] mt-5 mb-2">
                            <div
                                className="bg-[#0984E3] h-full rounded-l-full"
                                style={{ width: `calc(${customerSatisfactionData?.responseRate}% - 2px)` }}
                            >
                            </div>
                            <div style={{left: `${customerSatisfactionData?.responseRate || 0}%`}} className='absolute h-[22px] w-[6px] bg-white z-[3] top-1/2 -translate-y-1/2 border border-[#0984E3] rounded-full'></div>
                        </div>
                        <div className='h-full flex items-center justify-around'>
                            {/* <ResponsiveContainer width="50%" height={160}>
                                <PieChart>
                                    <Pie
                                        data={customerSatisfactionData?.dislikesData}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={50}
                                        outerRadius={65}
                                        startAngle={180}
                                        endAngle={-180}
                                        paddingAngle={0}
                                        cornerRadius={12}
                                    >
                                        {customerSatisfactionData?.dislikesData?.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                   {customerSatisfactionData?.dislikesData?.find((d) => d.name === "Dislikes")?.value !== undefined && <text 
                                        x="50%" 
                                        y="50%" 
                                        textAnchor="middle" 
                                        dominantBaseline="middle" 
                                        className="text-[25px] font-semibold fill-fontBlack"
                                    >
                                        {`${customerSatisfactionData?.dislikesData?.find((d) => d.name === "Dislikes")?.value}%`}
                                    </text>}
                                </PieChart>
                            </ResponsiveContainer> */}
                            
                            {/* <ResponsiveContainer width="50%" height={200}>
                                <PieChart>
                                    <Pie
                                        data={customerSatisfactionData?.likesData}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={50}
                                        outerRadius={80}
                                        startAngle={180}
                                        endAngle={-180}
                                        paddingAngle={0}
                                        cornerRadius={12}
                                    >
                                        {customerSatisfactionData?.likesData?.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    {customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value !== undefined && <text 
                                        x="50%" 
                                        y="50%" 
                                        textAnchor="middle" 
                                        dominantBaseline="middle" 
                                        className="text-[25px] font-semibold fill-fontBlack"
                                    >
                                        {`${customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value}%`}
                                    </text>}
                                </PieChart>
                            </ResponsiveContainer> */}
                            <div className='relative pr-[50px] shrink-0 flex items-center gap-5'>
                                <Progress
                                    strokeWidth={17} 
                                    type="circle" 
                                    percent={customerSatisfactionData?.dislikesData?.find((d) => d.name === "Dislikes")?.value} 
                                    // format={(percent) => (
                                    //     <span className='text-fontBlack text-[25px] font-semibold'>
                                    //     {Math.round(percent)}%
                                    //     </span>
                                    // )}
                                    format={() => null}
                                    trailColor='rgba(255, 40, 40, .1)'
                                    strokeColor={'#FF2828'}
                                    size={80}
                                />
                               
                                <div className='space-y-1'>
                                    <div className='text-fontBlack text-[25px] font-semibold'>
                                        {/* {Math.round(customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value)}% */}
                                        {customerSatisfactionData?.dislikesData?.find((d) => d.name === "Dislikes")?.value}%
                                    </div>
                                    <div className='px-1.5 py-1 min-w-[85px] text-white rounded-[5px] flex justify-center items-center gap-1.5 bg-[#FF2828]'>
                                        <div><Icons.ThumbsDown/></div>
                                    <p className='mb-0 text-sm font-medium'>Dislikes</p>
                                </div>
                                </div>
                            </div>

                            <div className='relative pr-[50px] shrink-0 flex items-center gap-5'>
                                <Progress 
                                    strokeWidth={17} 
                                    type="circle" 
                                    percent={customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value} 
                                    // format={(percent) => (
                                    //     <span className='text-fontBlack text-[25px] font-semibold'>
                                    //     {Math.round(percent)}%
                                    //     </span>
                                    // )}
                                    format={() => null}
                                    trailColor='rgba(81, 184, 80, .1)'
                                    strokeColor={'#51B850'}
                                    size={80}
                                />
                                <div className='space-y-1'>
                                    <div className='text-fontBlack text-[25px] font-semibold'>
                                        {/* {Math.round(customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value)}% */}
                                        {customerSatisfactionData?.likesData?.find((d) => d.name === "Likes")?.value}%
                                    </div>
                                    <div className='px-1.5 py-1 min-w-[85px] text-white rounded-[5px] flex justify-center items-center gap-1.5 bg-[#51B850]'>
                                        <div><Icons.ThumbsUp/></div>
                                        <p className='mb-0 text-sm font-medium'>Likes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex-[40%] ${topRated?.length == 0 && lowestRated?.length == 0 ? 'overflow-hidden' : ''} ${topRated?.length !== 0 || lowestRated?.length !== 0 ? 'grid grid-cols-[1.25fr_1fr] gap-5' : ''}`}>
                    {topRated?.length == 0 && lowestRated?.length == 0 ?
                        <NoDataFound /> :
                        <>
                            <ProductList
                                title={<span>Top Rated <br />Products</span>}
                                products={topRated}
                                bgColor="#51B8500D"
                                textColor="#00B894"
                                emoji={<Icons.RatingEmoji5/>}
                                maxProductsLimit={5}
                            />
                            <ProductList
                                title={<span>Lowest Rated <br />Products</span>}
                                products={lowestRated}
                                bgColor="#D3354F0D"
                                textColor="#FF2828"
                                emoji={<Icons.RatingEmoji1/>}
                                maxProductsLimit={3}
                            />
                        </>}
                </div>
        </div>
    )
}

export default ProductSurveyDashboard