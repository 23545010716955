import React, { useEffect, useState } from "react";
import AuthStack from "./authComponents/AuthStack";
import UserOfflineHome from "./components/helperComponents/UserOfflineHome";
import { AppStack } from "./components/AppStack";
import MasterDbData from "./authComponents/MasterDbData";
import ApiManagement from "./authComponents/ApiManagement";
import { HashRouter } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './styles/stylesheets/helper.scss'
import QrScan from "./authComponents/QrScan/QrScan";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import OtherStack from "./otherStacks/OtherStack";
import WarehouseJobStatusComponent from "./authComponents/WarehouseJobStatusComponent";
import { parseJwt } from "./helper/genericFunction";
import QzTraySecurityChecker from "./helper/components/QzTraySecurityChecker";
import BootConfigSetting from "./components/home/components/BootConfigSetting";
import CompleteProductSurvey from "./components/digicatalogue/create/productSurvey/CompleteProductSurvey";
import DraftForms from "./components/draftForms/DraftForms";

const ReloadContext = React.createContext();
const UserDetailsContext = React.createContext();

function App() {
	
	const location = useLocation();
	const [isVendorLoginFromRetailer, setIsVendorLoginFromRetailer] = useState(false);
	const [userDetails, setUserDetails] = useState({});

	const [bootConfig, setBootConfig] = useState({
		additionalConfig: {
			color: "#5d5fef",
			allowVendorRegistration: false
		}
	});

	let params = {};

	// Dev code
	// params = {
	// 	"token" : "eyJhbGciOiJIUzUxMiJ9.eyJtb2IiOiI4MzM2OTQ3MTU3IiwiZWlkIjo3NTQsImxhc3ROYW1lIjoiR3VudGEiLCJsb2dpblN0cmlwTWVzc2FnZSI6bnVsbCwic2NoZW1hRW50TmFtZSI6IkhpdCBUaGUgTWFyayBJbmMiLCJlbWwiOiJzaGFyYW4uZ3VudGFAaG9wc2NvdGNoLmluIiwicm9sZXMiOiIzMyIsIlgtVEVOQU5ULUlEIjoiYmQ3OTEyZWU3ZDM4M2ZjZTQ1NzhiMGZhYzRmOGYzYjgxYzc0YWI1M2YxZGYwZjYwOTljZDI5MWExY2RlMjJhNSIsIkJVQ0tFVCI6ImhvcHNjb3RjaC1zdXBwbHltaW50LWRldmVsb3AtY29tIiwiaXNzIjoiU3VwcGx5TWludCIsIm1vYmlsZVZlcmlmaWVkIjoxLCJvaWQiOjg5NiwidXNlckFzQWRtaW4iOmZhbHNlLCJnc3RpbiI6bnVsbCwib3JnSWQiOiI4OTYiLCJvY29kZSI6ImhvcHNjb3RjaF90ZXN0IiwidVR5cGUiOiJFTlQiLCJpcGEiOiJJUEEiLCJmdW5jdGlvblR5cGUiOiJERUZBVUxUX0ZVTkNUSU9OIiwiZXhwIjoxNzI0NzY3NzQ3LCJqdGkiOjg0NSwib3JnTmFtZSI6IkhvcHNjb3RjaCIsImNvcmVPcmdJRCI6Ijg5NiIsInNsQ29kZSI6bnVsbCwiZW50RGV0YWlscyI6W3siZWlkIjo3NTQsIm9pZCI6ODk2LCJlY29kZSI6ImhvcHNjb3RjaF90ZXN0IiwiZW5hbWUiOiJIb3BzY290Y2gtSGl0IFRoZSBNYXJrIEluYyIsInVjb2RlIjpudWxsLCJkZWZhdWx0IjoxLCJzdWJVc2VyVHlwZSI6bnVsbCwiaXNIb2xkIjowLCJob2xkTGFiZWwiOm51bGwsImhvbGRNZXNzYWdlIjpudWxsLCJhY3RpdmUiOjF9XSwicHJuIjoiaG9wc2NvdGNoIiwiZWNvZGUiOiJob3BzY290Y2hfdGVzdCIsIk9SR19ERVRBSUxTIjpbXSwiYXVkIjoiU2hhcmFuIEd1bnRhIiwic2NoZW1hRW50SUQiOiI3NTQiLCJlbWFpbFZlcmlmaWVkIjoxLCJmaXJzdE5hbWUiOiJTaGFyYW4iLCJlbmFtZSI6IkhpdCBUaGUgTWFyayBJbmMiLCJzdWJVc2VyVHlwZSI6IlYiLCJ3ZWJJZCI6bnVsbCwiaXBfcHJlZml4IjpbIklQT0FBIl0sInRlbmFudE9yZ0lEIjoiODk2IiwiZGV2aWNlU291cmNlIjoiV0VCIiwiaXNNZXJnZUVsaWdpYmxlIjowLCJzY2hlbWFPcmdJRCI6Ijg5NiIsIkVOVF9UWVBFIjoiUkVUQUlMX01BUktFVCIsImxvZ2luU291cmNlIjoiU0VMRiIsIm9yZ19sb2dvIjoiaHR0cHM6Ly9hc3NldHMtc3VwcGx5bWludC1jb20uczMuYXAtc291dGgtMS5hbWF6b25hd3MuY29tL29yZ19hc3NldHMvaW1nX3BsYWNlaG9sZGVyL3Byb2ZpbGVQbGFjZWhvbGRlci5zdmcifQ.m0QRc1TyPBBgvKkpaVNnyG-Klt07nGbtRdTXqUn5vB-dv77LZfYBZraBy2MCuVuqs9pxxmKaJKkHxVz58r3fnw"
	// }
	// DevCode
	
	if (location?.hash?.split('?')?.[1]?.substring(0, 19)?.includes("eyJhbGciOiJIUzUxMiJ")) {
		params = {
			"token": location?.hash?.split('?')?.[1],
		}
		// if (location?.hash?.split('?')?.[0]?.includes("hitLineReport") || location?.hash?.split('?')?.[0]?.includes("footfallReport")) {
		// 	sessionStorage.setItem("redirectNewUrl", location?.hash?.split('?')?.[0])
		// }
	}

	if (window.location.hash.includes("accessVendorPortalToken")) {
		params = {
			"token": window.location.hash?.split("?")?.[1]?.split?.("=")?.[1]
		};
	}

	const [isUserOnline, setIsUserOnline] = useState(navigator.onLine);
	const [currentDocumentState, setCurrentDocumentState] = useState("");
	// state : to store the appStack document state
	const [appStackDocState, setAppStackDocState] = useState("");

	useEffect(() => {
		if (window.location.hash.includes("accessVendorPortalToken")) {
			setIsVendorLoginFromRetailer(true);
		}
	}, [])

	useEffect(() => {
		// To handle the user is offline or online
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOnline);

		handleDocumentStatusChange();
	}, [params, params.token]);

	const handleDocumentStatusChange = () => {
		let status = "unauthorised";

		if (window.location.hash?.includes("accessVendorPortalToken")) {
			status = "rejected"
		} else if (!sessionStorage?.getItem("token")) {
			// Check if the token is set inside session storage
			if (!params.token) {
				status = "rejected"; // if token is not present is params then the user cannot access the application.
			}
		} else {
			console.log("Authorised application");

			status = "authorised"; // else the user is authorised.
		}
		setCurrentDocumentState(status);
	}

	useEffect(() => {
		if (sessionStorage.getItem("appStackDocState")) {
			setAppStackDocState(sessionStorage.getItem("appStackDocState"));
		}
	}, [])

	// useEffect(() => {
	// sessionStorage.setItem("appStackDocState", appStackDocState)
	// }, [appStackDocState])

	function handleOnline() {
		setTimeout(() => {
			setIsUserOnline(navigator.onLine)
		}, navigator.onLine ? 1900 : 0)
	}

	useEffect(() => {
		if (currentDocumentState == "authorised") {
			let uType = parseJwt(sessionStorage.getItem("token"))?.uType;

			if (["VENDOR", "CUSTOMER"]?.includes(uType)) {
				const script = document.createElement("script");
				script.src = '//in.fw-cdn.com/31764711/906749.js';
				script.chat = 'true';

				document.body?.appendChild(script);
			} else {
				const script = document.querySelector('script[src="//in.fw-cdn.com/31764711/906749.js"]');
				console.log("Script check", script);
				if (script) {
					script.parentNode?.removeChild(script); // Remove the script element from the DOM
					console.log("Script removed successfully!");
				} else {
					console.log("Script not found!");
				}
			}
		}
	}, [currentDocumentState])

	// Function : To clear the context of the current user details
	const clearUserContext = (config = {}) => {
		let { keys = [] } = config;

		if (keys?.length) {
			let updatedUserDetails = { ...userDetails };
			keys?.forEach(key => {
				delete updatedUserDetails?.[key];
			})
			setUserDetails(updatedUserDetails);
		} else {
			setUserDetails({});
		}
	}

	const renderApp = () => {
		let Component = null;
		const props = {};
		// eslint-disable-next-line default-case
		switch (currentDocumentState) {
			case "unauthorised":
			case "rejected": {
				// window.location.href = "/"
				Component = AuthStack;

				props.token = params?.["token"];
				props.setCurrentDocumentState = setCurrentDocumentState;
				props.setAppStackDocState = setAppStackDocState;
				props.currentDocumentState = currentDocumentState
				props.isVendorLoginFromRetailer = isVendorLoginFromRetailer
				break;
			}
			case "otherStack": {
				Component = OtherStack;
				break;
			}
			case "authorised": {
				props.token = params?.["token"];
				props.setCurrentDocumentState = setCurrentDocumentState;
				props.setAppStackDocState = setAppStackDocState;
				switch (appStackDocState) {
					case "otherStack": {
						Component = OtherStack;
						break;
					}
					default: {
						Component = AppStack
					}
				}
				break;
			}
		}
		if (Component) {
			return (
				<React.Fragment>
					<Component {...props} />
					{(sessionStorage?.getItem("HIDE_OFFLINE_STRIP") != "TRUE") && (!isUserOnline && <UserOfflineHome />)}
				</React.Fragment>
			);
		}
	};

	console.log("location pathname", location);

	return (
		<div id="app">
			<UserDetailsContext.Provider
				value={{
					// User details context data goes here;
					clearUserContext: clearUserContext,
					userDetails: userDetails,
					setUserDetails: setUserDetails,
				}}
			>
				<ReloadContext.Provider value={{
					handleDocumentStatusChange : () => {
						setCurrentDocumentState("unauthorised")
					},
					setBootConfig : setBootConfig,
					bootConfig : bootConfig,
				}}>
					<HashRouter>

						<DraftForms/>

						<BootConfigSetting/>

						<QzTraySecurityChecker />

						{/* MasterDbData -> Component to call and set Headers, filters and dropdowns data */}
						<MasterDbData />

						{/* Warehouse job run status component to show the status of the running job */}
						<WarehouseJobStatusComponent />

						{/* Loading, Error Message, Success Messages */}
						<ApiManagement />

					{!["#/catalogue/productSurvey/complete"]?.some(item => location?.hash?.includes(item)) && currentDocumentState ? renderApp() : null}

					{/* Render the qr scan for product catalog */}
					<Route exact path="/qrscan" render={() => <QrScan location={location} />} />
					<Route exact path="/catalogue/productSurvey/complete" render={(props) => <CompleteProductSurvey {...props} />} />
				</HashRouter>
				</ReloadContext.Provider>
			</UserDetailsContext.Provider>
		</div>
	);
}
export { App, ReloadContext, UserDetailsContext };
