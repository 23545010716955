import React from 'react'
import {Switch} from 'antd'
import LanguageTranslator from '../locals/LanguageTranslator';

const GenericToggleComponent = (props) => {
    const {checked = false, loading, checkedComponent, uncheckedComponent, className, disabled, onChange, style={}} = props;
    return (
        <div className='ant-toggle'>
            <Switch 
                style={style}
                className= {className}
                loading = {loading} 
                disabled = {disabled}
                checked={checked} 
                onChange = {val => onChange(val) }
                checkedChildren={<LanguageTranslator>{checkedComponent}</LanguageTranslator>}
                unCheckedChildren={<LanguageTranslator>{uncheckedComponent}</LanguageTranslator>}
            />
        </div>
    )
}

export default GenericToggleComponent