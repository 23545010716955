import React from 'react'

function SkeletonLoader() {
    return (
        <div className='space-y-1 max-h-[270px] overflow-y-auto'>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
            <div className='pt-[6px] grid grid-cols-3 gap-1 border-b border-[#E5E6EB] last:border-b-0'>
                <div className='col-span-2'>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
                <div>
                    <div className='generic-skeleton !h-[9px] !w-[50px] !mb-1'></div>
                    <div className='generic-skeleton !w-[70px]'></div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonLoader