import React, { useEffect, useState } from 'react';
import AllFilters from '../components/AllFilters';
import dayjs from 'dayjs';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, Cell, ComposedChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Radio } from 'antd';
import BoxAnalysisSkeleton from './BoxAnalysisSkeleton';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';

const filterPayload = {
    attributeType: "BOX_ANALYSIS_DASHBOARD",
    displayName: "BOX_ANALYSIS_DASHBOARD",
    filterName: ""
};

const PRIMARY_FILTERS = [
    {
        value: "Vendor",
        key: "vendor_name",
        code: "",
        entity: "asn_dashboard_details",
    },
    {
        value: "Site",
        key: "code",
        code: "name1",
        entity: "site",
        headerKeys: {
            multiSelectCol: {},
            'code': { value: 'Code' },
            'name1': { value: 'Name' }
        },
        itemKeys: {
            'code': ['code'],
            'name1': ['name1']
        },
        multiselect: {
            selectId: 'code',
        },
    },
];

const MAIN_FILTERS = [
    {
        value: "Division",
        key: "hl1_name",
        entity: "item",
    },
    {
        value: "Section",
        key: "hl2_name",
        entity: "item",
    },
    {
        value: "Department",
        key: "hl3_name",
        entity: "item",
    },
    {
        value: "State",
        key: "state1",
        entity: "box_performance_details",
    },
    {
        value: "City",
        key: "city",
        entity: "box_performance_details",
    },
]

const mappingSelectedFilters = {
    vendor_name: 'Vendor',
    city: 'City',
    hl1_name: 'Division',
    hl2_name: 'Section',
    hl3_name: 'Department',
    state1: 'State',
    code: 'Site'
}

const filterItems = {
    "Default Headers": {
        dateRange: "Date From - Date To",
        vendor_name: "Vendor",
        city: "City",
        hl1_name: "Division",
        hl2_name: "Section",
        hl3_name: "Department",
        state1: "state1",
        code: "Site",
    }
    ,
    "columnType": {
        dateRange: "DATE",
        vendor_name: "SEARCH",
        city: "SEARCH",
        hl1_name: "SEARCH",
        hl2_name: "SEARCH",
        hl3_name: "SEARCH",
        state1: "SEARCH",
        code: "SEARCH",
    },
}

const currentFilterKeys = ["vendor_name", "city", "state1", "hl1_name", "hl2_name", "hl3_name", "dateRange", "code"]

const BoxAnalysisDashboard = (props) => {
    const currentDate = dayjs()
    const disabledDate = (d) => !d || d.isAfter(currentDate)
    const dispatch = useDispatch()
    const digivendStore = useSelector((state) => state.digivendStore)
    const generalStore = useSelector((state) => state.generalStore)

    const [dashboardData, setDashboardData] = useState([])
    const [graphType, setGraphType] = useState('normal')

    useEffect(() => {
        if (digivendStore?.getBoxAnalysisDashboardData?.isSuccess) {
            setDashboardData(digivendStore?.getBoxAnalysisDashboardData?.data?.resource || [])
        }
    }, [digivendStore?.getBoxAnalysisDashboardData?.isSuccess])

    useEffect(() => {
        if (generalStore?.getQuickFiltersData?.isSuccess) {
            getGraphdata()
        }
    }, [generalStore?.getQuickFiltersData?.isSuccess])

    // function to call the graph api
    const getGraphdata = (filtersData = {}) => {
        setDashboardData([]);

        let apiPayload = {};
        if (Object.keys(filtersData)?.length === 0) {
            apiPayload = {};
        } else {
            apiPayload = { filter: {} }

            Object.keys(filtersData).forEach((filter) => {
                switch (filter) {
                    case 'dateRange':
                        const { from, to } = filtersData.dateRange || {};
                        if (from && to) {
                            apiPayload.filter.dateFrom = dayjs(from)?.format('YYYY-MM-DD');
                            apiPayload.filter.dateTo = dayjs(to)?.format('YYYY-MM-DD');
                        }
                        break;

                    default:
                        apiPayload.filter[filter] = filtersData[filter];
                        break;
                }
            });
        }
        console.log('api payload is', apiPayload, 'and filters', filtersData);
        dispatchHandler(dispatch, 'getBoxAnalysisDashboardRequest', apiPayload, getPermissionKey("dashboard", "boxanalysis", "view"));
    }

    let renderPieData = dashboardData && dashboardData?.boxScore?.map((item) => {
        return {
            "matchPer": item?.matchPer,
            "mismatchedPer": item?.mismatchedPer,
        }
    })
    const leftComponentData = [
        {
            name: 'Matched',
            value: dashboardData && renderPieData?.[0]?.matchPer,
        },
        {
            name: 'Mismatched',
            value: dashboardData && renderPieData?.[0]?.mismatchedPer,
        },
    ];
    const COLORS = ['#824bf6', '#ef6e8b',];

    const renderLeftComponent = () => {
        return <div className="pie-chart-section m-rgt-12">
            <div className="pcs-left pcsl-v2">
                <div className="piecsi-top">
                    <div className='pcsl-top p-top-20'>
                        <LanguageTranslator tag="h3">Box Score</LanguageTranslator>
                        <LanguageTranslator tag="p">Matched & Mismatched box score percentage</LanguageTranslator>
                    </div>
                </div>
                <div className='pcsl-circle pcslc-v2 items-center'>
                    {dashboardData &&
                        <ResponsiveContainer width={300} height={300}>
                            <PieChart
                            >
                                <Pie
                                    data={leftComponentData}
                                    cx="50%"
                                    cy="50%"
                                    isAnimationActive={false}
                                    outerRadius={90}
                                    fill="#ef6e8b"
                                    label={{ color: "#121212", fontSize: 12, fontWeight: 500, }}
                                    dataKey="value"
                                >
                                    {dashboardData && dashboardData?.boxScore?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
                                    ))}
                                </Pie>
                                <Tooltip
                                    overlayClassName='!p-[0]'
                                    labelStyle={{ color: "#ffffff82", fontWeight: 600, }}
                                    contentStyle={{ color: "#fff", fontWeight: 600 }}
                                />
                            </PieChart>
                        </ResponsiveContainer>}
                    <div className='pcslc-bottom'>
                        <div className='pcslc-col'>
                            <span className='pcslcc-dot'></span>
                            <LanguageTranslator tag="h3">Match Percentage</LanguageTranslator>
                        </div>
                        <div className='pcslc-col'>
                            <span className='pcslcc-dot pcslccd-v2'></span>
                            <LanguageTranslator tag="h3">Mismatch Percentage</LanguageTranslator>
                        </div>
                    </div>
                </div>
                <div className="piecsi-count-section">
                    <div className='piecsics-col'>
                        <LanguageTranslator tag="h3">ASN Count</LanguageTranslator>
                        <LanguageTranslator tag="p">{dashboardData && dashboardData?.asn_count}</LanguageTranslator>
                    </div>
                    <div className='piecsics-col'>
                        <LanguageTranslator tag="h3">Box Count</LanguageTranslator>
                        <LanguageTranslator tag="p">{dashboardData && dashboardData?.box_count}</LanguageTranslator>
                    </div>
                </div>
            </div>
        </div>
    }

    const vendorWiseData = dashboardData && dashboardData?.vendWiseBoxScorePer?.map((item) => {
        return {
            "Match Percentage": item?.matchPer,
            "Mismatch Percentage": item?.mismatchedPer,
            "name": item?.vendorName,
        }
    })

    const renderRightComponent = () => {
        return (
            <div className="pie-chart-section pie-right-section">
                <div className='piecs-inner'>
                    <div className='piecsi-top'>
                        <div className='piecsit-left'>
                            <LanguageTranslator tag="h3">Vendor Wise Box Score Percentage</LanguageTranslator>
                        </div>
                        <div className='piecsit-right'>
                            <Radio.Group value={graphType} onChange={(e) => setGraphType(e?.target?.value || "")}>
                                <Radio.Button value={"normal"}><LanguageTranslator>Normal</LanguageTranslator></Radio.Button>
                                <Radio.Button value={"compact"}><LanguageTranslator>Compact Graph</LanguageTranslator></Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className='pcslc-bottom'>
                        <div className='pcslc-col'>
                            <span className='pcslcc-dot'></span>
                            <LanguageTranslator tag="h3">Match Percentage</LanguageTranslator>
                        </div>
                        <div className='pcslc-col'>
                            <span className='pcslcc-dot pcslccd-v2'></span>
                            <LanguageTranslator tag="h3">Mismatch Percentage</LanguageTranslator>
                        </div>
                    </div>
                    <div className='piecsi-bottom py-6'>
                        {dashboardData &&
                            <ResponsiveContainer width="100%" height={363}>
                                <ComposedChart
                                    data={vendorWiseData}
                                >
                                    <XAxis
                                        yAxisId="left"
                                        dataKey="name"
                                        tick={{ fontSize: 10, fontWeight: 600, fill: "#67768e", }}
                                        interval={graphType === "compact" ? "preserveEnd" : 0}
                                        label={{ position: "insideLeft" }}
                                        angle={45}
                                        textAnchor="start"
                                    />
                                    <YAxis
                                        textAnchor="end"
                                        tick={{ fontSize: 10, fontWeight: 600, fill: "#67768e" }}
                                    />
                                    <Tooltip overlayClassName='!p-[0]' labelStyle={{ color: "#111d31", fontWeight: 700, fontSize: 14 }} contentStyle={{ color: "#12203c", fontWeight: 600, fontSize: 12 }} />
                                    <Bar dataKey="Match Percentage" barSize={20} stackId="a" fill="#824bf6" />
                                    <Bar dataKey="Mismatch Percentage" barSize={20} stackId="a" fill="#ef6e8b" />
                                </ComposedChart>
                            </ResponsiveContainer>}
                    </div>
                </div>
            </div>
        );
    }

    const defaultDateValue = [dayjs().subtract(1, 'month'), dayjs()]

    return (
        <React.Fragment>
            <AllFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                MAIN_FILTERS={MAIN_FILTERS}
                disabledDate={disabledDate}
                applyFilter={getGraphdata}
                mappingSelectedFilters={mappingSelectedFilters}
                filterPayload={filterPayload}
                filterItems={filterItems}
                currentFilterKeys={currentFilterKeys}
                defaultInputState={{
                    "dateRange" : defaultDateValue
                }}
            />

            {digivendStore?.getBoxAnalysisDashboardData?.isLoading ? <BoxAnalysisSkeleton /> :
                <div className='box-analysis' >
                    {renderLeftComponent()}
                    {renderRightComponent()}
                </div>
            }
        </React.Fragment>)
}

export default BoxAnalysisDashboard