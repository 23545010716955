import React from 'react';
import enTranslationJSON from './en.json'
import enHSTranslationJSON from './enHSTranslation.json'
import chineseTranslationJSON from './chinese.json'
let languageJson = {
    "en" : enTranslationJSON,
    "en-HS" : enHSTranslationJSON,
    "zh" : chineseTranslationJSON
}
const LanguageTranslator = (props) => {
    
    let currentLanguage = sessionStorage?.getItem("CURRENT_LANGUAGE") ?  languageJson?.hasOwnProperty(sessionStorage?.getItem("CURRENT_LANGUAGE")) ? sessionStorage?.getItem("CURRENT_LANGUAGE") : "en" : "en";

    const { children,tag: Tag, ...rest } = props;

    if(Tag){
        return <Tag {...rest}>{languageJson?.[currentLanguage]?.[props?.children]  || props.children}</Tag>
    }else{
        return (languageJson?.[currentLanguage]?.[props?.children]  || props.children)
    }
}


export default LanguageTranslator;
