import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reduxObject } from '../constants/reduxObject';
import ParentModal from '../genericComponents/ParentModal';
import useCustomToast from "../hooks/useCustomToast"
import { dispatchHandler } from '../helper/apiHelperFunctions';
import FullPageLoader from '../loaders/FullPageLoader';
import ToastLoader from '../loaders/ToastLoader';
import SessionExpiredModal from './login/sessionExpired/SessionExpiredModal';
import SwitchEntResponseHandler from '../components/home/profileSection/SwitchEntResponseHandler';
import { parseJwt } from '../helper/genericFunction';
import MaxUserLimitPopup from './login/sessionExpired/MaxUserLimitPopup';

const ApiManagement = () => {
    const [selectedModal, setSelectedModal] = useState([]);
    const [openModal, setOpenModal] = useState([])
    const [removeLoading, setRemoveLoading] = useState([]);
    const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
    const [maxLimitErrorPopup, setMaxLimitErrorPopup] = useState(false)

    const dispatch = useDispatch();

    const customToast = useCustomToast();

    const generalStore = useSelector(state => state.generalStore);
    const authStore = useSelector(state => state.authStore);
    const salesManagementStore = useSelector(state => state.salesManagementStore);
    const seasonPlanningStore = useSelector(state => state.seasonPlanningStore);
    const digiArsStore = useSelector(state => state.digiArsStore)
    const administrationStore = useSelector(state => state.administrationStore);
    const masterDbStore = useSelector(state => state.masterDbStore);
    const superAdminStore = useSelector(state => state?.superAdminStore);
    const digivendStore = useSelector(state => state?.digivendStore)
    const reportsAndAnalyticsStore = useSelector(state => state?.reportsAndAnalyticsStore);
    const digiProcStore = useSelector(state => state.digiProcStore)
    const digicatStore = useSelector(state => state?.digicatStore);

    useEffect(() => {
        handleReduxState(generalStore, "generalStore");
    }, [generalStore])

    useEffect(() => {
        handleReduxState(authStore, "authStore");
    }, [authStore])

    useEffect(() => {
        handleReduxState(salesManagementStore, "salesManagementStore")
    }, [salesManagementStore])

    useEffect(() => {
        handleReduxState(seasonPlanningStore, "seasonPlanningStore")
    }, [seasonPlanningStore])

    useEffect(() => {
        handleReduxState(digiArsStore, "digiArsStore")
    }, [digiArsStore])

    useEffect(() => {
        handleReduxState(administrationStore, "administrationStore");
    }, [administrationStore]);

    useEffect(() => {
        handleReduxState(masterDbStore, "masterDbStore")
    }, [masterDbStore])

    useEffect(() => {
        handleReduxState(superAdminStore, "superAdminStore")
    }, [superAdminStore])

    useEffect(() => {
        handleReduxState(digivendStore, "digivendStore")
    }, [digivendStore])

    useEffect(() => {
        handleReduxState(reportsAndAnalyticsStore, "reportsAndAnalyticsStore")
    }, [reportsAndAnalyticsStore])

    useEffect(() => {
        handleReduxState(digiProcStore, "digiProcStore")
    }, [digiProcStore])

    useEffect(() => {
        handleReduxState(digicatStore, "digicatStore")
    }, [digicatStore])


    useEffect(() => {
        if (removeLoading?.length > 0) {
            setSelectedModal((prev) => prev?.filter(item => !removeLoading?.includes(item)))
            setOpenModal((prev) => prev?.filter(item => !removeLoading?.includes(item)))
            setRemoveLoading([]);
        }
    }, [removeLoading]);

    useEffect(() => {
        if (authStore?.["refreshTokenData"]?.isSuccess) {
            if(authStore?.["refreshTokenData"]?.data?.resource?.passwordExpired == 0){
                setSessionExpiredModal(false);
            }
        }
    }, [authStore?.["refreshTokenData"]?.isSuccess])

    // Function : To get the current api message
    const getSuccessMessage = (data) => {
        if (data?.resource?.successMessage) {
            return data?.resource?.successMessage;
        }
        if (data?.message) {
            return data?.message;
        }   
        if (data?.resource?.message) {
            return data?.resource?.message;
        }
        if (data?.successMessage) {
            return data?.successMessage;
        }
        return "Your request has been completed successfully."
    }

    const getErrorMessage = (data) => {
        let message = "";
        if (data?.message?.error?.errorMessage) message = data?.message?.error?.errorMessage;
        else if (data?.message?.error?.message) message = data?.message?.error?.message;
        else if (data?.message?.resource?.errorMessage) message = data?.message?.resource?.errorMessage;
        else if (data?.message?.resource?.message) message = data?.message?.resource?.message;
        else if (data?.message?.errorMessage) message = data?.message?.errorMessage;
        else if (data?.message?.message) message = data?.message?.message;
        else if (data?.error?.errorMessage) message = data?.error?.errorMessage;
        else if (data?.error?.message) message = data?.error?.message;
        else if (data?.resource?.errorMessage) message = data?.resource?.errorMessage;
        else if (data?.resource?.message) message = data?.resource?.message;
        else if (data?.errorMessage) message = data?.errorMessage;
        else if (data?.message) message = data?.message;
        console.log("message", message);
        if (typeof (message) == "object") {
            message = message?.errorMessage || "";
        }
        let tokenData = parseJwt(sessionStorage.getItem("token"))
        if (["database exception", "error updating database"]?.some(item => message?.toLowerCase()?.includes(item))) {
            message = "We are unable to preocess your request at the moment."
        }
        if (message?.toLowerCase()?.includes("session expired")) {
            if(tokenData?.loginSource?.toUpperCase() == "SELF")
                setSessionExpiredModal(true)
            else if(tokenData?.loginSource?.toUpperCase() == "OTHER") setSessionExpiredModal("invalidatedSession")
            else if(data?.resource?.passwordExpired === 1){
                setSessionExpiredModal("passwordExpired");
            }
        }
        if(data?.message?.errorCode == "422"){
            if(tokenData?.loginSource?.toUpperCase() == "SELF")
                setSessionExpiredModal("invalidToken")
            else if(tokenData?.loginSource?.toUpperCase() == "OTHER") 
                setSessionExpiredModal("invalidatedSession")
        }
        return message || "We are unable to preocess your request at the moment."
    }


    const handleReduxState = (state, key) => {
        reduxObject?.[key]?.map((obj) => {
            let timer;
            // For all api of the given key
            if (state?.[`${obj?.["reduxKey"]}Data`]?.isLoading) {
                if (obj.loading === "toastLoader") {
                    if (!selectedModal.includes(obj.reduxKey))
                        setSelectedModal(prev => [...prev, obj.reduxKey]);
                }
                else if (obj.loading === "fullPageLoader") {
                    if (!openModal.includes(obj.reduxKey))
                        setOpenModal(prev => [...prev, obj.reduxKey]);
                }

                let dynamicTime = ["getProductAttributeFromImage"]?.includes(obj?.reduxKey) ? 200000 : ["submitProcExcel", "validateProcExcel", "validateGenericExcelSheet" ]?.includes(obj?.reduxKey) ? 70000 : 40000;

                timer = setTimeout(() => {
                    !removeLoading?.includes(obj?.reduxKey) && setRemoveLoading(prev => [...prev, obj?.reduxKey]);
                    dispatchHandler(dispatch, `${obj.reduxKey}ClearRequest`);
                }, dynamicTime)

            } else if (state?.[`${obj?.["reduxKey"]}Data`]?.isSuccess) {
                let message = getSuccessMessage(state?.[`${obj?.["reduxKey"]}Data`]?.data);
                if (obj?.successMessage) {
                    customToast({
                        title: state?.[`${obj?.["reduxKey"]}Data`]?.["message"] || "Operation Successful!",
                        description: message,
                        status: "success",
                    });
                }
                !removeLoading?.includes(obj?.reduxKey) && setRemoveLoading(prev => [...prev, obj?.reduxKey]);
                dispatchHandler(dispatch, `${obj.reduxKey}ClearRequest`);
                clearTimeout(timer);
            } else if (state?.[`${obj?.["reduxKey"]}Data`]?.isError) {
                let message = getErrorMessage(state?.[`${obj?.["reduxKey"]}Data`]);

                if(["allowPortalAccess", "coreUserCreateUpdate", "arsSiteConfigChanges"]?.includes(obj?.["reduxKey"]) && message?.toLowerCase()?.includes("maximum")){
                    setMaxLimitErrorPopup(obj?.["reduxKey"])
                }else{
                    customToast({
                        title: state?.[`${obj?.["reduxKey"]}Data`]?.message?.["reduxMessage"] || "An Error Occured!",
                        description: message,
                        status: "error",
                    });
                }
                !removeLoading?.includes(obj?.reduxKey) && setRemoveLoading(prev => [...prev, obj?.reduxKey]);
                dispatchHandler(dispatch, `${obj.reduxKey}ClearRequest`);
                clearTimeout(timer);
            }
        })
    }

    return (
        <React.Fragment>
            {selectedModal?.length > 0 && <ToastLoader />}
            {openModal?.length > 0 &&
                <FullPageLoader />
            }

            {/* Handler of the switch ent api response */}
            <SwitchEntResponseHandler />

            {sessionExpiredModal && <ParentModal
                getParentChildProps={{
                    isCloseBlocked : sessionExpiredModal === "invalidatedSession" ,
                    closeModal: sessionExpiredModal === "invalidatedSession" ? null : () => setSessionExpiredModal(false),
                    // postitionProps: { top: '27%', left: '27%', borderRadius: 0, margin: 0 },
                    // postitionProps: { top: '50%', left: '50%',transform: 'translate(-50%, -50%)', borderRadius: 0, margin: 0 },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
					postitionProps: { position: 'relative' },
                    // dimensions: { width: "46vw", height: "46vh", background: "transparent" },
                    dimensions: { width: "470px", height: "fit-content", maxHeight: '90vh' },
                    invalidToken : sessionExpiredModal === "invalidToken",
                    zIndexClass : 'zi999',
                    invalidatedSession : sessionExpiredModal === "invalidatedSession",
                    sessionExpiredModal : sessionExpiredModal
                }}
                childComponent={SessionExpiredModal}
                closeModal={() => { }}
            />}

            {maxLimitErrorPopup && <ParentModal
                getParentChildProps={{
                    closeModal: () => setMaxLimitErrorPopup(false),
                    // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 0, margin: 0, background: 'white' },
                    parentModalOuterMostClassName: 'flex items-center justify-center',
					postitionProps: { position: 'relative', background: 'white' },
                    dimensions: { width: "500px", height: "fit-content", maxHeight: '430px', overflowY: 'auto', background: "transparent" },
                    type : ["arsSiteConfigChanges"]?.includes(maxLimitErrorPopup) ? 2 : 1,
                    zIndexClass: "zi999"
                }}
                childComponent={MaxUserLimitPopup}
                closeModal={() => { }}
            />}
        </React.Fragment>
    )
}

export default ApiManagement