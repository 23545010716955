/* eslint-disable default-case */
import React from 'react'
import LanguageTranslator from '../locals/LanguageTranslator'
import {motion} from "framer-motion";

const GenericTabsComponent = (props) => {
    const { current, handleTabChange, currentTabMultipleClickAllowed = false, tabsData, tabsExtra, disabledAction, type = 1, className, disabledTab, borderDown, animatedUnderline = true } = props
    
    const getTabsKey  = ()=>{
        let modifiedKey = ''
       Object.keys(tabsData || {}).forEach((key) =>{
            modifiedKey += key?.toString().slice(0,1);  
        })
        return modifiedKey;
    }

    const renderTabs = () => {
        return <ul key={current} className={`generic-tabs ${className}`}>
            {Object.keys(tabsData || {})?.map(key => {
                return (
                    <LanguageTranslator tag = "button" className={`gt-list relative ${current == key ? "activeTab" : ""}`} disabled={disabledTab?.[key] || false} onClick={() => {(currentTabMultipleClickAllowed ?  true : key!=current) && handleTabChange(key)}} key = {key} >
                        <LanguageTranslator>{tabsData?.[key]}</LanguageTranslator> {tabsExtra?.[key]()}
                        {animatedUnderline && current === key ? (
                                <motion.div
                                    className={`absolute h-[2px] bg-primary ${borderDown ? 'top-full' : '-bottom-[1px]'} left-0 right-0`}
                                    // layoutId="underline"
                                    // id="underline"
                                    layoutId={getTabsKey()}
                                    id={getTabsKey()}
                                    initial={false}
                                    transition={{
                                        duration: .5,
                                        type: 'spring'
                                    }}
                                />
                            ) : null}
                    </LanguageTranslator>
                )
            })}
        </ul>
    }

    const renderTabsData = () => {
        switch(type){
            case 1 : {
                return tabsData && Object.keys(tabsData || {})?.length > 0 && renderTabs()
            }
        }
    }

    return (
        <React.Fragment>
            {renderTabsData()}
        </React.Fragment>
    )
}

export default GenericTabsComponent