import { openDB } from 'idb';
import React, { useEffect } from 'react'

const DraftForms = () => {

    //  component : to handle the draft forms and their initals
    useEffect(() => {
        createNewApplicationOfflineDatabase();
    }, []);

    const createNewApplicationOfflineDatabase = async () => {
        // create a new database for draft forms
        // add the necessary methods and logic to handle offline operations
        // like saving the draft forms, retrieving, deleting, etc.

        let db = await openDB('Supplymint-ai-offline-storage', 1, {
            upgrade(db) {
                db.createObjectStore("DARFT_FORM_TABLE");
            }
        });
        // perform offline operations here, like saving draft forms, retrieving, deleting, etc.
        db?.close();

    }

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default DraftForms
