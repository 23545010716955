import React from 'react'
import { renderUpgradePopup } from '../../helper/components/CacheChecker';
import { useClearCache } from 'react-clear-cache'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const UpgradePopUpRender = () => {
  const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache();
  console.log("LAtest version", isLatestVersion);

  const location = useLocation();

  // Function : To check 
  const shouldRenderUpgradePopup = () => {
    if (location.pathname?.includes('/catalogue/productSurvey/complete')) return false;
    return true;
  }
  return (
    shouldRenderUpgradePopup() && renderUpgradePopup({
      isLatestVersion: false,
      emptyCacheStorage,
      renderFromModal: true,
      latestVersion
    })
  )
}

export default UpgradePopUpRender