import React, { useContext, useEffect, useState } from 'react'
import { UserDetailsContext } from '../../App';
import ListComponent from '../../genericComponents/ListComponent';
import Icons from '../../assets/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LanguageTranslator from '../../locals/LanguageTranslator';
import { ChevronDown, ChevronUp, CirclePlay, Folder, FolderMinus, FolderPlus } from 'lucide-react';
import GenericFormHeaderComponent from '../../genericComponents/forms/GenericFormHeaderComponent';
import GenericInput from '../../genericComponents/input/GenericInputComponent';
import { parseJwt } from '../../helper/genericFunction';
import NoDataFound from '../../genericComponents/NoDataFound';

function ReplayAppTourModal(props) {

    const history = useHistory();

    const { userDetails } = useContext(UserDetailsContext);

    const dashboardModules = JSON.parse(userDetails?.dashboardModules || "[]");
    let modules = JSON.parse(userDetails?.modules || "[]");
    const [modulesJson, setModulesJson] = useState({});
    const availableModules = ["RDAMAIN", "RADMMAIN", "MDMMAIN", "SALMNGMAIN", "RSUPERADM", "VDREPOMAIN", "RDREPOMAIN", "RDVMAIN", "VDVMAIN", "RDPMAIN", "CSALMNGMAIN", "PDRMAIN", "PCVMAIN", "CDREPOMAIN"] // Available modules in newer portal
    let availableDashboardModules = ["RDADASH", "RDARSDASHSKX", "SALMNGDASH", "VDVDASHORDV2", "RDVDASHWH", "RDPDASH", "RDVDASHV2", "RDVDASHBOX", "RDVDASHASN", "CSALMNGDASH", "RDPCATDASH", "VDPCATDASH"];

    // const [tourList, setTourList] = useState([])
    const [currentExpandedModule, setCurrentExpandedModule] = useState({ mainLevelId: null, secondLevelId: null })
    const [search, setSearch] = useState("");


    const filteredDashboards = dashboardModules?.reduce((acc, item) => {
        if (availableDashboardModules?.includes(item?.code))
            acc[item.pageUrl] = (parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T" && item?.name == "Order Tracking") ? "Transporter Dashboard" : item?.name;
        return acc;
    }, {});

    const appTourList = {
        "/inventoryPlanning/assortment": "Plannig Assortment",
        "/inventoryPlanning/siteMapping": "Site To Site Mapping",
        "/inventoryPlanning/summary": "Replenishment Summary",
        "/inventoryPlanning/transferOrder": "Transfer Order",
        "/digiproc/excelUploadHistory": "Excel Upload History",
        "/digiproc/manage/mrpRange": "Mrp Range",
        "/digiproc/manageOtbPlan": "Manage Otb Plan",
        "/enterprise/purchaseOrder/pendingOrders": "Open PO",
        "/enterprise/order/design": "Order Design",
        "/enterprise/purchaseOrder/processedOrders": "Processed Order",
        "/enterprise/purchaseOrder/cancelledOrders": "Cancelled Order",
        "/enterprise/qualityCheck/pendingQc": "Pending Quality",
        "/enterprise/qualityCheck/cancelledInspection": "Cancelled Inspection",
        "/enterprise/shipment/asnUnderApproval": "ASN Under Approval",
        "/enterprise/shipment/approvedAsn": "Approved ASN",
        "/enterprise/shipment/cancelledAsn": "Cancelled ASN",
        "/enterprise/logistics/lrProcessing": "LR Processing",
        "/enterprise/logistics/goodsIntransit": "Goods In-Transit",
        "/enterprise/logistics/goodsDelivered": "Gate Entry",
        "/enterprise/logistics/grcStatus": "GRC Status",
        "/digivend/manage/autoASN": "Auto ASN",
        "/digivend/manage/siteAppointment": "Site Appointment",
        "/digivend/manage/poConfiguration": "PO Configuration",
        "/central/comment": "Central Comment",
        "/retailer/retailerPurchaseInvoice": "Purchase Invoice",
        "/retailer/retailerBillDiscounting": "Bill Discounting",
        "/core/custom/upload/digivend": "Custom Data Upload",
        "/digiproc/fabricNomination": "Fabric Nomination",
        "/digiproc/fabricConsumptionLogs": "Fabric Consumption Logs",
        "/purchase/purchaseIndentHistory": "View Indents",
        "/purchase/purchaseOrderHistory": "View Orders",
        "/salesManagement/customer/customerMapping": "Sales Customer Mapping",
        "/salesManagement/manageDcChallan": "Sales Manage DcChallan",
        "/salesManagement/retailerManageBuy": "Buying Plan Managements",
        "/digiars/inventoryPlanningReport": "Inventory Planning Report",
        "/inventoryPlanning/transferOrderReport": "Transfer Order Report",
        "/digiars/salesInventoryReport": "Sales Inventory Report",
        "/inventoryPlanning/saleContribution": "Sale Contribution",
        "/inventoryPlanning/lastMonthReport": "Sales Comparison",
        "/inventoryPlanning/topBottomReports": "Sell Thru Performance",
        "/inventoryPlanning/catSizeReport": "Category Size Inventory",
        "/inventoryPlanning/topMovingReport": "Top Moving Items",
        "/inventoryPlanning/sell/through/report": "Sell Through Report",
        "/inventoryPlanning/report7": "LY Comparison",
        "/inventoryPlanning/bill/report": "Bill Collection Report",
        "/analytics/retailerSalesReport": "Sales Report",
        "/analytics/retailerSalesReturnReport": "Return Report",
        "/analytics/retailerSalesReport/SALES_PERSON_REPORT": "Sales Person Report",
        "/analytics/retailerSalesStockItemReport": "Sales Stock Item Report",
        "/salesManagement/salesDrrMisReport": "Sales MIS",
        "/salesManagement/so/status/report": "Sales Order Status",
        "/inventoryPlanning/grn/report": "GRN Report",
        "/analytics/opsreport": "Ops Report",
        "/analytics/dispatchreport": "Dispatch Report",
        "/analytics/retailerPoReport": "Orders Report",
        "/analytics/entPurchaseReport": "PO Shipment Report",
        "/analytics/potogrnreport": "PO GRN Report",
        "/analytics/multiLevelApprovalLogs": "Multilevel Approval Report",
        "/analytics/poGrnException": "PO GRN Exception",
        "/analytics/tnaorderstatus": "T&A Report",
        "/analytics/retailerLedgerReport": "Ledger Report",
        "/analytics/retailerOutstandingReport": "Retailer Outstanding Report",
        "/analytics/customerOutstandingReport": "Customer Outstanding Report",
        "/analytics/customerLedgerReport": "Customer Ledger Report",
        "/analytics/retailerStockReport": "Retailer Stock Report",
        "/analytics/stockagingReport": "Stock Aging Report",
        "/salesManagement/stockDrrMisReport": "Stock MIS",
        "/mdm/factoryaddressreport": "Factory address report",
        "/analytics/appointmentReport": "Appointment Report",
        "/analytics/retailerLrReport": "Retailer Lr Report",
        "/analytics/logisticsInventoryReport": "Logistics Inventory Report",
        "/analytics/logisticsPaymentReconciliation": "Logistics Payment Reconciliation",
        "/analytics/autoAsnReport": "Auto Asn Report",
        "/analytics/shipmentBoxReport": "Shipment Box Report",
        "/analytics/retailerInspectionReport": "Retailer Inspection Report",
        "/analytics/retailerAsnReport": "Retailer Asn Report",
        "/mdm/manageVendors": "Vendors",
        "/mdm/manageItems": "Items",
        "/mdm/manageAgent": "Agent",
        "/mdm/manageTransporters": "Transporters",
        "/mdm/materiallibrary": "Material Library",
        "/mdm/fabric/library": "Fabric Library",
        "/catalogue/cost/sheet/library": "Costsheet Library",
        "/mdm/manageMailingList": "Mailing List",
        "/mdm/manageCustomers": "Customers",
        "/mdm/manageSalesAgents": "Sales Agents",
        "/mdm/manageSites": "Sites",
        "/administration/rolesMaster/manageRoles": "Roles",
        "/administration/rolesMaster/manageRolesNew": "Roles New",
        "/administration/rolesMaster/createRoles": "Create Roles",
        "/administration/users": "Users",
        "/administration/userDataMapping": "User Data Mapping",
        "/administration/dataSync": "Data Sync",
        "/administration/apilogs": "Api Logs",
        "/administration/auditLogs": "Audit Logs",
        "/administration/systemMails": "System Mails",

        "/digiproc/createIndent": "Create Indent",
        "/digiproc/createOrder": "Create Order",
        "/salesManagement/viewSalesOrders": "View Sales Orders",
        "/catalogue/products/history": "Product History",
        "/administration/globalConfiguration": "Global Configuration",

        "/inventoryPlanning/event-mapping": "Event Mapping",
        "/inventoryPlanning/inventoryAutoConfig": "Inventory Auto Config",
        "/inventoryPlanning/order": "Order Fulfillment",
        "/inventoryPlanning/history": "Replenishment History",
        "/inventoryPlanning/manageAdhocRequest": "Manage Adhoc Request",
        "/core/custom/upload/digiars": "ARS Custom Data Upload",
    };

    const dashboardPathObj = {
        "/home/asn/dashboard": "Asn Dashboard",
        "/home/box/analysis/dashboard": "Box Analysis Dashboard",
        "/home/ars/new/dashboard": "Ars New Dashboard",
        "/home/customer/dashboard": "Customer Dashboard",
        "/home/procurement/dashboard": "Procurement Dashboard",
        "/home/wh/appointment/dashboard": "Appointment Dashboard",
        "/home/orderTracking/dashboard": "OrderTracking Dashboard",
        "/home/vendor/orderTracking/dashboard": "OrderTracking Dashboard",
        "/home/ars/dashboard": "Ars Dashboard",
        "/home/sales/dashboard": "Sales Dashboard",
    }

    useEffect(() => {
        let obj = {};

        modules?.filter(item => availableModules?.includes(item?.code))?.map(item => {
            const updateObject = (module, mainCode) => {
                module?.map(val => {
                    if (val?.isPage == 1) {
                        if (!obj[mainCode]) obj[mainCode] = {};
                        obj[mainCode][val.pageUrl] = true;
                    } else {
                        updateObject(val.subModules, mainCode);
                    }
                })
            }
            updateObject(item.subModules, item?.code)
        })

        setModulesJson(obj);
    }, [])

    // useEffect(() => {
    //     const getPageUrls = (menu) => {
    //         const urls = [];
    //         const collectUrls = (modules) => {
    //             modules.forEach(module => {
    //                 if (module.pageUrl) urls.push(module.pageUrl);
    //                 if (module.subModules && module.subModules.length > 0) {
    //                     collectUrls(module.subModules);
    //                 }
    //             });
    //         };
    //         collectUrls(menu);
    //         return urls;
    //     }

    //     const filterAppTourList = (sidebarMenu, appTourList) => {
    //         const validPageUrls = getPageUrls(sidebarMenu);
    //         const filteredAppTourList = {};

    //         for (const [path, value] of Object.entries(appTourList)) {
    //             if (validPageUrls.some(url => path.endsWith(url.replace(/^#/, '')))) {
    //                 filteredAppTourList[path] = value;
    //             }
    //         }

    //         return filteredAppTourList;
    //     }

    //     const filteredAppTourList = filterAppTourList(modules, appTourList);

    //     // Get valid paths from dashboardArray
    //     const validPaths = dashboardModules?.map((item) => item?.pageUrl?.replace("#", ""));

    //     // Filter the dashboardPathObj
    //     const filteredDashboardPathObj = Object.keys(dashboardPathObj)
    //         .filter((path) => validPaths.includes(path))
    //         .reduce((obj, key) => {
    //             obj[key] = dashboardPathObj[key];
    //             return obj;
    //         }, {});

    //     const combinedObj = { ...filteredDashboardPathObj, ...filteredAppTourList };
    //     let newValue = Object.keys(combinedObj).map((path) => ({
    //         path,
    //         label: combinedObj[path],
    //     }));

    //     setTourList(newValue)
    // }, [])

    const handleChange = (action, data) => {
        console.log(22222222, action, data, history)
        switch (action) {
            case "replayTour": {
                history.push({
                    pathname: data?.path?.toString().charAt(0) === '#' ? data?.path?.toString().slice(1) : data?.path,
                    replayAppTour: true
                })
                props?.closeModal()
                break;
            }

            case "toggleModuleExpand":
                setCurrentExpandedModule(prev =>
                    prev?.mainLevelId === data?.id ? null : { mainLevelId: data?.id }
                );
                break;


            case "toggleSecondLevelModuleExpand":
                setCurrentExpandedModule(prev => ({
                    ...prev,
                    secondLevelId: prev?.secondLevelId === data?.id ? null : data?.id
                }));
                break;

            default:
                break;
        }
    }

    // Function to render the submenu
    const renderMenuByCategory = subModules => {
        const CATEGORY_MAP = {};
        subModules?.forEach(item => {
            if (!CATEGORY_MAP[item.category]) {
                CATEGORY_MAP[item.category] = [];
            }
            CATEGORY_MAP[item.category].push(item);
        });
        return (
            <div className="smv2">
                {Object.keys(CATEGORY_MAP || {})?.map(key =>
                    <ul className="sm-cat-vice" key={key}>
                        <li>{key}</li>
                        {CATEGORY_MAP?.[key]?.map((item, index) =>
                            <li>
                                {item?.name}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        );
    }

    // Function -> To get the current icon
    const getIcon = (url) => {
        switch (url) {
            case "digi-ars-icon.svg": return < Icons.DigiArsMenuIcon className='w-[20px] h-auto' />
            case "digi-vendor-icon.svg": return < Icons.DigiVendorMenuIcon className='w-[20px] h-auto' />
            case "vendor-catalogue-icon.svg":
            case "ent-catalogue-icon.svg": return < Icons.EntCatalogueMenuIcon className='w-[20px] h-auto' />
            case "sales-management.svg": return < Icons.SalesManagementMenuIcon className='w-[20px] h-auto' />
            case "master-data-icon.svg": return < Icons.MasterDataMenuIcon className='w-[20px] h-auto' />
            case "admin-icon-sidebar.svg": return < Icons.AdminIconMenuIcon className='w-[20px] h-auto' />
            // case "vendor-catalogue-icon.svg": return < Icons.VendorCatalogueMenuIcon />
            case "invoice-management.svg": return < Icons.InvoiceManagementMenuIcon className='w-[20px] h-auto' />
            case "digi-proc-icon.svg": return < Icons.DigiProcMenuIcon className='w-[20px] h-auto' />
            case "reports-analytics.svg": return < Icons.ReportsAnalyticsMenuIcon className='w-[20px] h-auto' />
            case "superadmin-icon-sidebar.svg": return < Icons.SuperAdminMenuIcon className='w-[20px] h-auto' />
            default: return <Icons.DigiArsMenuIcon className='w-[20px] h-auto' />
        }
    }

    const getIconFilled = (url) => {
        switch (url) {
            case "digi-ars-icon.svg": return < Icons.DigiArsMenuIconFilled className='w-[20px] h-auto' />
            case "digi-vendor-icon.svg": return < Icons.DigiVendorMenuIconFilled className='w-[20px] h-auto' />
            case "vendor-catalogue-icon.svg":
            case "ent-catalogue-icon.svg": return < Icons.EntCatalogueMenuIconFilled className='w-[20px] h-auto' />
            case "sales-management.svg": return < Icons.SalesManagementMenuIconFilled className='w-[20px] h-auto' />
            case "master-data-icon.svg": return < Icons.MasterDataMenuIconFilled className='w-[20px] h-auto' />
            case "admin-icon-sidebar.svg": return < Icons.AdminIconMenuIconFilled className='w-[20px] h-auto' />
            // case "vendor-catalogue-icon.svg": return < Icons.VendorCatalogueMenuIconFilled />
            case "invoice-management.svg": return < Icons.InvoiceManagementMenuIconFilled className='w-[20px] h-auto' />
            case "digi-proc-icon.svg": return < Icons.DigiProcMenuIconFilled className='w-[20px] h-auto' />
            case "reports-analytics.svg": return < Icons.ReportsAnalyticsMenuIconFilled className='w-[20px] h-auto' />
            case "superadmin-icon-sidebar.svg": return < Icons.SuperAdminMenuIconFilled className='w-[20px] h-auto' />
            default: return <Icons.DigiArsMenuIconFilled className='w-[20px] h-auto' />
        }
    }

    const getModuleName = (url) => {
        switch (url) {
            case "digi-ars-icon.svg": return 'DigiARS'
            case "digi-vendor-icon.svg": return 'DigiVend'
            case "vendor-catalogue-icon.svg":
            case "ent-catalogue-icon.svg": return 'DigiCatalogue'
            case "sales-management.svg": return 'Sales Management'
            case "master-data-icon.svg": return 'Master Data'
            case "admin-icon-sidebar.svg": return 'Administration'
            // case "vendor-catalogue-icon.svg": return < Icons.VendorCatalogueMenuIcon />
            case "invoice-management.svg": return 'Invoice Management'
            case "digi-proc-icon.svg": return 'DigiProc'
            case "reports-analytics.svg": return 'Reports & Analytics'
            case "superadmin-icon-sidebar.svg": return 'Super Admin'
            default: return '-'
        }
    }

    // Function to render the menu list
    const renderMenu = (module, isLevel2 = false) => {
        console.log(55555555555555, module)
        return <React.Fragment>
            <ul className={`sub-menu relative flex flex-col ${isLevel2 ? 'pl-4 mt-[-2px]' : 'px-4 pt-[3px]'}`} id="subMenu">
                {!availableModules.includes(module?.code) && <div className='absolute top-[-12px] left-[10px] h-[calc(100%+23px)] w-[2px] bg-borderSlateGrey'></div>}
                {/* {!module.parentCode && <li className="sm-head">
                    <span data-key="order_number" className="sm-head-menu"><LanguageTranslator>{module.name}</LanguageTranslator></span>
                </li>} */}
                {module?.subModules?.[0]?.category ?
                    renderMenuByCategory(module?.subModules) :
                    module?.subModules?.map((subModule, index) => {
                        const filteredSubModules = module?.subModules?.filter((item)=> (item?.pageUrl !== null || item?.subModules !== null))
                        const filteredPages = module?.subModules?.filter((item)=>item?.isPage !== 0);
                        if (subModule?.pageUrl === null && subModule?.subModules === null) return null
                        else {
                            return (
                                <li className={`relative last:mb-4 ${filteredPages[0]?.id === subModule?.id && !isLevel2 ? 'mt-[-6px]' : ''} ${filteredPages[filteredPages.length -1]?.id === subModule?.id ? 'mb-2' : ''} ${subModule?.isPage !== 0 ? 'flex items-end ' : ''}`}>
                                    {subModule?.isPage === 0 && index < (filteredSubModules.length - 1) && !isLevel2 && <div className={`${index === (module?.subModules?.length - 1) ? 'hidden' : ''} absolute top-[28px] left-[2px] h-[20px] mx-2 w-[2px] bg-borderSlateGrey`}></div>}

                                    {subModule?.isPage !== 0 && <div className={`${index === 0 ? 'pt-[1px]' : ''} ${subModule?.isPage === 0 ? '' : 'mt-[-9px]'} ${isLevel2 ? 'mb-[2px] ml-3' : 'mb-[4px] ml-[10px]'}`}>
                                        {
                                            (index === 0 || filteredPages[0]?.id === subModule?.id) ?
                                                <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.64844 0.5V29C1.64844 30.933 3.21544 32.5 5.14844 32.5H10.6484V33.5H5.14844C2.66316 33.5 0.648438 31.4853 0.648438 29V0.5H1.64844Z" stroke="#DAE4EE" />
                                                </svg> :
                                                <svg width="12" height="43" viewBox="0 0 12 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.64844 0.5V38C1.64844 39.933 3.21544 41.5 5.14844 41.5H10.6484V42.5H5.14844C2.66316 42.5 0.648438 40.4853 0.648438 38V0.5H1.64844Z" stroke="#DAE4EE" />
                                                </svg>
                                        }
                                    </div>}

                                    <div className={`group playIconWrapper ${subModule?.isPage !== 0 ? 'relative top-[10px] pl-2.5 pr-2 py-2 rounded-[3px] hover:bg-primary_w_less_opacity' : 'my-2'} w-full flex items-center gap-1.5 cursor-pointer`} onClick={() => subModule?.isPage === 0 ? handleChange('toggleSecondLevelModuleExpand', subModule) : handleChange("replayTour", { path: subModule?.pageUrl, label: subModule?.name })}>
                                        {subModule?.isPage === 0 && <span className={`duration-200 ${currentExpandedModule?.secondLevelId === subModule?.id ? 'rotate-90' : 'rotate-0'}`}><Icons.DownArrowFilledGrey /></span>}
                                        {subModule?.isPage !== 0 && !isLevel2 && <span className='-ml-0.5'><Folder size={16} /></span>}
                                        {subModule?.isPage === 0 && <span className='-ml-0.5'>{currentExpandedModule?.secondLevelId === subModule?.id ? <FolderMinus size={16} /> : <FolderPlus size={16} />}</span>}
                                        <LanguageTranslator tag="p" className={`mb-0 w-full flex items-center justify-between gap-3 group-hover:text-primary ${(subModule?.isPage === 0 || (subModule?.isPage !== 0 && !isLevel2)) ? 'text-[13px] font-semibold text-[#21314B]' : 'text-xs font-medium text-fontGrey'}`}>
                                            <span className={`${isLevel2 ? 'group-hover:text-primary' : ''}`}>{subModule?.name || ""}</span>
                                            {subModule?.isPage !== 0 && <span className='text-xs font-normal flex items-center gap-1 text-[#8496A9] group-hover:text-fontBlack group-hover:font-medium'>Play <Icons.PlayIcon className='playIconSvg' /></span>}
                                        </LanguageTranslator>
                                    </div>
                                    {/* {subModule?.isPage === 0 &&
                                        (<svg className="drop-menu-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.485" height="10.97" viewBox="0 0 6.485 10.97">
                                            <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_206" d="M6 9l4.071 4.071L14.142 9" data-name="Path 206" transform="rotate(-90 3.985 11.571)" />
                                        </svg>)} */}
                                    {subModule?.isPage === 0 && currentExpandedModule?.secondLevelId === subModule?.id && renderMenu(subModule, true)}
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </React.Fragment>
    }

    console.log('filteredDashboards', filteredDashboards)

    const renderMenuItems = () => {
        return (
            <div className='my-1 py-1 text-fontBlack h-[calc(100%-140px)] overflow-auto'>
                {/* Render the complete menu list */}
                {(!search || 'home'.includes(search)) && <li className='list-none'>
                    <div onClick={() => handleChange('toggleModuleExpand', { id: 'home' })} className={`w-full px-4 pr-6 py-3.5 flex justify-between items-center gap-3 cursor-pointer hover:bg-primary_w_less_opacity ${currentExpandedModule?.mainLevelId === 'home' ? 'bg-primary_w_less_opacity' : ''}`}>
                        <div className='flex items-center gap-3'>
                            <span>
                                <span className="h2mi-icon-area">
                                    {currentExpandedModule?.mainLevelId === 'home' ? <Icons.HomeIconFilled /> : <Icons.HomeIcon />}
                                </span>
                            </span>
                            <p className='mb-0 text-[13px] font-semibold'>Home</p>
                        </div>
                        <div className={`relative`}>
                            <span className={`duration-200 ${currentExpandedModule?.mainLevelId !== 'home' ? 'scale-100 opacity-100 visible pointer-events-auto' : 'scale-0 opacity-0 invisible pointer-events-none'}`}>
                                <ChevronDown size={15} strokeWidth={2.5} />
                            </span>
                            <span className={`absolute top-0 duration-200 ${currentExpandedModule?.mainLevelId === 'home' ? 'scale-100 opacity-100 visible pointer-events-auto' : 'scale-0 opacity-0 invisible pointer-events-none'}`}>
                                <ChevronUp size={15} strokeWidth={2.5} />
                            </span>
                        </div>
                    </div>
                    {/* <span className='text-xs font-normal flex items-center gap-1 text-[#8496A9] group-hover:brightness-50'>Play <Icons.PlayIcon/></span> */}
                    {currentExpandedModule?.mainLevelId === 'home' &&
                        <ul className='flex flex-col px-4 pt-[8px]'>
                            {
                                Object.keys(filteredDashboards || {})?.map((key, index) => (
                                    <li className={`last:pb-4 relative flex items-end gap-2`}>

                                        <div className={`mb-[4px] ml-2.5 mt-[-9px]`}>
                                            {
                                                index === 0 ?
                                                    <svg width="12" height="34" viewBox="0 0 12 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.64844 0.5V29C1.64844 30.933 3.21544 32.5 5.14844 32.5H10.6484V33.5H5.14844C2.66316 33.5 0.648438 31.4853 0.648438 29V0.5H1.64844Z" stroke="#DAE4EE" />
                                                    </svg> :
                                                    <svg width="12" height="43" viewBox="0 0 12 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.64844 0.5V38C1.64844 39.933 3.21544 41.5 5.14844 41.5H10.6484V42.5H5.14844C2.66316 42.5 0.648438 40.4853 0.648438 38V0.5H1.64844Z" stroke="#DAE4EE" />
                                                    </svg>
                                            }
                                        </div>

                                        <div className={`group py-1 mb-[-8px] w-full flex items-center gap-1.5 cursor-pointer`} onClick={() => handleChange("replayTour", { path: key, label: filteredDashboards[key] })}>
                                            <Folder size={16} />
                                            <LanguageTranslator tag="p" className={`mb-0 w-full flex items-center justify-between gap-3 group-hover:text-primary text-[13px] font-semibold text-[#21314B]`}>
                                                <span>{filteredDashboards[key] || ""}</span>
                                                <span className='text-xs font-normal flex items-center gap-1 text-[#8496A9]'>Play <Icons.PlayIcon /></span>
                                            </LanguageTranslator>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    }
                </li>}
                {modules?.filter(item => availableModules?.includes(item?.code))?.map((_, key) => {
                 if (getModuleName(_.iconUrl).toLowerCase().includes(search?.toLowerCase().trim())) return (
                        <li className='list-none'>
                            <div onClick={() => handleChange('toggleModuleExpand', _)} className={`px-4 pr-6 py-3.5 flex justify-between items-center gap-3 cursor-pointer hover:bg-primary_w_less_opacity ${currentExpandedModule?.mainLevelId === _.id ? 'bg-primary_w_less_opacity' : ''}`}>
                                <div className='flex items-center gap-3'>
                                    <span>
                                        {/* getIconFilled(_.iconUrl) :  */}
                                        {currentExpandedModule?.mainLevelId === _.id ? getIconFilled(_.iconUrl) : getIcon(_.iconUrl)}
                                    </span>
                                    <p className='mb-0 text-[13px] font-semibold'>{getModuleName(_.iconUrl)}</p>
                                </div>
                                <div className={`relative`}>
                                    <span className={`duration-200 ${currentExpandedModule?.mainLevelId !== _.id ? 'scale-100 opacity-100 visible pointer-events-auto' : 'scale-0 opacity-0 invisible pointer-events-none'}`}>
                                        <ChevronDown size={15} strokeWidth={2.5} />
                                    </span>
                                    <span className={`absolute top-0 duration-200 ${currentExpandedModule?.mainLevelId === _.id ? 'scale-100 opacity-100 visible pointer-events-auto' : 'scale-0 opacity-0 invisible pointer-events-none'}`}>
                                        <ChevronUp size={15} strokeWidth={2.5} />
                                    </span>
                                </div>
                            </div>

                            {
                                currentExpandedModule?.mainLevelId === _.id && renderMenu(_)
                            }
                        </li>
                    )
                })}
            </div>
        )
    }

    console.log(7777777777, modules?.filter(item => availableModules?.includes(item?.code)))

    return <>
        <GenericFormHeaderComponent
            label={{
                contentPrimary: 'Product Tour',
                contentSecondry: 'Please find below the list of tour guides to help you understand the product better.'
            }}
        />
        <div className='px-[15px]'>
            <GenericInput
                inputKey="searchTour"
                placeholder="Search Path for Product Tour..."
                // isDisabled={loading?.[getAllCommentsName]}
                dependentFieldsObject={["searchTour"]}
                onChange={(e) => {
                    setSearch(e?.target?.value || "")
                    // if ((e?.target?.value == "" || e?.target?.value == undefined) && search) {
                    //     setCommentsListData([]);
                    // }
                }}
                value={search}
                iconLeft={<span className='ml-[6px] mr-[8px] text-black'><Icons.SearchIconDark /></span>}
                // onKeyPress={(e) => {
                //     if (e.key == "Enter") {
                //         setCommentsListData([]);
                //         getComments({ timestamp: dayjs()?.format("YYYY-MM-DDTHH:mm:ss") })
                //     }
                // }}
                className={`placeholder:font-normal rounded-none border-[#DAE4EE]`}
            // genInputExtraClass={className || ''}
            />
        </div>
        {(Object.keys(filteredDashboards || {})?.filter((item)=> 'home'.includes(search?.toLowerCase().trim()))?.length > 0 || modules?.filter(item => availableModules?.includes(item?.code) && getModuleName(item.iconUrl).toLowerCase().includes(search?.toLowerCase().trim()))?.length > 0) ?
            renderMenuItems() :
            <NoDataFound />
        }
    </>;

    // if (tourList?.length) return (
    //     <>
    //         <ListComponent
    //             modalHeaderClassName='items-start'
    //             // search props.
    //             search={{
    //                 placeholder: "Type To Search here",
    //                 isSearchIconRequire: true,
    //                 customSearchFunction: (listState, listFunctions) => {
    //                     const { listData, currentSearch } = listState;
    //                     return listData?.filter(item => item?.label?.toLowerCase().includes(currentSearch?.toLowerCase()));
    //                 },
    //                 searchInputClassName: 'w-full placeholder:font-normal'
    //             }
    //             }
    //             headerKeys={{
    //                 label: {}
    //             }}
    //             renderCustomItems={{ 3: renderMenuItems }}
    //             itemKeys={{
    //                 "label": [
    //                     // "label",
    //                     (state, functions, data) => {
    //                         return <div>
    //                             <span className='mdlr-multiple-row' onClick={() => handleChange("replayTour", data)}>{data?.label}</span>
    //                         </div>
    //                     }
    //                 ]
    //             }}
    //             rowRightComponent={<Icons.VendorRedirectIcon />}
    //             label={{
    //                 content: () => (
    //                     <div>
    //                         <LanguageTranslator tag='h2'>Product Tour</LanguageTranslator>
    //                         <p className='!font-normal'>Please find below the list of tour guides to help you understand the product better.</p>
    //                     </div>
    //                 ),
    //             }}
    //             closeModal={props?.closeModal}
    //             getListData={(response) => {
    //                 return tourList || []
    //             }}
    //         />
    //     </>
    // )
}

export default ReplayAppTourModal