/* eslint-disable import/no-anonymous-default-export */
import NewSupplymintLogo from "./NewSupplymintLogo.svg";
import VendorSupplymintLogo from "./VendorSupplymintLogo.svg";
import CustomerSupplymintLogo from "./CustomerSupplymintLogo.svg";
import MenuHomeIcon from "./MenuHomeIcon.svg";
import { ReactComponent as DigiArsMenuIcon } from "./DigiArsMenuIcon.svg";
import { ReactComponent as DigiVendorMenuIcon } from "./DigiVendorMenuIcon.svg";
import { ReactComponent as EntCatalogueMenuIcon } from "./EntCatalogueMenuIcon.svg";
import { ReactComponent as SalesManagementMenuIcon } from "./SalesManagementMenuIcon.svg";
import { ReactComponent as MasterDataMenuIcon } from "./MasterDataMenuIcon.svg";
import { ReactComponent as AdminIconMenuIcon } from "./AdminIconMenuIcon.svg";
import { ReactComponent as VendorCatalogueMenuIcon } from "./VendorCatalogueMenuIcon.svg";
import { ReactComponent as InvoiceManagementMenuIcon } from "./InvoiceManagementMenuIcon.svg";
import { ReactComponent as DigiProcMenuIcon } from "./DigiProcMenuIcon.svg";
import { ReactComponent as ReportsAnalyticsMenuIcon } from "./ReportsAnalyticsMenuIcon.svg";
import { ReactComponent as SuperAdminMenuIcon } from "./SuperAdminMenuIcon.svg";
import { ReactComponent as DigiArsMenuIconFilled } from "./DigiArsMenuIconFilled.svg";
import { ReactComponent as DigiVendorMenuIconFilled } from "./DigiVendorMenuIconFilled.svg";
import { ReactComponent as EntCatalogueMenuIconFilled } from "./EntCatalogueMenuIconFilled.svg";
import { ReactComponent as SalesManagementMenuIconFilled } from "./SalesManagementMenuIconFilled.svg";
import { ReactComponent as MasterDataMenuIconFilled } from "./MasterDataMenuIconFilled.svg";
import { ReactComponent as AdminIconMenuIconFilled } from "./AdminIconMenuIconFilled.svg";
import { ReactComponent as InvoiceManagementMenuIconFilled } from "./InvoiceManagementMenuIconFilled.svg";
import { ReactComponent as SuperAdminMenuIconFilled } from "./SuperAdminMenuIconFilled.svg";
import { ReactComponent as DigiProcMenuIconFilled } from "./DigiProcMenuIconFilled.svg";
import { ReactComponent as ReportsAnalyticsMenuIconFilled } from "./ReportsAnalyticsMenuIconFilled.svg";
import RightArrowMenuIcon from "./RightArrowMenuIcon.svg";
import { ReactComponent as GlobalSearchIcon } from "./GlobalSearchIcon.svg";
import { ReactComponent as ModalCloseIcon } from "./ModalCloseIcon.svg";
import { ReactComponent as SelectedItemIcon } from "./SelectedItemIcon.svg";
import { ReactComponent as DeselectedItemIcon } from "./DeselectedItemIcon.svg";
import { ReactComponent as GlobalSearchArrowIcon } from "./GlobalSearchArrowIcon.svg";
import { ReactComponent as HeaderHelpMenuIcon } from "./HeaderHelpMenuIcon.svg";
import { ReactComponent as HeaderDataExportIcon } from "./HeaderDataExportIcon.svg";
import { ReactComponent as HeaderNotificationsIcon } from "./HeaderNotificationsIcon.svg";
import { ReactComponent as DataExportFileIcon } from "./DataExportFileIcon.svg";
import { ReactComponent as DataExportFileIconNew } from "./DataExportFileIconNew.svg";
import { ReactComponent as DataExportDownloadActiveIcon } from "./DataExportDownloadActiveIcon.svg";
import { ReactComponent as DataExportDownloadDisabledIcon } from "./DataExportDownloadDisabledIcon.svg";
import { ReactComponent as DataExportCancelActiveIcon } from "./DataExportCancelActiveIcon.svg";
import { ReactComponent as DataExportCancelDisabledIcon } from "./DataExportCancelDisabledIcon.svg";
import { ReactComponent as NotificationMenuIcon } from "./NotificationMenuIcon.svg";
import { ReactComponent as ProfileLogoutIcon } from "./ProfileLogoutIcon.svg";
import { ReactComponent as BackArrowIcon } from "./BackArrowIcon.svg";
import { ReactComponent as BackArrowIcon2 } from "./BackArrowIcon2.svg";
import { ReactComponent as CopyIcon } from "./copyIcon.svg";
import { ReactComponent as NewCopyIcon } from "./NewCopyIcon.svg";
import { ReactComponent as GreenDotWatchIcon } from "./GreenDotWatchIcon.svg";
import { ReactComponent as RedDotWatchIcon } from "./RedDotWatchIcon.svg";
import { ReactComponent as GreenDotActive } from "./GreenDotActive.svg";
import { ReactComponent as GreyDotInactive } from "./GreyDotInactive.svg";
import { ReactComponent as TriggerHandIcon } from "./TriggerHandIcon.svg";
import { ReactComponent as AttachFileBlueIcon } from "./AttachFileBlueIcon.svg";
import { ReactComponent as ReadMoreAttachIcon } from "./ReadMoreAttachIcon.svg";
import { ReactComponent as DownloadWhiteIcon } from "./DownloadWhiteIcon.svg";
import { ReactComponent as DownloadPdf } from "./downloadpdf.svg";
import { ReactComponent as DownloadPdf1 } from "./DownloadPdf1.svg";
import { ReactComponent as ErrorIconNew } from "./errorIconNew.svg";
import { ReactComponent as SiteMappingSVG1 } from "./SiteToSiteMappingSVG1.svg";
import { ReactComponent as SearchIcon } from "./searchicon.svg";
import { ReactComponent as SearchIconDark } from "./SearchIconDark.svg";
import { ReactComponent as AddGreenIcon } from "./AddGreenIcon.svg";
import { ReactComponent as DeleteIcon } from './DeleteIcon.svg'
import { ReactComponent as NoHeader } from "./no-header.svg";
import { ReactComponent as CircleTick } from "./circle-white-tick.svg";
import { ReactComponent as CircleTickFilledWhite } from "./CircleTickFilledWhite.svg";
import { ReactComponent as AdhocSvg1 } from "./adhocSVG1.svg";
import { ReactComponent as CustomUploadSvg1 } from "./customUploadSvg1.svg";
import { ReactComponent as WarehouseIcon } from './WarehouseIcon.svg';
import { ReactComponent as StoreReplenishmentIcon } from './StoreReplenishmentIcon.svg'
import { ReactComponent as AutoPurchaseReplenishmentIcon } from './AutoPurcahseReplenishmentIcon.svg'
import { ReactComponent as PrevIcon } from "./prevIcon.svg";
import { ReactComponent as NextIcon } from "./nextIcon.svg";
import { ReactComponent as PlusIcon } from "./plusIcon.svg";
import { ReactComponent as PlusIconBlack } from "./PlusIconBlack.svg";
import { ReactComponent as AddIcon } from "./addIcon.svg";
import { ReactComponent as ExpFilterIcon } from "./ExpFilterIcon.svg";
import { ReactComponent as GCExplanationIon } from "./GCExplanationIon.svg";
import { ReactComponent as ClearSearch } from "./clearSearch.svg";
import { ReactComponent as JobRunFailedIcon } from './JobRunFailedIcon.svg'
import { ReactComponent as JobRunSuccessIcon } from "./JobRunSuccessIcon.svg";
import { ReactComponent as WarningYellowIcon } from './WarningYellowIcon.svg'
import { ReactComponent as OnboardingWarningIcon } from './OnboardingWarningIcon.svg'
import { ReactComponent as EmailResendIcon } from './EmailResendIcon.svg'
import { ReactComponent as AddMappingPlusIcon } from './AddMappingPlusIcon.svg'
import { ReactComponent as VendorRedirectIcon } from './VendorRedirectIcon.svg'
import { ReactComponent as TGRedirectIcon } from './TGRedirection.svg'
import { ReactComponent as LogSearchIcon } from './LogSearch.svg'
import { ReactComponent as RightArrow1 } from './RightArrow1.svg'
import { ReactComponent as RightArrowThin } from './RightArrowThin.svg'
import { ReactComponent as RightArrow2 } from './RightArrow2.svg'
import { ReactComponent as LogIcon } from './LogIcon.svg'
import { ReactComponent as EyeIcon } from './eye.svg'
import { ReactComponent as DownArrowNew } from './downArrowNew.svg'
import { ReactComponent as DownArrowWhite } from './downArrowWhite.svg'
import { ReactComponent as RightArrowNewGreen } from './RightArrowNewGreen.svg'
import { ReactComponent as DownArrowFilled } from './DownArrowFilled.svg'
import { ReactComponent as DownArrowFilledGrey } from './DownArrowFilledGrey.svg'
import { ReactComponent as RefreshIcon } from './Refresh.svg'
import { ReactComponent as ConfirmationModalImg } from './confirmationModalImg.svg'
import { ReactComponent as NoDataFound } from './NoDataFound.svg'
import { ReactComponent as EditIcon } from './editicon.svg'
import { ReactComponent as TGEditIcon } from './TGEditIcon.svg'
import { ReactComponent as UploadImg } from './uploadImg.svg'
import { ReactComponent as NoJobsFound } from './NoJobsFound.svg'
import { ReactComponent as BCRightArrow } from './BCRightArrow.svg'
import { ReactComponent as ReloadIcon } from './reload.svg'
import { ReactComponent as PinnedIcon } from './PinnedIcon.svg'
import { ReactComponent as UnpinnedIcon } from './UnpinnedIcon.svg'
import { ReactComponent as GreenTick } from './GreenTick.svg'
import { ReactComponent as GreenCircledGreenTick } from './GreenCircledGreenTick.svg'
import { ReactComponent as ImageIcon } from './ImageIcon.svg'
import { ReactComponent as BannerImageIcon } from './BannerImageIcon.svg'
import { ReactComponent as ArsPendindIcon } from './sPending.svg'
import { ReactComponent as ArsApprovedIcon } from './arsdApproved.svg'
import { ReactComponent as ArsRejectedIcon } from './arsdrejected.svg'
import { ReactComponent as ArsDFailedIcon } from './arsdFailedIcon.svg'
import { ReactComponent as FileShareIcon } from './FileShareIcon.svg'
import { ReactComponent as DownloadContentIcon } from './DownloadContentIcon.svg'
import { ReactComponent as TickGreenIcon } from './TickGreenIcon.svg'
import { ReactComponent as CrossRedIcon } from './CrossRedIcon.svg'
import { ReactComponent as CreateNewTicketIcon } from './CreateNewTicketIcon.svg'
import { ReactComponent as SortingIcon } from './HeaderFilter.svg'
import { ReactComponent as UploadExcelIcon } from './UploadExcelIcon.svg'
import { ReactComponent as DownloadExcelTemplateGreenIcon } from './DownloadExcelTemplateGreenIcon.svg'
import { ReactComponent as VendorRedirectIconWhite } from './VendorRedirectIconWhite.svg'
import { ReactComponent as SummaryInfoIcon } from './SummaryInfoIcon.svg'
import { ReactComponent as SettingIcon } from './SettingIcon.svg';
import { ReactComponent as ArsHold } from './arsHold.svg';
import { ReactComponent as ArsDraft } from './arsUnauthorized.svg';
import { ReactComponent as RupeeIcon } from './RupeeIcon.svg';
import { ReactComponent as CrossIcon } from './CrossIcon.svg';
import { ReactComponent as DownArrow } from './downArrow.svg';
import { ReactComponent as NoImgFound } from './NoImgFound.svg';
import { ReactComponent as BagIcon } from './BagIcon.svg';
import { ReactComponent as ListViewIcon } from './ListViewIcon.svg';
import { ReactComponent as GridViewIcon } from './GridViewIcon.svg';
import { ReactComponent as NewTicket } from './CreateNewTicketIcon.svg';
import { ReactComponent as HelpNewTicket } from './HelpTicketIcon.svg';
import { ReactComponent as AllTicket } from './AllTicketIcon.svg';
import { ReactComponent as HelpIcon } from './HelpIcon.svg';
import { ReactComponent as VideoIcon } from './VideoIcon.svg';
import { ReactComponent as KeyboardShortcut } from './KeyboardShortcut.svg';
import { ReactComponent as replayAppTour } from './replayAppTour.svg';
import { ReactComponent as EscalationMatrix } from './EscalationMatrix.svg';
import { ReactComponent as SupportDoc } from './SupportDoc.svg';
import { ReactComponent as DownloadDocumentsIcon } from './DownloadDocumentsIcon.svg'
import { ReactComponent as BagEdit } from './BagEdit.svg';
import { ReactComponent as ArrowUpIcon } from './arrowUp.svg';
import { ReactComponent as DragDropIcon } from './dragedrop.svg';
import { ReactComponent as PendingQcIcon } from './PendingQcIcon.svg'
import { ReactComponent as ChatIconTableComponent } from './chat_Icon.svg'
import { ReactComponent as ScanBarcodeIcon } from './ScanBarcodeIcon.svg'
import { ReactComponent as ErrorIcon } from './ErrorIcon.svg'
import { ReactComponent as UploadIcon } from './UploadIcon.svg'
import { ReactComponent as UploadIconNew } from './UploadIconNew.svg'
import { ReactComponent as CaptureIcon } from './CaptureIcon.svg'
import { ReactComponent as LeftArrow } from './LeftArrow.svg'
import { ReactComponent as RightArrowBlue } from './RightArrowBlue.svg'
import { ReactComponent as CreatePlusIcon } from './CreatePlusIcon.svg'
import { ReactComponent as Minus } from './Minus.svg'
import { ReactComponent as MinusIconBlack } from './MinusIconBlack.svg'
import { ReactComponent as Plus } from './Plus.svg'
import { ReactComponent as NoProductFound } from './NoProductFound.svg'
import { ReactComponent as Tick } from './Tick.svg'
import { ReactComponent as Check } from './Check.svg'
import { ReactComponent as MatrixView } from './matrixView.svg'
import { ReactComponent as DeleteNew } from './DeleteNew.svg'
import { ReactComponent as CustomerIcon } from './CustomerIcon.svg'
import { ReactComponent as DeliveryLocation } from './DeliveryLocation.svg'
import { ReactComponent as ScanIcon } from './ScanIcon.svg'
import { ReactComponent as HeaderDeleteIcon } from './HeaderDeleteIcon.svg'
import { ReactComponent as TableEyeIcon } from './TableEyeIcon.svg'
import { ReactComponent as TableEditIcon } from './TableEditIcon.svg'
import { ReactComponent as ExpandedTableIcon } from './ExpandedTableIcon.svg'
import { ReactComponent as NotExpandedTableIcon } from './NotExpandedTableIcon.svg'
import { ReactComponent as SettingIconNew } from './SettingIconNew.svg'
import { ReactComponent as SupplymintRIcon } from './AuthBackgroundIcon.svg';
import { ReactComponent as SupportIcon } from './SupportIcon.svg'
import { ReactComponent as EmailReport } from './EmailReport.svg'
import { ReactComponent as ExportToExcel } from './ExportToExcel.svg'
import { ReactComponent as BreadcrumbSortingIcon } from "./BreadcrumbSortingIcon.svg";
import { ReactComponent as PasswordEyeIcon } from './PasswordEyeIcon.svg'
import { ReactComponent as PasswordEyeIconSmall } from './PasswordEyeIconSmall.svg'
import { ReactComponent as PasswordEyeDisabledIcon } from './PasswordEyeDisabledIcon.svg'
import { ReactComponent as SignInBadgeIcon } from './SignInBadgeIcon.svg'
import { ReactComponent as PasswordLockIcon } from './PasswordLockIcon.svg'
import { ReactComponent as PasswordCheckCircle } from './PasswordCheckCircle.svg'
import { ReactComponent as ProfileFaceIcon } from './ProfileFaceIcon.svg'
import { ReactComponent as LogoutIcon } from './LogoutIcon.svg'
import { ReactComponent as UserIcon } from './UserIcon.svg'
import { ReactComponent as DataMappingIcon } from './DataMappingIcon.svg'
import { ReactComponent as PackageProducts } from './packages-product.svg'
import { ReactComponent as SiteMappingData } from './site-data.svg'
import { ReactComponent as VendorMappingData } from './vendor-data.svg'
import { ReactComponent as ManagementData } from './management.svg'
import { ReactComponent as LoginStripIcon } from './LoginStripIcon.svg'
import { ReactComponent as Remark } from "./Remark.svg";
import { ReactComponent as AddComentEnterIcon } from "./AddComentEnterIcon.svg";
import { ReactComponent as EmailTranscriptIcon } from "./EmailTranscriptIcon.svg";
import { ReactComponent as MoverIcon } from "./MoverIcon.svg";
import { ReactComponent as NoScanIcon } from "./NoScanIcon.svg";
import { ReactComponent as CollapesIcon } from "./CollapesIcon.svg";
import { ReactComponent as ProcAddIcon } from "./ProcAddIcon.svg";
import { ReactComponent as ProcAddRow } from "./ProcAddRow.svg";
import { ReactComponent as ProcMoreBtn } from "./ProcMoreBtn.svg";
import { ReactComponent as ProcDeleteIcon } from "./ProcDeleteIcon.svg";
import { ReactComponent as ProcCopyIcon } from "./ProcCopyIcon.svg";
import { ReactComponent as ErrorWarning } from "./ErrorWarning.svg";
import { ReactComponent as CopyRightIcon } from "./CopyRightIcon.svg";
import { ReactComponent as MobileOTPIcon } from "./MobileOTPIcon.svg";
import { ReactComponent as EmailOTPIcon } from "./EmailOTPIcon.svg";
import { ReactComponent as ProfileSettingIcon } from "./ProfileSettingIcon.svg";
import { ReactComponent as ProfileOrgIcon } from "./ProfileOrgIcon.svg";
import { ReactComponent as ImgPrevIcon } from "./ImgPrevIcon.svg";
import { ReactComponent as ImgNextIcon } from "./ImgNextIcon.svg";
import { ReactComponent as ExpandRow } from "./ExpandRow.svg";
import { ReactComponent as DownArrowSmall } from "./DownArrowSmall.svg";
import { ReactComponent as HandDollarSvg } from "./HandDollar.svg";
import { ReactComponent as CommentSvg } from "./Comment.svg";
import { ReactComponent as RedYellowGreenDot } from './RedYellowGreenDot.svg'
import { ReactComponent as NoCommentSvg } from "./nocomment.svg";
import { ReactComponent as UnapprovedTag } from "./UnapprovedTag.svg";
import { ReactComponent as ExpandedConfigTableHeader } from './ExpandedConfigTableHeader.svg'
import { ReactComponent as UnexpandedConfigTableHeader } from './UnexpandedConfigTableHeader.svg'
import { ReactComponent as WebIcon } from './Webicon.svg'
import { ReactComponent as RemarkCancelIocn } from './RemarkCancel.svg'
import { ReactComponent as OrgLogoGeneric } from './org-logo-generic.svg'
import { ReactComponent as NoLogoIcon } from './NoLogoIcon.svg'
import { ReactComponent as EmptyBoxNew } from './empty-box-new.svg'
import { ReactComponent as ProcChooseFileIcon } from './ProcChooseFileIcon.svg'
import { ReactComponent as RightArrowWhite } from './RightArrowWhite.svg'
import { ReactComponent as TableHeader } from './TableHeader.svg'
import { ReactComponent as DocumentFormat } from './DocumentFormat.svg'
import { ReactComponent as APIIntegration } from './APIIntegration.svg'
import { ReactComponent as DataManagement } from './DataManagement.svg'
import { ReactComponent as GeneralSetting } from './GeneralSetting.svg'
import { ReactComponent as WorkflowSetting } from './WorkflowSetting.svg'
import { ReactComponent as FormSetting } from './FormSetting.svg'
import { ReactComponent as MultiLevelApproval } from './MultiLevelApproval.svg'
import { ReactComponent as DataSetting } from './DataSetting.svg'
import { ReactComponent as OrganizationData } from './OrganizationData.svg'
import { ReactComponent as BarcodeProfile } from './BarcodeProfile.svg'
import { ReactComponent as ReplenishmentSetting } from './ReplenishmentSetting.svg'
import { ReactComponent as DropdownField } from './DropdownField.svg'
import { ReactComponent as Training } from './Training.svg'
import { ReactComponent as Announcement } from './Announcement.svg'
import { ReactComponent as SystemMaintenance } from './SystemMaintenance.svg'
import { ReactComponent as GCNotification } from './GCNotification.svg'
import { ReactComponent as InfoIcon } from './InfoIcon.svg'
import { ReactComponent as TableComponentDownloadDocIcon } from './TableComponentDownloadDocIcon.svg'
import { ReactComponent as PdfDownloadDocumentIcon } from './PdfDownloadDocumentIcon.svg'
import { ReactComponent as ImageThumbnail } from './image-thumbnail.svg'
import { ReactComponent as CatalogueImageThumbnail } from './CatalogueImageThumbnail.svg'
import { ReactComponent as DraftFileIcon } from './DraftFile.svg'
import { ReactComponent as InfoWhiteIcon } from './infoWhite.svg'
import { ReactComponent as BlueInfoIcon } from './BlueInfoIcon.svg'
import { ReactComponent as TableConfigDefault } from './TableConfigDefault.svg'
import { ReactComponent as GCFilterIcon } from './GCFilterIcon.svg'
import { ReactComponent as BlueDownloadIcon } from './BlueDownloadIcon.svg'
import { ReactComponent as DelayIcon } from './DelayedIcon.svg'
import { ReactComponent as ArrowReviewSvg } from './ArrowReview.svg'
import { ReactComponent as FilePlusIcon } from './FilePlusIcon.svg'
import { ReactComponent as Threedot } from './Threedot.svg'
import { ReactComponent as TGImgIcon } from './TGImgIcon.svg'
import { ReactComponent as TGVideoIcon } from './TGVideoIcon.svg'
import { ReactComponent as TGPdfIcon } from './TGPdfIcon.svg'
import { ReactComponent as FilterPinned } from './FilterPinned.svg'
import { ReactComponent as GRCSuccessIcon } from './GRCSuccessIcon.svg'
import { ReactComponent as RightArrowExpand } from './RightArrowExpand.svg'
import { ReactComponent as RightBigArrow } from './RightBigArrow.svg'
import { ReactComponent as RightArrowExpand2 } from './RightArrowExpand2.svg'
import { ReactComponent as SupplierBasicDetailsIcon } from './SupplierBasicDetailsIcon.svg'
import { ReactComponent as FinanceIcon } from './FinanceIcon.svg'
import { ReactComponent as DocumentMediaIcon } from './DocumentMedia.svg'
import { ReactComponent as CompliancesIcon } from './CompliancesIcon.svg'
import { ReactComponent as ReportDownloadIcon } from './ReportDownloadIcon.svg'
import { ReactComponent as ScheduledJObIcon } from './ScheduledJObIcon.svg'
import { ReactComponent as GCUploadIcon } from './GCUploadIcon.svg'
import { ReactComponent as ApproveCircleTick } from './ApproveCircleTick.svg'
import { ReactComponent as UnapproveCircleTick } from './UnapproveCircleTick.svg'
import { ReactComponent as AnnouncementAlert } from './AnnouncementAlert.svg'
import { ReactComponent as AnnouncementGreenAlert } from './AnnouncementGreenAlert.svg'
import { ReactComponent as NoAnnouncementAlert } from './NoAnnouncementAlert.svg'
import { ReactComponent as RightArrowGrey } from './RightArrowGrey.svg'
import { ReactComponent as InfoYellow } from './InfoYellow.svg'
import { ReactComponent as EditNewIcon } from './EditNewIcon.svg'
import { ReactComponent as WHBlueTick } from './WHBlueTick.svg'
import { ReactComponent as Apparels } from './Apparels.svg'
import { ReactComponent as Fabric } from './Fabric.svg'
import { ReactComponent as FashionAccessories } from './FashionAccessories.svg'
import { ReactComponent as HomeFurnishing } from './HomeFurnishing.svg'
import { ReactComponent as EnterInputIcon } from './EnterInputIcon.svg'
import { ReactComponent as Battick } from './Battick.svg'
import { ReactComponent as Dyeing } from './Dyeing.svg'
import { ReactComponent as Embroidery } from './Embroidery.svg'
import { ReactComponent as KaajButton } from './KaajButton.svg'
import { ReactComponent as Printing } from './Printing.svg'
import { ReactComponent as TestingFacilities } from './TestingFacilities.svg'
import { ReactComponent as Washing } from './Washing.svg'
import { ReactComponent as YarnSpinning } from './YarnSpinning.svg'
import { ReactComponent as OnSupplierPayroll } from './OnSupplierPayroll.svg'
import { ReactComponent as ThirdPartyContracts } from './ThirdPartyContracts.svg'
import { ReactComponent as VOStrongIcon } from './VOStrongIcon.svg'
import { ReactComponent as VOWeakIcon } from './VOWeakIcon.svg'
import { ReactComponent as VOModerateIcon } from './VOModerateIcon.svg'
import { ReactComponent as TimeLeftIcon } from './TimeLeftIcon.svg'
import { ReactComponent as BlackHeart } from './BlackHeart.svg'
import { ReactComponent as GreyHeart } from './GreyHeart.svg'
import { ReactComponent as StepCompletedGreenTick } from './StepCompletedGreenTick.svg'
import { ReactComponent as StepCompletedGreyTick } from './StepCompletedGreyTick.svg'
import { ReactComponent as StepSeparationROd } from './StepSeparationROd.svg'
import { ReactComponent as ImgExpandIcon } from './ImgExpandIcon.svg'
import { ReactComponent as Configuration } from './Configuration.svg'
import { ReactComponent as closeRedNew } from './closeRedNew.svg'
import { ReactComponent as addGreen } from './add-green.svg'
import { ReactComponent as FilePlusGreenIcon } from './data-setting-plus-green-icon.svg'
import { ReactComponent as SummaryTransferOrderIcon } from './SummaryTransferOrderIcon.svg'
import { ReactComponent as NewDownloadIcon } from './NewDownloadIcon.svg'
import { ReactComponent as DownloadIcon } from './DownloadIcon.svg'
import { ReactComponent as PhoneIcon } from './PhoneIcon.svg'
import { ReactComponent as Calender } from './calenderNew2.svg'
import { ReactComponent as SubscriptionExpired } from './SubscriptionExpired.svg'
import { ReactComponent as ModuleIcon } from './ModuleIcon.svg'
import { ReactComponent as VerifiedIcon } from './verified.svg'
import { ReactComponent as FlagWithCountryCodeIcon } from './FlagWithCountryCode.svg'
import { ReactComponent as InfoIconNew } from './InfoIconNew.svg'
import { ReactComponent as PASSWORD } from './password.svg'
import { ReactComponent as BOTH } from './bothLogin.svg'
import { ReactComponent as MOBILE } from './mobileLogin.svg'
import { ReactComponent as ConfirmIdentityIcon } from './ConfirmIdentityIcon.svg'
import { ReactComponent as VendorHoldIcon } from './VendorHold.svg'
import { ReactComponent as WifiNotFoundIcon } from './NoWifiIcon.svg';
import { ReactComponent as WifiEnabledIcon } from './WifiEnabledicon.svg'
import { ReactComponent as UpgradSoft } from './upgradSoft.svg'
import { ReactComponent as PurchaseTerm } from './PurchaseTerm.svg'
import { ReactComponent as ZipAttack } from './zipAttack.svg'
import { ReactComponent as WorkLogsIcon } from './WorkLogsIcon.svg'
import { ReactComponent as DefaultReportIcon } from './DefaultReportIcon.svg'
import { ReactComponent as SendIcon } from './SendIcon.svg'
import { ReactComponent as WechatIcon } from './WechatIcon.svg'
import { ReactComponent as DummyQRCode } from './DummyQRCode.svg'
import { ReactComponent as DesktopWarningIcon } from './DesktopWarningIcon.svg'
import { ReactComponent as MacIcon } from './MacIcon.svg'
import { ReactComponent as WindowsIcon } from './WindowsIcon.svg'
import { ReactComponent as PlaneIconYellow } from './PlaneIconYellow.svg'
import { ReactComponent as CelebrationPopperIcon } from './CelebrationPopperIcon.svg'
import { ReactComponent as NewPdfIcon } from './NewPdfIcon.svg'
import {ReactComponent as SessionExpiredIcon} from './session-expired.svg'
import { ReactComponent as ExcelFileIcon } from './ExcelFileIcon.svg'
import { ReactComponent as WordFileIcon } from './WordFileIcon.svg'
import { ReactComponent as ChineseLangIcon } from './ChineseLangIcon.svg'
import { ReactComponent as WechatIcon2 } from './WechatIcon2.svg'
import { ReactComponent as KeyIcon } from './KeyIcon.svg'
import { ReactComponent as UsersLimitReached } from './UsersLimitReached.svg'
import {ReactComponent as NoDataFoundNew} from './NoDataFound.svg'
import {ReactComponent as NoPageAvailable} from './NoPageAvailable.svg'
import { ReactComponent as SiteLimitReached } from './SiteLimitReached.svg'
import { ReactComponent as SuccessIcon } from './SuccessIcon.svg'
import { ReactComponent as SaveFilterWarning } from './SaveFilteWarningIcon.svg'
import { ReactComponent as FabricIcon } from './FabricIcon.svg'
import { ReactComponent as thirdPartyIcon } from './thirdPartyIcon.svg'
import { ReactComponent as MoneyRating1 } from './MoneyRating1.svg'
import { ReactComponent as MoneyRating2 } from './MoneyRating2.svg'
import { ReactComponent as MoneyRating3 } from './MoneyRating3.svg'
import { ReactComponent as MoneyRating4 } from './MoneyRating4.svg'
import { ReactComponent as MoneyRating5 } from './MoneyRating5.svg'
import { ReactComponent as PdfFileIcon } from './PdfFileIcon.svg'
import { ReactComponent as CommentIcon } from './CommentIcon.svg'
import { ReactComponent as CelebrationColorfulPopper } from './CelebrationColorfulPopper.svg'
import { ReactComponent as InfiniteLoop } from './InfiniteLoop.svg'
import { ReactComponent as ExcelUploadTemplate } from './ExcelUploadTemplate.svg'
import { ReactComponent as EyeIcon2 } from './EyeIcon2.svg'
import { ReactComponent as productImagesCollection } from './productImagesCollection.svg'
import { ReactComponent as wallet } from './wallet.svg'
import { ReactComponent as UploadIconBlue } from './uploadIconBlue.svg'
import { ReactComponent as SortingUpArrow } from './sortingUpArrow.svg'
import { ReactComponent as PlayIcon } from './playIcon.svg'
import { ReactComponent as HomeIcon } from './homeIcon.svg'
import { ReactComponent as HomeIconFilled } from './homeIconFilled.svg'
import { ReactComponent as EmailReceived } from './emailReceived.svg'
import { ReactComponent as EmailSent } from './emailSent.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as RatingEmoji1 } from './ratingEmoji1.svg'
import { ReactComponent as RatingEmoji2 } from './ratingEmoji2.svg'
import { ReactComponent as RatingEmoji3 } from './ratingEmoji3.svg'
import { ReactComponent as RatingEmoji4 } from './ratingEmoji4.svg'
import { ReactComponent as RatingEmoji5 } from './ratingEmoji5.svg'
import { ReactComponent as ThumbsUp } from './thumbsUp.svg'
import { ReactComponent as ThumbsDown } from './thumbsDown.svg'
import { ReactComponent as Collapse2 } from './collapse2.svg'
import { ReactComponent as Expand2 } from './expand2.svg'
import { ReactComponent as FilterCheck } from './filterCheck.svg'
import { ReactComponent as Upgrade } from './upgrade.svg'


export default {
  NoDataFoundNew,
  SessionExpiredIcon,
  ZipAttack,
  PurchaseTerm,
  ModuleIcon,
  SubscriptionExpired,
  VendorHoldIcon,
  WifiNotFoundIcon,
  WifiEnabledIcon,
  UpgradSoft,
  ConfirmIdentityIcon,
  FlagWithCountryCodeIcon,
  VerifiedIcon,
  Battick,
  Dyeing,
  RightArrowThin,
  Embroidery,
  KaajButton,
  Printing,
  NoAnnouncementAlert,
  TestingFacilities,
  StepCompletedGreenTick,
  StepCompletedGreyTick,
  StepSeparationROd,
  Washing,
  YarnSpinning,
  OnSupplierPayroll,
  ThirdPartyContracts,
  VOStrongIcon,
  VOWeakIcon,
  VOModerateIcon,
  TimeLeftIcon,
  GCNotification,
  MOBILE,
  BOTH,
  PASSWORD,
  InfoIconNew,
  EscalationMatrix,
  Calender,
  OnboardingWarningIcon,
  PhoneIcon,
  FilePlusGreenIcon,
  TableComponentDownloadDocIcon,
  addGreen,
  closeRedNew,
  Configuration,
  ImgExpandIcon,
  RightArrowNewGreen,
  GreyHeart,
  RedYellowGreenDot,
  BlackHeart,
  SummaryTransferOrderIcon,
  NewDownloadIcon,
  DownloadIcon,
  WHBlueTick,
  EditNewIcon,
  InfoYellow,
  RightArrowGrey,
  BannerImageIcon,
  RightBigArrow,
  CatalogueImageThumbnail,
  AnnouncementGreenAlert,
  AnnouncementAlert,
  UnapproveCircleTick,
  ApproveCircleTick,
  GCUploadIcon,
  FashionAccessories,
  EnterInputIcon,
  HomeFurnishing,
  ReadMoreAttachIcon,
  Fabric,
  Apparels,
  InfoYellow,
  UnapproveCircleTick,
  ApproveCircleTick,
  GCUploadIcon,
  DocumentMediaIcon,
  SupplierBasicDetailsIcon,
  FinanceIcon,
  ScheduledJObIcon,
  ReportDownloadIcon,
  GRCSuccessIcon,
  NewCopyIcon,
  FilterPinned,
  GreenDotActive,
  GreyDotInactive,
  Threedot,
  TGImgIcon,
  TGVideoIcon,
  TGPdfIcon,
  RightArrowExpand,
  RightArrowExpand2,
  ArrowReviewSvg,
  DelayIcon,
  TGEditIcon,
  GCFilterIcon,
  FilePlusIcon,
  ImageThumbnail,
  PdfDownloadDocumentIcon,
  TableConfigDefault,
  CompliancesIcon,
  GCExplanationIon,
  SystemMaintenance,
  Announcement,
  Training,
  DropdownField,
  ReplenishmentSetting,
  BarcodeProfile,
  OrganizationData,
  BlueDownloadIcon,
  DataSetting,
  ExpFilterIcon,
  MultiLevelApproval,
  FormSetting,
  WorkflowSetting,
  GeneralSetting,
  DataManagement,
  APIIntegration,
  TableHeader,
  DocumentFormat,
  InfoWhiteIcon,
  ProcChooseFileIcon,
  DraftFileIcon,
  ProcChooseFileIcon,
  ImageThumbnail,
  Check,
  PdfDownloadDocumentIcon,
  EmptyBoxNew,
  CommentSvg,
  NoCommentSvg,
  CommentSvg,
  BlueInfoIcon,
  NoCommentSvg,
  UnapprovedTag,
  NoLogoIcon,
  OrgLogoGeneric,
  RemarkCancelIocn,
  CommentSvg,
  NoCommentSvg,
  UnapprovedTag,
  WebIcon,
  ExpandedConfigTableHeader,
  UnexpandedConfigTableHeader,
  HandDollarSvg,
  CircleTickFilledWhite,
  PasswordCheckCircle,
  DataExportFileIconNew,
  ImgNextIcon,
  ImageIcon,
  ImgPrevIcon,
  ProfileOrgIcon,
  ProfileSettingIcon,
  EmailOTPIcon,
  MobileOTPIcon,
  CopyRightIcon,
  NoScanIcon,
  MoverIcon,
  LoginStripIcon,
  PackageProducts,
  SiteMappingData,
  VendorMappingData,
  ManagementData,
  UserIcon,
  DataMappingIcon,
  LogoutIcon,
  ProfileFaceIcon,
  PasswordLockIcon,
  SignInBadgeIcon,
  PasswordEyeIcon,
  PasswordEyeIconSmall,
  PasswordEyeDisabledIcon,
  EmailTranscriptIcon,
  AddComentEnterIcon,
  SupportIcon,
  SupplymintRIcon,
  ExportToExcel,
  EmailReport,
  SearchIconDark,
  ExpandedTableIcon,
  NotExpandedTableIcon,
  ScanBarcodeIcon,
  ChatIconTableComponent,
  PendingQcIcon,
  DownloadDocumentsIcon,
  SettingIcon,
  DragDropIcon,
  ArrowUpIcon,
  ArsDraft,
  ArsHold,
  SummaryInfoIcon,
  VendorRedirectIconWhite,
  DownloadExcelTemplateGreenIcon,
  UploadExcelIcon,
  CreateNewTicketIcon,
  HelpNewTicket,
  DownloadContentIcon,
  FileShareIcon,
  NoJobsFound,
  SortingIcon,
  BreadcrumbSortingIcon,
  ArsDFailedIcon,
  ArsRejectedIcon,
  ArsApprovedIcon,
  ArsPendindIcon,
  GreenTick,
  PinnedIcon,
  UnpinnedIcon,
  VendorRedirectIcon,
  EditIcon,
  AddMappingPlusIcon,
  EmailResendIcon,
  WarningYellowIcon,
  EyeIcon,
  RefreshIcon,
  LogIcon,
  RightArrow1,
  RightArrow2,
  LogSearchIcon,
  JobRunSuccessIcon,
  JobRunFailedIcon,
  AutoPurchaseReplenishmentIcon,
  StoreReplenishmentIcon,
  WarehouseIcon,
  DeleteIcon,
  AddIcon,
  PlusIcon,
  PlusIconBlack,
  NextIcon,
  PrevIcon,
  AddGreenIcon,
  DownloadPdf,
  DownloadPdf1,
  CustomUploadSvg1,
  AdhocSvg1,
  CircleTick,
  DownloadWhiteIcon,
  AttachFileBlueIcon,
  TriggerHandIcon,
  RedDotWatchIcon,
  GreenDotWatchIcon,
  NewSupplymintLogo,
  VendorSupplymintLogo,
  CustomerSupplymintLogo,
  MenuHomeIcon,
  DigiArsMenuIcon,
  DigiVendorMenuIcon,
  EntCatalogueMenuIcon,
  SalesManagementMenuIcon,
  MasterDataMenuIcon,
  AdminIconMenuIcon,
  VendorCatalogueMenuIcon,
  InvoiceManagementMenuIcon,
  DigiProcMenuIcon,
  ReportsAnalyticsMenuIcon,
  SuperAdminMenuIcon,
  DigiArsMenuIconFilled,
  DigiVendorMenuIconFilled,
  EntCatalogueMenuIconFilled,
  SalesManagementMenuIconFilled,
  MasterDataMenuIconFilled,
  AdminIconMenuIconFilled,
  InvoiceManagementMenuIconFilled,
  SuperAdminMenuIconFilled,
  DigiProcMenuIconFilled,
  ReportsAnalyticsMenuIconFilled,
  RightArrowMenuIcon,
  GlobalSearchIcon,
  ModalCloseIcon,
  DeselectedItemIcon,
  SelectedItemIcon,
  GlobalSearchArrowIcon,
  HeaderHelpMenuIcon,
  HeaderNotificationsIcon,
  HeaderDataExportIcon,
  DataExportFileIcon,
  DataExportDownloadActiveIcon,
  DataExportDownloadDisabledIcon,
  DataExportCancelActiveIcon,
  DataExportCancelDisabledIcon,
  NotificationMenuIcon,
  ProfileLogoutIcon,
  BackArrowIcon,
  BackArrowIcon2,
  CopyIcon,
  CrossIcon,
  SiteMappingSVG1,
  SearchIcon,
  NoHeader,
  ClearSearch,
  DownArrowNew,
  DownArrowWhite,
  DownArrowFilled,
  DownArrowFilledGrey,
  ConfirmationModalImg,
  NoDataFound,
  UploadImg,
  BCRightArrow,
  ReloadIcon,
  TickGreenIcon,
  CrossRedIcon,
  RupeeIcon,
  DownArrow,
  NoImgFound,
  BagIcon,
  ListViewIcon,
  GridViewIcon,
  NewTicket,
  AllTicket,
  HelpIcon,
  VideoIcon,
  KeyboardShortcut,
  replayAppTour,
  SupportDoc,
  BagEdit,
  ErrorIcon,
  UploadIcon,
  CaptureIcon,
  LeftArrow,
  RightArrowBlue,
  CreatePlusIcon,
  Minus,
  MinusIconBlack,
  Plus,
  NoProductFound,
  Tick,
  MatrixView,
  DeleteNew,
  CustomerIcon,
  DeliveryLocation,
  ScanIcon,
  HeaderDeleteIcon,
  SettingIconNew,
  TableEyeIcon,
  TableEditIcon,
  Remark,
  CollapesIcon,
  ProcAddIcon,
  ProcAddRow,
  ProcMoreBtn,
  ProcDeleteIcon,
  ProcCopyIcon,
  ErrorWarning,
  ExpandRow,
  DownArrowSmall,
  RightArrowWhite,
  InfoIcon,
  TGRedirectIcon,
  GreenCircledGreenTick,
  WorkLogsIcon,
  DefaultReportIcon,
  SendIcon,
  WechatIcon,
  DummyQRCode,
  DesktopWarningIcon, 
  MacIcon,
  WindowsIcon,
  PlaneIconYellow,
  CelebrationPopperIcon,
  NewPdfIcon,
  NoPageAvailable,
  ExcelFileIcon,
  WordFileIcon,
  ChineseLangIcon,
  WechatIcon2,
  KeyIcon,
  UsersLimitReached,
  SaveFilterWarning,
  InfiniteLoop,
  FabricIcon,
  thirdPartyIcon,
  SiteLimitReached,
  SuccessIcon,
  MoneyRating1,
  MoneyRating2,
  MoneyRating3,
  MoneyRating4,
  MoneyRating5,
  PdfFileIcon,
  CommentIcon,
  CelebrationColorfulPopper,
  InfiniteLoop,
  ExcelUploadTemplate,
  EyeIcon2,
  productImagesCollection,
  wallet,
  UploadIconBlue,
  ErrorIconNew,
  UploadIconNew,
  SortingUpArrow,
  PlayIcon,
  HomeIcon,
  HomeIconFilled,
  EmailReceived,
  EmailSent,
  Star,
  RatingEmoji1,
  RatingEmoji2,
  RatingEmoji3,
  RatingEmoji4,
  RatingEmoji5,
  ThumbsUp,
  ThumbsDown,
  Collapse2,
  Expand2,
  FilterCheck,
  Upgrade
};
