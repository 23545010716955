import React, { useEffect, useState } from 'react'
import Icons from '../../../assets/icons';

function ImagePreviewCom(props) {
    const { imageUrl = "", handleChange = () => {}, item = {} } = props;
    const [isImgAvailable, setIsImgAvailable] = useState(null);

    useEffect(() => {
        const checkImage = async () => {
            const available = await isImagePreviewAvailable(imageUrl);
            setIsImgAvailable(available);
        };
        checkImage();
    }, [imageUrl]);

    const isImagePreviewAvailable = (imageUrl) => {
        return new Promise((resolve) => {
            if (!imageUrl) return resolve(false);
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => resolve(imageUrl); // Resolve with URL if image loads
            img.onerror = () => resolve(false);   // Resolve with false if it fails
        });
    };

    return (
        <div
            className="wid-30 hgt-30 flex items-center justify-center"
            data-interactive="true"
            onClick={() => isImgAvailable ? handleChange("openMediaViewModal", item) : null}
        >
            {isImgAvailable ? (
                <img
                    className={`w-100 h-100 ${isImgAvailable !== null ? 'cursor-pointer' : ''}`}
                    data-interactive="true"
                    src={isImgAvailable}
                />
            ) : (
                <Icons.ImageThumbnail />
            )}
        </div>
    )
}

export default ImagePreviewCom