import React, { Component } from 'react';
import TableItemLevelFilters from '../TableItemLevelFilters';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import RightArrowImageComponent from '../RightArrowImageComponent';
import Icons from '../../../assets/icons';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ImagePreviewCom from '../../../components/reportsAndAnalytics/inventoryManagementReportsStack/ImagePreviewCom';

export default class ExpandedRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        }
    }

    toggleEdit = () => {
        this.setState(prevState => ({
            edit: !prevState.edit
        }));
    }

    small = (str) => {
        if (str != null) {
            var str = str.toString()
            if (str.length <= 30) {
                return false;
            }
            return true;
        }
    }

    handleChange = (key, data) => {
        this.props.updateState({selectedModal : "imageModal", parentModalData : data?.["imageUrl"]})
    }

    renderImage = (item, key) => {
        // Function : to render the image on the imageUrl key
        return <>
            <ImagePreviewCom 
                imageUrl={item?.[key]} 
                item={item} 
                handleChange={this.handleChange} 
            />
        </>
    }

    render() {
        const { actionBarConfig } = this.props;
        return (
            <div className="gen-vend-sticky-table zi2">
                <div className="gvst-expend" id="expandTableMain">
                    <div className="gvst-inner">
                        <table className="table">
                            <thead>
                                {this.props.headersData?.[this.props.headersType] &&
                                    <tr>
                                        {(this.props.edit || this.props.expand || this.props.extras) &&
                                            (this.props.isLoading ? <th className="fix-action-btn"></th> :
                                                <th className="fix-action-btn">
                                                    {this.props.edit &&
                                                        <ul className="rab-refresh">
                                                            <li className="til-inner til-edit-btn" onClick={this.props.edit.toggleEdit}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" id="prefix__edit-tools_2_" width="16" height="16" data-name="edit-tools (2)" viewBox="0 0 16.298 16.211">
                                                                    <path fill="#a4b9dd" id="prefix__Path_587" d="M15.831 28.215a.427.427 0 0 0-.427.427v3.793a1.283 1.283 0 0 1-1.282 1.282H2.136a1.283 1.283 0 0 1-1.282-1.281V21.3a1.283 1.283 0 0 1 1.282-1.282h3.793a.427.427 0 0 0 0-.854H2.136A2.138 2.138 0 0 0 0 21.3v11.136a2.138 2.138 0 0 0 2.136 2.136h11.986a2.138 2.138 0 0 0 2.136-2.136v-3.793a.427.427 0 0 0-.427-.428zm0 0" className="prefix__cls-1" data-name="Path 587" transform="translate(0 -18.361)" />
                                                                    <path fill="#a4b9dd" id="prefix__Path_588" d="M156.367 38.566l6.237-6.237 2.012 2.012-6.237 6.237zm0 0" className="prefix__cls-1" data-name="Path 588" transform="translate(-149.688 -30.959)" />
                                                                    <path fill="#a4b9dd" id="prefix__Path_589" d="M132.543 199.442l2.223-.616-1.607-1.607zm0 0" className="prefix__cls-1" data-name="Path 589" transform="translate(-126.881 -188.806)" />
                                                                    <path fill="#a4b9dd" id="prefix__Path_590" d="M318.491.581a1.069 1.069 0 0 0-1.51 0l-.453.453 2.012 2.012.453-.453a1.069 1.069 0 0 0 0-1.51zm0 0" className="prefix__cls-1" data-name="Path 590" transform="translate(-303.006 -.269)" />
                                                                </svg>
                                                                <LanguageTranslator tag="span" className="generic-tooltip">Edit</LanguageTranslator>
                                                            </li>
                                                        </ul>}
                                                </th>)}
                                        {Object.keys(this.props.headersData?.[this.props.headersType] || {})?.map(key =>
                                            <th key={key}>
                                                <label><LanguageTranslator>{this.props.headersData[this.props.headersType][key]}</LanguageTranslator></label>
                                            </th>)}
                                    </tr>}
                                {actionBarConfig && actionBarConfig?.filters && <TableItemLevelFilters
                                    appliedFilters={this.props.customSearchApplied ? {} : this.props.tableDataPayload.filter}
                                    applyFilter={this.props.applyFilter}
                                    closeFilterModal={this.props.closeFilterModal}
                                    filterItems={this.props.headersData.item}
                                    isLoading={this.props.generalStore.createQuickFilters.isLoading}
                                    quickFilterItems={this.props.quickFilterData}
                                    saveQuickFilter={this.props.saveQuickFilter}
                                    saveSuccess={this.props.generalStore.createQuickFilters.isSuccess}
                                    headersData={this.props.headersData}
                                    {...this.props}
                                />}
                            </thead>
                            <tbody>
                                {this.props.isLoading ?
                                    <tr>
                                        {(this.props.edit || this.props.expand?.() || this.props.extras) && <td className="fix-action-btn"></td>}
                                        <td colSpan={100}><LanguageTranslator tag="label">Fetching data...</LanguageTranslator></td>
                                    </tr> :
                                    this.props.expandedData.length == 0 ?
                                        <tr><td colSpan={100} align="center"><label>No data found!</label></td></tr> :
                                        this.props.expandedData.map((item, index) =>
                                            <React.Fragment key={item.id}>
                                                <tr>
                                                    {(this.props.edit || this.props.expand || this.props.extras) &&
                                                        <td className="fix-action-btn">
                                                            {(this.props.expand || this.props.extras) &&
                                                                <ul className="table-item-list justify-content-center">
                                                                    {this.props.expand?.(item) && <li className="til-inner til-add-btn" onClick={typeof this.props.expand?.(item)?.onClick === "function" ? this.props.expand?.(item)?.onClick(item) : null}>
                                                                        {this.props.expand?.(item)?.expandedRow?.[item.id] ?
                                                                            <React.Fragment>
                                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_3375_6075)">
                                                                                        <rect x="19.5" y="2.5" width="17" height="17" rx="4.5" transform="rotate(90 19.5 2.5)" fill="white" stroke="#C7D3DF" />
                                                                                        <path d="M11 13.6001L7 9.6001L15 9.6001L11 13.6001Z" fill="#111D31" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3375_6075">
                                                                                            <rect width="21" height="21" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <LanguageTranslator tag="span" className="generic-tooltip top-minus-28">Collapse</LanguageTranslator>
                                                                            </React.Fragment> :
                                                                            <React.Fragment>
                                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_3375_6075)">
                                                                                        <rect x="2.5" y="1.5" width="17" height="17" rx="4.5" fill="white" stroke="#C7D3DF" />
                                                                                        <path d="M13.5996 10L9.59961 14L9.59961 6L13.5996 10Z" fill="#111D31" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3375_6075">
                                                                                            <rect width="21" height="21" fill="white" transform="translate(0 21) rotate(-90)" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <LanguageTranslator tag="span" className="generic-tooltip top-minus-28">Expand</LanguageTranslator>
                                                                            </React.Fragment>}
                                                                    </li>}
                                                                    {this.props.extras && this.props.extras(item)}
                                                                </ul>}
                                                        </td>}
                                                    {Object.keys(this.props.headersData?.[this.props.headersType] || {})?.map((key,innerIndex) =>
                                                        //  handle the case of custom cols in case when user require to show up a custom cols
                                                        <td id={`${item['apiName']}${item['id']}${innerIndex}`} className={key == "setBarCode" || key == "itemCode" || key == "shipmentAdviceCode" || key == "setBarcode" || key == "itemBarcode" ? "po-num-col" : ""} key={key}>
                                                            {this.props.expandCustomCols?.[key] ?

                                                                this.props.expandCustomCols?.[key]({
                                                                    item: item
                                                                })

                                                                : this.props.edit && this.props.edit.cols.includes(key) ?
                                                                    <input type="text" className={this.props.edit.editExpandedRow ? "" : "bgDisable"} disabled={!this.props.edit.editExpandedRow} value={item[key]} onChange={this.props.edit.onChange(key, index)} /> :
                                                                    <>
                                                                    { key == "imageUrl" ? this.renderImage(item, key) : 
                                                                    <label className={key == "tnaStatus" ? item.tnaStatusGroup : "rightArrowLabel"} key={key} onClick={() => key == "setBarCode" || key == "itemCode" || key == "shipmentAdviceCode" || key == "setBarcode" || key == "itemBarcode" ? this?.props?.handleImageTrackingModal(item[key], "", key) : null}>{item[key]}
                                                                    {key == "setBarCode" || key == "itemCode" || key == "shipmentAdviceCode" || key == "setBarcode" || key == "itemBarcode" ?
                                                                        // <svg className='ponc-icon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => key == "setBarCode" || key == "itemCode" || key == "shipmentAdviceCode" || key == "setBarcode" || key == "itemBarcode" ? this?.props?.handleImageTrackingModal(item[key], "", key) : null}>
                                                                        //     <path d="M9.5 8.709V6.334L12.667 9.5 9.5 12.667v-2.375H6.333V8.709H9.5zm0-7.125A7.92 7.92 0 0 1 17.417 9.5 7.92 7.92 0 0 1 9.5 17.417 7.92 7.92 0 0 1 1.583 9.5 7.92 7.92 0 0 1 9.5 1.583zm0 14.25c3.5 0 6.334-2.835 6.334-6.334 0-3.499-2.835-6.333-6.334-6.333A6.332 6.332 0 0 0 3.167 9.5C3.167 13 6 15.834 9.5 15.834z" fill="#334A81" />
                                                                        // </svg>
                                                                        <div className='ponc-icon rightArrowIcon' >
                                                                            <RightArrowImageComponent />
                                                                        </div> 
                                                                        // <svg className='ponc-icon rightArrowIcon' onClick={() => key == "setBarCode" || key == "itemCode" || key == "shipmentAdviceCode" || key == "setBarcode" || key == "itemBarcode" ? this?.props?.handleImageTrackingModal(item[key], "", key) : null} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        //     <path d="M14.25 2.54688H3.75C2.92157 2.54688 2.25 3.21845 2.25 4.04688V14.5469C2.25 15.3753 2.92157 16.0469 3.75 16.0469H14.25C15.0784 16.0469 15.75 15.3753 15.75 14.5469V4.04688C15.75 3.21845 15.0784 2.54688 14.25 2.54688Z" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        //     <path d="M6 9.29688H12" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        //     <path d="M9 12.2969L12 9.29688L9 6.29688" stroke="#57606F" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        //     </svg>
                                                                        : null
                                                                    }
                                                                    </label>}
                                                                    {key != "imageUrl" && this.small(item[key]) && <ReactTooltip
                                                                        anchorSelect={`#${item['apiName']}${item['id']}${innerIndex}`}
                                                                        clickable={true}
                                                                        border={true}
                                                                        borderColor="gray"
                                                                        place="top"
                                                                        className="tooltip"
                                                                        delayShow={.3}
                                                                        delayHide={0}
                                                                        effect={false}
                                                                        globalEventOff="click" // Ensure tooltips are dismissed when another is triggered
                                                                        events="hover focus"
                                                                        style={{ zIndex: '4', transition: 'none', fontSize: "12px", wordWrap: "break-word", whiteSpace: 'normal', wordBreak: 'break-all', pointerEvents: "auto" }}
                                                                    >
                                                                        <div className='max-w-[250px] max-h-[200px] overflow-y-auto'>{item[key]}</div>
                                                                    </ReactTooltip>}
                                                                    {/* {key != "imageUrl" && this.small(item[key]) && <div className="table-tooltip"><label>{item[key]}</label></div>} */}
                                                                    </>}
                                                            
                                                        </td>)}
                                                </tr>
                                                {this.props.expand?.(item)?.expandedRow?.[item?.id] &&
                                                    <tr>
                                                        <td colSpan="100%" className="pad-0">
                                                            <ExpandedRow handleImageTrackingModal={this.props?.handleImageTrackingModal} {...this.props.expand?.(item)}  updateState = {this.props.updateState}/>
                                                        </td>
                                                    </tr>}
                                            </React.Fragment>
                                        )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}