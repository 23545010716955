import React, { useState, useEffect } from 'react'
import SalesDashboardCardsSkeletonLoader from '../../skeletons/SalesDashboardSkeletonLoader';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/icons';
import { formatNumber, parseJwt } from '../../../helper/genericFunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';

const SalesOrderDashboardCountCards = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let urlSearchParams = new URLSearchParams("?");
    let uType = parseJwt(sessionStorage?.getItem('token'))?.uType || "ENT";
    const [statusCountData, setStatusCountData] = useState({})
    const salesManagementStore = useSelector(state => state.salesManagementStore);

    useEffect(() => {
        let filters = props.getCurrentMainLevelFilters?.() || {};
        console.log("Count Filters", filters);
        dispatchHandler(dispatch, 'getSOStatusCountRequest', {
            filter: filters
        }, getPermissionKey("dashboard", "sotracking", "view"));
    }, [props?.filterData])

    useEffect(() => {
        if (salesManagementStore?.getSOStatusCountData?.isSuccess) {
            setStatusCountData(salesManagementStore?.getSOStatusCountData?.data?.resource || {})
        }
    }, [salesManagementStore?.getSOStatusCountData?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "redirectFunction": {
                if (props?.filterData?.orderDate && data.payload?.filter) {
                    let filters = props.getCurrentMainLevelFilters?.() || {};
                    data.payload.filter["creationTime"] = filters?.["orderDate"];
                }

                Object.keys(data.payload).forEach(key => {
                    if ((typeof data?.payload[key] != "object" && data?.payload[key]) || (typeof data?.payload[key] == "object" && Object.keys(data?.payload[key])?.length)) {
                        urlSearchParams.append(key, encodeURIComponent(JSON.stringify(data?.payload[key])));
                    }
                });

                if (uType === "CUSTOMER") {
                    history.push({
                        pathname: "/salesManagement/customer/viewSalesOrders",
                        search: urlSearchParams === "?" ? "" : urlSearchParams.toString(),
                    });
                } else {
                    history.push({
                        pathname: "/salesManagement/viewSalesOrders",
                        search: urlSearchParams === "?" ? "" : urlSearchParams.toString(),
                    });
                }
                break;
            }
            case "redirectDcFunction": {
                if (props?.filterData?.orderDate && data.payload?.filter) {
                    let filters = props.getCurrentMainLevelFilters?.() || {};
                    data.payload.filter["creationTime"] = filters?.["orderDate"];
                }

                Object.keys(data.payload).forEach(key => {
                    if ((typeof data?.payload[key] != "object" && data?.payload[key]) || (typeof data?.payload[key] == "object" && Object.keys(data?.payload[key])?.length)) {
                        urlSearchParams.append(key, encodeURIComponent(JSON.stringify(data?.payload[key])));
                    }
                });
                history.push({
                    pathname: "/salesManagement/manageDcChallan",
                    search: urlSearchParams === "?" ? "" : urlSearchParams.toString(),
                });
            }
        }
    }

    // Render Customer Dashboard Object
    const customerStatusCount = {
        pending: {
            label: "Pending for Approval",
            count: statusCountData?.pendingCount,
            sum: statusCountData?.pendingNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "PENDING"
                }
            }
        },
        hold: {
            label: "On Hold",
            count: statusCountData?.holdCount,
            sum: statusCountData?.holdNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "HOLD"
                }
            }
        },
        reject: {
            label: "Rejected",
            count: statusCountData?.rejectedCount,
            sum: statusCountData?.rejectedNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "REJECTED"
                }
            }
        },
        approved: {
            label: "Approved Sales Order",
            count: statusCountData?.approvedCount,
            sum: statusCountData?.approvedNetAmount,
            payload: {
                type: 2,
                orderType: "order",
                filter: {
                    status: "APPROVED,PARTIAL,DC_CREATED"
                }
            }
        },
        draft: {
            label: "Draft",
            count: statusCountData?.DraftCount,
            sum: statusCountData?.draftNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "DRAFT"
                }
            }
        },
        cancelled: {
            label: "Cancelled",
            count: statusCountData?.cancelledCount,
            sum: statusCountData?.cancelledNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "CANCELLED"
                }
            }
        }
    }

    // Render all cards json
    const statusCount = {
        pending: {
            label: "Pending for Approval",
            count: statusCountData?.pendingCount,
            sum: statusCountData?.pendingNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "PENDING"
                }
            },
            cardClassName: "sodpfa"
        },
        approved: {
            label: "Approved",
            count: statusCountData?.approvedCount,
            sum: statusCountData?.approvedNetAmount,
            payload: {
                type: 2,
                orderType: "order",
                filter: {
                    status: "APPROVED,PARTIAL,DC_CREATED"
                }
            }
        },
        hold: {
            label: "On Hold",
            count: statusCountData?.holdCount,
            sum: statusCountData?.holdNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "HOLD"
                }
            }
        }
    };

    const soTrackingLeftBottom = {
        cancelled: {
            label: "Cancelled",
            count: statusCountData?.cancelledCount,
            sum: statusCountData?.cancelledNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "CANCELLED"
                }
            },
            cardClassName: "sodapp"
        },
        draft: {
            label: "Draft",
            count: statusCountData?.DraftCount,
            sum: statusCountData?.draftNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "DRAFT"
                }
            },
            cardClassName: "soddraft"
        },
        reject: {
            label: "Rejected",
            count: statusCountData?.rejectedCount,
            sum: statusCountData?.rejectedNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "REJECTED"
                }
            }
        }
    }

    const dcStatusCount = {
        pending: {
            label: "Pending",
            count: statusCountData?.dc_pending_count || 0,
            sum: statusCountData?.dc_pending_amt || 0,
            payload: {
                status: "PENDING",
                filter: {
                    status: "PENDING"
                }
            }
        },
        reject: {
            label: "Rejected",
            count: statusCountData?.dc_rejected_count || 0,
            sum: statusCountData?.dc_rejected_amt || 0,
            payload: {
                status: "REJECTED",
                filter: {
                    status: "REJECTED"
                }
            },
            cardClassName: "sodcan"
        }
    }

    const statusObject = {
        "pending": <Icons.ArsPendindIcon />,
        "hold": <Icons.ArsHold />,
        "reject": <Icons.ArsRejectedIcon />,
        "cancelled": <Icons.ArsRejectedIcon />,
        "approved": <Icons.ArsApprovedIcon />,
        "draft": <Icons.ArsDraft />
    }

    return (
        salesManagementStore?.getSOStatusCountData?.isLoading ? <SalesDashboardCardsSkeletonLoader  /> : uType === "CUSTOMER" ? <>
            <div className="flex justify-between p-bot-12">
                {Object.keys(customerStatusCount).map(key =>
                    <div className="sd-cards-customer" onClick={() => handleChange('redirectFunction', { payload: customerStatusCount[key]?.payload, key: key })}>
                        <span className={`sdc-img ${key === "pending" ? "" : key === "hold" ? "sdc-hold" : key === "reject" || key === "cancelled" ? "sdc-rejected" : key === "approved" ? "sdc-approved" : "sdc-unauthorised"}`}>
                            {statusObject?.[key?.toLowerCase()] || null}
                        </span>
                        <LanguageTranslator tag="span" className="sdc-size">{customerStatusCount[key]?.count}</LanguageTranslator>
                        <LanguageTranslator tag="p">{customerStatusCount[key]?.label}</LanguageTranslator>
                        <div className="sdc-amount">
                            <LanguageTranslator tag="p">Amount</LanguageTranslator>
                            <span>₹ {customerStatusCount[key]?.sum || "N/A"}</span>
                        </div>

                    </div>
                )}
            </div>
        </> :
        <div className='mb-3 flex gap-3.5 w-full'>
            <div 
                className='relative overflow-hidden p-[12px] xl:p-[20px] w-3/5 bg-white flex flex-col justify-between rounded-md'
                style={{
                    boxShadow: '0 0 16px 1px #a3a3a340'
                }}
            >
            <div class="absolute -top-[10px] -left-[16px] w-0 h-0 border-l-[25px] border-r-[25px] border-b-[20px] border-transparent border-b-primary_light -rotate-[40deg]"></div>
                <div className="grid grid-cols-4 gap-4 pb-10">
                    <div className='h-full'>
                        <LanguageTranslator tag="h3" className='text-fontGrey text-xl font-bold'>Sales Order <br /> <span className='text-base font-semibold'>Tracking</span></LanguageTranslator>
                    </div>

                    {Object.keys(statusCount).map((key, index) =>
                        <div className="sd-cards" onClick={() => handleChange('redirectFunction', { payload: statusCount[key]?.payload, key: key })}>
                            <span className={`sdc-img ${key === "pending" ? "" : key === "hold" ? "sdc-hold" : key === "reject" || key === "cancelled" ? "sdc-rejected" : key === "approved" ? "sdc-approved" : "sdc-unauthorised"}`}>
                                {statusObject?.[key?.toLowerCase()] || null}
                            </span>
                            <LanguageTranslator tag="span" className="sdc-size">{statusCount[key]?.count}</LanguageTranslator>
                            <LanguageTranslator tag="p">{statusCount[key]?.label}</LanguageTranslator>
                            <div className="sdc-amount">
                                <LanguageTranslator tag="p" className='!text-[#B7B7B7]'>Amount</LanguageTranslator>
                                <div className='tooltip-target relative'>
                                    <span>₹ {formatNumber(statusCount[key]?.sum).short || "N/A"}</span>
                                    {formatNumber(statusCount[key]?.sum).full && <span className={`generic-tooltip !text-[11px] !font-semibold ${index === Object.keys(statusCount).length -1 ? '!left-auto !right-0 after:!left-auto after:!right-[15px]' : ''}`}>{formatNumber(statusCount[key]?.sum).full || "N/A"}</span>}
                                </div>
                            </div>

                        </div>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    {Object.keys(soTrackingLeftBottom).map((key, index) =>
                        <div key={key} className="detailsBox group cursor-pointer px-2.5 py-1 xl:px-3.5 xl:py-1.5 relative rounded-md w-full flex items-start gap-2 border border-transparent hover:border hover:border-borderSlateGrey" onClick={() => handleChange('redirectFunction', { payload: soTrackingLeftBottom[key]?.payload, key: key })}>
                            <div className='flex items-center gap-3 xl:gap-4'>
                                <span className={`sdc-img ${key === "pending" ? "" : key === "reject" || key === "cancelled" ? "sdc-rejected" : "sdc-unauthorised"}`}>
                                    {statusObject?.[key?.toLowerCase()] || null}
                                </span>
                                <div>
                                    <LanguageTranslator tag="span" className="sdc-size">{soTrackingLeftBottom[key]?.count}</LanguageTranslator>
                                    <LanguageTranslator tag="p" className='mt-[4px]'>{soTrackingLeftBottom[key]?.label}</LanguageTranslator>
                                </div>
                            </div>
                            <div className="sdc-amount ml-4 xl:ml-5 mt-[4px]">
                                <div className='tooltip-target relative'>
                                    <span>₹ {formatNumber(soTrackingLeftBottom[key]?.sum).short || "N/A"}</span>
                                    {formatNumber(soTrackingLeftBottom[key]?.sum).full && <span className={`generic-tooltip !text-[11px] !font-semibold ${index === Object.keys(soTrackingLeftBottom).length -1 ? '!left-auto !right-0 after:!left-auto after:!right-[15px]' : ''}`}>{formatNumber(soTrackingLeftBottom[key]?.sum).full || "N/A"}</span>}
                                </div>
                                <LanguageTranslator tag="p" className="!text-[#B7B7B7]">Amount</LanguageTranslator>
                            </div>

                            {index < Object.keys(soTrackingLeftBottom)?.length-1 && <div className={`absolute top-1/2 -translate-y-1/2 right-[-2px] shrink-0 w-[1px] h-[60%] bg-borderSlateGrey group-hover:-right-[1px]`} />}
                        </div>
                    )}
                </div>
            </div>

            <div 
                className='relative overflow-hidden p-[12px] xl:p-[20px] w-2/5 bg-white rounded-md'
                style={{
                    boxShadow: '0 0 16px 1px #a3a3a340'
                }}

            >

                <div class="absolute -top-[10px] -left-[16px] w-0 h-0 border-l-[25px] border-r-[25px] border-b-[20px] border-transparent border-b-primary_light -rotate-[40deg]"></div>

                <div className='mb-6'>
                    <LanguageTranslator tag="h3" className='text-xl font-bold text-[#67768E] mb-0'>Delivery <br /> Challan</LanguageTranslator>
                </div>
                <div className='flex gap-5'>
                    <div className='w-[35%] flex flex-col justify-between gap-5'>
                        <div className="sd-cards" onClick={() => handleChange('redirectDcFunction', { payload: {status: "APPROVED", filters: {}}, key: "approved" })}>
                            <span className={`sdc-img sdc-approved`}>
                                {statusObject?.["approved"]}
                            </span>
                            <LanguageTranslator tag="span" className="sdc-size">{statusCountData?.dc_approved_count}</LanguageTranslator>
                            <LanguageTranslator tag="p">Approved</LanguageTranslator>
                            <div className="sdc-amount">
                                <LanguageTranslator tag="p" className='!text-[#B7B7B7]'>Amount</LanguageTranslator>
                                <div className='tooltip-target relative'>
                                    <span>₹ {formatNumber(statusCountData?.dc_approved_amt).short || "N/A"}</span>
                                    {formatNumber(statusCountData?.dc_approved_amt).full && <span className={`generic-tooltip !text-[11px] !font-semibold`}>{formatNumber(statusCountData?.dc_approved_amt).full || "N/A"}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[65%] flex flex-col">
                        {Object.keys(dcStatusCount).map((key, index) =>
                            <div className="detailsBox group cursor-pointer px-4 py-5 relative rounded-md w-full flex items-start gap-2 border border-transparent hover:border hover:border-borderSlateGrey" onClick={() => handleChange('redirectDcFunction', { payload: dcStatusCount[key]?.payload, key: key })}>
                                <div className='flex items-center gap-4'>
                                    <span className={`sdc-img ${key === "pending" ? "" : key === "reject" ? "sdc-rejected" : "sdc-unauthorised"}`}>
                                        {statusObject?.[key?.toLowerCase()] || null}
                                    </span>
                                    <div>
                                        <LanguageTranslator tag="span" className="sdc-size">{dcStatusCount?.[key]?.count}</LanguageTranslator>
                                        <LanguageTranslator tag="p">{dcStatusCount?.[key]?.label}</LanguageTranslator>
                                    </div>
                                </div>
                                <div className="sdc-amount ml-7 mt-[4px]">
                                    <div className='tooltip-target relative'>
                                        <span>₹ {formatNumber(dcStatusCount?.[key]?.sum).short || "N/A"}</span>
                                        {formatNumber(dcStatusCount?.[key]?.sum).full && <span className={`generic-tooltip !text-[11px] !font-semibold !left-auto !right-0 after:!left-auto after:!right-[15px]`}>{formatNumber(dcStatusCount?.[key]?.sum).full || "N/A"}</span>}
                                    </div>
                                    <LanguageTranslator tag="p" className='!text-[#B7B7B7]'>Amount</LanguageTranslator>
                                </div>
                                {index < Object.keys(dcStatusCount)?.length-1 && <div className='absolute top-[calc(100%+1px)] left-[16px] w-[40px] h-[1px] mb-2.5 bg-borderSlateGrey group-hover:top-full' />}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesOrderDashboardCountCards