/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import AllFilters from '../components/AllFilters'
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import UpperCardComponent from './UpperCardComponent';
import GraphComponent from './GraphComponent';
import BottomTableData from './BottomTableData';
import GenericToggleComponent from '../../../genericComponents/GenericToggleComponent';
import { parseJwt } from '../../../helper/genericFunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { getPermissionKey } from '../../../helper/permissions';
import useRenderClassComponent from '../../../hooks/useRenderClassComponent';
import GenericTableData from '../salesOrder/GenericTableData';
import { CONFIG } from '../../../config';
import OrderTrackingProcessedOrders from './OrderTrackingProcessedOrders';
import OrderTrackingDashboardHirDetails from './OrderTrackingDashboardHirDetails';

const filterPayload = {
    attributeType: "ORDER_TRACKING_DASHBOARD",
    displayName: "ORDER_TRACKING_DASHBOARD",
    filterName: ""
};
let mappingSelectedFilters = {
    hl4_name: "Article Name",
    hl3_name: "Department",
    hl1_name: "Division",
    hl2_name: "Section",
    code: "Site",
    vendorCode: "Vendor Code",
    cat1name: "CAT1 Name",
    cat2name: "CAT2 Name",
    cat3name: "CAT3 Name",
    cat4name: "CAT4 Name",
    cat5name: "CAT5 Name",
    cat6name: "CAT6 Name",
    desc1: "Desc 1"
};
const MAIN_FILTERS = [
    {
        value: "Division",
        key: "hl1_name",
        entity: "item",
    },
    {
        value: "Section",
        key: "hl2_name",
        entity: "item",
    },
    {
        value: "Department",
        key: "hl3_name",
        entity: "item",
    },
    {
        value: "Article Name",
        key: "hl4_name",
        entity: "item",
    },
    {
        value: "CAT1 Name",
        key: "cat1name",
        entity: "po_details",
    },
    {
        value: "CAT2 Name",
        key: "cat2name",
        entity: "po_details",
    },
    {
        value: "CAT3 Name",
        key: "cat3name",
        entity: "po_details",
    },
    {
        value: "CAT4 Name",
        key: "cat4name",
        entity: "po_details",
    },
    {
        value: "CAT5 Name",
        key: "cat5name",
        entity: "po_details",
    },
    {
        value: "CAT6 Name",
        key: "cat6name",
        entity: "po_details",
    },
    {
        value: "Desc 1",
        key: "desc1",
        entity: "po_details",
    },
]
const PRIMARY_FILTERS = [
    {
        value: "Site",
        key: "code",
        code: "name1",
        entity: "site",
        headerKeys: {
            multiSelectCol: {},
            'code': { value: 'Code' },
            'name1': { value: 'Name' }
        },
        itemKeys: {
            'code': ['code'],
            'name1': ['name1']
        },
        multiselect: {
            selectId: 'code',
        },
    },
];
const filterItems = {
    "Default Headers": {
        dateRange: "Date From - Date To",
        hl4_name: "Article Name",
        hl3_name: "Department",
        hl1_name: "Division",
        hl2_name: "Section",
        code: "Site",
        vendorCode: "Vendor Code",
        cat1name: "CAT1 Name",
        cat2name: "CAT2 Name",
        cat3name: "CAT3 Name",
        cat4name: "CAT4 Name",
        cat5name: "CAT5 Name",
        cat6name: "CAT6 Name",
        desc1: "Desc 1"
    }
    ,
    "columnType": {
        dateRange: "DATE",
        hl4_name: "SEARCH",
        hl3_name: "SEARCH",
        hl1_name: "SEARCH",
        hl2_name: "SEARCH",
        code: "SEARCH",
        vendorCode: "SEARCH",
        cat1name: "SEARCH",
        cat2name: "SEARCH",
        cat3name: "SEARCH",
        cat4name: "SEARCH",
        cat5name: "SEARCH",
        cat6name: "SEARCH",
        desc1: "SEARCH"
    },
}

const OrderTrackingDashboard = (props) => {
    let uType = parseJwt(sessionStorage?.getItem('token'))?.uType;

    if (uType === 'ENT' && !PRIMARY_FILTERS.some(item => item?.key === "slcode")) {
        PRIMARY_FILTERS.push({
            value: "Vendor",
            key: "slcode",
            code: "slname",
            entity: "vendor",
            headerKeys: {
                multiSelectCol: {},
                'slcode': { value: 'Code' },
                'slname': { value: 'Name' }
            },
            itemKeys: {
                'slcode': ['slcode'],
                'slname': ['slname']
            },
            multiselect: {
                selectId: 'slcode',
            },
        },)
    }

    if (uType === 'ENT') {
        mappingSelectedFilters.slcode = "Vendor";
    }
    const currentFilterKeys = ["hl4_name", 'hl3_name', "hl1_name", "hl2_name", "vendorCode", "cat1name", 'cat2name', 'cat3name', 'cat4name', 'cat5name', 'cat6name', 'desc1', 'code', "dateRange",]
    if (uType === 'ENT') {
        currentFilterKeys.push('slcode')
    }

    if (uType === 'ENT') {
        filterItems["Default Headers"].slcode = "Vendor";
        filterItems["columnType"].slcode = "SEARCH";
    }

    const dispatch = useDispatch()
    const history = useHistory()
    const generalStore = useSelector(state => state.generalStore)
    const digivendStore = useSelector(state => state.digivendStore)

    const [toggleValue, setToggleValue] = useState('false')
    const [upperDashboardData, setUpperDashboardData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [bottomDashboardData, setBottomDashboardData] = useState([])
    const [grcDashboardData, setGrcDashboardData] = useState([])
    const [saveFiltersData, setSaveFiltersData] = useState({})

    const [currentFilters, setCurrentFilters] = useState({});
    const [callApiAfterFilterApplied, setCallApiAfterFilterApplied] = useState(false);

    console.log(22222222211111, saveFiltersData)

    useEffect(() => {
        if (uType === 'ENT') {
            if (generalStore?.getQuickFiltersData?.isSuccess) {
                dispatchHandler(dispatch, 'getSystemConfigKeysRequest', {}, getPermissionKey("common", "commonfunctionality", "view"));
            }
        } else {
            dispatchHandler(dispatch, 'getSystemConfigKeysRequest', {}, getPermissionKey("common", "commonfunctionality", "view"));
        }
    }, [uType === 'ENT' ? generalStore?.getQuickFiltersData?.isSuccess : null]);

    const isLoadingData = () => {
        return ["getOrderTrackingDashboardUpperDetailsData","getOrderTrackingDashboardGraphDetailsData", "getOrderTrackingDashboardGrcDetailsData", "getOrderTrackingDashboardBottomDetailsData"]?.some(key => digivendStore?.[key]?.isLoading)
    }

    useEffect(() => {
        if (digivendStore?.getSystemConfigKeysData?.isSuccess && !isLoadingData()) {
            getDashboardData()
        }
    }, [digivendStore?.getSystemConfigKeysData?.isSuccess])

    useEffect(() => {
        if (digivendStore?.getOrderTrackingDashboardUpperDetailsData?.isSuccess) {
            setUpperDashboardData(digivendStore?.getOrderTrackingDashboardUpperDetailsData?.data?.resource || [])
        }
    }, [digivendStore?.getOrderTrackingDashboardUpperDetailsData?.isSuccess])

    useEffect(() => {
        if (digivendStore?.getOrderTrackingDashboardGraphDetailsData?.isSuccess) {
            setGraphData(digivendStore?.getOrderTrackingDashboardGraphDetailsData?.data?.resource || [])
        }
    }, [digivendStore?.getOrderTrackingDashboardGraphDetailsData?.isSuccess])

    useEffect(() => {
        if (digivendStore?.getOrderTrackingDashboardGrcDetailsData?.isSuccess) {
            setGrcDashboardData(digivendStore?.getOrderTrackingDashboardGrcDetailsData?.data?.resource?.grcDashBoard || [])
        }
    }, [digivendStore?.getOrderTrackingDashboardGrcDetailsData?.isSuccess])

    useEffect(() => {
        if (digivendStore?.getOrderTrackingDashboardBottomDetailsData?.isSuccess) {
            setBottomDashboardData(digivendStore?.getOrderTrackingDashboardBottomDetailsData?.data?.resource || [])
        }
    }, [digivendStore?.getOrderTrackingDashboardBottomDetailsData?.isSuccess])

    let tableHeadersMap = {
        main: {
            name: "Main",
            key: "VENDOR_PROCESSED_ORDER"
        },
    }
    


    // Function : To render the order tracking dashboard processed order table
    const renderProcessedOrderTable = () => {
        if(uType?.toUpperCase() == "VENDOR"){
            return      <OrderTrackingProcessedOrders
            digivendStore = {digivendStore}
                generalStore = {generalStore}
                tableHeadersMap = {tableHeadersMap}
            />
        }
    }

    // function to get the dashboard data
    const getDashbaordFilter = (filtersData = {}) => {
        const HLMapping = { hl1_name: 'division', hl2_name: 'section', hl3_name: 'department', hl4_name: 'article' }

        let EntApiPayload = {}
        Object.keys(filtersData).forEach((filter) => {
            switch (filter) {
                case 'dateRange':
                    const { from, to } = filtersData.dateRange || {};
                    if (from && to) {
                        EntApiPayload.dateFrom = dayjs(from)?.format('YYYY-MM-DD');
                        EntApiPayload.dateTo = dayjs(to)?.format('YYYY-MM-DD');
                    }
                    break;
                case 'code':
                    EntApiPayload.site = filtersData?.code || ''
                    break
                case 'slcode':
                    EntApiPayload.vendorCode = filtersData?.slcode || ''
                    break
                case 'hl1_name':
                case 'hl2_name':
                case 'hl3_name':
                case 'hl4_name':
                    EntApiPayload[HLMapping?.[filter]] = filtersData?.[filter] || ''
                    break
                default:
                    EntApiPayload[filter] = filtersData[filter]
                    break;
            }
        });

        return EntApiPayload;
    }

    // function to get the dashboard data
    const getDashboardData = (filtersData = {}) => {
        setBottomDashboardData([])
        setUpperDashboardData([])
        setGraphData([])
        setGrcDashboardData([])
        setSaveFiltersData(filtersData)
        const HLMapping = { hl1_name: 'division', hl2_name: 'section', hl3_name: 'department', hl4_name: 'article' }

        let EntApiPayload = getDashbaordFilter(filtersData)

        setCurrentFilters(EntApiPayload);

        let VendorApiPayload = {
            dashboard: 'v2',
            vendor: '',
            vendorCode: '',
            filter: {}
        }
        Object.keys(filtersData).forEach((filter) => {
            switch (filter) {
                case 'dateRange':
                    const { from, to } = filtersData.dateRange || {};
                    if (from && to) {
                        VendorApiPayload.dateFrom = dayjs(from)?.format('YYYY-MM-DD');
                        VendorApiPayload.dateTo = dayjs(to)?.format('YYYY-MM-DD');
                    }
                    break;
                case 'code':
                    VendorApiPayload.site = filtersData?.code || ''
                    break
                case 'hl1_name':
                case 'hl2_name':
                case 'hl3_name':
                case 'hl4_name':
                    VendorApiPayload[HLMapping?.[filter]] = filtersData?.[filter] || ''
                    break
                default:
                    VendorApiPayload.filter[filter] = filtersData[filter]
                    break;
            }
        });

        let filter = { ...EntApiPayload }
        const upperDetailPayload = uType === 'ENT' ? { filter, dashboard: "v2", } : VendorApiPayload;
        const graphDataPayload = uType === 'ENT' ? { filter, dashboard: "v2", isGraphData: true } : { ...VendorApiPayload, isGraphData: true };
        const grcDetailPayload = uType === 'ENT' ? { filter, dashboard: "v2", grcDashboard: true } : { ...VendorApiPayload, grcDashboard: true };
        const bottomDetailPayload = uType === 'ENT' ? { filter, dashboard: "v2", } : VendorApiPayload;
        if(parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T") {
            dispatchHandler(dispatch, 'getOrderTrackingDashboardUpperDetailsRequest', { filter, dashboard: "v2", transporterDashboard: true }, getPermissionKey("dashboard", "ordertracking", "view"))
        }
        else {
            // api to get the upper dashboard data
            dispatchHandler(dispatch, 'getOrderTrackingDashboardUpperDetailsRequest', upperDetailPayload, getPermissionKey("dashboard", "ordertracking", "view"))
    
            // api to get the data for graph
            dispatchHandler(dispatch, 'getOrderTrackingDashboardGraphDetailsRequest', graphDataPayload, getPermissionKey("dashboard", "ordertracking", "view"))
    
            // api to get the grc data
            dispatchHandler(dispatch, 'getOrderTrackingDashboardGrcDetailsRequest', grcDetailPayload, getPermissionKey("dashboard", "ordertracking", "view"))
    
            // api to get the bottom dashboard data
            dispatchHandler(dispatch, 'getOrderTrackingDashboardBottomDetailsRequest', bottomDetailPayload, getPermissionKey("dashboard", "ordertracking", "view"))
        }
    }

    // Function : To render the order tracking heirarchy
    const renderOrderTrackingHeirarchyTable = () => {
        return <div className='pt-[12px] pb-[24px] pl-[30px] pr-[15px]'>
            <OrderTrackingDashboardHirDetails
                currentFilters = {currentFilters}
                callApiAfterFilterApplied = {callApiAfterFilterApplied}
                setCallApiAfterFilterApplied = {setCallApiAfterFilterApplied}
                getDashbaordFilter = {getDashbaordFilter}
                permissionObj = {getPermissionKey("dashboard", "ordertracking", "view")}
            />
        </div>
    }

    let toggleType = 'quantityWise'
    if (toggleValue) toggleType = 'quantityWise'
    if (!toggleValue) toggleType = 'amountWise'
    const handleChange = (value) => { setToggleValue(value) }
    return (
        <div className='order-tracking-dashboard'>

            <div className='od-toggle'>
                <GenericToggleComponent
                    checked={toggleValue}
                    onChange={handleChange}
                />
                <p><LanguageTranslator>Showing:</LanguageTranslator> <span>{toggleValue ? <LanguageTranslator>Quantity Wise</LanguageTranslator> : <LanguageTranslator>Amount Wise</LanguageTranslator>}</span></p>
            </div>

            <AllFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                MAIN_FILTERS={MAIN_FILTERS}
                disabledDate={null}
                applyFilter={(filtersData) => {
                    setCallApiAfterFilterApplied(true);
                    getDashboardData(filtersData)
                }}
                mappingSelectedFilters={mappingSelectedFilters}
                filterPayload={filterPayload}
                filterItems={filterItems}
                currentFilterKeys={currentFilterKeys}
                isQuickFilterRequired={uType === 'ENT' ? true : false}
            />

            {/* Upper Card Data */}
            {uType !== 'ENT' && <div className={`otd-alert ${upperDashboardData?.grcPendingQty && upperDashboardData?.grcPendingQty?.grc_pending_qty_diff === 0 && "p-0"}`}>
                {(upperDashboardData?.grcPendingQty && upperDashboardData?.grcPendingQty?.grc_pending_qty_diff === 0) || parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T" ? null :
                    <div className='discrepancies-body'>
                        <p>
                            <span>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6703 16.5492H5.83394C5.62041 16.5492 5.47806 16.478 5.33571 16.3356L1.13638 12.1363C0.994027 12.0651 0.922852 11.8516 0.922852 11.6381V5.80171C0.922852 5.58818 0.994027 5.44583 1.13638 5.30348L5.33571 1.10415C5.40688 0.9618 5.62041 0.890625 5.83394 0.890625H11.7415C11.955 0.890625 12.0973 0.9618 12.2397 1.10415L16.439 5.30348C16.5102 5.37466 16.5814 5.58818 16.5814 5.80171V11.7092C16.5814 11.9228 16.5102 12.0651 16.3679 12.2075L12.1685 16.4068C12.0973 16.478 11.8838 16.5492 11.6703 16.5492ZM6.11864 15.1257H11.4568L15.2291 11.3534V6.08641L11.3856 2.31413H6.11864L2.34635 6.08641V11.4245L6.11864 15.1257Z" fill="#E31F1F"></path>
                                    <path d="M8.75212 9.43164C8.32506 9.43164 8.04036 9.14694 8.04036 8.71989V5.87288C8.04036 5.44583 8.32506 5.16113 8.75212 5.16113C9.17917 5.16113 9.46387 5.44583 9.46387 5.87288V8.71989C9.46387 9.14694 9.17917 9.43164 8.75212 9.43164Z" fill="#E31F1F"></path>
                                    <path d="M8.75212 12.2786C8.53859 12.2786 8.39624 12.2075 8.25389 12.0651C8.11154 11.9228 8.04036 11.7804 8.04036 11.5669C8.04036 11.4957 8.04036 11.3534 8.11154 11.2822C8.18271 11.211 8.18271 11.1398 8.25389 11.0687C8.46742 10.8551 8.75212 10.784 9.03682 10.9263C9.10799 10.9263 9.10799 10.9263 9.17917 10.9975C9.17917 10.9975 9.25034 11.0687 9.32152 11.0687C9.39269 11.1398 9.46387 11.211 9.46387 11.2822C9.46387 11.3534 9.46387 11.4957 9.46387 11.5669C9.46387 11.6381 9.46387 11.7804 9.39269 11.8516C9.32152 11.9228 9.32152 11.9939 9.25034 12.0651C9.10799 12.2075 8.96564 12.2786 8.75212 12.2786Z" fill="#E31F1F"></path>
                                </svg>
                            </span>
                            <LanguageTranslator>Total</LanguageTranslator> {upperDashboardData?.grcPendingQty?.grc_pending_qty_diff} <LanguageTranslator>discrepancies are found in the orders.</LanguageTranslator>
                            <LanguageTranslator tag='label' onClick={() => history.push('/vendor/logistics/grcStatus')}>Check Now</LanguageTranslator>
                        </p>
                    </div>
                    }
            </div>}
            <div className={`otd-scroll ${(uType !== 'ENT' && !((upperDashboardData?.grcPendingQty && upperDashboardData?.grcPendingQty?.grc_pending_qty_diff === 0) || parseJwt(sessionStorage?.getItem('token'))?.subUserType == "T")) && "otd-scroll-more"}`}>
                <div className='otd-mid'>
                    <UpperCardComponent
                        upperDashboardData={upperDashboardData}
                        grcDashboardData={grcDashboardData}
                        bottomDashboardData={bottomDashboardData}
                        toggleType={toggleType}
                        saveFiltersData={saveFiltersData}
                    />
                </div>

                {/* render the processed order table in the case of the vendor order tracking dashbaord */}
                {renderProcessedOrderTable()}
                {/* Render the retailer side mapping table here */}
                {uType == "ENT" && renderOrderTrackingHeirarchyTable()}

                {parseJwt(sessionStorage?.getItem('token'))?.subUserType != "T" && <div className='otd-bot'>
                    {/* Bottom data i.e Table */}
                    <div className='otdb-left'>
                        <BottomTableData bottomDashboardData={bottomDashboardData} upperDashboardData={upperDashboardData} toggleType={toggleType} />
                    </div>

                    {/* Graph  */}
                    <GraphComponent graphData={graphData} />
                </div>}
            </div>
        </div>
    )
}

export default OrderTrackingDashboard