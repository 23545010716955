import { MessageSquareText } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import LanguageTranslator from '../../locals/LanguageTranslator'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import GenericSpinnerLoader from '../../loaders/GenericSpinnerLoader'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'

const CentalCommentsOptions = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const getCommentInformationData = useSelector(state => state?.generalStore?.getCommentInformationData);

    const [loading, setLoading] = useState(false);
    const [commentCount, setCommentCount] = useState(null);
    

    useEffect(() => {
        setLoading(true);
        getComments();
    },[props.unreadComments])

    useEffect(() => {
        if(getCommentInformationData?.isSuccess){
            setLoading(false);
            setCommentCount(getCommentInformationData?.data?.count);
        }
        if(getCommentInformationData?.isError){
            setLoading(false);
        }
    },[getCommentInformationData])


    const getComments = () => {
        dispatchHandler(dispatch, "getCommentInformationRequest", {
            "type" : "getAllUnreadCommentCountForThisUser"
        })
    }

    console.log('commentCount',commentCount)

    return (
        <li id='headerComments' className="group relative cursor-pointer h-[40px] w-[40px] flex items-center justify-center rounded-full mr-[7px] hover:bg-primary_w_less_opacity" onClick={() => history.push('/central/comment')}>
            <span><MessageSquareText className='text-[#67768E] group-hover:text-primary' /></span>
            <LanguageTranslator tag="span" class="generic-tooltip !top-[59px] !bg-[#1f2f4c] !-translate-x-1/2 !-translate-y-1/2 group-hover:opacity-100 group-hover:visible after:!left-[50%] after:!top-[-10px] after:!border-transparent after:!border-b-[#1f2f4c]">Comments</LanguageTranslator>
            {/* {Object.keys(props?.unreadComments || {})?.length > 0 && Object.values(props?.unreadComments || {})?.reduce((acc, item) => Number(acc) + Number(item) , 0)} */}
            {/* {Object.keys(props?.unreadComments || {})?.length > 0 && <div className='absolute z-10 top-[4px] left-[22px] min-w-[16px] h-[16px] w-auto bg-[#20BF6B] px-[4px] py-[1px] rounded-full flex items-center justify-center'><p className={`mb-0 text-[10px] text-white text-center leading-[inherit]`}>{Object.values(props?.unreadComments || {})?.reduce((acc, item) => Number(acc) + Number(item), 0) > 99 ? '99+' : Object.values(props?.unreadComments || {})?.reduce((acc, item) => Number(acc) + Number(item), 0)}</p></div>} */}
            {commentCount === null && loading ? <GenericSpinnerLoader/> : Object.values(commentCount || {})?.reduce((acc, item) => Number(acc) + Number(item), 0) > 0 && <div className='absolute z-10 top-[4px] left-[22px] min-w-[16px] h-[16px] w-auto bg-[#20BF6B] px-[4px] py-[1px] rounded-full flex items-center justify-center'><p className={`mb-0 text-[10px] text-white text-center leading-[inherit]`}>{Object.values(commentCount || {})?.reduce((acc, item) => Number(acc) + Number(item), 0) > 99 ? '99+' : Object.values(commentCount || {})?.reduce((acc, item) => Number(acc) + Number(item), 0) ?? 0}</p></div>}
        </li>
    )
}

export default CentalCommentsOptions
