import { parseJwt } from "../../helper/genericFunction";
import locale from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";

const UseDatePickerLocale = () => {
  let languageJson = {
    "en": true,
    "en-HS": true,
    "zh": true,
  };
  let currentLanguage = sessionStorage?.getItem("CURRENT_LANGUAGE")
    ? languageJson?.hasOwnProperty(sessionStorage?.getItem("CURRENT_LANGUAGE"))
      ? sessionStorage?.getItem("CURRENT_LANGUAGE")
      : "en"
    : "en";

  const isMonthPickerFormatAvailable = () => {
    const tokenData = parseJwt(sessionStorage.getItem("token"));
    return currentLanguage === "zh";
  };

  let updatedLocale = locale;

  if (isMonthPickerFormatAvailable()) {
    updatedLocale = {
      ...locale,
      lang: {
        ...locale.lang,
        shortMonths: [
          "壹月", "貳月", "叁月", "肆月", "伍月", "陸月",
          "柒月", "捌月", "玖月", "拾月", "拾壹月", "拾貳月"
        ],
        weekdays: [
          "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"
        ],
        shortWeekDays: ["日", "一", "二", "三", "四", "五", "六"],
        today: "今天"
      },
    };
  }

  return updatedLocale;
};

export default UseDatePickerLocale;
