import React, { useContext, useEffect, useState } from 'react'
import CommentEditor from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentEditor';
import CommentBoxModalWrapper from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentBoxModalWrapper';
import ListComponent from '../../../genericComponents/ListComponent';
import CommentTableComponent from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentTableComponent';
import GenericSpinnerLoader from '../../../loaders/GenericSpinnerLoader';
import { getPayloadFromURLParams, setPayloadAsURLParams } from '../../../helper/URLParamsHelper';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { FileText, MessageSquareMore, MessageSquareText, SquareDashedMousePointer } from 'lucide-react';
import Icons from '../../../assets/icons';
import GenericInput from '../../../genericComponents/input/GenericInputComponent';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import moment from 'moment';
import { Tooltip } from 'antd';
import { CommentContext } from '../../AppStack';
import { getNumberOfStrips } from '../../../initialPhaseOperations/InitialAnnouncementModal'
import LanguageTranslator from '../../../locals/LanguageTranslator';

const Comments = () => {

    const [currentSelectedDocument, setCurrentSelectedDocument] = useState(null);

    const [updateSelectedDocument, setUpdatedSelectedDocument] = useState(null);


    const location = useLocation();
    const history = useHistory();
    const {unreadComments} = useContext(CommentContext) 

    const [searchType, setSearchtype] = useState({type : "All", key : "all"})

    const [refreshData, setRefreshData] = useState(false);

    const [loading, setLoading] = useState(true);
    const [initialSearch, setInitialSearch] = useState("");
    const [backupSelectedDocument, setBackupSelectedDocument] = useState(null);
    const [stripHeight, setStripHeight] = useState(getNumberOfStrips() || 0);

    const getCommentsDocumentsListData = useSelector(state => state?.generalStore?.getCommentsDocumentsListData);

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    useEffect(() => {
        if(getCommentsDocumentsListData?.isSuccess){
            setRefreshData(false);
        }
        if(getCommentsDocumentsListData?.isError){
            setRefreshData(false);
        }
    },[getCommentsDocumentsListData])

    useEffect(() => {
        // Initital comment modal changes
        getQueryPropsAndSetTheData()
    }, [])

    const getQueryPropsAndSetTheData = () => {

        if (!location) return;

        const urlSearchParams = new URLSearchParams(location.search),
            params = Object.fromEntries(urlSearchParams.entries());

        console.log("params ldajfsdf", params, params?.documentId && params?.documentNumber);

        if (params?.documentId && params?.documentNumber) {
            console.log('caseeeeee', 'json case')
            setInitialSearch(JSON.parse(decodeURIComponent(params?.documentNumber || "")));
            setBackupSelectedDocument({
                document_id: JSON.parse(decodeURIComponent(params?.documentId || "")),
                document_no: JSON.parse(decodeURIComponent(params?.documentNumber || "")),
                document_type: params?.documentType ? JSON.parse(decodeURIComponent(params?.documentType || "")) : "",
            })

            setUpdatedSelectedDocument(null);
        }

        // Remove the applied selected items from the URL search params 
        setPayloadAsURLParams({
            data: {
                documentId: undefined,
                documentNumber: undefined,
                documentType: undefined,
            },
            l: location,
            h: history,
        })

        setLoading(false);
    }

    useEffect(() => {
        if (updateSelectedDocument) {
            setCurrentSelectedDocument(updateSelectedDocument);
        }
    }, [updateSelectedDocument]);

    useEffect(() => {
        if (getCommentsDocumentsListData?.isLoading && initialSearch) {
            console.log('caseeeeee', 'first')
            setInitialSearch(null);
        }
        if (getCommentsDocumentsListData?.isSuccess && backupSelectedDocument) {
            console.log('caseeeeee', 'second')
            console.log("Check34234234", getCommentsDocumentsListData, getCommentsDocumentsListData?.data, getCommentsDocumentsListData?.data?.data?.some(item => item?.document_no == backupSelectedDocument?.document_no));
            setInitialSearch(null);
            if (getCommentsDocumentsListData?.data?.data?.length > 0 && getCommentsDocumentsListData?.data?.data?.some(item => item?.document_no == backupSelectedDocument?.document_no)) {
                setUpdatedSelectedDocument(backupSelectedDocument);
                setCurrentSelectedDocument(null);
                setBackupSelectedDocument(null);
            }
        }
        if (getCommentsDocumentsListData?.isError && backupSelectedDocument) {
            console.log('caseeeeee', 'third')
            setBackupSelectedDocument(null);
            setInitialSearch(null);
        }
    }, [getCommentsDocumentsListData])

    console.log('getCommentsDocumentsListData?.data?.totalItems',getCommentsDocumentsListData?.data?.totalItems)


    const renderCommentDocuments = () => {
        return (
            <ListComponent
                initialSearch={initialSearch || ""}
                useKeyboardShortcuts={false}
                headerKeys={{
                    "label": {}
                }}
                itemKeys={{
                    "label": ["label",]
                }}
                search={{
                    placeholder: "Search any document",

                    isSearchIconRequire: true,
                }}
                api={{
                    reduxState: "generalStore",
                    apiName: "getCommentsDocumentsList",
                    apiPayload: (params) => {

                        let filtersData = {};

                        if(searchType?.key != "all"){
                            filtersData = {
                                "documentType" : searchType?.key
                            }
                        }

                        return {
                            "pageNo": params?.currentPage || 1,
                            "pageSize": 30,
                            "search": params?.currentSearch || "",
                            "filter": filtersData,
                        }
                    },
                    getMaxPage: (states, fucntions, response) => {
                        return response.maxPage;
                    },
                }}
                refreshData = {refreshData}
                getListData={(data) => data?.data || []}
                renderCustomListCompleteItem={(s, f, item, index) => {

                    let parsedAdditionalInfo = JSON.parse(item?.additional || "{}");
                    let updatedItem = {
                        ...item,
                        "vendorCode": parsedAdditionalInfo?.["vendorCode"] || "",
                        "customerCode": parsedAdditionalInfo?.["customerCode"] || "",
                    }

                    function formatCommentTime(creationTime) {
                        const now = moment();
                        const commentTime = moment(creationTime);
                        const diffMinutes = now.diff(commentTime, "minutes");
                        const diffDays = now.diff(commentTime, "days");
                      
                        if (diffMinutes < 60) {
                          return `${diffMinutes} min ago, ${commentTime.format("hh:mm A")}`;
                        } else if (diffMinutes < 1440) {
                          const hours = Math.floor(diffMinutes / 60);
                          return `${hours} hour${hours > 1 ? "s" : ""} ago, ${commentTime.format("hh:mm A")}`;
                        } else if (diffDays < 3) {
                          return `${diffDays} day${diffDays > 1 ? "s" : ""} ago, ${commentTime.format("hh:mm A")}`;
                        } else {
                          return commentTime.format("DD MMM YYYY, hh:mm A");
                        }
                      }                      

                    //   const lastUserDivColor = {
                    //     A: "#D32F2F", B: "#C2185B", C: "#7B1FA2", D: "#512DA8",
                    //     E: "#303F9F", F: "#1976D2", G: "#0288D1", H: "#0097A7",
                    //     I: "#00796B", J: "#388E3C", K: "#689F38", L: "#AFB42B",
                    //     M: "#FBC02D", N: "#FFA000", O: "#F57C00", P: "#E64A19",
                    //     Q: "#5D4037", R: "#616161", S: "#455A64", T: "#1E88E5",
                    //     U: "#D81B60", V: "#8E24AA", W: "#3949AB", X: "#039BE5",
                    //     Y: "#43A047", Z: "#F4511E", "": "black"
                    //   };

                    const lastUserDivColor = (char, opacity = 1) => {
                        const colors = {
                            A: `rgba(211, 47, 47, ${opacity})`,  // #D32F2F
                            B: `rgba(194, 24, 91, ${opacity})`,  // #C2185B
                            C: `rgba(123, 31, 162, ${opacity})`, // #7B1FA2
                            D: `rgba(81, 45, 168, ${opacity})`,  // #512DA8
                            E: `rgba(48, 63, 159, ${opacity})`,  // #303F9F
                            F: `rgba(25, 118, 210, ${opacity})`, // #1976D2
                            G: `rgba(2, 136, 209, ${opacity})`,  // #0288D1
                            H: `rgba(0, 151, 167, ${opacity})`,  // #0097A7
                            I: `rgba(0, 121, 107, ${opacity})`,  // #00796B
                            J: `rgba(56, 142, 60, ${opacity})`,  // #388E3C
                            K: `rgba(104, 159, 56, ${opacity})`, // #689F38
                            L: `rgba(175, 180, 43, ${opacity})`, // #AFB42B
                            M: `rgba(251, 192, 45, ${opacity})`, // #FBC02D
                            N: `rgba(255, 160, 0, ${opacity})`,  // #FFA000
                            O: `rgba(245, 124, 0, ${opacity})`,  // #F57C00
                            P: `rgba(230, 74, 25, ${opacity})`,  // #E64A19
                            Q: `rgba(93, 64, 55, ${opacity})`,   // #5D4037
                            R: `rgba(97, 97, 97, ${opacity})`,   // #616161
                            S: `rgba(69, 90, 100, ${opacity})`,  // #455A64
                            T: `rgba(30, 136, 229, ${opacity})`, // #1E88E5
                            U: `rgba(216, 27, 96, ${opacity})`,  // #D81B60
                            V: `rgba(142, 36, 170, ${opacity})`, // #8E24AA
                            W: `rgba(57, 73, 171, ${opacity})`,  // #3949AB
                            X: `rgba(3, 155, 229, ${opacity})`,  // #039BE5
                            Y: `rgba(67, 160, 71, ${opacity})`,  // #43A047
                            Z: `rgba(244, 81, 30, ${opacity})`,  // #F4511E
                            "": `rgba(0, 0, 0, ${opacity})`      // black
                      };
                      return colors[char];
                    }       
                    
                    const getNameInitial = (name) => {
                        if (!name?.trim()) return ''; // Handle empty or invalid input
                      
                        const nameArr = name.trim().split(/\s+/); // Split by one or more spaces
                        const firstInitial = nameArr[0][0]?.toUpperCase();
                        const lastInitial = nameArr[nameArr.length - 1][0]?.toUpperCase();
                      
                        return nameArr.length > 1 ? `${firstInitial}${lastInitial}` : firstInitial;
                      };
                      

                    // console.log("parsed additional info: ", item.additional, parsedAdditionalInfo);
                    console.log('item?.["username"]', item?.["username"])
                    return (
                        <div
                            className={`group flex items-center justify-between gap-4 mx-3 px-3 py-3 rounded-lg cursor-pointer mb-1 last:mb-0 hover:bg-[#F7F8FA] ${currentSelectedDocument?.document_id === item?.document_id && 'bg-[#F7F8FA]'}`}
                            onClick={() => {
                                if (currentSelectedDocument?.document_id !== item?.document_id || updateSelectedDocument?.document_id !== item?.document_id)
                                    setCurrentSelectedDocument(null);
                                setUpdatedSelectedDocument(updatedItem)
                            }}
                        >
                            <div className='flex items-center gap-2.5'>
                                <div>
                                    <FileText size={22} strokeWidth={1.5} className={`text-[#718098] group-hover:text-black ${currentSelectedDocument?.document_id === item?.document_id && '!text-black'}`} />
                                </div>
                                <div >
                                    <p className={`mb-0.5 text-xs font-medium group-hover:font-semibold ${currentSelectedDocument?.document_id === item?.document_id && 'font-semibold'}`}>{item?.["document_no"] || ""}</p>
                                    {/* {item?.["comment_count"] && <p className='mb-0 text-[10px] text-fontGrey'>Total : {item?.["comment_count"] || "-"}</p>} */}
                                    {item?.["creation_time"] && <p className='mb-0 text-[9px] text-fontGrey'>Last activity : {formatCommentTime(moment(item?.["creation_time"])?.format("DD MMM YYYY HH:mm:ss")) || "-"}</p>}
                                </div>
                            </div>
                            <div className='flex items-center gap-3'>
                                {/* Render the total number of comments here */}
                                {/* {item?.["comment_count"] && <p>Total : {item?.["comment_count"] || "-"}</p>} */}
                                <div>
                                    <CommentTableComponent
                                        commentRowIndex={index}
                                        comment={{
                                            disabled: false,
                                            configKeys: {
                                                "documentId": "document_id",
                                                "documentNumber": "document_no",
                                                "vendorCode": "vendorCode",
                                                "customerCode": "customerCode",
                                                "documentType": "document_type"
                                            }
                                        }}
                                        commentModal={currentSelectedDocument}
                                        item={updatedItem || {}}
                                        mode={"central"}
                                    />
                                </div>
                                {item?.["username"] && 
                                    <Tooltip overlayClassName='!p-0' overlayStyle={{ fontSize: '12px' }} title={item?.["username"] || ''}>
                                        <div className='relative'>
                                            <div 
                                                className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[118%] w-[118%] rounded-full' 
                                                style={{backgroundColor: lastUserDivColor(item?.["username"]?.slice(0, 1).toString().toUpperCase() || '', .1)}}
                                            ></div>
                                            <div 
                                                className={`relative rounded-full aspect-square flex items-center justify-center p-[6px] text-base h-[34px] w-[34px] shrink-0 text-white capitalize`} 
                                                style={{
                                                    backgroundColor: lastUserDivColor(item?.["username"]?.slice(0,1).toString().toUpperCase() || ''), 
                                                }}
                                            >
                                                <p className='mb-0 leading-[inherit]'>{getNameInitial(item?.["username"]) || ''}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                    }
                            </div>
                        </div>
                    )

                }}
                renderCustomItems={{
                    "1": () =>
                         (
                        <div className='px-[15px] py-3 flex items-center justify-between gap-3'>
                            <GenericInput
                                // genInputExtraClass="!mb-0 !w-1/2 !max-w-[300px]"
                                genInputExtraClass="!mb-0"
                                type = "dropdown"
                                inputKey={"searchType"}
                                placeholder="Document Type"
                                isDisabled={getCommentsDocumentsListData?.isLoading}
                                onChange={(e) => {
                                    setSearchtype(e)
                                    if (e != searchType) {
                                        setRefreshData(true);
                                    }
                                }}
                                value={`${searchType?.type} ${getCommentsDocumentsListData?.data?.totalItems ? `(${getCommentsDocumentsListData?.data?.totalItems})` : ''}`}
                                inputProps={() => ({
                                    modalMainClass: '!top-full !mt-[4px] !rounded-none',
                                    headerKeys : {
                                        "type" : {},
                                    },
                                    itemKeys : {
                                        "type" : ["type"]
                                    },
                                    getListData: () => ([
                                        { type: "All", key: "all" },
                                        { type: "Purchase Indent", key: "PI" },
                                        { type: "Purchase Order", key: "PO" },
                                        { type: "ASN", key: "ASN" },
                                        { type: "Logistics Request", key: "LR" },
                                        { type: "Gate Entry", key: "GATE" },
                                        { type: "GRC", key: "GRC" },
                                        { type: "Sales Order", key: "SO" },
                                        { type: "Delivery Challan", key: "DC" },
                                        { type: "Transfer Order", key: "TO" },
                                        { type: "Product", key: "PICS" }
                                    ])
                                })}
                                iconRight={<span className='ml-[6px] mr-[8px] text-black'><Icons.DownArrowNew /></span>}
                            />
                            {/* <div className='flex items-center gap-4'>
                                {getCommentsDocumentsListData?.data?.totalItems > 0 && <div className='mb-0 text-fontGrey text-[11px] flex items-center gap-1.5'>
                                    <p className='mb-0'>All <span className='text-fontBlack'>({getCommentsDocumentsListData?.data?.totalItems})</span></p>
                                </div>}
                                {unreadComments && Object.values(unreadComments || {})?.reduce((acc, item) => Number(acc) + Number(item) , 0) > 0 && <div className='mb-0 relative text-fontGrey text-[11px] flex items-center gap-1.5'>
                                    <p className='mb-0'>Unread <span className='text-fontBlack'>({Object.values(unreadComments || {})?.reduce((acc, item) => Number(acc) + Number(item) , 0)})</span></p>
                                </div>}
                            </div> */}
                        </div>
                    )
                }}
            />
        )
    }


    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <GenericSpinnerLoader />
            </div>
        )
    }

	const topHeight = 60 + Number(stripHeight || 0);

    return (
        <React.Fragment>

            <div className={`flex pt-[12px] ml-7 mr-3 overflow-hidden`} style={{height: `calc(100vh - ${topHeight}px`}}>
                <div className='commentDocList h-full w-full max-w-[40%] lg:max-w-[400px] 2xl:max-w-[25%] bg-white'>
                    {renderCommentDocuments()}
                </div>
                <div className='w-full max-w-[calc(100%-300px)]'>
                    {currentSelectedDocument ? <div className='h-full'>
                        <CommentBoxModalWrapper
                            onClose={() => setCurrentSelectedDocument(null)}
                            item={currentSelectedDocument || {}}
                            comment={{
                                disabled: false,
                                configKeys: {
                                    "documentId": "document_id",
                                    "documentNumber": "document_no",
                                    "vendorCode": "vendorCode",
                                    "documentType": "document_type"
                                }
                            }}
                            // @Ankit : Uncomment this for central comment UI
                            mode='central'
                        />
                    </div> : <div className='h-full flex flex-col items-center justify-center p-[1rem]'>
                        {/* @Vishnu : Please create the no documents select icons*/}
                        <div className='mb-4'><SquareDashedMousePointer strokeWidth={1.5} size={80} /></div>
                        <LanguageTranslator tag='p' className='font-semibold mb-1'>No Document selection found</LanguageTranslator>
                        <LanguageTranslator tag='p' className='mb-0 text-xs text-fontGrey'>Please select a document to view comments</LanguageTranslator>
                    </div>}
                </div>
            </div>

        </React.Fragment>

    )
}

export default Comments