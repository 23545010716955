import React, { useEffect, useRef, useState } from 'react'
import AllFilters from '../components/AllFilters'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import { CONFIG } from '../../../config'
import { useDispatch, useSelector } from 'react-redux'
import Icons from '../../../assets/icons'
import { getPermissionKey } from '../../../helper/permissions'
import GenericInput from '../../../genericComponents/input/GenericInputComponent'
import NoDataFound from '../../../genericComponents/NoDataFound'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { BadgeIndianRupee, ChevronRight, CircleCheck, ExternalLink, GalleryVerticalEnd, IndianRupee, LibraryBig, OctagonX, RotateCcw, Send, Shirt, TriangleAlert } from 'lucide-react'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import { useHistory } from 'react-router-dom'
import { CollectionDropdown } from '../../digicatalogue/vendorProduct/CollectionDropdown'
import { parseJwt } from '../../../helper/genericFunction'
import LanguageTranslator from '../../../locals/LanguageTranslator'
import ProductSurveyDashboard from '../../digicatalogue/create/productSurvey/ProductSurveyDashboard'
import ParentModal from '../../../genericComponents/ParentModal'

let PRIMARY_FILTERS = [
    {
        value: "Color",
        key: "color",
        code: "",
        entity: "vendor.product_library",
        headerKeys: {
            multiSelectCol: {},
            'color': { value: 'Color' },
        },
        itemKeys: {
            'color': ['color'],
        },
        multiselect: {
            selectId: 'color',
        },
    },
    {
        value: "Design",
        key: "design_group_number",
        code: "",
        entity: "vendor.product_library",
        headerKeys: {
            multiSelectCol: {},
            'design_group_number': { value: 'Design Group No' },
        },
        itemKeys: {
            'design_group_number': ['design_group_number'],
        },
        multiselect: {
            selectId: 'design_group_number',
        },
    },
    {
        value: "Division",
        key: "hl1name",
        code: "hl1code",
        entity: "vendor.product_library",
        headerKeys: {
            multiSelectCol: {},
            'hl1code': { value: "Code" },
            'hl1name': { value: 'Name' },
        },
        itemKeys: {
            'hl1code': ['hl1code'],
            'hl1name': ['hl1name']
        },
        multiselect: {
            selectId: 'hl1code',
        },
    },
    {
        value: "Section",
        key: "hl2name",
        code: "hl2code",
        entity: "vendor.product_library",
        headerKeys: {
            multiSelectCol: {},
            'hl2code': { value: "Code" },
            'hl2name': { value: 'Name' },
        },
        itemKeys: {
            'hl1code': ['hl2code'],
            'hl2name': ['hl2name']
        },
        multiselect: {
            selectId: 'hl2code',
        },
    },
    {
        value: "Department",
        key: "hl3name",
        code: "hl3code",
        entity: "vendor.product_library",
        headerKeys: {
            multiSelectCol: {},
            'hl3code': { value: "Code" },
            'hl3name': { value: 'Name' },
        },
        itemKeys: {
            'hl3code': ['hl3code'],
            'hl3name': ['hl3name']
        },
        multiselect: {
            selectId: 'hl3code',
        },
    },
]

let filterItems = {
    "Default Headers": {
        dateRange: "Date From - Date To",
        color : "Color",
        design_group_number : "Design Group No",
        hl1name : "Division",
        hl2name : "Section",
        hl3name : "Department",
    },
    "columnType": {
        dateRange: "DATE",
        color : "SEARCH",
        design_group_number : "SEARCH",
        hl1name : "SEARCH",
        hl2name : "SEARCH",
        hl3name : "SEARCH",
    }
}

const DigicatalogueDashbaord = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        getTableComponentListData,
        getTableComponentSecondListData,
        getTableComponentThirdListData,
        getSearchFiltersData
    } = useSelector(state => state?.generalStore);

    let permissionsObj = {
        "view": { "X-Scope-Id": "common.commonfunctionality.view" }  // getPermissionKey("dashboard", "digicatalogue", "view"),
    }   
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [productCardsData, setProductCardsData] = useState(null);
    const [collectionData, setCollectionData] = useState(null);
    const [surveyStatisticsData, setSurveyStatisticsData] = useState(null);
    const [errorData, setErrorData] = useState({});
    const [filtersData, setFiltersData] = useState({});
    const [showCollectionDropdown, setShowCollectionDropdown] = useState(false);

    const [ selectedModal, setSelectedModal] = useState(false);

    const currentFilterKeys = ["color", 'design_group_number', "hl1name", "hl2name", "hl3name", "dateRange"]

    let loadCurrentSurvey = useRef(false);
    const collectionPopoverRef = useRef(null)

    const renderChartSkeletonLoader = () => {
        return <div className='px-3 mt-[1rem] mb-3 h-[300px] w-full'><div className='px-8 h-full flex items-end gap-5 justify-between border-l border-b'>
            <div className='generic-skeleton !w-[35px] !h-[250px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[100px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[200px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[250px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[100px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[200px] !rounded-none !mb-0'></div>
            <div className='generic-skeleton !w-[35px] !h-[250px] !rounded-none !mb-0'></div>
        </div></div>
    }

    const getBarColor = {
        'delivered': '#3D399F',
        'opened': '#93C5FD',
        'undelivered': '#FD3757',
        'submitted': '#3DC1D3',
        '': ''
    }

    console.log("Suafaksdf",surveyStatisticsData);


    useEffect(() => {
        getDashboardData();
        // Function : To get the dashboard data 
        // 1. Get the collection data
        getCollectionData();
        // 3. get the survey statistics data
        getSurveyStatisticsData();
    }, [])

    useEffect(() => {
        if (currentSurvey) {
            getSurveyStatisticsData();
        }
    }, [currentSurvey])


    useEffect(() => {
        if (getTableComponentListData?.isSuccess) {
            setCollectionData(getTableComponentListData?.data?.resource || {});
            let errorDataClone = { ...errorData };
            if(errorDataClone?.collectionData) {
                delete errorDataClone["collectionData"];
            }
            setErrorData(errorDataClone);
        }
        if (getTableComponentListData?.isError) {
            setErrorData(prev => {
                prev["collectionData"] = "Failed to get collection's summary!";
                return prev;
            })
        }
    }, [getTableComponentListData])

    useEffect(() => {
        if (getTableComponentSecondListData?.isSuccess) {
            setProductCardsData(getTableComponentSecondListData?.data?.resource);
            let errorDataClone = { ...errorData };
            if(errorDataClone?.productData) {
                delete errorDataClone["productData"];
            }
            setErrorData(errorDataClone);
        }
        if (getTableComponentSecondListData?.isError) {
            setErrorData(prev => {
                prev["productData"] = "Failed to get products's summary!";
                return prev;
            })
        }
    }, [getTableComponentSecondListData])

    useEffect(() => {
        if (getTableComponentThirdListData?.isSuccess) {
            setSurveyStatisticsData(getTableComponentThirdListData?.data?.resource || []);
            let errorDataClone = { ...errorData };
            if(errorDataClone?.surveyStatistics) {
                delete errorDataClone["surveyStatistics"];
            }
            setErrorData(errorDataClone);
        }
        if (getTableComponentThirdListData?.isError) {
            setErrorData(prev => {
                prev["surveyStatistics"] = "Failed to get survey's summary!";
                return prev;
            })
        }
    }, [getTableComponentThirdListData])

    console.log("cusdfasdf", currentSurvey);
    useEffect(() => {
        if (loadCurrentSurvey?.current) {
            if (getSearchFiltersData?.isSuccess) {
                loadCurrentSurvey.current = false;
                setCurrentSurvey(getSearchFiltersData?.data?.resource?.searchResultNew?.[0]);
            }
            if (getSearchFiltersData?.isError) {
                loadCurrentSurvey.current = false;
                setErrorData(prev => {
                    prev["surveyStatistics"] = "Failed to get survey's summary!";
                    return prev;
                })
            }
        }
    }, [getSearchFiltersData])

    const getDashboardData = (filters = filtersData || {}) => {
        if (JSON.stringify(filters) !== JSON.stringify(filtersData))
            setFiltersData(filters)
        // 2 Get the product card data
        getProductCardData(filters);
    }

    const getFilterDataForPayload = (config) => {
        let { type, filters } = config || {};
        switch (type) {
            default: {
                return filters;
            }
        }
    }

    const getChildComponent = () => {
        switch(selectedModal){
            case "viewSurveyStatisticsDetails" : {
                return ProductSurveyDashboard
                break;
            }
        }
    }

    const getParentChildProps = () => {
        let payload = {
            animationType: "bottom",
            closeModal : () => {
                setSelectedModal(false);
            },
            dimensions: { width: "85vw", height: "90vh", maxHeight: '700px', maxWidth: '1100px' },
            // postitionProps: { top: "50%", left: "50%", transform: "translate(-50%,-50%)", borderRadius: 4 },
            parentModalOuterMostClassName: 'flex items-center justify-center',
            postitionProps: { position: 'relative' },
        }

        switch(selectedModal){
            case "viewSurveyStatisticsDetails" : {
                return {
                    ...payload,
                    selectedData : currentSurvey,
                }
            }
        }
    }
    
    const uType = parseJwt(sessionStorage.getItem("token"))?.uType?.toUpperCase() || "";

    const getCollectionData = () => {
        setCollectionData(null);
        setErrorData(prev => {
            delete prev?.["collectionData"];
            return prev;
        })
        dispatchHandler(dispatch, "getTableComponentListRequest", {
            baseUrl: CONFIG.SM_BACKEND_BASE_URL,
            limit : uType == "VENDOR" ? 10 : 5,
            apiUrl: "/purchase/dashboard/catalogue/data",
            dashboardType: "COLLECTION",
        }, permissionsObj?.["view"])
    }

    const getProductCardData = (filters) => {
        setProductCardsData(null);
        setErrorData(prev => {
            delete prev?.["productCardData"];
            return prev;
        })
        dispatchHandler(dispatch, "getTableComponentSecondListRequest", {
            baseUrl: CONFIG.SM_BACKEND_BASE_URL,
            apiUrl: "/purchase/dashboard/catalogue/data",
            dashboardType: "PRODUCT",
            filter: getFilterDataForPayload({ filters }),
        }, permissionsObj?.["view"])
    }

    const getSurveyStatisticsData = (filters) => {
        setSurveyStatisticsData(null);
        setErrorData(prev => {
            delete prev?.["surveyStatisticsData"];
            return prev;
        })
        if (currentSurvey) {
            dispatchHandler(dispatch, "getTableComponentThirdListRequest", {
                baseUrl: CONFIG.SM_BACKEND_BASE_URL,
                apiUrl: "/purchase/dashboard/catalogue/data",
                "dashboardType": "SURVEY",
                filter: {
                    // ...getFilterDataForPayload({ filters }),
                    surveyName: currentSurvey?.survey_name || "",
                    id: currentSurvey?.id,
                },
            }, permissionsObj?.["view"])
        } else {
            loadCurrentSurvey.current = true;
            dispatchHandler(dispatch, "getSearchFiltersRequest", {
                "entity": "vendor.product_survey",
                "key": "survey_name",
                "code": "id",
                "search": "",
                "searchBy": "STARTS_WITH",
                "pageNo": 1,
                "moduleName": "",
                "other_data": {
                    org_id: [parseJwt(sessionStorage.getItem("token"))?.orgId || ""]
                },
                "columnName": {}
            }, permissionsObj?.["view"])
        }
    }

    const handlePopoverClickOutside = (event) => {
        if (collectionPopoverRef.current && !collectionPopoverRef.current.contains(event.target)) {
            setShowCollectionDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handlePopoverClickOutside);
        return () => {
            document.removeEventListener("mousedown", handlePopoverClickOutside);
        };
    }, []);


    // Function : To see the modal to view all the collections
    const renderCollectionsList = () => {
        // if (showCollectionDropdown) {
        return <div
            id="collectionDrop"
            className={`seeAllCollectionsPopover mb-1 bg-white w-[300px] h-[300px] absolute z-[4] left-full bottom-0 border drop-shadow-lg duration-200 ${showCollectionDropdown ? 'translate-x-0 scale-100 opacity-100 visible pointer-events-auto' : '-translate-x-2 scale-90 opacity-0 invisible pointer-events-none'}`}
            style={{
                boxShadow: '0px 7px 14px 0px #D2D2D240'
            }}
        >
            <CollectionDropdown
                selectedItems={[]}
                actionsConfig={{
                    addNewCollection: false,
                    addNewProduct: false,
                    saveAndAddNewProduct: false,
                    redirectToCollection: true,
                }}
                redirectToCollection={(config = {}) => {
                    let { item } = config || {};
                    moveToCollection(item)
                }}
            />
        </div>
        // }
    }

    const moveToCollection = (item) => {
        if (item) {
            history.push({
                pathname: "/catalogue/products/history",
                state: {
                    collectionConfig: {
                        item,
                    }
                }
            })
        }
    }

    const viewCollectionsList = (e) => {
        console.log("sdfljbkasdf")
        if (!showCollectionDropdown) {
            setShowCollectionDropdown(true);
            setTimeout(() => {
                document.addEventListener('click', handleCollection)
            }, 0)
        } else {
            let element = document.getElementById("collectionDrop");
            console.log("sdfljbkasdf", element, e?.target, element?.contains(e?.target));
            if (!element?.contains(e?.target)) {
                setShowCollectionDropdown(false)
                setTimeout(() => document.removeEventListener("click", handleCollection), 0)
            }
        }
    }

    const handleCollection = () => {
        if (showCollectionDropdown) { setShowCollectionDropdown(false); }
    }

    // Function : To render the product summary
    const renderProductSummary = () => {
        // Render the product summary
        if (getTableComponentSecondListData?.isLoading) {
            return <div className='my-4 ml-[27px] mr-[12px] grid grid-cols-[1fr_1.3fr_1.3fr_1.7fr] gap-4'>
                <div className='generic-skeleton !mb-0 !h-[200px]'></div>
                <div className='flex flex-col gap-4'>
                    <div className='generic-skeleton !mb-0 !h-full'></div>
                    <div className='generic-skeleton !mb-0 !h-full'></div>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='generic-skeleton !mb-0 !h-full'></div>
                    <div className='generic-skeleton !mb-0 !h-full'></div>
                </div>
                <div className='generic-skeleton !mb-0 !h-full'></div>
            </div>
        }

        if (errorData?.["productData"]) {
            return <div className={`my-6 ml-[27px] mr-[12px] w-full flex flex-col gap-3 items-center justify-center`}>
                <div className='p-[1rem] rounded-full bg-[#EA868530]'><TriangleAlert size={40} className='text-[#FD3757]' /></div>
                <p className='text-fontBlack text-xl font-semibold'>Something Went Wrong</p>
                {errorData?.["productData"] && <p className='text-fontGrey text-[13px]'>{errorData?.["productData"] || ""}</p>}
                <GenericCustomButton
                    label={'Retry'}
                    leftIcon={<RotateCcw size={18} />}
                    onClick={() => getProductCardData()}
                    className={'border mt-[8px] !h-[35px] w-[300px] text-center border-[#E5E7EB] font-medium flex justify-center items-center gap-2 text-fontBlack hover:opacity-75'}
                />
            </div>
        }

        const redirectPage = (details) => {
            let { status, url } = details || {};

            let params = {
                status: status,
            }

            let urlSearchParams = new URLSearchParams("?");
            urlSearchParams.append("filter", encodeURIComponent(JSON.stringify(filtersData)));

            params && Object.keys(params)?.forEach((key) => {
                urlSearchParams.append(key, encodeURIComponent(JSON.stringify(params[key])));
            });

            console.log("kljasbdlkfalsd", urlSearchParams);

            history.push(
                {
                    pathname: url,
                    search: urlSearchParams?.toString(),
                })
        }

        const renderDetails = (details) => {

            let { alignStart = false, url, disabled = false } = details || {};

            return (
                <div className={`flex ${alignStart ? 'justify-start' : 'justify-end'} ${disabled ? 'opacity-50' : ''}`}>
                    <div
                        onClick={disabled ? null : () => url && redirectPage(details)}
                        className={`flex items-center duration-200 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer group active:translate-x-2 hover:opacity-75'}`}
                    >
                        <LanguageTranslator tag='p' className='mb-0 text-[13px] font-semibold'>Details</LanguageTranslator>
                        <div className='duration-200 group-hover:translate-x-1'><ChevronRight size={18} /></div>
                    </div>
                </div>
            )
        }

        return <div className='text-fontBlack grid grid-cols-[1fr_1.3fr_1.3fr_1.7fr] gap-4 my-[16px] ml-[27px] mr-[12px]'>
            <div
                className='bg-white rounded-xl flex flex-col gap-3 items-center justify-center p-[16px] border border-[#EBEBEB] min-h-[190px]'
                style={{
                    boxShadow: '0px 7px 14px 0px #D2D2D240'
                }}
            >
                <div className='bg-[#0984E3] p-[7px] rounded-md'><GalleryVerticalEnd size={22} color='white' /></div>
                <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Total Products</LanguageTranslator>
                <p className='text-[35px] font-bold mb-0 leading-[34px]'>{productCardsData?.["total_count"] || 0}</p>
                {/* {renderDetails()} */}
            </div>
            <div className='h-full flex flex-col gap-4'>
                <div
                    className='h-full p-[12px] rounded-xl bg-white border border-[#EBEBEB]'
                    style={{
                        boxShadow: '0px 7px 14px 0px #D2D2D240'
                    }}
                >
                    <div className='flex items-start gap-4'>
                        <div className='bg-[#636E72] p-[6px] rounded-md'><Shirt size={17} color='white' /></div>
                        <div className='mt-[5px]'>
                            <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Drafted Products</LanguageTranslator>
                            <p className='text-[25px] font-semibold mt-[10px] mb-0'>{productCardsData?.["draft_count"] || 0}</p>
                        </div>
                    </div>
                    {renderDetails({
                        status: "DRAFTED",
                        url: "/catalogue/products/history",
                        disabled: productCardsData?.["draft_count"] == 0,
                    })}
                </div>
                <div
                    className='h-full p-[12px] rounded-xl bg-white border border-[#EBEBEB]'
                    style={{
                        boxShadow: '0px 7px 14px 0px #D2D2D240'
                    }}
                >
                    <div className='flex items-start gap-4'>
                        <div className='bg-[#00B894] p-[6px] rounded-md'><CircleCheck size={17} color='white' /></div>
                        <div className='mt-[5px]'>
                            <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Approved Products</LanguageTranslator>
                            <p className='text-[25px] font-semibold mt-[10px] mb-0'>{productCardsData?.["approved_count"] || 0}</p>
                        </div>
                    </div>
                    {renderDetails({
                        status: "APPROVED",
                        url: "/catalogue/products/history",
                        disabled: productCardsData?.["approved_count"] == 0,
                    })}
                </div>
            </div>
            <div className='flex flex-col gap-3'>
                <div
                    className='h-full p-[12px] rounded-xl bg-white border border-[#EBEBEB]'
                    style={{
                        boxShadow: '0px 7px 14px 0px #D2D2D240'
                    }}
                >
                    <div className='flex items-start gap-4'>
                        <div className='bg-[#74B9FF] p-[6px] rounded-md'><Send size={17} color='white' /></div>
                        <div className='mt-[5px]'>
                            <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Submitted Products</LanguageTranslator>
                            <p className='text-[25px] font-semibold mt-[10px] mb-0'>{productCardsData?.["submitted_count"] || 0}</p>
                        </div>
                    </div>
                    {renderDetails({
                        status: "SUBMITTED",
                        url: "/catalogue/products/history",
                        disabled: productCardsData?.["submitted_count"] == 0,
                    })}
                </div>
                <div
                    className='h-full p-[12px] rounded-xl bg-white border border-[#EBEBEB]'
                    style={{
                        boxShadow: '0px 7px 14px 0px #D2D2D240'
                    }}
                >
                    <div className='flex items-start gap-4'>
                        <div className='bg-[#D63031] p-[6px] rounded-md'><OctagonX size={17} color='white' /></div>
                        <div className='mt-[5px]'>
                            <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Rejected Products</LanguageTranslator>
                            <p className='text-[25px] font-semibold mt-[10px] mb-0'>{productCardsData?.["rejected_count"] || 0}</p>
                        </div>
                    </div>
                    {renderDetails({
                        status: "REJECTED",
                        url: "/catalogue/products/history",
                        disabled: productCardsData?.["rejected_count"] == 0,
                    })}
                </div>
            </div>
            <div
                className='p-[12px] rounded-xl flex flex-col gap-3 bg-white border border-[#EBEBEB]'
                style={{
                    boxShadow: '0px 7px 14px 0px #D2D2D240'
                }}
            >
                <div className='w-full flex-1'>
                    <div className='flex items-start gap-4'>
                        <div className='bg-[#FDCB6E] p-[6px] rounded-md'><BadgeIndianRupee size={17} color='white' /></div>
                        <div className='mt-[5px]'>
                            <LanguageTranslator tag='p' className='text-[13px] font-semibold text-[#414B59] mb-0'>Costing And Sample Request</LanguageTranslator>
                        </div>
                    </div>
                    <LanguageTranslator tag='p' className='text-[13px] font-medium mt-[16px] text-[#797F87] mb-0'>Requested</LanguageTranslator>
                    <p className='text-[25px] font-semibold mb-0'>{productCardsData?.["sample_cost_req_count"] || 0}</p>
                    {renderDetails({
                        alignStart: 'alignStart',
                        status: "REQUESTED",
                        url: "/catalogue/products/history",
                        disabled: productCardsData?.["sample_cost_req_count"] == 0,
                    })}
                    <LanguageTranslator tag='p' className='text-[13px] font-medium mt-[14px] text-[#797F87] mb-0'>Pending Approval</LanguageTranslator>
                    <p className='text-[25px] font-semibold mb-0'>{productCardsData?.["pending_approval_count"] || 0}</p>
                </div>
                {renderDetails({
                    alignStart: 'alignStart',
                    status: "PENDING_APPROVAL",
                    url: "/catalogue/products/history",
                    disabled: productCardsData?.["pending_approval_count"] == 0,
                })}
            </div>
        </div>
    }

    // Function : To render the collection summary
    const renderCollectionSummary = () => {

        if (getTableComponentListData?.isLoading) {
            return <div className='generic-skeleton !rounded-xl !h-[350px] !w-full'></div>
        }

        if (errorData?.["collectionData"]) {
            return <div className={`my-6 ml-[27px] mr-[12px] h-full w-full flex flex-col gap-3 items-center justify-center`}>
                <div className='p-[1rem] rounded-full bg-[#EA868530]'><TriangleAlert size={40} className='text-[#FD3757]' /></div>
                <p className='text-fontBlack text-xl font-semibold'>Something Went Wrong</p>
                {errorData?.["collectionData"] && <p className='text-fontGrey text-[13px]'>{errorData?.["collectionData"] || ""}</p>}
                <GenericCustomButton
                    label={'Retry'}
                    leftIcon={<RotateCcw size={18} />}
                    onClick={() => getCollectionData()}
                    className={'border mt-[8px] !h-[35px] w-[300px] text-center border-[#E5E7EB] font-medium flex justify-center items-center gap-2 text-fontBlack hover:opacity-75'}
                />
            </div>
        }

        const uType = parseJwt(sessionStorage.getItem("token"))?.uType?.toUpperCase() || "";

        return (
            <div
                className={`px-5 py-3 h-full rounded-xl bg-white w-full flex ${parseJwt(sessionStorage.getItem("token")).uType === "VENDOR" ? 'gap-[50px]' : 'gap-4'} border border-[#EBEBEB]`}
                style={{
                    boxShadow: '0px 7px 14px 0px #D2D2D240'
                }}
            >
                <div className='w-1/3 flex flex-col'>
                    <LanguageTranslator tag='p' className='text-base font-semibold mb-3'>Overall Collections</LanguageTranslator>
                    <div className='border border-[#EBEBEB] rounded-xl h-full flex flex-col'>
                        <div className='p-[16px] h-full'>
                            {/* <Icons.AdhocSvg1 height={90} width={90} className='mt-[8px] mb-6'/> */}
                            <Icons.productImagesCollection className='mt-[8px] mb-6' />
                            <div className='mb-6'>
                                <LanguageTranslator tag='p' className='text-[11px] font-medium text-[#8D99AC] mb-1.5'>Total Collections</LanguageTranslator>
                                <p className='text-base font-semibold'>{collectionData?.["overall_collections"]?.["total_collection"]}</p>
                            </div>
                            <div className='mb-7'>
                                <LanguageTranslator tag='p' className='text-[11px] font-medium text-[#8D99AC] mb-1.5'>Total Products</LanguageTranslator>
                                <p className='text-base font-semibold flex items-center'>{collectionData?.["overall_collections"]?.["total_product"]}</p>
                            </div>
                        </div>
                        <div className='py-2 px-4 border-t border-[#EBEBEB] flex justify-center items-center'>
                            <div
                                ref={collectionPopoverRef}
                                className='relative'>
                                <div className='group flex items-center gap-2 cursor-pointer duration-200 hover:opacity-75 active:translate-x-2' onClick={(e) => viewCollectionsList(e)}>
                                    <LanguageTranslator tag='p' className='mb-0 text-[13px] font-semibold'>See All Collections</LanguageTranslator>
                                    <div className='duration-200 group-hover:translate-x-1'><ChevronRight size={17} /></div>
                                </div>

                                {renderCollectionsList()}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Render the top 5 collection list */}
                <div className='w-2/3 h-full flex flex-col'>
                    <LanguageTranslator tag='p' className='text-base font-semibold mb-3'>Top 5 Collections</LanguageTranslator>
                    <div className='h-full border border-[#EBEBEB] rounded-xl'>

                        <div className='p-[16px] h-full space-y-3'>
                            {(!collectionData?.["top_collections"] || collectionData?.["top_collections"]?.length == 0) && <div className='p-[16px] h-full flex items-center justify-center'>
                                <NoDataFound />
                            </div>}

                            {/* render the collecitons */}
                            {collectionData?.["top_collections"]?.map(item => (
                                <div
                                    onClick={() => moveToCollection({
                                        collectionName: item?.collection_name || "",
                                        collectionCode: item?.collection_code || "",
                                        totalProductCount: item?.total_product || "",
                                    })}
                                    className='rounded-[10px] px-3 py-2 flex items-center justify-between gap-5 border border-[#EBEBEB] bg-white cursor-pointer duration-200 hover:translate-x-1 hover:drop-shadow-xl hover:shadow-[#DAE4EE] active:scale-100'
                                    style={{
                                        boxShadow: '0px 7px 14px 0px #D2D2D240'
                                    }}
                                >
                                    <div className='flex items-center gap-3 w-full min-w-0'>
                                        <div className='bg-[#0984E3] p-[7px] rounded-md'><LibraryBig size={18} color='white' /></div>
                                        <div className='w-full min-w-0'>
                                            <p className='text-sm font-medium mb-0 truncate w-full'>{item?.["collection_name"] || "-"}</p>
                                            <div className='mb-0 text-[11px] font-medium text-[#8D99AC] flex items-center gap-1.5'><span>Total Products</span> <span className='text-xs font-medium text-fontBlack'>{item?.["total_product"] || 0}</span></div>
                                        </div>
                                    </div>
                                    <div><ChevronRight size={18} /></div>
                                </div>
                            ))}



                        </div>

                    </div>
                </div>
            </div>
        )
    }

    // Function : To move to the survey statistics feedback page
    const moveToSurveyStatistics = (item) => {

        let params = {
            status: item?.status,
        }

        let modifiedFilters = {
            surveyName: currentSurvey?.survey_name || "",
        }

        let urlSearchParams = new URLSearchParams("?");
        urlSearchParams.append("filter", encodeURIComponent(JSON.stringify(modifiedFilters)));

        params && Object.keys(params)?.forEach((key) => {
            urlSearchParams.append(key, encodeURIComponent(JSON.stringify(params[key])));
        });

        history.push({
            pathname: "/catalogue/productSurvey/feedback/view",
            search: urlSearchParams.toString(),
        })
    }

    const renderSurveyStatisticsSummary = () => {

        if ((loadCurrentSurvey?.current ? getSearchFiltersData?.isLoading : false)) {
            return <div className='generic-skeleton !rounded-xl !h-[350px] !w-full'></div>
        }
        const capitalizeEachWord = (text) => {
            console.log('texttttttt', text)
            if (!text) return '';
            return text
              .toString()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
        };    
        
        // Custom Tooltip Component
        const CustomTooltip = ({ active, payload }) => {
            if (active && payload && payload.length) {
            return (
                <div className="bg-gray-100 p-[8px] rounded shadow-md">
                    <LanguageTranslator tag='p' className="mb-1 text-gray-800 font-semibold">
                        {capitalizeEachWord(payload[0]?.payload?.status)}
                    </LanguageTranslator>
                    <p className="intro mb-0 text-xs"><LanguageTranslator>Total Count</LanguageTranslator>: {payload[0]?.value}</p>
                </div>
            );
            }
            return null;
        };          

        return (
            <div
                className='px-5 py-3 h-full rounded-xl bg-white w-full border border-[#EBEBEB]'
                style={{
                    boxShadow: '0px 7px 14px 0px #D2D2D240'
                }}
            >
                <div className='flex justify-between items-start gap-4'>
                    <div>
                        <LanguageTranslator tag='p' className='text-base font-semibold mb-3'>Survey Statistics</LanguageTranslator>
                        {currentSurvey && <div className='group cursor-pointer text-[13px] font-semibold text-fontBlack flex items-center gap-1.5' onClick={() => {
                            setSelectedModal("viewSurveyStatisticsDetails")
                        }}>  
                            <LanguageTranslator tag='p' className='mb-0 group-hover:underline'>Review Details</LanguageTranslator>
                            <div><ExternalLink size={17} /></div>
                        </div>}
                        {/* <p className='text-[11px] font-medium text-[#8D99AC]'>Showing survey Data last week</p> */}
                    </div>
                    <div>
                        <GenericInput
                            label="Survey Name"
                            inputKey="survey_name"
                            type="dropdown"
                            onChange={(val) => {
                                setCurrentSurvey(val);
                            }}
                            value={currentSurvey?.["survey_name"] || ""}
                            iconRight={<Icons.DownArrowNew />}
                            inputProps={() => ({
                                getListData: (response) => {
                                    return response?.resource?.searchResultNew || [];
                                },
                                headerKeys: {
                                    "survey_name": { value: "Survey Name" },
                                },
                                itemKeys: {
                                    "survey_name": ["survey_name"]
                                },
                                viewDataType: "table-view",
                                modalMainClass: "use-as-generic-dropdown",
                                search: {
                                    placeholder: "Type & Press Enter To Search..."
                                },
                                api: {
                                    reduxState: "generalStore",
                                    apiName: "getSearchFilters",
                                    apiPayload: (params) => {
                                        return {
                                            "entity": "vendor.product_survey",
                                            "key": "survey_name",
                                            "code": "id",
                                            "search": params?.currentSearch || "",
                                            "searchBy": params.searchFilter || "STARTS_WITH",
                                            "pageNo": params?.currentPage || 1,
                                            "moduleName": "",
                                            "other_data": {
                                                org_id: [parseJwt(sessionStorage.getItem("token"))?.orgId || ""]
                                            },
                                            "columnName": {}
                                        };
                                    },
                                    getMaxPage: (states, functions, response) => {
                                        return Number(response?.resource?.maxPage || "");
                                    },
                                    apiExtraHeaders: permissionsObj?.["view"],
                                },
                            })}
                        />
                    </div>
                </div>

                {
                    errorData?.["surveyStatistics"] ?
                        <div className={`mt-[65px] w-full flex flex-col gap-3 items-center justify-center`}>
                            <div className='p-[1rem] rounded-full bg-[#EA868530]'><TriangleAlert size={40} className='text-[#FD3757]' /></div>
                            <p className='text-fontBlack text-xl font-semibold'>Something Went Wrong</p>
                            {errorData?.["surveyStatistics"] && <p className='text-fontGrey text-[13px]'>{errorData?.["surveyStatistics"] || ""}</p>}
                            <GenericCustomButton
                                label={'Retry'}
                                leftIcon={<RotateCcw size={18} />}
                                onClick={() => getSurveyStatisticsData()}
                                className={'border mt-[8px] !h-[35px] w-[300px] text-center border-[#E5E7EB] font-medium flex justify-center items-center gap-2 text-fontBlack hover:opacity-75'}
                            />
                        </div>
                        : <div>
                            {getTableComponentThirdListData?.isLoading ? renderChartSkeletonLoader() :
                                surveyStatisticsData?.length === 0 ? <div>
                                    <NoDataFound PrimaryText="No Survey Statistics Available!" />
                                </div> : <div>
                                    {/* Render the bar granph for delivered and undelivered data. */}
                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={surveyStatisticsData}
                                            margin={{
                                                top: 20,
                                                right: 5,
                                                left: -40,
                                                bottom: 5,
                                            }}
                                            barSize={50}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="status" tickFormatter={capitalizeEachWord} />
                                            <YAxis tickFormatter={(value) => Math.round(value)} />
                                            <Tooltip content={<CustomTooltip />} />
                                            {/* <Legend /> */}
                                            {/* <Bar label="Total Count" dataKey="total_count" fill="#8884d8" /> */}
                                            <Bar
                                                dataKey="total_count"
                                                name="Total Count"
                                                fill="#8884d8"
                                                label={{ position: "top" }}
                                            >
                                                {surveyStatisticsData?.map((entry, index) => {
                                                    return (
                                                        <Cell onClick={() => {
                                                            moveToSurveyStatistics(entry)
                                                        }} key={`cell-${index}`} fill={getBarColor[entry.status?.toString().toLowerCase() ?? '']} />
                                                    )
                                                })}
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                    <div className='flex items-center gap-6 px-3 mb-3'>
                                        <div className='flex items-center gap-2'>
                                            <div className='h-[8px] w-[8px] rounded-full bg-[#3D399F]'></div>
                                            <LanguageTranslator tag='p' className='mb-0 text-[11px] font-medium'>Delivered</LanguageTranslator>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className='h-[8px] w-[8px] rounded-full bg-[#93C5FD]'></div>
                                            <LanguageTranslator tag='p' className='mb-0 text-[11px] font-medium'>Opened</LanguageTranslator>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className='h-[8px] w-[8px] rounded-full bg-[#FD3757]'></div>
                                            <LanguageTranslator tag='p' className='mb-0 text-[11px] font-medium'>Undelivered</LanguageTranslator>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className='h-[8px] w-[8px] rounded-full bg-[#3DC1D3]'></div>
                                            <LanguageTranslator tag='p' className='mb-0 text-[11px] font-medium'>Submitted</LanguageTranslator>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                }
            </div>
        )

    }

    return (
        <div className='text-fontBlack'>
            {/* renderr the filters component */}
            <AllFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                MAIN_FILTERS={[]}
                disabledDate={null}
                applyFilter={getDashboardData}
                mappingSelectedFilters={{}}
                filterPayload={{
                    attributeType: "PRODUCT_CATALOGUE_DASHBOARD_DATA",
                    displayName: "PRODUCT_CATALOGUE_DASHBOARD_DATA",
                    filterName: ""
                }}
                filterItems={filterItems}
                currentFilterKeys={currentFilterKeys}
                hideAddFilter={true}
                isQuickFilterRequired={true}
            />

            {/* Render the product's dashbaord data  summary */}
            {renderProductSummary()}

            <div className='flex gap-4 pb-4 ml-[27px] mr-[12px]'>
                <div className={`${parseJwt(sessionStorage.getItem("token")).uType === "VENDOR" ? 'w-full' : 'w-3/5'}`}>
                    {renderCollectionSummary()}
                </div>
                {parseJwt(sessionStorage.getItem("token")).uType !== "VENDOR" && <div className='w-2/5'>
                    {renderSurveyStatisticsSummary()}
                </div>}
            </div>

            {selectedModal &&
                <div className='create-cost-sheet-modal'>
                    <ParentModal
                        getParentChildProps={getParentChildProps()}
                        childComponent={getChildComponent()}
                        closeModal={() => { setSelectedModal(false); }}
                    />
                </div>}
        </div>
    )
}

export default DigicatalogueDashbaord
