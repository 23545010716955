import React, { useEffect, useRef, useState } from 'react'
import { dispatchHandler, getTokenData } from '../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import TableLoader from '../../../genericComponents/oldGenericComponents/tableHelperComponents/TableLoader';
import Pagination from '../../../genericComponents/oldGenericComponents/tableHelperComponents/Pagination';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import Icons from '../../../assets/icons';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import { ChevronDown, ChevronRight, SquareChevronRight, X } from 'lucide-react';
import NoDataFound from '../../../genericComponents/NoDataFound';
import TableSkeleton from './TableSkeleton';

import { AnimatePresence, motion } from 'framer-motion'
import { formatNumber, parseJwt } from '../../../helper/genericFunction';
import moment from 'moment';

const OrderTrackingDashboardHirDetails = (props = {}) => {

    // Implement the logic to render the hierarchy details table using the provided data
    let {
        currentFilters,
        permissionObj
    } = props || {};

    const dispatch = useDispatch();

    const jumpInputRef = useRef();

    const getRefreshReportData = useSelector(state => state?.generalStore?.getRefreshReportData);

    const getOrderTrackingDashboardHirData = useSelector(state => state?.digivendStore?.getOrderTrackingDashboardHirData);

    const [tableData, setTableData] = useState({
        "hl1_name": [],
        "hl2_name": [],
        "hl3_name": [],
    })

    const [loading, setLoading] = useState(false);
    const [selectPage, setSelectPage] = useState(false);
    const [enableJumpPage, setEnableJumpPage] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [expandInput, setExpandInput] = useState(false);

    const [currentPage, setCurrentPage] = useState({
        hl1_name: 1,
        hl2_name: 1,
        hl3_name: 1,
    })

    const [maxPage, setMaxPage] = useState({
        hl1_name: 1,
        hl2_name: 1,
        hl3_name: 1,
    })

    const [errorOccured, setErrorOccured] = useState({
        hl1_name: null,
        hl2_name: null,
        hl3_name: null,
    })

    const [pageSize, setPageSize] = useState({
        hl1_name: 10,
        hl2_name: 10,
        hl3_name: 10,
    })

    const [totalRecords, setTotalRecords] = useState({
        hl1_name: 0,
        hl2_name: 0,
        hl3_name: 0,
    })

    const [levelData, setLevelData] = useState({
        hl1_name: 0,
        hl2_name: 0,
    })

    const [currentSearch, setCurrentSearch] = useState({
        hl1_name: '',
        hl2_name: '',
        hl3_name: '',
    })

    const [currentLevel, setCurrentLevel] = useState("hl1_name")

    const [getTableApiData, setGetTableApiData] = useState(null);

    useEffect(() => {
        resetLevelToDivision()
    }, [])

    useEffect(() => {
        if(getRefreshReportData?.isSuccess){
            resetLevelToDivision();
        }
    },[getRefreshReportData])

    let mapping = {
        "hl1_name": "division",
        "hl2_name": "section",
        "hl3_name": "department",
    }

    console.log("filter appilsddf", props.callApiAfterFilterApplied);

    useEffect(() => {
        if (props.callApiAfterFilterApplied) {
            props.setCallApiAfterFilterApplied(false)
            setCurrentPage(prev => {
                prev[currentLevel] = 1;
                return prev;
            })
            getData({
                pageNo: 1
            })
        }
    }, [props.callApiAfterFilterApplied])

    // Function : To reset the level to level 0
    const resetLevelToDivision = () => {
        setCurrentLevel("hl1_name")
        setCurrentPage({ hl1_name: 1, hl2_name: 1, hl3_name: 1 })
        setMaxPage({ hl1_name: 1, hl2_name: 1, hl3_name: 1 })
        setTableData({
            hl1_name: [],
            hl2_name: [],
            hl3_name: [],
        })
        setErrorOccured({
            hl1_name: null,
            hl2_name: null,
            hl3_name: null,
        })
        setPageSize({
            hl1_name: 10,
            hl2_name: 10,
            hl3_name: 10,
        })
        setTotalRecords({
            hl1_name: 0,
            hl2_name: 0,
            hl3_name: 0,
        })
        setCurrentSearch({
            hl1_name: "",
            hl2_name: "",
            hl3_name: "",
        })
        setLevelData({
            "hl1_name": null,
            "hl2_name": null,
        })
        // Get the data for page 1
        setTimeout(() => {
            getData({
                pageNo: 1,
                size: 10,
                search: "",
            });
        }, 0)
    }

    // Function : To get the data for the current page and tab
    const getData = (config = {}) => {
        let {
            pageNo = currentPage?.[currentLevel] ?? 1,
            size = pageSize?.[currentLevel] ?? 10,
            search = currentSearch?.[currentLevel] ?? "",
            level = currentLevel,
            levelObj = levelData,
        } = config;

        setLoading(true)

        setErrorOccured(prev => {
            switch (level) {
                case "hl1_name": {
                    delete prev?.["hl1_name"];
                }
                case "hl2_name": {
                    delete prev?.["hl2_name"];
                }
                case "hl3_name": {
                    delete prev?.["hl3_name"];
                }
            }
        })

        let obj = {};

        Object.keys(levelObj)?.map(key => {
            obj[key] = levelObj?.[key]?.[key];
        })
        if (currentFilters?.[mapping[level]]) {
            obj[level] = currentFilters[mapping[level]];
        }

        for (let key of ["dateFrom", "dateTo", "site", "vendorCode"]) {
            if (props.currentFilters[key]) {
                obj[key] = props.currentFilters[key]
            }
        }

        dispatchHandler(dispatch, "getOrderTrackingDashboardHirRequest", {
            displayName: "DETAIL_LEVEL_ORDER_TRACKING_DASHBOARD",
            filter: obj,
            levelName: level,
            pageNo: pageNo,
            pageSize: size,
            search: search,
        }, permissionObj)

    }

    // Handle the success and the error of the api
    useEffect(() => {
        if (getOrderTrackingDashboardHirData?.isSuccess) {
            setGetTableApiData(getOrderTrackingDashboardHirData?.data)
            setTableData(prev => ({
                ...prev,
                [currentLevel]: getOrderTrackingDashboardHirData?.data?.resource || {}
            }))
            setMaxPage(prev => ({
                ...prev,
                [currentLevel]: getOrderTrackingDashboardHirData?.data?.maxPage,
            }))
            setTotalRecords(prev => ({
                ...prev,
                [currentLevel]: getOrderTrackingDashboardHirData?.data?.totalRecord,
            }))
            setLoading(false);
        }
        if (getOrderTrackingDashboardHirData?.isError) {
            setErrorOccured({
                ...errorOccured,
                [currentLevel]: true,
            })
            setTableData({
                ...tableData,
                [currentLevel]: [],
            })
            setLoading(false)
            return;
        }
    }, [getOrderTrackingDashboardHirData])

    const selectPageination = () => {
        setSelectPage(!selectPage);
        setTimeout(() => document.addEventListener("click", closeSelectPage), 0)
    }

    const closeSelectPage = () => {
        setSelectPage(false);
        setTimeout(() => document.removeEventListener("click", closeSelectPage), 0)
    }

    const updatePageSize = (size) => {
        setCurrentPage(prev => {
            prev[currentLevel] = 1
            return prev;
        })
        setPageSize(prev => {
            prev[currentLevel] = size;
            return prev;
        })
        getData({
            pageNo: 1,
            size: size,
        })
    }

    const changePage = (page) => {
        setCurrentPage(prev => {
            prev[currentLevel] = page;
            return prev;
        })
        getData({
            pageNo: page,
        })
    }

    const setPageNumberValue = (e) => {
        let pageNumber = Number(e.target.value),
            valid = e.target.value == "" || (/^\d*$/.test(pageNumber) && pageNumber > 0 && pageNumber <= maxPage?.[currentLevel]);
        if (valid) {
            setPageNumber(pageNumber);
            if (e.key == "Enter" && pageNumber && pageNumber != currentPage?.[currentLevel]) {
                changePage(pageNumber);
                setEnableJumpPage(false);
                setTimeout(() => document.removeEventListener("click", closeJumpPage), 0)
            }
        }
    }

    const handleJumpPage = () => {
        setEnableJumpPage(true);
        setTimeout(() => document.addEventListener("click", closeJumpPage), 0)
    }

    const closeJumpPage = (e) => {
        if (jumpInputRef?.current && !jumpInputRef.current.contains(e.target)) {
            setEnableJumpPage(false);
            setTimeout(() => document.removeEventListener("click", closeJumpPage), 0)
        }
    }

    const expandField = () => {
        setExpandInput(!expandInput);
        setTimeout(() => document.addEventListener("click", closeExpand), 0)
    }

    const closeExpand = () => {
        setExpandInput(false);
        setTimeout(() => document.removeEventListener("click", closeExpand), 0)
    }
    

    const renderPagination = () => {
        return <div className="new-gen-pagination">
            <div className="ngp-left">
                <div className='flex items-center gap-3'>
                    {getTableApiData && getTableApiData?.mvUpdateDate && (
                        <span className="header-last-update text-[10px] font-normal text-[#4A5D7A]"><LanguageTranslator>Last Updated</LanguageTranslator>{` : ${moment(
                            getTableApiData?.mvUpdateDate || ""
                        ).format("DD-MMM-YYYY h:mm:ss a")}`}</span>
                    )}

                    {/* render the refresh mvvview */}
                    {tokenData?.uType?.toUpperCase() == "ENT" && getTableApiData && getTableApiData?.mvName && (
                        <GenericCustomButton
                            sendEventAnalyticsToGoogle={true}
                            label="Refresh Data"
                            loading={getRefreshReportData?.isLoading}
                            className="generic-approve-btn whitespace-nowrap"
                            onClick={refreshapidata}
                        />
                    )}
                </div>

                <div className='flex items-center gap-2'>
                    {/* Render the search mode toggle button here */}
                    <div className={`imhl-search group`}>
                        {/* <input
                                    className={`search_bar onfocus ${currentSearch?.[currentLevel]?.length !== 0 && "wid-250"}`}
                                    onChange={setSearchValue()}
                                    onKeyDown={handleSearchKeyDown}
                                    onClick={expandField}
                                    placeholder="Search"
                                    type="search"
                                    value={currentSearch?.[currentLevel] || ""}
                                    autoComplete="off"
                                /> */}
                        {/* <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.52673 2.37419C4.23287 2.37419 2.37333 4.23373 2.37333 6.52759C2.37333 8.82145 4.23287 10.681 6.52673 10.681C8.82059 10.681 10.6801 8.82145 10.6801 6.52759C10.6801 4.23373 8.82059 2.37419 6.52673 2.37419ZM1.18665 6.52759C1.18665 3.57834 3.57748 1.1875 6.52673 1.1875C9.47598 1.1875 11.8668 3.57834 11.8668 6.52759C11.8668 9.47684 9.47598 11.8677 6.52673 11.8677C3.57748 11.8677 1.18665 9.47684 1.18665 6.52759Z" fill="#23235F" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.45955 9.45894C9.69127 9.22723 10.067 9.22723 10.2987 9.45894L12.8797 12.04C13.1114 12.2717 13.1114 12.6474 12.8797 12.8791C12.648 13.1108 12.2723 13.1108 12.0406 12.8791L9.45955 10.2981C9.22784 10.0663 9.22784 9.69066 9.45955 9.45894Z" fill="#23235F" />
                                </svg> */}
                        {currentSearch?.[currentLevel] !== "" &&
                            <span className="absolute top-0 left-[80%]" onClick={() => {
                                setCurrentSearch(prev => {
                                    prev[currentLevel] = "";
                                    return prev;
                                })
                                setCurrentPage(prev => {
                                    prev[currentLevel] = 1;
                                    return prev;
                                })
                                getData({
                                    search: "",
                                    pageNo: 1,
                                })
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 13.119 13.119">
                                    <path fill="none" d="M0 0h13.119v13.119H0z"></path>
                                    <path d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)" fill="#627da8"></path>
                                </svg>
                            </span>}
                    </div>

                    {/* {!props.hidePaginationDropdown && <div className="drop-toggle-btn tablePageSize">
                            <button type="button" className="dtb-show" onClick={selectPageination}>
                                <span className="total-count">
                                    <span className='to-num'>{totalRecords?.[currentLevel] <= pageSize?.[currentLevel] ? totalRecords?.[currentLevel] : pageSize?.[currentLevel] || "10"}</span>
                                    <LanguageTranslator tag="span" className="to-slas">of</LanguageTranslator>
                                    <span className='to-num'>{totalRecords?.[currentLevel]}
                                        <span className="generic-tooltip">
                                            {totalRecords?.[currentLevel]}
                                        </span>
                                    </span>
                                </span>
                                <LanguageTranslator>Items</LanguageTranslator> <Icons.DownArrowFilled /></button>
                            {selectPage &&
                                <div className="page-dropdown">
                                    {[10, 15, 20].map(pageSize =>
                                        <button type="button" onClick={() => updatePageSize(pageSize)} className="sel-page">
                                            {pageSize}
                                        </button>
                                    )}
                                </div>}
                        </div>} */}

                </div>
            </div>
            {!props.hidePaginationDropdown && <div className="ngp-right tablePagination">
                <div className="nt-btn">
                    <div className="pagination-inner">
                        <ul className="pagination-item">
                            <li>
                                <button className={`${currentPage[currentLevel] == 1 && "gen-action-btn-disable disable-"}first-btn`} disabled={currentPage?.[currentLevel] == 1} onClick={() => changePage(1)}>
                                    <span className="page-item-btn-inner">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.8977 6.49114C11.2557 6.84912 11.2557 7.42952 10.8977 7.7875L6.96254 11.7227L10.8977 15.6578C11.2557 16.0158 11.2557 16.5962 10.8977 16.9542C10.5397 17.3122 9.95931 17.3122 9.60133 16.9542L5.018 12.3708C4.66002 12.0129 4.66002 11.4325 5.018 11.0745L9.60133 6.49114C9.95931 6.13316 10.5397 6.13316 10.8977 6.49114Z" fill="#23235F" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.3143 6.49114C17.6723 6.84912 17.6723 7.42952 17.3143 7.7875L13.3792 11.7227L17.3143 15.6578C17.6723 16.0158 17.6723 16.5962 17.3143 16.9542C16.9563 17.3122 16.3759 17.3122 16.018 16.9542L11.4346 12.3708C11.0766 12.0129 11.0766 11.4325 11.4346 11.0745L16.018 6.49114C16.3759 6.13316 16.9563 6.13316 17.3143 6.49114Z" fill="#23235F" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                            <li>
                                <button className={`${currentPage?.[currentLevel] == 1 && "gen-action-btn-disable dis-"}prev-btn`} disabled={currentPage?.[currentLevel] == 1} onClick={() => changePage(currentPage?.[currentLevel] - 1)}>
                                    <span className="page-item-btn-inner">
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.89781 0.491142C7.2558 0.849122 7.2558 1.42952 6.89781 1.7875L2.96266 5.72266L6.89781 9.65781C7.2558 10.0158 7.2558 10.5962 6.89781 10.9542C6.53983 11.3122 5.95943 11.3122 5.60145 10.9542L1.01812 6.37084C0.660139 6.01286 0.660139 5.43246 1.01812 5.07448L5.60145 0.491142C5.95943 0.133161 6.53983 0.133161 6.89781 0.491142Z" fill="#23235F" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                            <li>
                                {console.log('enableJumpPage', !enableJumpPage)}
                                {!enableJumpPage ? <button className="pi-number-btn" onClick={() => handleJumpPage()}>
                                    <span>{currentPage?.[currentLevel]}<label>of</label>{maxPage?.[currentLevel]}</span>
                                </button> : <input
                                    ref={jumpInputRef}
                                    className="pinum-input"
                                    onChange={setPageNumberValue}
                                    onKeyPress={setPageNumberValue}
                                    type="number"
                                    autoComplete="off"
                                    value={pageNumber}
                                />}
                            </li>
                            <li>
                                <button className={`${currentPage?.[currentLevel] == maxPage?.[currentLevel] && "gen-action-btn-disable dis-"}next-btn`} disabled={currentPage?.[currentLevel] == maxPage?.[currentLevel]} onClick={() => changePage(currentPage?.[currentLevel] + 1)}>
                                    <span className="page-item-btn-inner">
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.934461 0.491142C0.57648 0.849122 0.57648 1.42952 0.934461 1.7875L4.86961 5.72266L0.934461 9.65781C0.57648 10.0158 0.57648 10.5962 0.934461 10.9542C1.29244 11.3122 1.87284 11.3122 2.23082 10.9542L6.81416 6.37084C7.17214 6.01286 7.17214 5.43246 6.81416 5.07448L2.23082 0.491142C1.87284 0.133161 1.29244 0.133161 0.934461 0.491142Z" fill="#23235F" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                            <li >
                                <button className={`${currentPage?.[currentLevel] == maxPage?.[currentLevel] && "gen-action-btn-disable dis-"}last-btn`} disabled={currentPage?.[currentLevel] == maxPage?.[currentLevel]} onClick={() => changePage(maxPage?.[currentLevel])}>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9346 6.49114C11.5766 6.84912 11.5766 7.42952 11.9346 7.7875L15.8697 11.7227L11.9346 15.6578C11.5766 16.0158 11.5766 16.5962 11.9346 16.9542C12.2926 17.3122 12.873 17.3122 13.2309 16.9542L17.8143 12.3708C18.1723 12.0129 18.1723 11.4325 17.8143 11.0745L13.2309 6.49114C12.873 6.13316 12.2926 6.13316 11.9346 6.49114Z" fill="#23235F" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.51796 6.49114C5.15998 6.84912 5.15998 7.42952 5.51796 7.7875L9.45311 11.7227L5.51796 15.6578C5.15998 16.0158 5.15998 16.5962 5.51796 16.9542C5.87594 17.3122 6.45634 17.3122 6.81432 16.9542L11.3977 12.3708C11.7556 12.0129 11.7556 11.4325 11.3977 11.0745L6.81432 6.49114C6.45634 6.13316 5.87594 6.13316 5.51796 6.49114Z" fill="#23235F" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>}
        </div>

    }

    const moveLevel = (row, type, modifiedKey) => {

        let updatedSearch = { ...currentSearch }
        let updatedCurrentPage = { ...currentPage }
        let updatedMaxPage = { ...maxPage };
        let updatedTotalRecords = { ...totalRecords }
        let updatedLevelData = { ...levelData }
        let updatedKey;

        switch (type) {
            case "down": {
                updatedLevelData[currentLevel] = row;
                switch (currentLevel) {
                    case "hl1_name": {
                        updatedKey = "hl2_name";
                        break;
                    }
                    case "hl2_name": {
                        updatedKey = "hl3_name";
                        break;
                    }
                }
                break;
            }
            case "up": {
                switch (currentLevel) {
                    case "hl3_name": {
                        updatedKey = "hl2_name"
                        if (modifiedKey == "hl1_name") {
                            updatedKey = "hl1_name"
                            updatedSearch["hl2_name"] = "";
                            updatedCurrentPage["hl2_name"] = 1;
                            updatedTotalRecords["hl2_name"] = 0;
                            updatedMaxPage["hl2_name"] = 1;
                            updatedLevelData["hl2_name"] = null;
                        }
                        updatedSearch[currentLevel] = "";
                        updatedCurrentPage[currentLevel] = 1;
                        updatedTotalRecords[currentLevel] = 0;
                        updatedMaxPage[currentLevel] = 1;
                        updatedLevelData[updatedKey] = null;
                        break;
                    }
                    case "hl2_name": {
                        updatedKey = "hl1_name"
                        updatedSearch[currentLevel] = "";
                        updatedCurrentPage[currentLevel] = 1;
                        updatedTotalRecords[currentLevel] = 0;
                        updatedMaxPage[currentLevel] = 1;
                        updatedLevelData[updatedKey] = null;
                        break;
                    }
                }
                setCurrentSearch(updatedSearch);
                setCurrentPage(updatedCurrentPage)
                setTotalRecords(updatedTotalRecords)
                setMaxPage(updatedMaxPage);
                break;
            }
        }
        setLevelData(updatedLevelData)
        setCurrentLevel(updatedKey);
        setTimeout(() => {
            getData({
                level: updatedKey,
                pageNo: 1,
                search: "",
                levelObj: updatedLevelData,
            })
        }, 0)
    }

    const renderComponent = () => {
        if (loading) {
            // return <div className='w-full'><TableLoader className='!p-[0]' loading={loading} isError={errorOccured?.[currentLevel]} onRefresh={() => getData()} /></div>
            return <div
                // initial={{opacity: 0}}
                // animate={{opacity: 1}}
                // exit={{opacity: 0}}
                // transition={{ duration: .3, delay: .3}} 
                className='w-full h-full bg-white'><TableSkeleton className='!h-full' /></div>
        } else if (errorOccured?.[currentLevel]) {
            return <div
                // initial={{opacity: 0}}
                // animate={{opacity: 1}}
                // exit={{opacity: 0}}
                // transition={{ duration: .3, delay: .3 }}
                className='w-full h-full bg-white'><TableLoader className='!p-[0] !h-full' loaderModalClassName='!h-full' loading={loading} isError={errorOccured?.[currentLevel]} onRefresh={() => getData()} /></div>
        }

        return (
            // <AnimatePresence>
            <div
                // initial={{opacity: 0}}
                // animate={{opacity: 1}}
                // exit={{opacity: 0}}
                // transition={{ duration: .3, delay: .3}}
                className='min-h-[450px] h-full w-full bg-white text-fontBlack border border-borderSlateGrey'>
                <table className='w-full'>
                    <thead className='bg-white'>
                        <tr className='relative border-b border-borderSlateGrey'>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start'>{currentLevel == "hl1_name" && "Division"} {currentLevel == "hl2_name" && "Section"} {currentLevel == "hl3_name" && "Department"}</th>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Open Purchase Order</th>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Pending ASN Approval</th>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Pending LR Creation</th>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Pending Gate Entry</th>
                            <th className='border-r border-borderSlateGrey text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Pending GRC</th>
                            <th className='text-xs font-semibold px-2 py-2 text-start' colSpan={2}>Completed GRC</th>
                        </tr>
                        <tr className='relative border-b border-borderSlateGrey'>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'></th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                            <th className='border-r border-borderSlateGrey text-start text-[10px] font-semibold px-2 py-2'>Quantity</th>
                            <th className='text-start text-[10px] font-semibold px-2 py-2'>Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-right'>
                        {tableData?.[currentLevel]?.length == 0 && <tr><td colSpan={"100%"}><NoDataFound /></td></tr>}

                        {tableData?.[currentLevel]?.map((row, index) => (
                            <tr key={index} className='border-b border-borderSlateGrey'>
                                <td
                                    onClick={() => {
                                        currentLevel != "hl3_name" && moveLevel(row, "down")
                                    }}
                                    className={`border-r border-borderSlateGrey px-2 py-2 text-xs font-semibold ${currentLevel != "hl3_name" ? 'cursor-pointer hover:bg-primary_w_less_opacity' : ''}`}
                                >
                                    {["hl1_name", "hl2_name"]?.includes(currentLevel) ?
                                        // <GenericCustomButton
                                        //     onClick={() => {
                                        //         moveLevel(row, "down")
                                        //     }}
                                        //     label = {row?.[currentLevel] || ""}
                                        //     rightIcon={<SquareChevronRight size={18} />}
                                        //     className={'flex items-center gap-1.5 whitespace-nowrap'}
                                        // /> 
                                        <div className='w-full flex items-center justify-between gap-3'>
                                            <p className='mb-0 whitespace-nowrap'>{row?.[currentLevel]}</p>
                                            <span><SquareChevronRight size={18} /></span>
                                        </div>
                                        : <div className='w-full flex items-center justify-between gap-3'>
                                            <p className='mb-0 whitespace-nowrap'>{row?.[currentLevel]}</p>
                                        </div>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["open_po_qty"])).short}
                                    {formatNumber(Number(row?.["open_po_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["open_po_qty"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["open_po_amount"])).short}
                                    {formatNumber(Number(row?.["open_po_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["open_po_amount"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_asn_qty"])).short}
                                    {formatNumber(Number(row?.["pending_asn_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_asn_qty"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_asn_amount"])).short}
                                    {formatNumber(Number(row?.["pending_asn_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_asn_amount"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_lr_qty"])).short}
                                    {formatNumber(Number(row?.["pending_lr_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_lr_qty"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_lr_amount"])).short}
                                    {formatNumber(Number(row?.["pending_lr_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_lr_amount"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pening_gateentry_qty"])).short}
                                    {formatNumber(Number(row?.["pening_gateentry_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pening_gateentry_qty"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_gateentry_amount"])).short}
                                    {formatNumber(Number(row?.["pending_gateentry_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_gateentry_amount"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_grc_qty"])).short}
                                    {formatNumber(Number(row?.["pending_grc_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_grc_qty"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["pending_grc_amount"])).short}
                                    {formatNumber(Number(row?.["pending_grc_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["pending_grc_amount"])).full}</span>}
                                </td>
                                <td className='group relative border-r border-borderSlateGrey px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["grc_qty"])).short}
                                    {formatNumber(Number(row?.["grc_qty"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["grc_qty"])).full}</span>}
                                </td>
                                <td className='group relative px-2 py-2 text-xs font-normal'>
                                    {formatNumber(Number(row?.["grc_amount"])).short}
                                    {formatNumber(Number(row?.["grc_amount"])).full && <span className='generic-tooltip last:left-auto last:right-0 last:after:left-auto last:after:right-[15px] group-hover:opacity-100 group-hover:visible'>{formatNumber(Number(row?.["grc_amount"])).full}</span>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            // {/* </AnimatePresence> */}
        )
    }

    console.log("Level data", levelData);

    const renderLeftColumns = () => {
        return (
            // <div className='shrink-0 relative bg-primary w-[30px] flex justify-center items-center overflow-x-hidden overflow-y-auto'>
            //     {/* <div className='h-full w-full flex justify-center items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[11px] font-semibold uppercase'> */}
            //         <div className='-rotate-90 origin-center whitespace-nowrap flex items-center gap-3 text-[11px] font-semibold uppercase'>
            //             <div className='flex items-center gap-1.5 cursor-pointer'><span><ChevronDown size={17}/></span><p className='mb-0' onClick={() => levelData?.["hl1_name"] ?  moveLevel(null, "up") : null}>Division {levelData?.["hl1_name"] ? ` : ${levelData?.["hl1_name"]?.["hl1_name"]}` : ""}</p></div>
            //             {levelData?.["hl1_name"] ? <div className='flex items-center gap-1.5 cursor-pointer'><span><ChevronDown size={17}/></span><p className='mb-0' onClick={() => levelData?.["hl2_name"] ? moveLevel(null, "up") : null}>Section {levelData?.["hl2_name"] ? ` : ${levelData?.["hl2_name"]?.["hl2_name"]}` : ""}</p></div> : ""}
            //             {levelData?.["hl2_name"] ? <div className='flex items-center gap-1.5 cursor-pointer'><span><ChevronDown size={17}/></span><p className='mb-0'>Department</p></div> : ""}
            //         </div>
            //     {/* </div> */}
            // </div>
            <div className='shrink-0 whitespace-nowrap flex items-center gap-0.5 text-[11px] font-semibold '>
                <div
                    className={`px-2 py-2 h-full flex items-center justify-center cursor-pointer bg-primary_w_less_opacity ${currentLevel === 'hl1_name' ? 'border border-primary w-[36px]' : 'w-[30px] border border-borderSlateGrey duration-200 hover:w-[36px] hover:border hover:border-primary'}`}
                    onClick={() => levelData?.["hl1_name"] ? moveLevel(null, "up", "hl1_name") : null}
                >
                    <p className='mb-0 -rotate-90 origin-center whitespace-nowrap flex items-center gap-1.5'>
                        <span className='uppercase'>Division</span>
                        {levelData?.["hl1_name"] ?
                            <>
                                <span><ChevronRight size={15} /></span>
                                {levelData?.["hl1_name"]?.["hl1_name"] && <span className='text-xs font-semibold text-primary'>{levelData?.["hl1_name"]?.["hl1_name"]}</span>}
                                <span className='p-[1px] bg-primary rounded'><X size={16} color='white' /></span>
                            </> : ''}
                    </p>
                </div>
                <AnimatePresence>
                    {levelData?.["hl1_name"] ?
                        <motion.div
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: currentLevel === 'hl2_name' ? 36 : 30, opacity: 1 }}
                            // exit={{ maxWidth: 0, opacity: 0 }}
                            transition={{ duration: .3}}
                            className={`overflow-hidden h-full flex items-center justify-center cursor-pointer bg-primary_w_less_opacity duration-200 hover:!w-[36px] hover:border hover:border-primary ${currentLevel === 'hl2_name' ? 'border border-primary w-[36px]' : 'w-[30px] border border-borderSlateGrey duration-200 hover:w-[36px] hover:border hover:border-primary'}`}
                            onClick={() => levelData?.["hl1_name"] ? moveLevel(null, "up", "hl2_name") : null}
                        >
                            <p className='mb-0 -rotate-90 origin-center whitespace-nowrap flex items-center gap-1.5'>
                                <span className='uppercase'>Section</span>
                                {levelData?.["hl2_name"] ?
                                    <>
                                        <span><ChevronRight size={15} /></span>
                                        <span className='text-xs font-semibold text-primary rounded'>{levelData?.["hl2_name"]?.["hl2_name"]}</span>
                                        <span className='p-[1px] bg-primary rounded'><X size={16} color='white' /></span></> : ''}
                            </p>
                        </motion.div> : ''}
                </AnimatePresence>
                <AnimatePresence>
                    {levelData?.["hl2_name"] ?
                        <motion.div
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: currentLevel === 'hl3_name' ? 36 : 30, opacity: 1 }}
                            // exit={{ maxWidth: 0, opacity: 0 }}
                            transition={{ duration: .3}}
                            className={`overflow-hidden h-full flex items-center justify-center cursor-pointer bg-primary_w_less_opacity ${currentLevel === 'hl3_name' ? 'border border-primary w-[36px]' : 'w-[30px] border border-borderSlateGrey duration-200 hover:w-[36px] hover:border hover:border-primary'}`}
                        >
                            <p className='mb-0 -rotate-90 origin-center whitespace-nowrap'>Department</p>
                        </motion.div> : ''}
                </AnimatePresence>
            </div>
        )
    }

    const renderRightColumns = () => {
        return <div className='shrink-0 whitespace-nowrap flex items-center text-[11px] font-semibold uppercase'>
            <AnimatePresence>
                {!levelData?.["hl1_name"] && <motion.div
                    // initial={{  x: "-85vw" }}
                    // animate={{ x: 0 }}
                    // exit={{ x: `-${window.innerWidth * 0.9 - 90}px` }}
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: 30, opacity: 1 }}
                    exit={{width: 0, opacity: 0}}
                    transition={{ duration: .3 }}
                    className={`relative z-10 px-2 py-2 h-full overflow-hidden bg-[#EBEBEB] flex items-center justify-center border-r border-white`}>
                    <p className='mb-0 -rotate-90 origin-center whitespace-nowrap'>Section</p>
                </motion.div>}
            </AnimatePresence>
            <AnimatePresence>
                {!levelData?.["hl2_name"] && <motion.div
                    // initial={{x: "-85vw" }}
                    // animate={{ x: 0 }}
                    // exit={{ x: `-${window.innerWidth * 0.9 - 90}px` }}
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: 30, opacity: 1 }}
                    exit={{width: 0, opacity: 0}}
                    transition={{ duration: .3 }}
                    className={`relative z-10 px-2 py-2 h-full overflow-hidden bg-[#EBEBEB] flex items-center justify-center`}>
                    <p className='mb-0 -rotate-90 origin-center whitespace-nowrap'>Department</p>
                </motion.div>}
            </AnimatePresence>
        </div>
    }

    let tokenData = parseJwt(sessionStorage.getItem('token'));

    const refreshapidata = () => {
        dispatchHandler(dispatch,"getRefreshReportRequest", {
            viewName: getTableApiData.mvName
        } )
    }

    console.log("asdklnlasdfds",tokenData , getTableApiData , getTableApiData?.mvName);

    return (
        <div className='order-tracking-dashboard-table text-fontBlack'>
            <div className='px-[15px] py-2.5 mb-3 flex items-center justify-between gap-4 bg-white rounded-md'>
                <p className='text-base font-semibold whitespace-nowrap mb-0'>Division, Section & Department Wise Report</p>

                {/* Render the right pagination here */}
                {renderPagination()}
            </div>
            <div className='flex min-h-[450px] bg-white'>
                {renderLeftColumns()}
                {renderComponent()}
                {renderRightColumns()}
            </div>
        </div>
    )
}

export default OrderTrackingDashboardHirDetails