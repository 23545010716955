import React, { useEffect, useState } from 'react'
import ProcDashTopSection from './dashboardComponents/ProcDashTopSection';
import ProcDashBottomSection from './ProcDashBottomSection';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent';
import Icons from '../../../assets/icons';
import { Radio } from 'antd';
import QuickFilter from '../components/QuickFilter';
import dayjs from 'dayjs';
import { getCurrentDateFormat } from '../../../helper/helperTableFunctions';

const FILTERS = [
    {
        label: "Site",
        key: "SiteName",
        code: "SiteCode",
    },
    {
        label: "Vendor",
        key: "slName",
        code: "slCode",
    },
]
const PRIMARY_FILTERS = [
    {
        label: "Division",
        key: "hl1Name",
        uniqueKey: 'HL1GET',
        code: "hl1Code",
    }, {
        label: "Section",
        key: "hl2Name",
        uniqueKey: 'HL2GET',
        code: "hl2Code",
    }, {
        label: "Department",
        key: "hl3Name",
        uniqueKey: 'HL3GET',
        code: "hl3Code",
    }, {
        label: "Article",
        key: "hl4Name",
        uniqueKey: 'HL4GET',
        code: "hl4Code",
    }
]
let headerKeys = {
    "division": {
        "hl1Name": {},
    },
    "section": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
    },
    "department": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
        "hl3Name": {
            value: "Department",
        }
    },
    "article": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
        "hl3Name": {
            value: "Department",
        },
        "hl4Name": {
            value: "Article",
        }
    }
}
let itemKeys = {
    "division": {
        "hl1Name": ["hl1Name"],
    },
    "section": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
    },
    "department": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
        "hl3Name": ["hl3Name"],
    },
    "article": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
        "hl3Name": ["hl3Name"],
        "hl4Name": ["hl4Name"],
    }
}

function ProcDashboard(props) {

    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({});
    const [filters, setFilters] = useState({ setType: undefined });
    const [disabledApplyFilterBtn, setDisabledApplyFilterBtn] = useState(true);

    useEffect(() => {
        if (Object.keys(inputData || {}).length !== 0) {
            setDisabledApplyFilterBtn(false)
        }
    }, [inputData]);

    const getPayloads = (key, params, getBy) => {
        let payload = {}
        switch (key) {
            case 'Site':
                payload = {
                    isPO: false,
                    fromDate: '',
                    toDate: '',
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    pageNo: params?.currentPage || 1,
                    apiUrl: '/procurement/dashboard/get/site'
                }
                break;
            case 'Vendor':
                payload = {
                    isPO: false,
                    fromDate: '',
                    toDate: '',
                    type: params?.currentSearch.length === 0 ? 1 : 3,
                    pageNo: params?.currentPage || 1,
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    siteCode: inputData?.site?.SiteCode || "",
                    apiUrl: '/procurement/dashboard/get/sl'
                }
                break
            case 'Division':
            case 'Section':
            case 'Department':
            case 'Article':
                payload = {
                    type: params?.currentSearch.length === 0 ? 1 : 3,
                    isPO: false,
                    "hl1Name": inputData?.division?.hl1Name || "",
                    "hl2Name": inputData?.section?.hl2Name || "",
                    "hl3Name": inputData?.department?.hl3Name || "",
                    "hl4Name": inputData?.article?.hl4Name || "",
                    fromDate: inputData?.dateRange?.[0]?.format('YYYY-MM-DD') || '',
                    toDate: inputData?.dateRange?.[1]?.format('YYYY-MM-DD') || '',
                    slCode: inputData?.vendor?.slCode || '',
                    getBy: getBy,
                    pageNo: params?.currentPage || 1,
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    siteCode: inputData?.site?.SiteCode || "",
                    apiUrl: '/procurement/dashboard/get/hierarachylevels'
                }
                break
            default: return payload
        }
        return payload
    }

    const handleApplyFilter = (state = inputData) => {
        let payload = {
            ...filters,
            creationTime: {
                from: state?.dateRange?.[0]?.format('YYYY-MM-DD') || '',
                to: state?.dateRange?.[1]?.format('YYYY-MM-DD') || '',
            },  
            siteCode: state?.site?.SiteCode || '',
            slCode: state?.vendor?.slCode || '',
            hl1Code: state?.division?.hl1Code || "",
            hl2Code: state?.section?.hl2Code || "",
            hl3Code: state?.department?.hl3Code || "",
            hl4Code: state?.article?.hl4Code || "",
        }
        Object.keys(payload || {})?.map((key) => {
            if(key == "creationTime" && (payload?.[key]?.from == "" || payload?.[key]?.to == "")) {
                delete payload[key]
            }
            else if (payload?.[key] == "") {
                delete payload[key]
            }
        })
        setFilters(payload);
        setDisabledApplyFilterBtn(true);
    }

    const handleFilterChange = (type, data) => {
        let { value, state } = data;
        if (!value) {
            switch (type) {
                case "division": {
                    delete state.section;
                }
                case 'section': {
                    delete state.department;
                }
                case 'department': {
                    delete state.article
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (type) {
                case 'section': {
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                    }
                    break;
                }
                case 'department': {
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                        section: { hl2Name: value?.hl2Name, hl2Code: value?.hl2Code }
                    }
                    break;
                }
                case 'article':
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                        section: { hl2Name: value?.hl2Name, hl2Code: value?.hl2Code },
                        department: { hl3Name: value?.hl3Name, hl3Code: value?.hl3Code }
                    }
                    break;
                default:
                    break;
            }
        }
        if (!value) {
            handleApplyFilter(state)
        }
        return state;
    };

    const provideInputs = () => {
        let inputFieldsData = [{ key: "dateRange", type: "range", placeholder: ['Start Date', 'End Date'] }]
        inputFieldsData.push(...FILTERS.map((config) => {
            return {
                key: config?.label?.toLowerCase(),
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `${config?.label}`,
                type: 'dropdown',
                inputProps: () => ({
                    search: {
                        placeholder: `Search ${config?.label}`,
                    },
                    viewDataType: "table-view",
                    api: {
                        reduxState: 'digiProcStore',
                        apiName: "piPoDashboardFilter",
                        apiPayload: (params) => getPayloads(config?.label, params),
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: response => response?.resource?.map(data => ({ [config.label.toLowerCase()]: data[config.key], ...data })),
                    headerKeys: {
                        [config.label.toLowerCase()]: { value: [config.label] },
                        [config.code]: { value: 'Code' },
                    },
                    itemKeys: {
                        [config.label.toLowerCase()]: [config.key],
                        [config.code]: [config.code]
                    },
                }),
            }
        }))

        inputFieldsData.push(...PRIMARY_FILTERS.map((config) => {
            return {
                key: config?.label?.toLowerCase(),
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `${config?.label}`,
                type: 'dropdown',
                customInputValue: (data) => data?.[config?.key] || "",
                onChange: (val, state) => {
                    return handleFilterChange(config?.label?.toLowerCase(), {
                        value: val,
                        state: state
                    })
                },
                inputProps: () => ({
                    search: {
                        placeholder: `Search ${config?.label}`,
                    },
                    viewDataType: "table-view",
                    api: {
                        reduxState: 'digiProcStore',
                        apiName: "piPoDashboardFilter",
                        apiPayload: (params) => getPayloads(config?.label, params, config?.uniqueKey),
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: response => response?.resource?.map(data => ({ ...data })),
                    headerKeys: headerKeys?.[config?.label?.toLowerCase()],
                    itemKeys: itemKeys?.[config?.label?.toLowerCase()],
                }),

            }
        }))
        return inputFieldsData
    }

    const handleClearFilters = () => {
        setInputData({})
        handleApplyFilter({ setType: undefined })
    }

    // Function to render the primary filters data
    const renderPrimaryFilters = () => {
        return (
            <div className='proc-dashboard all-filter-dashboard procV2Styling p-lr-0'>
                <GenericFormComponent
                    rowInput={{
                        rowType: "singleRow",
                        availableInputs: provideInputs(),
                        inputState: { ...inputData },
                        inputStateChangeFunction: (data) => {
                            setInputData(data);
                        },
                        errorData: { ...errorData },
                        updateErrorData: (data) => {
                            setErrorData(data);
                        },
                        // rowLeftExtras: <div className='statusWiseStatistics mr-2.5'>
                        //                <Radio.Group className='flex w-full text-xs text-center whitespace-nowrap border-2 border-[#ECEEEF]' value={filters?.setType} onChange={(val) => setFilters({ ...filters, setType: val?.target?.value })}>
                        //                     <Radio.Button className='flex items-center justify-center text-xs w-full border-none !rounded-none' value={undefined}>All</Radio.Button>
                        //                     <Radio.Button className='flex items-center justify-center text-xs w-full border-none !rounded-none' value={true}>Set</Radio.Button>
                        //                     <Radio.Button className='flex items-center justify-center text-xs w-full border-none !rounded-none' value={false}>Non-Set</Radio.Button>
                        //                 </Radio.Group>
                        //             </div>,
                        rowRightExtras: <React.Fragment>
                            <div className='gfbi-btns'>
                                <GenericCustomButton
                                    label='Apply Filter'
                                    type='primary'
                                    onClick={() => handleApplyFilter()}
                                    disabled={disabledApplyFilterBtn}

                                />
                                <GenericCustomButton
                                    label="Clear All"
                                    type="default"
                                    onClick={handleClearFilters}
                                    disabled={Object.keys(inputData || {}).length === 0}
                                />
                            </div>
                        </React.Fragment>,
                    }}
                    availableDocument={props.availableDocument || null}
                />
                <QuickFilter
                    applyFilter={applyFilter}
                    filterPayload={filterPayload}
                    getCurrentFilters={() => getCurrentFilters()}
                    filterItems={filterItems}
                />
            </div>
        )
    }

    const transformData = (input) => {
        const transformedDataClone = {};
      
        if (input.hl4Name || input.hl4Code) {
            transformedDataClone.article = {
                hl4Name: input.hl4Name || "",
                hl4Code: input.hl4Code || ""
            };
        }
        
        if (input.hl1Name || input.hl1Code) {
            transformedDataClone.division = {
                hl1Name: input.hl1Name || "",
                hl1Code: input.hl1Code || ""
            };
        }
    
        if (input.hl2Name || input.hl2Code) {
            transformedDataClone.section = {
                hl2Name: input.hl2Name || "",
                hl2Code: input.hl2Code || ""
            };
        }
    
        if (input.hl3Name || input.hl3Code) {
            transformedDataClone.department = {
                hl3Name: input.hl3Name || "",
                hl3Code: input.hl3Code || ""
            };
        }
    
        if (input.creationTime?.from || input.creationTime?.to) {
            transformedDataClone.dateRange = [
                input.creationTime?.from ? dayjs(input.creationTime.from, getCurrentDateFormat(input.creationTime.from)) : "",
                input.creationTime?.to ? dayjs(input.creationTime.to, getCurrentDateFormat(input.creationTime.to)) : ""
            ].filter(Boolean);
        }
    
        if (input.site || input.SiteCode) {
            transformedDataClone.site = {
                site: input.site || "",
                SiteCode: input.SiteCode || "",
                SiteName: input.site || ""
            };
        }
    
        if (input.slName || input.slCode) {
            transformedDataClone.vendor = {
                vendor: input.slName || "",
                slCode: input.slCode || "",
                slName: input.slName || ""
            };
        }
        return transformedDataClone;
    }

    const applyFilter = (filters) => {
        let data = transformData(filters);
        setInputData(data);
        handleApplyFilter(data);
    }

    const filterPayload = {
        attributeType: "PROCUREMENT_DASHBOARD_FILTER",
        displayName: "PROCUREMENT_DASHBOARD_FILTER",
        filterName: ""
    };

    const getKeys = {
        dateRange: "creationTime",
        division: "hl1Name",
        section: "hl2Name",
        department: "hl3Name",
        article: "hl4Name",
        site: "site",
        vendor: "slName",
        // setType: "setType"
    }

    const getCurrentFilters = () => {
        let filterData = {};
        Object.keys(inputData || {})?.forEach(key => {
            switch (key) {
                case "article": {
                    filterData["hl4Name"] = inputData?.[key]?.[getKeys[key]]
                    filterData["hl4Code"] = inputData?.[key]?.["hl4Code"]
                    break;
                }
                case "division": {
                    filterData["hl1Name"] = inputData?.[key]?.[getKeys[key]]
                    filterData["hl1Code"] = inputData?.[key]?.["hl1Code"]
                    break;
                }
                case "section": {
                    filterData["hl2Name"] = inputData?.[key]?.[getKeys[key]]
                    filterData["hl2Code"] = inputData?.[key]?.["hl2Code"]
                    break;
                }
                case "department": {
                    filterData["hl3Name"] = inputData?.[key]?.[getKeys[key]]
                    filterData["hl3Code"] = inputData?.[key]?.["hl3Code"]
                    break;
                }
                case "vendor": {
                    filterData["slName"] = inputData?.[key]?.[getKeys[key]]
                    filterData["slCode"] = inputData?.[key]?.["slCode"]
                    break;
                }
                case "site": {
                    filterData["site"] = inputData?.[key]?.[getKeys[key]]
                    filterData["SiteCode"] = inputData?.[key]?.["SiteCode"]
                    break;
                }
                case "dateRange": {
                    filterData["creationTime"] = {
                        from: inputData?.[key]?.[0] ? dayjs(inputData[key][0].$d)?.format('YYYY-MM-DD') || '' : "",
                        to: inputData?.[key]?.[1].$d ? dayjs(inputData[key][1].$d)?.format('YYYY-MM-DD') || '' : "",
                    }
                    break;
                }
            }
        });
        Object.keys(filterData ||{})?.forEach(key => {
            if (!filterData?.[key]) {
                delete filterData[key]
            }
        });
        return filterData
    }

    console.log("Filters:", filters, inputData)

    let filterItems = {
        "Default Headers": {
            creationTime: "Date",
            site : "Site",
            slName : "Vendor",
            hl1Name : "Division",
            hl2Name : "Section",
            hl3Name : "Department",
            hl4Name : "Article",
            hl1Code: "Department Code",
            hl2Code: "Section Code",
            hl3Code: "Department Code",
            hl4Code: "Article Code",
            SiteCode: "Site Code",
            slCode: "Vendor Code",
        },
        "columnType": {
            creationTime: "DATE",
            site : "SEARCH",
            slName : "SEARCH",
            hl1Name : "SEARCH",
            hl2Name : "SEARCH",
            hl3Name : "SEARCH",
            hl4Name : "SEARCH",
            hl1Code: "SEARCH",
            hl2Code: "SEARCH",
            hl3Code: "SEARCH",
            hl4Code: "SEARCH",
            SiteCode: "SEARCH",
            slCode: "SEARCH",
        }
    }

    return (
        <div className='pl-[30px] pr-[15px] pb-[15px] pt-[16px] mt-[-16px] overflow-hidden'>
            {/* <div className='all-filter-dashboard'> */}
                {renderPrimaryFilters()}
            {/* </div> */}
            <div className='space-y-5 text-[#0F172A] mt-1'>
                <ProcDashTopSection filters={filters} setFilters={setFilters} inputData={inputData} />
                <ProcDashBottomSection filters={filters} setFilters={setFilters} />
            </div>
        </div>
    )
}

export default ProcDashboard