import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Icons from '../../assets/icons'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import ListComponent from '../../genericComponents/ListComponent'
import '../../styles/stylesheets/SignIn/SignIn.scss'
import { decodeToken, getCookie } from '../../helper/genericFunction'
import { isGetAutoLoginCookie } from '../../authComponents/login/helper/autoLoginFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import FullPageLoader from '../../loaders/FullPageLoader'
import LoginSupplymintAnimation from '../LoginSupplymintAnimation'
import { AUTH_CONFIG } from '../../authConfig'
import useCustomToast from '../../hooks/useCustomToast'
import { loginRequest } from './helper/loginHelperFunctions'
import LanguagePopup from '../../locals/LanguagePopup'
import { ReloadContext } from '../../App'

const Login = React.lazy(() => import('./Login'))

// const validateSupplymintToken = async (config = {}) => {
//     let { token, successHandler = () => {}, errorHandler = () => {} } = config || {};
//     let decodedToken = decodeToken(token);
//     if (decodedToken?.hasOwnProperty("loginSource") && decodedToken?.loginSource) {
//         if (token) {
//             await fetch(`${AUTH_CONFIG?.BASE_URL}/validate/token`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({
//                     token: token,
//                 })
//             })
//             ?.then(response => response?.json())
//             ?.then((response) => {
//                 console.log("SM Auth Token Validation Response", response)
//                 if (response.status == 2000) {
//                     successHandler(response)
//                 } else {
//                     errorHandler()
//                 }
//             })
//         } 
//     }
//     else {
//         errorHandler()
//         return "Please provide a valid token!"
//     }
// }

const LoginStack = (props) => {


    const [signUpLoading, setSignUpLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const jwtToken = getCookie('jwt')
    const dispatch = useDispatch();
    const location = useLocation();
    let timer = useRef(null);

    const browserLanguage = navigator.language?.slice(0, 2) || navigator.userLanguage?.slice(0, 2);

    const authStore = useSelector(state => state?.authStore);
    const getBootSettingsForApplicationData = useSelector(state => state?.generalStore?.getBootSettingsForApplicationData);
    console.log("Check234",sessionStorage.getItem("CURRENT_LANGUAGE"), browserLanguage);
    const [mode, setMode] = useState("normal");
    const [currentLanguage, setCurrentLanguage] = useState(!sessionStorage.getItem("CURRENT_LANGUAGE") ? browserLanguage  : sessionStorage.getItem("CURRENT_LANGUAGE") )

    const [selectedModal, setSelectedModal] = useState("showLanguagePopup");

    const customToast = useCustomToast()

    console.log("Check condition 1", jwtToken);

    useEffect(() => {
        if(!sessionStorage.getItem("CURRENT_LANGUAGE")){
            sessionStorage.setItem("CURRENT_LANGUAGE",currentLanguage)
        }
        if(currentLanguage == browserLanguage) {
            setSelectedModal(false)
        }
    },[])

    useEffect(() => {
        let registrationJwt = getCookie('registrationJwt');
        if(!(jwtToken !== null && !sessionStorage.getItem("token") && isGetAutoLoginCookie({location})) && registrationJwt){
            setMode("autoLogin");
            // Check : For validation of the token inside the cookie else delete the token
            dispatchHandler(dispatch, "getCurrrentUserAuthDetailsCopyRequest", {
                token: registrationJwt,
                flowType: "RELOGIN"
            })
        }
    }, [])

    // Get cookie here for auto login 
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const freshdeskParams = Object.fromEntries(urlSearchParams.entries());
        console.warn("Fresh desk params", freshdeskParams)
    
        if (freshdeskParams.client_id && freshdeskParams.nonce && freshdeskParams.redirect_uri && freshdeskParams.state) {
            // Auto login for the fresh desk params
            if(jwtToken){
                loginRequest({
                    token : jwtToken,
                    location: location,
                    dispatch: dispatch,
                    setLoading : setSignUpLoading,
                })
            }
        }
        else if (jwtToken !== null && !sessionStorage.getItem("token") && isGetAutoLoginCookie({location})) {
            setMode("autoLogin");
            // Check : For validation of the token inside the cookie else delete the token
            dispatchHandler(dispatch, "getCurrrentUserAuthDetailsCopyRequest", {
                token: jwtToken,
                flowType: "RELOGIN"
            })
        }
    }, [jwtToken])

    useEffect(() => {
        if (authStore?.getCurrrentUserAuthDetailsCopyData?.isError) {
            setMode("normal");
        }
        if(authStore?.getCurrrentUserAuthDetailsCopyData?.isSuccess && authStore?.getCurrrentUserAuthDetailsCopyData?.data?.resource?.token === null) setMode("normal");
    }, [authStore?.getCurrrentUserAuthDetailsCopyData])

    const {bootConfig, setBootConfig} = useContext(ReloadContext);

    const handleChange = (type) => {
        switch (type) {
            case "supportDropdown": {
                if (openModal !== "supportDropdown") {
                    setTimeout(() => document?.addEventListener("click", handleClickOutside), 0)
                    setOpenModal("supportDropdown");
                break;
                }
            }
        }
    }

    // Function to handle the outside click
    const handleClickOutside = () => {
        setTimeout(() => document.removeEventListener("click", handleClickOutside), 0);
        setOpenModal(false);
    }

    const renderSupportDropdown = () => {
        return (
            <>
            <div className='sft-btn'>
                <GenericCustomButton
                    label="Support"
                    rightIcon={<Icons.SupportIcon />}
                    onClick={() => handleChange("supportDropdown")}
                />

                {/* <div style={{width:"149px", left: "80%"}}>
                    <LanguageChooserComponent currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} setSelectedModal={setSelectedModal} timer={timer} showDefaultPopup = {true}/>
                </div> */}

                {/* Render the support dropdown */}
                {openModal == "supportDropdown" && <ListComponent
                    label={{
                        content: "Help & Support",
                    }}
                    // closeModal = {() => handleClickOutside()}
                    headerKeys={{
                        "help": {}
                    }}
                    itemKeys={{
                        "help": ["label", "support"],
                    }}
                    getListData={() => ([
                        { label: "New Ticket", support: "Create New Support Ticket", onClick: () => { window.open("https://help.supplymint.com/support/tickets/new") } },
                        { label: "Support Documentation", support: "Supplymint Help Portal", onClick: () => { window.open("https://support.supplymint.com/") } },
                        { label: "Blogs", support: "Get Latest News & Updates", onClick: () => { window.open("https://www.supplymint.com/blogs/") } },
                        { label: "Contact Us", support: "We'll Respond As Quick As Possible", onClick: () => { window.open("https://www.supplymint.com/contact") } },
                    ])}
                    submitHandler={(data) => data?.onClick?.()}
                />}
            </div>
            {<div className={`bg-[#2969FF] text-white text-[13px] fixed !z-[999] top-0 left-0 duration-300 delay-200 shadow-md px-4 py-2 w-full ${selectedModal == "showLanguagePopup" ? 'translate-y-0 opacity-100 pointer-events-auto' : '-translate-y-full opacity-0 pointer-events-none'}`}>
                <LanguagePopup
                    dropdownType = {props.dropdownType}
                    closeModal={() => {
                        setSelectedModal(false);
                    }}
                    currentLanguage={currentLanguage}
                    setCurrentLanguage={setCurrentLanguage}
                />
            </div>}
            </>
        )
    }
    if (mode == "autoLogin") {
        return <LoginSupplymintAnimation msg={"Logging Back To Supplymint..."} />
    }

    return (
        <Suspense fallback={<FullPageLoader />}>
            <div className='signin-flow'>
                <div className='sf-top' style={bootConfig?.bannerImage ? { backgroundImage: `url(${bootConfig?.bannerImage})` } : {}}>
                    {/* the Top div for the support dropdown */}
                    {renderSupportDropdown()}
                </div>

                {/* the backgorund div for the login screen */}
                <div className='py-[2.187rem] h-full w-full max-h-screen overflow-y-auto z-50'>
                    <div className='sf-modal'>
                        <Switch>
                            <Route path='*' render={() => <Login {...props} setSignUpLoading={setSignUpLoading} signUpLoading={signUpLoading} />} />
                        </Switch>
                    </div>
                </div>
            </div>
            {signUpLoading && <FullPageLoader />}
        </Suspense>
    )
}

export default LoginStack