import React, { useEffect, useState } from 'react'
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import { useSelector } from 'react-redux'
import Icons from '../../../assets/icons'
import QuickFilter from './QuickFilter'
import dayjs from 'dayjs'
import GenericInput from '../../../genericComponents/input/GenericInputComponent'
import moment from 'moment'
import LanguageTranslator from '../../../locals/LanguageTranslator'
import { convertToChinese } from '../../../locals/convertToChinese'

const AllFilters = (props) => {
    const { availableDocument, PRIMARY_FILTERS, filterPayload, currentFilterKeys, defaultInputState = {}, disabledDate, MAIN_FILTERS, mappingSelectedFilters, filterItems } = props
    console.log("Filter props", MAIN_FILTERS, PRIMARY_FILTERS);
    const digivendStore = useSelector(state => state.digivendStore)

    const [errorData, setErrorData] = useState({})
    const [inputData, setInputData] = useState(defaultInputState || {})
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [updatedKey, setUpdatedKey] = useState("");
    const [callFun, setCallFun] = useState("")

    // State : To store the current search value for labels inside the filters
    const [currentFilterLabelSearch, setCurrentFilterLabelSearch] = useState("");

    useEffect(() => {
        if (updatedKey) {
            setUpdatedKey("");
            props.applyFilter(getCurrentFilters());
        }
    }, [updatedKey])

    const handleChange = (type, data) => {
        setInputData(prevInputData => ({
            ...prevInputData,
            [type]: data
        }));
        setUpdatedKey(type);
    }

    const provideInputs = () => {
        let inputFieldsData = [
            {
                key: "dateRange", type: "range", placeholder: ['Date From', 'Date To'],
                disabledDate,
                onChange: (data) => handleChange("dateRange", data),
                inputProps: () => ({
                    ranges: {
                        [convertToChinese("Today")]: [dayjs(), dayjs()],
                        [convertToChinese('Yesterday')]: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
                        [convertToChinese('Last 7 Days')]: [dayjs().subtract(7, "days"), dayjs().subtract(1, "day")],
                        [convertToChinese('Last Week')]: [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')],
                        [convertToChinese('This Month')]: [dayjs().startOf('month'), dayjs().endOf('month')],
                        [convertToChinese('Last Month')]: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                        [convertToChinese('Last 90 Days')]: [dayjs().subtract(90, "day"), dayjs().subtract(0, "day")],
                    }, format: 'YYYY/MM/DD'
                })
            },]
        inputFieldsData.push(...PRIMARY_FILTERS.map((config) => {
            return {
                key: config?.key,
                iconRight: <Icons.DownArrowFilled />,
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `${config?.value}`,
                type: 'dropdown',
                value: inputData?.[config?.key],
                onChange: (data) => handleChange(config?.key, data),
                inputProps: () => ({
                    search: {
                        placeholder: `Search ${config?.value}`,
                        // searchOptions: {
                        //     "CONTAINS": "Contains",
                        //     "STARTS_WITH": "Starts With",
                        //     "EXACT_MATCH": "Exactly Matched"
                        // },
                        // searchOptionLabel: "Search By",
                    },
                    viewDataType: "table-view",
                    api: {
                        reduxState: 'generalStore',
                        apiName: 'getSearchFilters',
                        apiPayload: (params) => {
                            return {
                                "entity": config?.entity,
                                "key": config.key,
                                "code": config?.code,
                                "search": params?.currentSearch || "",
                                "searchBy": params.searchFilter || "CONTAINS",
                                "pageNo": params?.currentPage || 1,
                                "moduleName": "",
                                "other_data": {},
                                "columnName": {}
                            }
                        },
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: (response) => {
                        switch (config.key) {
                            default: return response?.resource?.searchResultNew?.map(data => ({ [config.key]: data[config.key], ...data }))
                        }
                    },
                    headerKeys: config.headerKeys ?? {
                        multiSelectCol: {},
                        [config.key]: { value: [config.value] },
                    },
                    itemKeys: config.itemKeys ?? {
                        [config.key]: [config.key],
                    },
                    multiselect: config.multiselect ?? {
                        selectId: config?.key,
                    },
                }),

            }
        }))
        return inputFieldsData
    }

    const renderPrimaryFilters = () => {
        return (
            <GenericFormComponent
                rowInput={{
                    rowType: "singleRow",
                    availableInputs: provideInputs(),
                    inputState: { ...inputData },
                    inputStateChangeFunction: (data) => {
                        setInputData(data);
                    },
                    errorData: { ...errorData },
                    updateErrorData: (data) => {
                        setErrorData(data);
                    },
                    rowRightExtras: <React.Fragment>
                        <div className='gfbi-btns'>

                            {/* button for more filters */}
                            {!props.hideAddFilter && <GenericCustomButton
                                sendEventAnalyticsToGoogle={true}
                                label="Add Filter"
                                type="default"
                                leftIcon={<Icons.CreatePlusIcon />}
                                onClick={() => setShowMoreFilters(!showMoreFilters)}
                                disabled={() => MAIN_FILTERS?.length === 0}
                            />}
                            {showMoreFilters && (
                                <div className='gfbii-dropdown' id='mainFilters'>
                                    {renderMainFilters()}
                                    {Object.keys(inputData || {})?.length !== 0 && (
                                        <div className='applied-filter'>
                                            <div className='af-top'>
                                                <LanguageTranslator tag='h2'>Applied Filters</LanguageTranslator>
                                                <GenericCustomButton
                                                    type='default'
                                                    label='Clear All'
                                                    onClick={() => {
                                                        setInputData({})
                                                        setUpdatedKey('dateRange')
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className='af-body'>
                                                {Object.keys(inputData || {})?.map((filterKey, index) => (
                                                    filterKey !== 'date' && (
                                                        <div key={index.toString()} className='af-det'>
                                                            <p>{mappingSelectedFilters?.[filterKey]}</p>
                                                            {Object?.keys?.(inputData?.[filterKey] || {})?.map((item) => {
                                                                return <label>{item}</label>
                                                            })}
                                                        </div>)
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </React.Fragment>,
                }}
                availableDocument={availableDocument || null}
            />
        )
    }

    const provideMainFilterInputs = () => {
        let inputFieldsData = []
        inputFieldsData?.push(...MAIN_FILTERS?.filter(config => config?.value?.includes(currentFilterLabelSearch || ""))?.map((config) => {
            return {
                key: config?.key,
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `Select ${config?.value}`,
                type: 'dropdown',
                value: inputData?.[config?.key],
                onChange: (data) => handleChange(config?.key, data),
                inputProps: () => ({
                    viewDataType: "table-view",
                    search: {
                        placeholder: `Search ${config?.value}`,
                    },
                    api: {
                        reduxState: 'generalStore',
                        apiName: 'getSearchFilters',
                        apiPayload: (params) => {
                            return {
                                "entity": config?.entity,
                                "key": config.key,
                                "code": config?.code,
                                "search": params?.currentSearch || "",
                                "searchBy": params.searchFilter || "CONTAINS",
                                "pageNo": params?.currentPage || 1,
                                "moduleName": "",
                                "other_data": {},
                                "columnName": {}
                            }
                        },
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: (response) => {
                        switch (config.uniqueKey) {
                            default: return response?.resource?.searchResultNew?.map(data => ({ [config.key]: data[config.key], ...data }))
                        }
                    },
                    headerKeys: {
                        multiSelectCol: {},
                        [config.key]: { value: [config.value] },
                    },
                    itemKeys: {
                        [config.key]: [config.key],
                    },
                    multiselect: {
                        selectId: config?.key,
                    },
                }),

            }
        }))
        return inputFieldsData
    }

    const handleClickOutsideButton = (event) => {
        const secondButton = document.getElementById('mainFilters');
        if (secondButton && !secondButton.contains(event.target)) {
            setShowMoreFilters(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideButton);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideButton);
        };
    }, []);

    const renderMainFilters = () => {
        return (
            <div className='gfbiid-input'>
                <GenericInput
                    inputKey="inputLabelSearch"
                    iconLeft={<Icons.GlobalSearchIcon />}
                    value={currentFilterLabelSearch}
                    dependentFieldsObject={["inputLabelSearch"]}
                    placeholder="Search Filter"
                    onChange={e => setCurrentFilterLabelSearch(e?.target?.value || "")}
                />
                <GenericFormComponent
                    rowInput={{
                        rowType: "spread",
                        availableInputs: provideMainFilterInputs(),
                        inputState: { ...inputData },
                        inputStateChangeFunction: (data) => {
                            setInputData(data);
                        },
                        errorData: { ...errorData },
                        updateErrorData: (data) => {
                            setErrorData(data);
                        },
                    }}
                    availableDocument={availableDocument || null}
                />

            </div>
        )
    }

    // Function : To get the current filters data to save the filters and send it inside the payload
    const getCurrentFilters = (keys = currentFilterKeys || []) => {
        let filtersData = {};
        keys?.map(key => {
            if (
                Array?.isArray(inputData?.[key]) ? inputData?.[key]?.length > 0 : typeof (inputData?.[key]) == "object" ? Object.keys(inputData?.[key] || {})?.length > 0 : inputData?.[key]
            ) {
                switch (key) {
                    case "dateRange": {
                        filtersData.dateRange = {
                            "from": dayjs(inputData?.[key]?.[0])?.format("YYYY-MM-DD") || "",
                            "to": dayjs(inputData?.[key]?.[1])?.format("YYYY-MM-DD") || "",
                        }
                        break;
                    }
                    default: {
                        filtersData[key] = Object?.keys(inputData?.[key] || {})?.join(',');
                        break;
                    }
                }
            }
        })
        return filtersData;
    }

    const convertFilterToInput = (filters) => {
        console.log('all filters are selected', filters);
        let initialData = {};
        Object.keys(filters || {}).forEach(key => {
            switch (key) {
                case "dateRange": {
                    initialData["dateRange"] = [
                        dayjs(filters?.[key]?.from || ""),
                        dayjs(filters?.[key]?.to || "")
                    ];
                    break;
                }
                default: {
                    let obj = {};
                    // Check if the value is a string separated by commas
                    console.log('filters key4554', filters);
                    if (typeof filters[key] === 'string') {
                        filters?.[key]?.split(',')?.forEach(item => {
                            obj[item] = { [key]: item };
                        });
                    } else if (Array.isArray(filters?.[key])) { // Check if the value is an array
                        filters[key]?.forEach(item => {
                            obj[item] = { [key]: item };
                        });
                    }
                    initialData[key] = obj;
                    break;
                }
            }
        });

        return initialData;
    };

    const applyFilter = (filters) => {
        let initialData = convertFilterToInput(filters);
        setInputData(initialData);
        props.applyFilter(filters);
    }

    const clearAllFilters = () => {
        setCallFun("clearFilters")
    }

    return (
        <div className='all-filter-dashboard'>
            {renderPrimaryFilters()}

            {/* {showMoreFilters && (
                <div className='' id='mainFilters' >
                    {renderMainFilters()}

                    {Object.keys(inputData || {})?.length !== 0 && (
                        <div>
                            <div className='flex'>
                                <h2>Applied Filters</h2>
                                <GenericCustomButton
                                    type='default'
                                    label='Clear All'
                                    onClick={() => {
                                        setInputData({})
                                        setUpdatedKey('dateRange')
                                    }
                                    }
                                />
                            </div>
                            {Object.keys(inputData || {})?.map((filterKey, index) => (
                                filterKey !== 'date' && (
                                    <div key={index.toString()}>
                                        <h3 >{mappingSelectedFilters?.[filterKey]}</h3>
                                        <span>{Object?.keys?.(inputData?.[filterKey] || {})?.join(',')}</span>
                                    </div>)
                            ))}
                        </div>
                    )}
                </div>
            )}   */}

            {props.isQuickFilterRequired || props.isQuickFilterRequired === undefined ? (
                <QuickFilter
                    applyFilter={applyFilter}
                    filterPayload={filterPayload}
                    getCurrentFilters={() => getCurrentFilters(["dateRange", ...PRIMARY_FILTERS?.map(item => item?.key), ...MAIN_FILTERS?.map(item => item?.key)], {})}
                    filterItems={filterItems}
                    clearAll={clearAllFilters}
                    callFun={callFun}
                    setCallFun={setCallFun}
                />
            ) : null}

            <GenericCustomButton
                className='whitespace-nowrap m-lr-10'
                label="Clear All"
                type="default"
                onClick={() => {
                    setInputData({});
                    setUpdatedKey('dateRange')
                    clearAllFilters()
                }}
                disabled={() => digivendStore?.getWhDashboardData?.isLoading ||
                    Object.keys(inputData || {})?.length === 0}
            />
        </div>)
}

export default AllFilters