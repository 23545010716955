import React, { useEffect, useState } from 'react'
import CommentBoxModal from './CommentBoxModal';
import { AtSign, Download, Paperclip } from 'lucide-react';
import { getSocketConnection } from '../../../../socket/socketHelperConnection';

const CommentBoxModalWrapper = (props = {}) => {

    const {mode = 'normal'} = props

    const [openModal, setOpenModal] = useState(false);
    return (
        <div className='flex h-full'>
            <CommentBoxModal
                {...props}
                mode = {mode}
            />

            {/* Render the Mentions here */}
            {
            <div className={`duration-200 overflow-hidden ${openModal == "mentions" ? 'visible opacity-100 pointer-events-auto w-[300px] min-w-[300px]' : 'invisible opacity-0 pointer-events-none w-[0px] min-w-[0px]'}`}>
                {openModal == "mentions" && <CommentBoxModal
                    {...props}
                    mode = "mentions"
                    outerMode = {mode == "central" ? "mentions_central" : undefined}
                    closeSection = {() => setOpenModal(false)}
                />}
            </div>
            }

            {
            <div className={`duration-200 overflow-hidden ${openModal == "attachments" ? 'visible opacity-100 pointer-events-auto w-[300px] min-w-[300px]' : 'invisible opacity-0 pointer-events-none w-[0px] min-w-[0px]'}`}>
                {openModal == "attachments" && <CommentBoxModal
                    {...props}
                    mode = "attachments"
                    outerMode = {mode == "central" ? "attachments_central" : undefined}
                    closeSection = {() => setOpenModal(false)}
                />}
            </div>
            }

            <div className={`p-[12px] bg-white z-10 ${mode === 'central' ? 'h-full' : 'h-screen'}`}>
                <ul className='space-y-3'>
                    <li onClick={() => setOpenModal(openModal == "mentions" ? false : "mentions")} className={`aspect-square p-[6px] flex items-center justify-center bg-gray-100 font-semibold cursor-pointer hover:opacity-75 ${openModal == "mentions" && 'bg-primary_light'}`}>
                        <span><AtSign className={`${openModal == "mentions" ? 'text-primary' : 'black'}`} size={17}/></span>
                    </li>
                    <li onClick={() => setOpenModal(openModal == "attachments" ? false : "attachments")} className={`aspect-square p-[6px] flex items-center justify-center bg-gray-100 font-semibold cursor-pointer hover:opacity-75 ${openModal == "attachments" && 'bg-primary_light'}`}>
                        <span><Download className={`${openModal == "attachments" ? 'text-primary' : 'black'}`} size={17}/></span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CommentBoxModalWrapper