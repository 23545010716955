import enTranslationJSON from './en.json'
import enHSTranslationJSON from './enHSTranslation.json'
import chineseTranslationJSON from './chinese.json'
let languageJson = {
    "en" : enTranslationJSON,
    "en-HS" : enHSTranslationJSON,
    "zh" : chineseTranslationJSON
}
 const convertToChinese = (str) => {
    let currentLanguage = sessionStorage?.getItem("CURRENT_LANGUAGE") ?  languageJson?.hasOwnProperty(sessionStorage?.getItem("CURRENT_LANGUAGE")) ? sessionStorage?.getItem("CURRENT_LANGUAGE") : "en" : "en";

    return languageJson?.[currentLanguage]?.[str]  || str;
}

export {convertToChinese}