import React from 'react';
import IconsPath from '../../../assets/icons/index'
import LanguageTranslator from '../../../locals/LanguageTranslator';

export default class TableLoader extends React.PureComponent {
    render () {
        return (
            <div className={`table-loader-bg ${this.props.className}`}>
                <div className={`table-loader-modal ${this.props.loaderModalClassName}`}>
                    <div className="tlm-head"></div>
                    <div className="tlm-body">
                        <div className="tlmb-loader">
                            <span className={this.props.isError ? "tlmbsm-load-static" : "tlmbsm-load"}>
                                {this.props.isError ?
                                <div className="tlmbsml-inner" onClick={this.props.onRefresh}>
                                    <IconsPath.ReloadIcon  />
                                </div> :
                                <span className="tlmbsml-inner"></span>}
                            </span>
                             <LanguageTranslator tag="p">{this.props.isError ? <LanguageTranslator>Failed to fetch data!</LanguageTranslator> : <LanguageTranslator>Fetching data...</LanguageTranslator>}</LanguageTranslator>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}