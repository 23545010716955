import React, { useContext, useEffect, useState } from 'react'
import Icons from '../../../../assets/icons'
import { ReloadContext } from '../../../../App';
import GenericCustomButton from '../../../../genericComponents/GenericCustomButton';
import { ChevronLeft, ChevronRight, CopyIcon, Image, RotateCcw, SquareLibrary, ThumbsDown, ThumbsUp, TriangleAlert } from 'lucide-react';
import ListComponent from '../../../../genericComponents/ListComponent';
import GenericInput from '../../../../genericComponents/input/GenericInputComponent';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import GenericSpinnerLoader from '../../../../loaders/GenericSpinnerLoader';
import { Carousel, Progress, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ParentModal from '../../../../genericComponents/ParentModal';
import GenericFormHeaderComponent from '../../../../genericComponents/forms/GenericFormHeaderComponent';
import JobSuccessTickMarkGreen from './../../../../animationsJSON.js/JobSuccessTickMarkGreen.json'
import Lottie from 'lottie-react';
import { getPermissionKey } from '../../../../helper/permissions';
import { openDB } from 'idb';
import GenericConfirmationModal from '../../../../genericComponents/GenericConfiramationModal';

const getAnimation = () => ({
    loop: true,
    autoplay: true,
    animationData: JobSuccessTickMarkGreen,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    style: {
        width: 150,
        height: 120
    }
})

const CompleteProductSurvey = (props) => {

    const { mode = "create", selectedData } = props || {};

    const submitProductSurveyData = useSelector(state => state?.digicatStore?.submitProductSurveyData);
    const getSubmittedSurveyDetailsData = useSelector(state => state?.digicatStore?.getSubmittedSurveyDetailsData);
    const { bootConfig } = useContext(ReloadContext);
    const [surveyMessage, setSurveyMessage] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search), params = Object.fromEntries(urlSearchParams.entries());
    let data = params?.data ? JSON.parse(decodeURIComponent(params?.data)) : {};

    let { productSurveyId = data?.surveyId ? Number(atob(data?.surveyId)) || "" : "", mailingListId = data?.mailing_list_id, email = data?.email, mailingListName = data?.mailing_list_name || "", employeeName = data?.employee_name || "", employeeCode = data?.employee_code || "", countryCode = data?.country_code || "", mobileNumber = data?.mobile_no || "" } = data || {};

    const getProductSurveyDetailsData = useSelector(state => state?.digicatStore?.getProductSurveyDetailsData);

    let interval;

    const [surveyBasicInfo, setSurveyBasicInfo] = useState({})

    const [surveyStarted, setSurveyStarted] = useState(false);

    const [surveyExpired, setSurveyExpired] = useState(false);

    const [currentTime, setCurrentTime] = useState((surveyBasicInfo?.timeout || 0) * 60);
    const [totalTime, setTotalTime] = useState(0)

    const [completedSurvey, setCompletedSurvey] = useState({});
    const [surveyKeys, setSurveyKeys] = useState({})
    const [surveyData, setSurveyData] = useState({})

    const [error, setError] = useState({});

    const [surveyProductList, setSurveyProductList] = useState([]);
    const [collectionsList, setCollectionsList] = useState([]);
    const [surveySubmitted, setSurveySubmitted] = useState(false);

    const [updateStatusOfSurvey, setUpdateStatusOfSurvey] = useState(false);

    const [surveyAlreadySubmitted, setSurveyAlreadySubmitted] = useState(false);
    const [selectedModal, setSelectedModal] = useState(false)
    const [copySuccess, setCopySuccess] = useState(false);
    const [isDraftReady, setIsDraftReady] = useState(false)

    // useEffect(() => {
    //     getProductSurveyDetailsFromTheDB();
    // }, [])

    useEffect(() => {
        let id = getFormId();
        if (surveyData && isDraftReady && id) {
            addDraftedValueInTheDatabase(id);
        }
    }, [surveyData])

    // Function : To add the drafted values in the database
    const addDraftedValueInTheDatabase = async (id) => {
        let draftedValue = {
            "k1": productSurveyId,
            "k2": mailingListId,
            "k3": surveyData,
        }
        const db = await openDB("Supplymint-ai-offline-storage", 1);
        await db.put("DARFT_FORM_TABLE", JSON.stringify(draftedValue), id)
    }

    // Function : To get the value of the product survey
    const getProductSurveyDetailsFromTheDB = async (check) => {
        const db = await openDB("Supplymint-ai-offline-storage", 1);
        let id = getFormId();
        if (id) {
            const value = await db.get("DARFT_FORM_TABLE", getFormId());
            console.log("Cousajbld",value);
            if (value) {
                setSelectedModal("loadDefaultData")
                // if(check){
                //     let parsedValue = JSON.parse(value);
                //     if(parsedValue.k1 == productSurveyId && parsedValue.k2 == mailingListId){
                //         setSurveyData(parsedValue?.k3 || {})
                //     }
                // }
            }else{
                setIsDraftReady(true);
            }
        }else{
            setIsDraftReady(true);
        }
    }

    const getData = async () => {
        setSelectedModal(false);
        const db = await openDB("Supplymint-ai-offline-storage", 1);
        let id = getFormId();
        if (id) {
            const value = await db.get("DARFT_FORM_TABLE", getFormId());
            console.log("Cousajbld",value);
            setIsDraftReady(true);
            if (value) {
                let parsedValue = JSON.parse(value);
                if (parsedValue.k1 == productSurveyId && parsedValue.k2 == mailingListId) {
                    setSurveyData(parsedValue?.k3 || {})
                }
            }
        }
    }

    // Function : To remove the values of the product survey
    const removeValueFormTheDB = async () => {
        const db = await openDB("Supplymint-ai-offline-storage", 1);
        let id = getFormId();
        if (id) {
            await db.delete("DARFT_FORM_TABLE", id)
        }
    }

    // The id to store the form draft information into the database.
    const getFormId = () => {
        if (productSurveyId && mailingListId) {
            return `PRODUCT_SURVEY_${productSurveyId}_${mailingListId}`
        }
        return null;
    }

    useEffect(() => {
        getProductSurveyDetails();

        if (mode == "view") {
            setSurveyBasicInfo({
                surveyName: selectedData?.surveyName || "",
            })
        }
    }, [])

    useEffect(() => {
        if (updateStatusOfSurvey && mode === "create") {
            dispatchHandler(dispatch, "updateProdctSurveyStatusRequest", [{
                "entId": surveyBasicInfo?.entId || "",
                "orgId": surveyBasicInfo?.orgId || "",
                "surveyId": productSurveyId || "",
                "surveyName": surveyBasicInfo?.surveyName || "",
                "mailingListId": mailingListId || "",
                "mailingListName": mailingListName || "",
                "employeeName": employeeName || "",
                "employeeCode": employeeCode || "",
                "countryCode": countryCode || "",
                "mobileNo": mobileNumber || "",
                "email": email || "",
                "status": "OPENED"
            }], getPermissionKey("common", "commonFunctionality", "view"))
        }
    }, [updateStatusOfSurvey])

    // Function : TO reset every state 
    const resetData = () => {
        setSurveyBasicInfo({});
        setSurveyStarted(false);
        setSurveyExpired(false);
        // setSelectedModal(false)
        setCompletedSurvey({});
        setSurveyKeys({});
        setSurveyData({});
        setError({});
        setCurrentTime(0);
        setTotalTime(0)
    }

    useEffect(() => {
        if (submitProductSurveyData?.isSuccess && mode == "create") {
            removeValueFormTheDB();
            dispatchHandler(dispatch, "updateProdctSurveyStatusRequest", [{
                "entId": surveyBasicInfo?.entId || "",
                "orgId": surveyBasicInfo?.orgId || "",
                "surveyId": productSurveyId || "",
                "surveyName": surveyBasicInfo?.surveyName || "",
                "mailingListId": mailingListId || "",
                "mailingListName": mailingListName || "",
                "employeeName": employeeName || "",
                "employeeCode": employeeCode || "",
                "countryCode": countryCode || "",
                "mobileNo": mobileNumber || "",
                "email": email || "",
                "status": "SUBMITTED"
            }], getPermissionKey("common", "commonFunctionality", "view"))
            setSurveySubmitted(true);
            setSelectedModal('surveySubmitted');
            setSurveyMessage(submitProductSurveyData?.data?.resource?.message ?? false,)
            resetData()
        }
    }, [submitProductSurveyData?.isSuccess])

    let keys = {
        "Vote": "vote",
        "Rating Scale": "Rating",
        "Expected Sales Qty": "expectedSalesQty",
        "Expected Sell Thru": "expectedSaleThrough",
        "Comments & Remarks": "comments"
    }


    useEffect(() => {
        if (getProductSurveyDetailsData?.isSuccess) {
            let resource = getProductSurveyDetailsData?.data?.resource || {};
            if (resource?.["data"] && resource?.["headers"]) {
                getProductSurveyDetailsFromTheDB();
                let vals = [];

                let colsList = {}

                resource?.data?.forEach((item, index) => {
                    vals?.push(({ ...item, id: index }))
                    let names = item?.collection_name?.split('|');
                    item?.collection_code?.split('|')?.map((code, index) => {
                        colsList[code] = names?.[index];
                    })
                    // console.log("jkbaksjdnksadf", item);
                })

                setCollectionsList(colsList);
                setSurveyProductList(vals || []);

                let obj = {};

                let temp = resource?.headers?.surveySubmissionOptions?.split(',')

                temp?.map(header => {
                    console.log("check this headers", header, keys?.[header]);
                    if (keys[header == "Like & Dislike" ? "Vote" : header]) {
                        obj[keys[header == "Like & Dislike" ? "Vote" : header]] = header;
                    }
                })

                setSurveyExpired(false);
                // setSelectedModal(false);
                setSurveyKeys(obj || {})
                setSurveyBasicInfo({ ...resource?.headers, productCount: resource?.data?.length || 0 })
                setCurrentTime((resource?.headers?.timeLimit ?? 0) * 60)
                setTotalTime((resource?.headers?.timeLimit ?? 0) * 60)

                setUpdateStatusOfSurvey(true);
            }
        }
        if (getProductSurveyDetailsData?.isError) {
            if (getProductSurveyDetailsData?.message?.errorCode == 4030) {
                setSurveyExpired(getProductSurveyDetailsData?.message?.errorMessage || "");
                setSelectedModal('surveyExpired')
            }
            else {
                // if(getProductSurveyDetailsData?.data?.er)
                setError(prev => ({
                    ...prev,
                    "getAll": "Failed to get survey details!",
                }))
            }
        }
    }, [getProductSurveyDetailsData])

    useEffect(() => {
        if (getSubmittedSurveyDetailsData?.isSuccess) {

            let vals = [];

            let obj = {};

            console.log("cjbhaksdlfnkasdf", getSubmittedSurveyDetailsData?.data?.resource?.data);
            getSubmittedSurveyDetailsData?.data?.resource?.data?.forEach((item, index) => {

                vals?.push(({ ...item, id: index }))

                obj[index] = item?.surveyFeedbackSubmitJson || {};

                let reverseKeys = {
                    "vote": "Vote",
                    "Rating": "Rating Scale",
                    "expectedSalesQty": "Expected Sales Qty",
                    "expectedSaleThrough": "Expected Sell Thru",
                    "comments": "Comments & Remarks"
                }


                if (index == 0) {
                    let surveyKeysData = {};

                    Object.keys(item?.surveyFeedbackSubmitJson || {})?.map(key => {
                        surveyKeysData[key] = reverseKeys?.[key]
                    });
                    setSurveyKeys(surveyKeysData);
                }
            })

            setSurveyData(obj);
            setSurveyProductList(vals || []);
            setSurveyExpired(false);
        } else if (getSubmittedSurveyDetailsData?.isError) {
            setSurveyExpired(false);
            setError(prev => ({
                ...prev,
                "getAll": "Failed to get survey details!",
            }))
        }
    }, [getSubmittedSurveyDetailsData])

    const getProductSurveyDetails = () => {
        setError(prev => {
            delete prev?.["getAll"];
            return prev;
        })

        if (productSurveyId && mode == "create") {
            dispatchHandler(dispatch, 'getProductSurveyDetailsRequest', {
                "surveyId": productSurveyId,
                "mailingListId": mailingListId || 5,
                "email": email,
            }, getPermissionKey("common", "commonFunctionality", "view"))
        } else if (selectedData?.surveyId && selectedData?.email) {
            dispatchHandler(dispatch, "getSubmittedSurveyDetailsRequest", {
                id: selectedData?.surveyId,
                email: selectedData?.email || "",
            }, getPermissionKey("purchase", "productsurvey", "view"))
        }
    }

    useEffect(() => {
        console.log(55555555555555, currentTime, totalTime)
        if (currentTime == 0 && totalTime) {
            clearInterval(interval);
            if (mode == "create") {
                setSurveyExpired(true);
                setSelectedModal('surveyExpired');
                submitSurvey("autoSubmit");
            }
        }
    }, [currentTime])

    const renderRemainingTime = () => {
        const hours = Math.floor(currentTime / 3600);
        const minutes = Math.floor((currentTime % 3600) / 60);
        const secs = currentTime % 60;

        // Use padStart to ensure each value is at least 2 digits
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }


    console.log("Survey Keys", surveyKeys);

    const updateSurveyKeys = (config) => {

        let { key, value, id } = config;

        let updateSurveyData = { ...surveyData };

        if (!updateSurveyData?.[id]) {
            updateSurveyData = { ...updateSurveyData, [id]: {} };
        }

        if (["vote", "Rating"]?.includes(key) && value == updateSurveyData[id][key]) {
            delete updateSurveyData?.[id]?.[key]
        } else {
            if ((["vote", "Rating"]?.includes(key))) {
                updateSurveyData[id][key] = value;
            } else {
                if (value) {
                    updateSurveyData[id][key] = value;
                } else {
                    delete updateSurveyData?.[id]?.[key]
                }
            }
        }

        if (Object.keys(updateSurveyData?.[id] || {})?.length == 0) {
            delete updateSurveyData?.[id];
        }
        if (!surveyStarted) {
            startSurvey();
        }
        setSurveyData(updateSurveyData)
        isSurveyCompleted(updateSurveyData, id);

    }

    console.log("Surveyt data", surveyData, surveyKeys);

    const isSurveyCompleted = (data = surveyData, id) => {
        let updatedCompletedSurvey = { ...completedSurvey };

        if (
            Object.keys(surveyKeys || {})?.length > 0 &&
            Object.keys(surveyKeys || {})?.every(key => data?.[id]?.hasOwnProperty(key)) &&
            (["expectedSalesQty", "expectedSaleThrough"]?.every(key => !isNaN(Number(data?.[id]?.[key] || "")) && Number(data?.[id]?.[key] || "") >= 0))
        ) {
            updatedCompletedSurvey[id] = true;
            let updatedError = { ...error };
            if (updatedError?.["surveyCompletionError"]?.[id]) {
                delete updatedError?.["surveyCompletionError"]?.[id]
                setError(updatedError)
            }
        } else {
            delete updatedCompletedSurvey?.[id]
        }
        setCompletedSurvey(updatedCompletedSurvey);
        return updatedCompletedSurvey?.[id] ? true : false
    }

    const renderSurveyFormKeys = (item = {}) => {
        return <div className='h-full w-full flex items-start'>
            {
                Object.keys(surveyKeys || {})?.map(key => {
                    switch (key) {
                        case "vote": {
                            return <div className='h-full flex flex-col'>
                                <p className='px-3 py-2.5 text-[13px] font-semibold mb-0 whitespace-nowrap'>{surveyKeys?.[key] || ""}</p>
                                <div className='h-full flex flex-col gap-2 p-[12px] border-t border-r border-[#DAE4EE]'>
                                    <GenericCustomButton
                                        leftIcon={<ThumbsUp size={18} />}
                                        label="Like"
                                        onClick={() => mode == "create" && updateSurveyKeys({ key, id: item?.id, value: 'like' })}
                                        className={`rounded border border-[#E5E7EB] px-2 py-2 bg-[#F8FAFB] flex items-center gap-2 duration-200 transition-transform ${mode === 'view' ? '!cursor-not-allowed' : ''} ${mode == "create" ? 'hover:bg-[#51B850] hover:border-[#51B850] hover:text-white' : ''} ${surveyData?.[item?.id]?.["vote"] == "like" ? '!bg-[#51B850] !border-[#51B850] text-white' : 'text-black'}`}
                                    />
                                    <GenericCustomButton
                                        leftIcon={<ThumbsDown size={18} />}
                                        label="Dislike"
                                        onClick={() => mode == "create" && updateSurveyKeys({ key, id: item?.id, value: 'dislike' })}
                                        className={`rounded border border-[#E5E7EB] px-2 py-2 bg-[#F8FAFB] flex items-center gap-2 duration-200 transition-transform ${mode === 'view' ? '!cursor-not-allowed' : ''} ${mode == "create" ? 'hover:bg-[#FF2A28] hover:border-[#FF2A28] hover:text-white' : ''} ${surveyData?.[item?.id]?.["vote"] == "dislike" ? 'bg-[#FF2A28] border-[#FF2A28] text-white' : 'text-black'}`}
                                    />
                                </div>
                            </div>
                        }
                        case "Rating": {
                            return <div className='h-full flex flex-col'>
                                <p className='px-3 py-2.5 text-[13px] font-semibold mb-0 whitespace-nowrap'>{surveyKeys?.[key] || ""}</p>
                                <div className='h-full p-[12px] flex gap-1.5 border-t border-r border-[#DAE4EE]'>
                                    {[<Icons.MoneyRating1 width={35} />, <Icons.MoneyRating2 width={35} />, <Icons.MoneyRating3 width={35} />, <Icons.MoneyRating4 width={35} />, <Icons.MoneyRating5 width={35} />]?.map((ratingIcon, index) => (
                                        <div className={`rounded flex flex-col justify-between gap-0.5 border border-[#DAE4EE] px-1.5 pt-[12px] pb-1.5 ${mode === 'view' ? '!cursor-not-allowed' : 'cursor-pointer'} duration-200 transition-transform ${mode == "create" ? 'hover:scale-105 hover:text-white hover:bg-gradient-to-b hover:from-[#51B850] hover:to-[#51B85080] hover:border-[#51B850]' : ''} ${surveyData?.[item?.id]?.["Rating"] == (index + 1) ? 'text-white bg-gradient-to-b from-[#51B850] to-[#51B85080] border-[#51B850]' : ''}`} onClick={() => mode == "create" && updateSurveyKeys({ key, id: item?.id, value: index + 1 })}>
                                            <p className='text-[13px] font-semibold text-center'>{index + 1}</p>
                                            {ratingIcon}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        case "expectedSalesQty":
                        case "expectedSaleThrough":
                        case "comments": {
                            return <div className='h-full w-full flex flex-col'>
                                <p className='px-3 py-2.5 text-[13px] font-semibold mb-0 whitespace-nowrap'>{surveyKeys?.[key] || ""}</p>
                                <div className='rowInput h-full p-[12px] border-t border-r border-[#DAE4EE]'>
                                    <GenericInput
                                        dependentFieldsObject={mode == "create" ? [key] : null}
                                        type={key === "comments" ? "text" : "number"}
                                        value={surveyData?.[item?.id]?.[key] || ""}
                                        inputKey={key + item?.id}
                                        isDisabled={mode != "create"}
                                        genInputExtraClass={`!h-full !mb-0`}
                                        inputClassName={mode === 'view' ? '!cursor-not-allowed' : ''}
                                        onChange={(e) => mode == "create" && updateSurveyKeys({ key, id: item?.id, value: e?.target?.value })}
                                        errorData={error}
                                        setErrorData={setError}
                                    />
                                </div>
                            </div>
                        }
                        default:
                    }
                })
            }
        </div>
    }

    const renderSurveyForm = () => {

        // if (surveyExpired) {
        //     return (
        //         <div>
        //             <Icons.ErrorWarning />
        //             <p>Survey Expired</p>
        //             <p>{surveyExpired || ""}</p>
        //             <p>Please contact your organization or reach out to us at support@supplymint.com.</p>
        //         </div>

        //     )
        // }

        const contentStyle = {
            margin: 0,
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };

        console.log('stepsPercent', (Object.keys(completedSurvey || {})?.length / (surveyBasicInfo?.["productCount"] || 0)) * 100)

        return (
            <div className='bg-white h-full'>
                {mode == "create" && <div className='px-4 py-3 w-full flex items-center justify-between gap-4 border-b border-[#DAE4EE]'>
                    <div className='flex items-center gap-3'>
                        {/* <div> */}
                        {/* {
                               Array.from({ length: 25 }, (_, i) => i + 1).map((index)=> (
                                    <div className={`h-[23px] w-[3px] shrink-0 bg-[#DAE4EE] rounded-full ${getProgressZoneColor(index, 25)}`}></div>
                                ))
                            } */}

                        <Progress className='stepsProgress' steps={25} trailColor='#DAE4EE' strokeLinecap='round' percent={(Object.keys(completedSurvey || {})?.length / (surveyBasicInfo?.["productCount"] || 0)) * 100} size={{ height: '23px', width: '3px' }} showInfo={false} />

                        {/* </div> */}

                        <p className='mb-0 px-2 py-0.5 rounded-md text-white font-semibold bg-gradient-to-r from-[#FF2A28] to-[#FF6128]'>{Object.keys(completedSurvey || {})?.length} of {surveyBasicInfo?.["productCount"] || 0} Completed</p>
                    </div>
                </div>}

                {totalTime && <ListComponent
                    modalMainClass={mode === 'view' ? ' !max-h-[calc(100vh-320px)] 2xl:!max-h-[calc(100vh-350px)]' : ''}
                    headerKeys={{
                        "collectionName": ""
                    }}
                    itemKeys={{
                        "collectionName": ["collectionName"]
                    }}
                    getListData={() => {
                        return surveyProductList || [];
                    }}
                    renderCustomListCompleteItem={(s, f, item) => {

                        let availableimageKeys = [];

                        ["image_url_front", "image_url_back", "image_url_side", "image_url_look", "image_url_detailed"]?.map(key => {
                            if (item?.[key]) { }
                            availableimageKeys?.push(key);
                        })

                        // availableimageKeys = ["image_url_front", "image_url_back"]

                        return <div
                            id={`surveyItem${item?.id}`}
                            className='min-w-fit w-full flex items-start h-[150px] mb-4 last:mb-0'
                            style={{
                                boxShadow: '0px 0px 9px 0px #ABABAB40'
                            }}
                        >
                            {availableimageKeys?.length == 0 ? <div className='bg-borderGrey shrink-0 w-[215px] h-[150px] flex items-center justify-center p-[12px]'><Image color={'#5F677A'} size={35} /></div> :
                                <Carousel arrows prevArrow={<ChevronLeft color='black' />} nextArrow={<ChevronRight color='black' />} infinite={false} className='w-[215px] h-[150px]'>
                                    {availableimageKeys?.map(key => (
                                        <div className='h-[150px] !flex !items-center !justify-center bg-[#DFE1E6]'>
                                            {item?.[key] ?
                                                // <img className='w-full h-full object-cover object-center' src={'https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-nature-mountain-scenery-with-flowers-free-photo.jpg?w=2210&quality=70'} alt={`${key}`} />

                                                <img className=' w-full h-full object-cover object-center' src={item?.[key]} alt={`${key}`} />
                                                : <Image strokeWidth={1.5} size={30} />
                                            }
                                        </div>
                                    ))}
                                </Carousel>}

                            <div className='w-[180px] shrink-0 h-full flex flex-col border-r border-[#E3E4E4]'>
                                {item?.["collection_code"] ? item?.["collection_name"]?.split('|')?.map(cl => cl ? <p className='mx-3 my-2.5 mb-0 border px-0.5 text-xs font-medium w-fit'>{cl || "-"}</p> : null) : <p className='mx-3 my-2.5 mb-0 text-xs font-medium w-fit'>-</p>}
                                <div className='h-full flex flex-col gap-2 p-[12px]'>
                                    {item?.product_name && <div>
                                        <p className='mb-1 text-xs text-[#74767A]'>Product Name</p>
                                        <p className='text-[13px] font-medium line-clamp-2 text-ellipsis'>{item?.product_name || ""}</p>
                                    </div>}
                                    {mode == "create" && completedSurvey?.[item?.id] ?
                                        <p className='w-fit text-white text-[11px] bg-green-500 px-1 rounded-sm'>Completed</p>
                                        : error?.["surveyCompletionError"]?.[item?.id] ? <p className='w-fit font-medium text-danger text-[11px]'>{error?.["surveyCompletionError"]?.[item?.id] || ""}</p> : null
                                    }
                                </div>
                            </div>

                            {/* Render the input keys */}
                            {renderSurveyFormKeys(item)}
                        </div>
                    }}
                />}
            </div>
        )

    }
    // Function : To submit the survey data
    const submitSurvey = (type = "normal") => {

        let updatedError = type == "autoSubmit" ? {} : { ...error };
        updatedError["surveyCompletionError"] = {}
        let errorKey = null;
        if (type == "normal") {
            surveyProductList?.forEach(item => {
                if (!isSurveyCompleted(surveyData, item?.id)) {
                    if (!errorKey) errorKey = `surveyItem${item?.id}`
                    updatedError["surveyCompletionError"][item?.id] = "Not Completed"
                }
            })
        }

        if (Object.keys(updatedError["surveyCompletionError"]).length > 0 || Object.keys(updatedError).length > 1) {
            document.getElementById(errorKey)?.scrollIntoViewIfNeeded();
            setError(updatedError)
            return;
        } else {
            let payload = [];
            if (!productSurveyId) return;
            // Update the survey using the submit api.

            surveyProductList?.map(item => {
                let obj = {
                    "surveyId": productSurveyId,
                    "surveyName": surveyBasicInfo?.surveyName || "",
                    "productId": item?.product_id || "",
                    "productCode": item?.product_code || "",
                    "productName": item?.product_name || "",
                    "entId": surveyBasicInfo?.entId || "",
                    "orgId": surveyBasicInfo?.orgId || "",
                    "mailingListId": mailingListId || "",
                    "email": email || "",
                    "surveyFeedbackSubmitJson": surveyData?.[item?.id] || {}
                }

                let collectionsName = item?.["collection_name"]?.split('|') || [];

                item?.collection_code?.split('|')?.map((col, index) => {
                    payload?.push({
                        ...obj,
                        "collectionCode": col || "",
                        "collectionName": collectionsName?.[index] || ""
                    })
                })
            })

            console.log("Payload changes new", payload);

            // if (payload?.length > 0) {
            dispatchHandler(dispatch, "submitProductSurveyRequest", payload, getPermissionKey("common", "commonFunctionality", "view"));
            // }
        }

    }

    const startSurvey = () => {
        setSurveyStarted(true);
        interval = setInterval(() => {
            setCurrentTime((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    }

    if (getProductSurveyDetailsData?.isLoading || getSubmittedSurveyDetailsData?.isLoading) {
        return <div className={`w-full flex items-center justify-center ${mode === "create" ? 'h-screen' : 'h-full'}`}>
            <GenericSpinnerLoader />
        </div>
    }

    if (error?.["getAll"]) {
        return <div className={`w-full flex flex-col gap-3 items-center justify-center ${mode === "create" ? 'h-screen' : 'h-full'}`}>
            <div className='p-[1rem] rounded-full bg-[#EA868530]'><TriangleAlert size={40} className='text-[#FD3757]' /></div>
            <p className='text-fontBlack text-xl font-semibold'>Something Went Wrong</p>
            {error?.["getAll"] && <p className='text-fontGrey text-[13px]'>{error?.["getAll"] || 'This is an error message'}</p>}
            <GenericCustomButton
                label={'Retry'}
                leftIcon={<RotateCcw size={18} />}
                onClick={() => getProductSurveyDetails()}
                className={'border mt-[8px] !h-[35px] w-[300px] text-center border-[#E5E7EB] font-medium flex justify-center items-center gap-2 text-fontBlack hover:opacity-75'}
            />
        </div>
    }

    let surveyTimeout = null;

    // if (surveySubmitted) {
    //     return <div className={`w-full flex flex-col gap-4 items-center justify-center ${mode === "create" ? 'h-screen' : 'h-full'}`}>
    //         {
    //             <Lottie
    //                 {...getAnimation()}
    //             />
    //         }
    //         <div>
    //         {surveyMessage ? <p className='text-center font-medium flex items-center gap-2 mb-0'>
    //             <span>{surveyMessage}</span> 
    //             {
    //                 surveyMessage?.split(":")?.[1]?.trim() && <span className='relative cursor-pointer' onClick={() => {
    //                     setCopySuccess(true);
    //                     clearTimeout(surveyTimeout);
    //                     navigator.clipboard.writeText(surveyMessage?.split(":")?.[1]?.trim())
    //                     surveyTimeout = setTimeout(() => setCopySuccess(false), 5000)
    //                 }}>
    //                     <CopyIcon size={18} className='text-black hover:text-primaryColor'/>
    //                     {copySuccess && <div className='absolute bottom-full left-1/2 -translate-x-1/2 mb-1 bg-black px-1 py-0.5 rounded shadow text-white text-xs'>Copied</div>}
    //                 </span>
    //             }
    //         </p> : <p className='mb-0 text-base font-semibold'>Survey Submitted Successfully</p>}
    //         {}
    //         </div>
    //     </div>
    // }

    if (surveyAlreadySubmitted) {
        return <div className={`w-full flex flex-col gap-4 items-center justify-center ${mode === "create" ? 'h-screen' : 'h-full'}`}>
            {
                <Lottie
                    {...getAnimation()}
                />
            }
            <p className='mb-0 text-base font-semibold'>Survey Already Submitted</p>
        </div>
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "surveyExpired":
                return <div className='py-2 text-center flex flex-col gap-3 items-center justify-center text-[#0F172A]'>
                    <div className='mx-[2.5rem] mt-[2.5rem] bg-[#EA868530] p-[1.25rem] rounded-full'><TriangleAlert size={40} color='#F53B57' /></div>
                    <p className='px-[2.5rem] mt-3 mb-0 text-xl font-semibold'>Survey Expired</p>
                    <p className='px-[2.5rem] text-[13px] text-[#575D6A]'>{surveyExpired || ""}</p>
                    <div className='h-[1px] w-full bg-[#D6D9E4] mb-4'></div>
                    <p className='px-[2.5rem] pb-[2.5rem] mb-0 text-[13px] text-[#575D6A] w-5/6 mx-auto'><span className='whitespace-nowrap'>Please contact your organization or reach out to us at</span> <br /><span className='font-bold'>support@supplymint.com</span></p>
                </div>

            case "surveySubmitted":
                // console.log('modeeeeee', mode)
                return <div className={`px-10 py-4 w-full flex flex-col gap-4 items-center justify-center h-full`}>
                    {
                        <Lottie
                            {...getAnimation()}
                        />
                    }
                    <div>
                        {surveyMessage ?
                            <p className='text-center font-medium flex items-center justify-center gap-2 flex-wrap'>
                                {
                                    surveyMessage?.split(":")?.[1]?.trim() ?
                                        <>
                                            <span className='mb-3 text-lg font-semibold'>{surveyMessage?.split(":")?.[0]?.trim()}</span>
                                            {surveyMessage?.split(":")?.[1]?.trim().split(',')?.map((el) => (
                                                <span className='bg-borderGrey text-fontBlack font-medium text-[11px] px-1.5 py-0.5'>{el}</span>
                                            ))}

                                            {/* copy icon */}
                                            {
                                                surveyMessage?.split(":")?.[1]?.trim() && <Tooltip overlayClassName='!p-[0]' overlayStyle={{ fontSize: '12px' }} title={copySuccess ? 'Copied!' : 'Copy'}><span className='relative cursor-pointer' onClick={() => {
                                                    setCopySuccess(true);
                                                    clearTimeout(surveyTimeout);
                                                    navigator.clipboard.writeText(surveyMessage?.split(":")?.[1]?.trim())
                                                    surveyTimeout = setTimeout(() => setCopySuccess(false), 5000)
                                                }}>
                                                    <CopyIcon size={18} className='text-black hover:text-primaryColor' />
                                                    {/* {copySuccess && <div className='absolute bottom-full left-1/2 -translate-x-1/2 mb-1 bg-black px-1 py-0.5 rounded shadow text-white text-xs'>Copied</div>} */}
                                                </span></Tooltip>
                                            }
                                        </>
                                        :
                                        surveyMessage
                                }
                            </p>
                            // {/* <span>{surveyMessage?.split(":")?.[1]?.trim() && surveyMessage?.split(":")?.[0]?.trim()}<span></span></span>  */}
                            : <p className='mb-0 text-lg font-semibold'>Survey Submitted Successfully</p>}
                    </div>
                </div>
            case "loadDefaultData": {
                return <GenericConfirmationModal {...getParentChildProps()} />;
            }

            default:
        }
    }

    const getParentChildProps = () => {
        switch (selectedModal) {
            case "surveyExpired": {
                return {
                    postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 0, margin: 0, background: 'white' },
                    dimensions: { width: "45vw", height: "fit-content", minHeight: '350px', maxHeight: '70vh', minWidth: '500px', maxWidth: '600px', overflowY: 'auto', background: "transparent" },
                    closeModal: () => { },
                }
            }
            case "surveySubmitted": {
                return {
                    postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 0, margin: 0, background: 'white' },
                    dimensions: { width: "37vw", height: "55vh", minHeight: '350px', maxHeight: '450px', minWidth: '500px', maxWidth: '600px', overflowY: 'auto', background: "transparent" },
                    closeModal: () => { },
                }
            }
            case "loadDefaultData": return {
                animationType: 'bottom',
                // postitionProps: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: 0, position: "fixed", borderRadius: 4 },
                parentModalOuterMostClassName: 'flex items-center justify-center',
                postitionProps: { position: 'relative' },
                dimensions: { width: "36vw", height: "36vh", maxHeight: '330px', maxWidth: '540px' },
                icon: () => { return <Icons.ConfirmationModalImg /> },
                closeModal: () => {
                    removeValueFormTheDB()
                    setSelectedModal(false)
                },
                labelComponent: 'We found your latest saved information. Would you like to keep going with it?',
                buttons: [
                    {
                        label: "No, Reset",
                        className: "no-wait-btn",
                        onClick: () =>  {
                            removeValueFormTheDB()
                            setSelectedModal(false)
                        }
                    },
                    {
                        label: "Yes, proceed",
                        className: "generic-approve-btn",
                        onClick: () => getData(true)
                    }
                ]
            }
            default:
        }
    }

    const renderFeedbackInfo = () => {
        return (
            <div className='flex items-start gap-5'>
                <div>
                    <p className='font-semibold'>Email</p>
                    <p className='mb-0 text-xs'>{selectedData?.email || ""}</p>
                </div>
                <div>
                    <p className='font-semibold'>Employee Name</p>
                    <p className='mb-0 text-xs'>{selectedData?.employeeName || ""}</p>
                </div>
                <div>
                    <p className='font-semibold'>Employee Code</p>
                    <p className='mb-0 text-xs'>{selectedData?.employeeCode || ""}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='completeProductSurvey h-full flex flex-col text-fontBlack'>

                {mode == "view" && <GenericFormHeaderComponent
                    label={{
                        contentPrimary: "View Product Survey Feedback",
                    }}

                    closeModal={props.closeModal}
                />}

                <div
                    style={{
                        boxShadow: '0px 4px 20px -16px #8B93BB'
                    }}
                    className='bg-white px-4 py-3 flex items-center justify-between gap-4 w-full sticky top-0 z-10'
                >
                    <div className='sfmi-head'>
                        {bootConfig?.orgLogo ? <img className='h-[35px] w-auto' src={bootConfig?.orgLogo} /> :
                            <Icons.SupplymintRIcon />}
                    </div>


                    {mode == "create" && <GenericCustomButton
                        onClick={() => {
                            if (!surveyStarted) {
                                startSurvey()
                            } else {
                                submitSurvey();
                            }
                        }}
                        label={!surveyStarted ? "Start Survey" : "Submit Survey"}
                        // type='primary'
                        disabled={surveyExpired || currentTime <= 0 || surveyProductList?.length == 0}
                        className={'!rounded-none text-[15px] !h-[38px] px-5 !bg-[#51B850] font-medium text-white hover:opacity-75'}
                    />}
                </div>

                <div className='bg-[#F3F4F9] px-4 py-3 space-y-3 h-full flex flex-col'>

                    {/* Render the product survey information here */}
                    <div className='bg-white px-4 py-3 w-full flex items-center justify-between gap-4'>
                        <div className='w-full flex items-center justify-between gap-4'>
                            <section className='space-y-2'>
                                <p className='mb-0 text-xs font-semibold'>PRODUCT SURVEY</p>
                                <div className='flex items-center gap-2'>
                                    <SquareLibrary className='text-primary' />
                                    <p className='mb-0 text-lg font-semibold'>{surveyBasicInfo?.["surveyName"] || "-"}</p>
                                </div>
                                <p className='mb-0 px-1.5 py-0.5 w-fit text-xs font-medium rounded-full bg-primary_w_less_opacity border border-primaryLight'>{mode == "create" ? surveyBasicInfo?.["productCount"] || 0 : surveyProductList?.length || 0} Products</p>
                            </section>
                            {/* <section>
                                <GenericInput
                                    inputKey="collectionDropdown"
                                    iconLeft={<Icons.SquareLibrary />}
                                    value
                                />
                            </section> */}
                            {/* Render the view information here */}
                            {mode == "view" && renderFeedbackInfo()}
                        </div>

                        {mode == "create" && <div className='max-w-[250px] space-y-2'>
                            <div
                                className='px-2.5 py-1.5 flex items-center gap-3 rounded-md'
                                style={{
                                    boxShadow: '0px 2px 12px 0px #93939340'
                                }}
                            >
                                <Progress className={`timerProgress ${surveyStarted ? 'timeStarted' : ''}`} steps={15} trailColor='#DAE4EE' strokeLinecap='round' percent={(currentTime / totalTime) * 100} size={{ height: '18px', width: '3px' }} showInfo={false} />
                                <p className='mb-0 text-base font-semibold flex items-center gap-1.5'><span className='w-[70px]'>{renderRemainingTime()}</span> <span className='text-[11px] font-normal'>Time Left</span></p>
                            </div>
                            <p className='mb-0 text-[#4A5D7A] text-[11px]'>This survey is valid for a limited time and will auto-submit when time runs out.</p>
                        </div>}
                    </div>

                    {!surveyExpired && renderSurveyForm()}

                </div>

            </div>
            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent= {getChildComponent}
                closeModal={() => { setSelectedModal(false) }}
            />}
        </>
    )
}

export default CompleteProductSurvey