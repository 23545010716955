import { CONFIG } from '../../config/index';

export const digiProcStore = [
    {
        title: "getAavailablekeysData",
        actionPrefix: 'getAavailablekeys',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/digiproc/get/availablekeys`,
        }
    },
    {
        title: "procGenericApiData",
        actionPrefix: 'procGenericApi',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "getOtbData",
        actionPrefix: 'getOtb',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/digiproc/calculate/multiple/otb`,
        }
    },
    {
        title: "newMultipleLineItemData",
        actionPrefix: 'newMultipleLineItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/digiproc/get/multiple/lineitem/charges`,
        }
    },
    {
        title: "apiAfterSelectionData",
        actionPrefix: 'apiAfterSelection',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                delete payload?.uniqueKey;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "indentBasePoData",
        actionPrefix: 'indentBasePo',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "poViewImagesData",
        actionPrefix: 'poViewImages',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/view/images`,
        }
    },
    {
        title: "markUpData",
        actionPrefix: 'markUp',
        message: 'Request Completed Successfully',
        responseData: {
            successDataHandler: (data, payload) => {
                console.log("Checkqr2r",data.data);
                if(data.data.data)
                    data.data.data["payloadSource"] = payload?.source === "" ? "rate" : payload?.source;
                return data.data.data;
            }
        },
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/calculate/actual/markup/article/{data}`,
        }
    },
    {
        title: "getSizeColorData",
        actionPrefix: 'getSizeColor',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                delete payload?.uniqueKey;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "procSubmitData",
        actionPrefix: 'procSubmit',
        message: 'Request Completed Successfully',
        requestData: {
            getExtraHeaderWithPayload: (payload) => {
                return { "X-REQUEST-ID": payload?.uuid }
            },
            updatePayload: ({ ...payload }) => {
                delete payload?.formType;
                delete payload?.uuid;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/digiproc/multiple/article/${payload.formType}/submit`,
        }
    },
    {
        title: "genericSelectItemData",
        actionPrefix: 'genericSelectItem',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                delete payload?.uniqueKey;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "genGetApiV2Data",
        actionPrefix: 'genGetApiV2',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                delete payload?.uniqueKey;
                if (payload?.payloadType == "array") {
                    payload = payload?.arrayPayload
                    return payload;
                }
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "po_approve_rejectData",
        actionPrefix: 'po_approve_reject',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/update/status`,
        }
    },
    {
        title: "downloadDataData",
        actionPrefix: 'downloadData',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl
                delete payload?.summaryApiUrl
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/download/module/data?fileType=XLS&module=${payload?.module}&isAllData=${payload?.isAllData}&isOnlyCurrentPage=${payload?.isOnlyCurrentPage}`,
        }
    },
    {
        title: "discardPoPiDataData",
        actionPrefix: 'discardPoPiData',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.value
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/${payload?.value}/discard`
        }
    },
    {
        title: "getLineItemData",
        actionPrefix: 'getLineItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/detail/v2`
        }
    },
    {
        title: "updateViewOrderExtensionDateData",
        actionPrefix: 'updateViewOrderExtensionDate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.PO}/extend/validity`
        }
    },
    {
        title: "piUpdateData",
        actionPrefix: 'piUpdate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/update/status`
        }
    },
    {
        title: "gen_IndentBasedData",
        actionPrefix: 'gen_IndentBased',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                return {
                    indentNo: [payload?.indentNo],
                    isIndentBaseCreatePO: payload?.isLoadIndent !== undefined ? payload?.isLoadIndent : false
                }
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/indent/base`
        }
    },
    {
        title: "getPiLineItemData",
        actionPrefix: 'getPiLineItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/get/detail/v2`
        }
    },
    {
        title: "uploadManageMrpExcelData",
        actionPrefix: 'uploadManageMrpExcel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/upload/excel`
        }
    },
    {
        title: "deleteMrpRangeData",
        actionPrefix: 'deleteMrpRange',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/mrpRange/delete`
        }
    },
    {
        title: "getModalMrpData",
        actionPrefix: 'getModalMrp',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/article/data?hl4code=${payload?.hl4code}`
        }
    },
    {
        title: "editMrpRangeData",
        actionPrefix: 'editMrpRange',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/update/MrpRange`
        }
    },
    {
        title: "createMrpData",
        actionPrefix: 'createMrp',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/create/level`
        }
    },
    {
        title: "uploadManageOtbExcelData",
        actionPrefix: 'uploadManageOtbExcel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/otb/manage/upload/excel`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "updateManageOtbData",
        actionPrefix: 'updateManageOtb',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/otb/manage/update/status`
        }
    },
    {
        title: "deleteManageOtbData",
        actionPrefix: 'deleteManageOtb',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/otb/manage/delete/otb`
        }
    },
    {
        title: "systemConfigData",
        actionPrefix: 'systemConfig',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                return {
                    type: payload?.type,
                    schema: payload?.schema || undefined
                }
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/data?type`
        }
    },
    {
        title: "submitSystemConfigData",
        actionPrefix: 'submitSystemConfig',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/otb/config`
        }
    },
    {
        title: "getProcMasterInfoData",
        actionPrefix: 'getProcMasterInfo',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/digiproc/get/basic/master/info`
        }
    },
    {
        title: "getAllFabricData",
        actionPrefix: 'getAllFabric',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/catalogue/get/all/fabric`
        }
    },
    {
        title: "createUpdateFabricNominationData",
        actionPrefix: 'createUpdateFabricNomination',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/fabric/nomination/submit`
        }
    },
    {
        title: "deleteFabricNominationData",
        actionPrefix: 'deleteFabricNomination',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/fabric/nomination/delete`
        }
    },
    {
        title: "editManageOtbData",
        actionPrefix: 'editManageOtb',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/otb/manage/update/config/Data`
        }
    },
    {
        title: "createNewManageOtbData",
        actionPrefix: 'createNewManageOtb',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/otb/manage/config/create`
        }
    },
    {
        title: "piPoDashboardFilterData",
        actionPrefix: 'piPoDashboardFilter',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl
                return payload
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload?.apiUrl}`
        }
    },
    {
        title: "piDashBottomData",
        actionPrefix: 'piDashBottom',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/procurement/dashboard/get/actionable/data`
        }
    },

    {
        title: "poErpRetryData",
        actionPrefix: 'poErpRetry',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/erp/retry`
        }
    },
    {
        title: "updateItemUdfMappingData",
        actionPrefix: 'updateItemUdfMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/insertupdate/itemudfmapping`
        }
    },
    // {
    //     title: "itemCatDescUdfData",
    //     actionPrefix: 'itemCatDescUdf',
    //     message: 'Request Completed Successfully',
    //     requestData: {
    //         buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/itemudfmapping`
    //     }
    // },
    {
        title: "catDescDropdownData",
        actionPrefix: 'catDescDropdown',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/active/mapping?hl3Code=${payload.hl3Code}`
        }
    },
    {
        title: "itemCatDescUdfData",
        actionPrefix: 'itemCatDescUdf',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                let load = {
                    type: payload.type == "" ? 1 : payload.type,
                    pageNo: payload.no == undefined ? 1 : payload.no,
                    description: payload.description,
                    ispo: payload.ispo == undefined ? "" : payload.ispo,
                    search: payload.search,
                    cat_desc_udf: payload.itemUdfType,
                    hl1code : payload.hl1Code,
                    hl2code : payload.hl2Code,
                    hl3code: payload.hl3Code,
                    hl4code: payload.hl4Code,
                    hl4Name: payload.hl4Name,
                    searchBy: payload.searchBy == undefined ? "startWith" : payload.searchBy
                }
                return load;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/itemudfmapping`
        }
    },
    {
        title: "getCnameListData",
        actionPrefix: 'getCnameList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                let load = {
                    pageNo: payload.no,
                    type: payload.type == "" ? 1 : payload.type,
                    search: payload.search,
                    header: payload.udfType,
                    searchBy: payload.searchBy == undefined ? "startWith" : payload.searchBy

                }
                return load;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/catdescudfname`
        }
    },
    {
        title: "poArticleData",
        actionPrefix: 'poArticle',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/custom/article/hierarchy/data`
        }
    },
    {
        title: "poUdfMappingData",
        actionPrefix: 'poUdfMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/setudfmapping`
        }
    },
    {
        title: "updateUdfMappingData",
        actionPrefix: 'updateUdfMapping',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                let load = {
                    "isInsert": payload.isInsert,
                    "resultUDFType": payload.udfMappingData,
                }
                return load;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/insertupdate/setudfmapping`
        }
    },
    {
        title: "procDataMappingData",
        actionPrefix: 'procDataMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/call/functions`
        }
    },
    {
        title: "mappingExcelExportData",
        actionPrefix: 'mappingExcelExport',
        message: 'Request Completed Successfully',
        requestData: {
            method : "GET",
            buildUrl: (payload) =>  `${CONFIG.BASE_URL}/admin/po/export/mappings/data?catDescUdfType=${payload?.catDescUdfType}&hl1Code=${payload?.hl1Code}&hl2Code=${payload?.hl2Code}&hl3Code=${payload?.hl3Code}`
        }
    }, 
    {
        title: "getSelectedItembarcodeData",
        actionPrefix: 'getSelectedItembarcode',
        errorMessage : "Failed to get the selected item data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.BASE_URL}/admin/po/get/itembarcode`
        }
    },
    {
        title: "systemConfigGetSdcConfigData",
        actionPrefix: 'systemConfigGetSdcConfig',
        errorMessage : "Failed to get the data!",
        requestData: {
            method: 'GET',
            buildUrl: (payload) =>  `${CONFIG.BASE_URL}/system/config/get/sdc/config/data/?type=${payload?.type}`
        }
    },
    {
        title: "getPiCountPerData",
        actionPrefix: 'getPiCountPer',
        message: 'Request Completed Successfully',
        errorMessage : "Failed to get data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.SM_BACKEND_BASE_URL}/purchase/dashboard/pi/po/data`
        }
    },
    {
        title: "getPoCountPerData",
        actionPrefix: 'getPoCountPer',
        message: 'Request Completed Successfully',
        errorMessage : "Failed to get data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.SM_BACKEND_BASE_URL}/purchase/dashboard/pi/po/data`
        }
    },
    {
        title: "getPiSummaryData",
        actionPrefix: 'getPiSummary',
        message: 'Request Completed Successfully',
        errorMessage : "Failed to get data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.SM_BACKEND_BASE_URL}/purchase/dashboard/pi/po/data`
        }
    },
    {
        title: "getPiPoCreationStatsData",
        actionPrefix: 'getPiPoCreationStats',
        message: 'Request Completed Successfully',
        errorMessage : "Failed to get data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.SM_BACKEND_BASE_URL}/purchase/dashboard/pi/po/data`
        }
    },
    {
        title: "getPerformanceAnalysisData",
        actionPrefix: 'getPerformanceAnalysis',
        message: 'Request Completed Successfully',
        errorMessage : "Failed to get data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.SM_BACKEND_BASE_URL}/purchase/dashboard/pi/po/data`
        }
    },
    {
        title: "getDefaultArticleDetailsData",
        actionPrefix: 'getDefaultArticleDetails',
        errorMessage : "Failed to get the data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.BASE_URL}/admin/digiproc/get/custom/article/hierarchy/data`
        }
    },
    {
        title: "getDefaultArticleDetailsData",
        actionPrefix: 'getDefaultArticleDetails',
        errorMessage : "Failed to get the data!",
        requestData: {
            buildUrl: (payload) =>  `${CONFIG.BASE_URL}/admin/digiproc/get/custom/article/hierarchy/data`
        }
    },
    {
        title: "uploadProcIcodeData",
        actionPrefix: 'uploadProcIcode',
        message: 'Icodes uploaded successfully!',
        errorMessage : `Failed to upload Icodes!`,
        requestData: {
            buildUrl: (payload) =>`${CONFIG.BASE_URL}/admin/digiproc/get/itembarcode/upload`,
        }
    },
    {
        title: "updatePiStatusData",
        actionPrefix: 'updatePiStatus',
        message: 'Status updated successfully!',
        errorMessage : `Failed to update status!`,
        requestData: {
            buildUrl: (payload) =>`${CONFIG.SM_BACKEND_BASE_URL}/purchase/procurement/update/pi/status`,
        }
    },
]