import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './components/helperComponents/ErrorBoundary';
import { Provider } from 'react-redux';
import createStore from './redux/store'
import { CacheChecker } from './helper/components/CacheChecker';
import * as Sentry from "@sentry/react";

let lastCapturedPath = "";

Sentry.init({
    dsn: "https://8b5cf00a6b58f1c2b064faffa9474ac2@o4508380007956480.ingest.us.sentry.io/4508380023095296",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://prod.supplymint.com/'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSendTransaction: (event) => {
        const currentHash = window.location.hash;
        // Remove 'otherInfo' from the hash
        const cleanHash = cleanHashWithoutKey(currentHash, "otherInfo");
        if (cleanHash !== lastCapturedPath) {
            lastCapturedPath = cleanHash;
            return event; // Capture transaction
        }

        return null; // Skip transaction
    },
});

function cleanHashWithoutKey(hash, keyToRemove) {
    if (!hash.startsWith("#")) return hash;

    // Remove the `#` prefix and split into key-value pairs
    const queryParams = hash.substring(1).split("&").filter(Boolean);

    // Rebuild the hash without the specified key
    const filteredParams = queryParams.filter(
        (param) => !param.startsWith(`${keyToRemove}=`)
    );

    // Return the cleaned hash
    return `#${filteredParams.join("&")}`;
}

// Hide al console.log inside this system
if (sessionStorage?.getItem("SHOW_CONSOLE_MSG") != "TRUE") {
    if (process.env.REACT_APP_SHOW_CONSOLE_LOGS != "TRUE")
        console.log = console.warn = console.error = () => { };
}

const store = createStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <CacheChecker />
        <ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
