import tourData from './tourConfig.json';

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_RETRY_DELAY = 200; // 200 milliseconds

/**
 * Waits for all elements in the given selectors to be available in the DOM.
 * @param {string[]} selectors - Array of CSS selectors to query.
 * @param {number} retries - Maximum number of retries.
 * @param {number} delay - Delay between retries in milliseconds.
 * @returns {Promise<HTMLElement[]>} - Resolves with the array of elements found or an empty array.
 */
const waitForElements = (selectors, retries = DEFAULT_MAX_RETRIES, delay = DEFAULT_RETRY_DELAY) => {
    return new Promise((resolve) => {
        const tryQuery = (remainingRetries) => {
            const elements = selectors.map(selector => document.querySelector(selector) || null); // Use `null` if not found

            const allElementsChecked = elements.every(el => el !== null);

            if (allElementsChecked) {
                return resolve(elements); // All elements found
            }

            if (remainingRetries <= 0) {
                console.warn('Some elements could not be found in time:', selectors);
                return resolve(elements); // Resolve with `null` values for missing elements
            }

            setTimeout(() => tryQuery(remainingRetries - 1), delay); // Retry after delay
        };

        tryQuery(retries);
    });
};



/**
 * Fetches and filters the tour steps for the given module based on available elements in the DOM.
 * @param {string} module - Name of the module to get steps for.
 * @returns {Promise<Object[]>} - Resolves with the filtered tour steps.
 */
export const getTourSteps = async (module) => {
    const changeModule = {
        catalogueHistory: "tableComponent",
        viewSalesOrders: "tableComponent"
    }
    const moduleSteps = tourData?.[changeModule?.[module] || module] || [];
    const selectors = moduleSteps.map(step => step.element);
    try {
        const elements = await waitForElements(selectors);
        // Filter out steps where the corresponding element is not found
        return moduleSteps.filter((step, index) => elements[index] !== null);
    } catch (error) {
        console.error(`Error fetching tour steps for module "${module}":`, error);
        return []; // Return empty array if elements are not found
    }
};
